import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../common/components/CustomDataTable";
import { ApiGet } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import ProductBox from "../../../assets/productCreation/pro.png";
import DraftProduct from "./DraftProduct";
import { CustomDraftProductSearchFilter } from "../common/components/CustomSearchFilter";

const DraftProductGrid = ({ fetchProduct }) => {
  const initialValue = {
    product_name: "",
    product_display_name: "",
    max_price: "",
    min_price: "",
    rowsPerPage: 5,
    page: 1,
    limit: 5,
    orderBy: "DESC",
    shortBy: "",
  };
  const draftProductGridColumns = [
    {
      name: "Name",
      selector: (row) => (row.product_name ? row.product_name : "-"),
    },
    {
      name: "Price(₹)",
      sortField: "base_price",
      selector: (row) =>
        row?.product_base_price
          ? row?.product_base_price.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })
          : "-",
      right: true,
    },
    {
      name: "Display Name",
      selector: (row) =>
        row?.product_display_name ? row?.product_display_name : "-",
    },
    {
      name: "Action",
      selector: (row) => row.id,
      center: true,
      cell: (row) => (
        <DraftProduct
          draftProductData={row}
          fetchProduct={debouncedFetchDraftProducts}
        />
      ),
    },
  ];
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchFilters, setSearchFilters] = useState(initialValue);

  const payloadRef = useRef();

  const debouncedFetchDraftProducts = async (data = {}) => {
    if (payloadRef.current === JSON.stringify(data)) {
      return;
    } else {
      setLoading(true);
      payloadRef.current = JSON.stringify(data);
      const response = await ApiGet(
        Object.keys(data)?.length
          ? `admin/get-product-in-draft?page=${data.page}&limit=${data.limit}&shortBy=${data.shortBy}&orderBy=${data.orderBy}&productDisplayName=${data.product_display_name}&productName=${data.product_name}`
          : `admin/get-product-in-draft?page=${searchFilters.page}&limit=${searchFilters.limit}&shortBy=${searchFilters.shortBy}&orderBy=${searchFilters.orderBy}&productDisplayName=${searchFilters.product_display_name}&productName=${searchFilters.product_name}`
      );

      if (response.code == 200) {
        setRowData(response.data?.data);
        setTotal(response.data?.total);
      } else {
        toast.error(response?.message);
        setRowData([]);
        setTotal(0);
      }
      setLoading(false);
    }
  };

  const debouncedHandleSort = ({ sortField }) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        shortBy: sortField,
        orderBy: prev.orderBy === "DESC" ? "ASC" : "DESC",
      };
      debouncedFetchDraftProducts(data);
      return data;
    });
  };

  const handlePageChange = (page) => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: page };
      debouncedFetchDraftProducts(data);
      return data;
    });
  };

  const handlePerRowsChange = (limit, page) => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: page, limit: limit };
      debouncedFetchDraftProducts(data);
      return data;
    });
  };

  const handleSearch = () => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: 1 };

      debouncedFetchDraftProducts(data);
      return data;
    });
  };

  const onResetHandle = () => {
    setSearchFilters((prev) => {
      debouncedFetchDraftProducts(initialValue);
      return initialValue;
    });
  };

  useEffect(() => {
    setLoading(true);
    debouncedFetchDraftProducts(searchFilters);
  }, []);

  return (
    <div>
      <CustomDataTable
        title={
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center gap-2 ">
              <img style={{ width: "30px", height: "30px" }} src={ProductBox} />
              <p className="text-l font-bold">Draft Products</p>
            </div>
          </div>
        }
        data={rowData}
        columns={draftProductGridColumns}
        loading={loading}
        rowsPerPage={searchFilters.rowsPerPage}
        totalRows={total}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        handleSort={debouncedHandleSort}
        subHeader={true}
        subHeaderComponent={
          <CustomDraftProductSearchFilter
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            handleSubmit={handleSearch}
            onResetHandle={onResetHandle}
          />
        }
      />
    </div>
  );
};

export default DraftProductGrid;
