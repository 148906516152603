import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import InputLabel from "../../../components/form/InputLabel";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export function CustomInputDate({
  loading = false,
  requiredMark = "",
  starMark = false,
  label = "",
  maxDate = null,
  minDate = null,
  labelLink = null,
  placeholder = "Select date",
  attrs = {},
  onEnter = () => {},
  error = "",
  isError = false,
  validateError,
  valdiateOnChange,
  format = "dd-MM-yyyy",
  value,
  calDefValue,
  style,
}) {
  const [selectedDate, setSelectedDate] = useState(value || null);
  const [showError, setShowError] = useState(null);

  useEffect(() => {
    setShowError(isError);
  }, [isError]);

  const handleChange = (newValue) => {
    setSelectedDate(newValue);
    if (attrs?.onChange) attrs.onChange(newValue);
  };

  return (
    <div className="custom-datepicker-container">
      <label className="flex text-xs text-gray-700 font-bold justify-left pt-1 mb-1">
        {label}
      </label>
      <div className="mt-0 relative">
        <DatePicker
          onChange={handleChange}
          value={selectedDate}
          format={format}
          maxDate={maxDate}
          minDate={minDate}
          className="custom-datepicker"
          calendarClassName="custom-calendar"
          clearIcon={null}
          calendarIcon={<span className="calendar-icon">📅</span>}
        />
      </div>
      {showError && <p className="error-message">This field is required</p>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { loading: state.loading };
};

export default connect(mapStateToProps)(CustomInputDate);
