import { useRef } from "react";
import * as Yup from "yup";

//styling for components
export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#D3D3D3",
    },
  },
  headCells: {
    style: {
      borderRightStyle: "solid",
      borderRightWidth: "1px",
      borderRightColor: "#D3D3D3",
      backgroundColor: "#393939",
      color: "white",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      borderRightStyle: "solid",
      borderRightWidth: "1px",
      borderRightColor: "#D3D3D3",
      fontWeight: "500",
    },
  },
  pagination: {
    style: {
      backgroundColor: "#f8f9fa",
      color: "#333",
      borderTop: "1px solid #ddd",
      fontWeight: "500",
    },
    pageButtonsStyle: {
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      padding: "5px",
      margin: "0 5px",
      cursor: "pointer",
    },
  },
};

export const saveToDraftsButtonStyles = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px 8px",
  minWidth: "100px",
  height: "32px",
  backgroundColor: "white",
  color: "#d32f2f",
  fontSize: "0.8rem",
  fontWeight: 600,
  borderRadius: "5px",
  border: "1px solid #d32f2f",
  textTransform: "none",
  transition: "0.2s",
  "&:hover": { backgroundColor: "#fce4e4", color: "#d32f2f" },
  "&:active": { backgroundColor: "#f2b2b1", color: "#9a0007" },
};

export const addProductButtonStyles = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px 8px",
  minWidth: "100px",
  height: "32px",
  backgroundColor: "#d32f2f",
  color: "white",
  fontSize: "0.8rem",
  fontWeight: 600,
  borderRadius: "5px",
  textTransform: "none",
  "&:hover": { backgroundColor: "#b71c1c" },
  "&:active": { backgroundColor: "#9a0007" },
};

export const refreshIconStyles = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px 8px",
  minWidth: "30px",
  height: "32px",
  backgroundColor: "#d32f2f",
  color: "white",
  fontSize: "0.8rem",
  fontWeight: 600,
  borderRadius: "5px",
  textTransform: "none",
  transition: "0.2s",
  "&:hover": { backgroundColor: "#b71c1c" },
  "&:active": { backgroundColor: "#9a0007" },
};

export const searchButtonStyles = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px 8px",
  minWidth: "80px",
  height: "32px",
  backgroundColor: "#d32f2f",
  color: "white",
  fontSize: "0.8rem",
  fontWeight: 600,
  borderRadius: "5px",
  textTransform: "none",
  transition: "0.2s",
  "&:hover": { backgroundColor: "#b71c1c" },
  "&:active": { backgroundColor: "#9a0007" },
};

//Initial values, validations and schema

export const productCreationInitialValues = {
  productName: "",
  productDisplayName: "",
  tenureType: "",
  tenure: "",
  paymentMode: false,
  sellableToIndividual: "",
  gst: null,
  productBasePrice: null,
  productMRP: null,
  membersCovered: null,
  productDescription: "",
  productTypeCategory: "",
  paymentPartner: "",
  subProducts: {
    DOC: {
      DOC: false,
      membersCovered: "",
      maxConsultantCount: "",
      vendorId: "",
      tenure: "",
      tenureType: "",
      vendorPlanId: "",
      productBenefitId: [],
    },
    HC: {
      HC: false,
      membersCovered: "",
      vendorId: "",
      hospiSlab: {
        hospitalSlab1_days: "",
        hospitalSlab1_amount: "",
        hospitalSlab2_days: "",
        hospitalSlab2_amount: "",
        hospitalSlab3_days: "",
        hospitalSlab3_amount: "",
        hospitalSlab4_days: "",
        hospitalSlab4_amount: "",
      },
      icuSlab: {
        icuSlab1_days: "",
        icuSlab1_amount: "",
        icuSlab2_days: "",
        icuSlab2_amount: "",
      },
      productBenefitId: [],
    },
    AC: {
      AC: false,
      membersCovered: "",
      vendorId: "",
      eligibleForClaim: "",
      claimAmount: "",
      productBenefitId: [],
      tenure: "",
      tenureType: "",
      claimDocuments: [],
    },

    NUTR: {
      NUTR: false,
      membersCovered: "",
      maxConsultantCount: "",
      vendorId: "",
      tenure: "",
      tenureType: "",
      vendorPlanId: "",
      productBenefitId: [],
    },
    CLOAN: {
      CLOAN: false,
      membersCovered: "",
      vendorId: "",
      amountType: "factor",
      factorAmount: "",
      lowerLimit: "",
      upperLimit: "",
      productBenefitId: [],
    },
    SKIN: {
      SKIN: false,
      membersCovered: "",
      maxConsultantCount: "",
      vendorId: "",
      tenure: "",
      tenureType: "",
      vendorPlanId: "",
      productBenefitId: [],
    },
  },
  labTestID: [],
  vendorProductAddOnBenefit: {
    AMBULANCE_ON_CALL: {
      productAddOnBenefitId: null,
      numberOfUses: null,
    },
    FITNESS: { productAddOnBenefitId: null, numberOfUses: null },
    MENTAL_WELLNESS: { productAddOnBenefitId: null, numberOfUses: null },
  },
  productBenefitId: {},
};

export const productCreationValidationSchema = Yup.object().shape({
  productName: Yup.string()
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 _-]{1,48}[A-Za-z0-9]$/,
      "Invalid product name format"
    )
    .required("Product name is required"),

  productDisplayName: Yup.string()
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 _-]{1,48}[A-Za-z0-9]$/,
      "Invalid product display name format"
    )
    .required("Product display name is required"),

  tenure: Yup.number()
    .typeError("Tenure must be a number")
    .required("Tenure is required")
    .test("max-tenure", function (value) {
      const { tenureType } = this.parent;

      if (tenureType === "DAYS" && value > 365) {
        return this.createError({ message: "Tenure cannot exceed 365 days" });
      }
      if (tenureType === "MONTHS" && value > 60) {
        return this.createError({ message: "Tenure cannot exceed 60 months" });
      }
      if (tenureType === "YEARS" && value > 5) {
        return this.createError({ message: "Tenure cannot exceed 5 years" });
      }
      return true;
    }),

  tenureType: Yup.string().nullable().required("Tenure type is required"),
  gst: Yup.number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required("GST is required"),
  productBasePrice: Yup.number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required("Product base-price is required"),
  paymentPartner: Yup.string().required("Payment partner is required"),
  productDescription: Yup.string().required("Product description is required"),
  productTypeCategory: Yup.string().required(
    "Product type category is required"
  ),
  sellableToIndividual: Yup.number().required(
    "Show in aiqahealth app is required"
  ),
  membersCovered: Yup.number().required("Member covered is required"),

  // SubProducts Validation

  subProducts: Yup.object().shape({
    DOC: Yup.object().shape({
      DOC: Yup.boolean(),
      membersCovered: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("DOC", {
          is: true,
          then: (schema) => schema.required("DOC members covered is required"),
        }),
      maxConsultantCount: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("DOC", {
          is: true,
          then: (schema) =>
            schema.required("DOC max consultant count is required"),
        }),
      vendorId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("DOC", {
          is: true,
          then: (schema) => schema.required("DOC vendor is required"),
        }),
      tenure: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("DOC", {
          is: true,
          then: (schema) => schema.required("DOC tenure is required"),
        }),
      tenureType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("DOC", {
          is: true,
          then: (schema) => schema.required("DOC tenure Type is required"),
        }),
      vendorPlanId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("DOC", {
          is: true,
          then: (schema) => schema.required("DOC vendor plan id is required"),
        }),
      productBenefitId: Yup.array(),
    }),

    HC: Yup.object().shape({
      HC: Yup.boolean(),
      membersCovered: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("HC", {
          is: true,
          then: (schema) =>
            schema.required("Hospi cash members covered is required"),
        }),
      vendorId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("HC", {
          is: true,
          then: (schema) => schema.required("Hospi cash vendor is required"),
        }),
      hospiSlab: Yup.object().shape({
        hospitalSlab1_days: Yup.string()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab1_amount: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab2_days: Yup.string()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab2_amount: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab3_days: Yup.string()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab3_amount: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab4_days: Yup.string()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        hospitalSlab4_amount: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        productBenefitId: Yup.array(),
      }),
      icuSlab: Yup.object().shape({
        icuSlab1_days: Yup.string()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        icuSlab1_amount: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        icuSlab2_days: Yup.string()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
        icuSlab2_amount: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      }),
      tenure: Yup.number().when("HC", {
        is: true,
        then: (schema) =>
          schema
            .required("Hospi cash tenure is required")
            .test("max-tenure", function (value) {
              const { tenureType } = this.parent;

              if (tenureType === "DAYS" && value > 365) {
                return this.createError({
                  message: "Hospi cash tenure cannot exceed 365 days",
                });
              }
              if (tenureType === "MONTHS" && value > 60) {
                return this.createError({
                  message: "Hospi cash tenure cannot exceed 60 months",
                });
              }
              if (tenureType === "YEARS" && value > 5) {
                return this.createError({
                  message: "Hospi cash tenure cannot exceed 5 years",
                });
              }
              return true;
            }),
      }),

      tenureType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("HC", {
          is: true,
          then: (schema) => schema.required("Tenure Type is required"),
        }),
      productBenefitId: Yup.array(),
    }),

    AC: Yup.object().shape({
      productBenefitId: Yup.array(),
      AC: Yup.boolean(),
      membersCovered: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("AC", {
          is: true,
          then: (schema) =>
            schema.required("Accidental members covered is required"),
        }),
      vendorId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("AC", {
          is: true,
          then: (schema) => schema.required("Accidental vendor is required"),
        }),
      tenure: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("AC", {
          is: true,
          then: (schema) =>
            schema
              .typeError("Accidental tenure must be a number")
              .required("Tenure is required")
              .test("max-tenure", function (value) {
                const { tenureType } = this.parent;

                if (tenureType === "DAYS" && value > 365) {
                  return this.createError({
                    message: "Accidental tenure cannot exceed 365 days",
                  });
                }
                if (tenureType === "MONTHS" && value > 60) {
                  return this.createError({
                    message: "Accidental tenure cannot exceed 60 months",
                  });
                }
                if (tenureType === "YEARS" && value > 5) {
                  return this.createError({
                    message: "Accidental tenure cannot exceed 5 years",
                  });
                }
                return true;
              }),
        }),

      tenureType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("AC", {
          is: true,
          then: (schema) =>
            schema.required("Accidental tenure Type is required"),
        }),
      claimAmount: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("AC", {
          is: true,
          then: (schema) =>
            schema
              .min(
                1,
                "Accidental reimbursement amount should be greater than 0"
              )
              .required("Accidental reimbursement amount is required"),
        }),
      claimDocuments: Yup.array().when("AC", {
        is: true,
        then: (schema) =>
          schema
            .min(1, "Accidental reimbursement documents are required")
            .required("Accidental reimbursement documents are required"),
      }),
    }),

    NUTR: Yup.object().shape({
      productBenefitId: Yup.array(),
      NUTR: Yup.boolean(),
      membersCovered: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("NUTR", {
          is: true,
          then: (schema) =>
            schema.required("Nutritionist members covered is required"),
        }),
      maxConsultantCount: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("NUTR", {
          is: true,
          then: (schema) =>
            schema.required("Nutritionist max consultant count is required"),
        }),
      vendorId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("NUTR", {
          is: true,
          then: (schema) => schema.required("Nutritionist vendor is required"),
        }),
      tenure: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("NUTR", {
          is: true,
          then: (schema) =>
            schema
              .required("Nutritionist tenure is required")
              .test("max-tenure", function (value) {
                const { tenureType } = this.parent;

                if (tenureType === "DAYS" && value > 365) {
                  return this.createError({
                    message: "Nutritionist tenure cannot exceed 365 days",
                  });
                }
                if (tenureType === "MONTHS" && value > 60) {
                  return this.createError({
                    message: "Nutritionist tenure cannot exceed 60 months",
                  });
                }
                if (tenureType === "YEARS" && value > 5) {
                  return this.createError({
                    message: "Nutritionist tenure cannot exceed 5 years",
                  });
                }
                return true;
              }),
        }),

      tenureType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("NUTR", {
          is: true,
          then: (schema) =>
            schema.required("Nutritionist tenure type is required"),
        }),
      vendorPlanId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("NUTR", {
          is: true,
          then: (schema) =>
            schema.required("Nutritionist vendor plan id is required"),
        }),
    }),

    CLOAN: Yup.object().shape({
      productBenefitId: Yup.array(),
      CLOAN: Yup.boolean(),
      membersCovered: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("CLOAN", {
          is: true,
          then: (schema) =>
            schema.required("Credit life members covered is required"),
        }),
      vendorId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("CLOAN", {
          is: true,
          then: (schema) => schema.required("Credit life vendor is required"),
        }),
      tenure: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("CLOAN", {
          is: true,
          then: (schema) =>
            schema
              .required("Credit life tenure is required")
              .test("max-tenure", function (value) {
                const { tenureType } = this.parent;

                if (tenureType === "DAYS" && value > 365) {
                  return this.createError({
                    message: "Credit life tenure cannot exceed 365 days",
                  });
                }
                if (tenureType === "MONTHS" && value > 60) {
                  return this.createError({
                    message: "Credit life tenure cannot exceed 60 months",
                  });
                }
                if (tenureType === "YEARS" && value > 5) {
                  return this.createError({
                    message: "Credit life tenure cannot exceed 5 years",
                  });
                }
                return true;
              }),
        }),

      tenureType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("CLOAN", {
          is: true,
          then: (schema) =>
            schema.required("Credit life tenure Type is required"),
        }),

      amountType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("CLOAN", {
          is: true,
          then: (schema) =>
            schema.required("Credit life amount Type is required"),
        }),

      factorAmount: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when(["CLOAN", "amountType"], {
          is: (CLOAN, amountType) => CLOAN && amountType === "factor",
          then: (schema) =>
            schema.required("Credit life factor amount is required"),
          otherwise: (schema) => schema.notRequired(),
        }),

      lowerLimit: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when(["CLOAN", "amountType"], {
          is: (CLOAN, amountType) => CLOAN && amountType === "range",
          then: (schema) =>
            schema.required("Credit life lower limit is required"),
          otherwise: (schema) => schema.notRequired(),
        }),

      upperLimit: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when(["CLOAN", "amountType"], {
          is: (CLOAN, amountType) => CLOAN && amountType === "range",
          then: (schema) =>
            schema.required("Credit life upper limit is required"),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),

    SKIN: Yup.object().shape({
      productBenefitId: Yup.array(),
      SKIN: Yup.boolean(),
      membersCovered: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("SKIN", {
          is: true,
          then: (schema) =>
            schema.required("SKIN Consultation members covered is required"),
        }),
      maxConsultantCount: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("SKIN", {
          is: true,
          then: (schema) =>
            schema.required(
              "SKIN Consultation max consultant count is required"
            ),
        }),
      vendorId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("SKIN", {
          is: true,
          then: (schema) =>
            schema.required("SKIN Consultation vendor is required"),
        }),
      tenure: Yup.number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("SKIN", {
          is: true,
          then: (schema) =>
            schema
              .required("SKIN Consultation tenure is required")
              .test("max-tenure", function (value) {
                const { tenureType } = this.parent;

                if (tenureType === "DAYS" && value > 365) {
                  return this.createError({
                    message: "SKIN Consultation tenure cannot exceed 365 days",
                  });
                }
                if (tenureType === "MONTHS" && value > 60) {
                  return this.createError({
                    message: "SKIN Consultation tenure cannot exceed 60 months",
                  });
                }
                if (tenureType === "YEARS" && value > 5) {
                  return this.createError({
                    message: "SKIN Consultation tenure cannot exceed 5 years",
                  });
                }
                return true;
              }),
        }),

      tenureType: Yup.string()
        .nullable()
        .transform((value) => (value === null ? "" : value))
        .when("SKIN", {
          is: true,
          then: (schema) =>
            schema.required("SKIN Consultation tenure Type is required"),
        }),
      vendorPlanId: Yup.string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("SKIN", {
          is: true,
          then: (schema) =>
            schema.required("SKIN Consultation vendor plan id is required"),
        }),
    }),
  }),
  vendorProductAddOnBenefit: Yup.object()
    .nullable()
    .shape({
      AMBULANCE_ON_CALL: Yup.object().shape({
        productAddOnBenefitId: Yup.string()
          .nullable()
          .test(
            "vendor-required",
            "Vendor is required when number of usages is provided.",
            function (value) {
              const { numberOfUses } = this.parent;
              return !numberOfUses || !!value; // If numberOfUses exists, vendorId must exist
            }
          ),
        numberOfUses: Yup.string()
          .nullable()
          .test(
            "usages-required",
            "Number of usages is required.",
            function (value) {
              const { productAddOnBenefitId } = this.parent;
              return !productAddOnBenefitId || !!value; // If vendorId exists, numberOfUses must exist
            }
          ),
      }),
      FITNESS: Yup.object().shape({
        productAddOnBenefitId: Yup.string()
          .nullable()
          .test(
            "vendor-required",
            "Vendor is required when number of usages is provided.",
            function (value) {
              const { numberOfUses } = this.parent;
              return !numberOfUses || !!value;
            }
          ),
        numberOfUses: Yup.string()
          .nullable()
          .test(
            "usages-required",
            "Number of usages is required.",
            function (value) {
              const { productAddOnBenefitId } = this.parent;
              return !productAddOnBenefitId || !!value;
            }
          ),
      }),
      MENTAL_WELLNESS: Yup.object().shape({
        productAddOnBenefitId: Yup.string()
          .nullable()
          .test(
            "vendor-required",
            "Vendor is required when number of usages is provided.",
            function (value) {
              const { numberOfUses } = this.parent;
              return !numberOfUses || !!value;
            }
          ),
        numberOfUses: Yup.string()
          .nullable()
          .test(
            "usages-required",
            "Number of usages is required.",
            function (value) {
              const { productAddOnBenefitId } = this.parent;
              return !productAddOnBenefitId || !!value;
            }
          ),
      }),
    }),
});

const cleanedSlabData = (data) => {
  const newData = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const validRangeRegex = /^\d+-\d+$/;
      if (key.includes("days") && !validRangeRegex.test(value)) {
        return [key, ""]; // Empty invalid day ranges
      }
      return [key, value]; // Keep valid values
    })
  );
  return newData;
};

export function cleanPayload(payload) {
  let data = { ...payload };
  Object.keys(data.subProducts).map((item) => {
    if (
      data.subProducts?.[item] &&
      data.subProducts?.[item]?.[item] === false
    ) {
      delete data.subProducts?.[item];
    }
  });

  if (data?.labTestID?.length) {
    data.labTestID = data?.labTestID.map((item) => item.id);
  }
  if (data?.subProducts?.HC?.HC) {
    data.subProducts.HC.hospiSlab = cleanedSlabData(
      data.subProducts?.HC.hospiSlab
    );
    data.subProducts.HC.icuSlab = cleanedSlabData(data.subProducts?.HC.icuSlab);
  }

  for (let key in data?.vendorProductAddOnBenefit) {
    if (data.vendorProductAddOnBenefit[key]?.productAddOnBenefitId === null) {
      delete data.vendorProductAddOnBenefit[key];
    }
  }

  if (Object.keys(data?.productBenefitId)?.length > 0) {
    data.productBenefitId = Object.values(data?.productBenefitId).flat();
  }

  return data;
}

export const memberCoveredOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
];

export const tenureTypeOptions = [
  { label: "Days", value: "DAYS" },
  { label: "Months", value: "MONTHS" },
  { label: "Years", value: "YEARS" },
];

export const DotPulse = () => {
  return (
    <div className="flex space-x-2 items-center gap-1 ml-1">
      Checking
      <span className="ml-1 w-2 h-2 bg-red-500 rounded-full animate-bounce"></span>
      <span className="w-2 h-2 bg-red-500 rounded-full animate-bounce [animation-delay:0.4s]"></span>
      <span className="w-2 h-2 bg-red-500 rounded-full animate-bounce [animation-delay:0.8s]"></span>
    </div>
  );
};

export const validateSubProductsSelection = (subProducts) => {
  if (subProducts) {
    // Check if any subProduct has its corresponding boolean key set to true
    const isAnySubProductSelected = Object.entries(subProducts).some(
      ([key, value]) => value[key] === true
    );

    if (!isAnySubProductSelected) {
      return "Please select at least one sub product.";
    }
    return null; // No error if any is true
  } else {
    return true;
  }
};

export const convertToDays = (value, type) => {
  const daysInMonth = 30; // Approximate
  const daysInYear = 365; // Approximate

  switch (type) {
    case "DAYS":
      return value;
    case "MONTHS":
      return value * daysInMonth;
    case "YEARS":
      return value * daysInYear;
    default:
      return 0;
  }
};

export const validateTenure = (
  inputTenure,
  inputType,
  maxTenure,
  maxType,
  key
) => {
  const inputInDays = convertToDays(Number(inputTenure), inputType);
  const maxInDays = convertToDays(Number(maxTenure), maxType);

  if (inputInDays > maxInDays) {
    return `${key} Tenure exceeds the maximum allowed value of ${maxTenure} ${maxType}.`;
  }
  return null; // No error
};

export const validateSubProductsTenure = (productData) => {
  const {
    tenure: maxTenure,
    tenureType: maxTenureType,
    subProducts,
  } = productData;
  const errors = {};

  Object.entries(subProducts).forEach(([key, subProduct]) => {
    // Check if subproduct is active and has tenure details
    if (subProduct[key] && subProduct.tenure && subProduct.tenureType) {
      const error = validateTenure(
        subProduct.tenure,
        subProduct.tenureType,
        maxTenure,
        maxTenureType,
        key
      );
      if (error) {
        errors[key] = { tenure: error };
      }
    }
  });

  if (Object.keys(errors).length) {
    return { subProducts: errors };
  } else {
    return null;
  }
};

export const getFirstError = (errors) => {
  if (!errors) return null;

  for (const key in errors) {
    if (typeof errors[key] === "string") {
      return errors[key]; // Return if it's a direct error string
    } else if (typeof errors[key] === "object") {
      const nestedError = getFirstError(errors[key]);
      if (nestedError) return nestedError; // Recursively check nested objects
    }
  }

  return null; // No errors found
};
