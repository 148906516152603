import React, { useEffect, useState } from "react";
import AdminMain from "../AdminMain";
import { AppBackButton } from "../../../components/AppComponents";
import { ApiGet, ApiPost } from "../../../lib/AppHelper";
import { AppStore } from "../../../lib/AppStore";
import Button from "../../../components/Button";
import CustomModal from "../components/CustomModal";
import { InputGroup } from "../../../components/form/InputGroup";
import InputSearchableDropdown from "../../../components/form/InputSearchableDropdown";
import { Close } from "@mui/icons-material";
import InputLabel from "../../../components/form/InputLabel";
import { toast } from "react-toastify";
import { RadioInput } from "../../../components/form/RadioInput";
import { Pagination } from "rsuite";
import { useSelector } from "react-redux";
import InfintyDropdown from "../../StepClaimForm/InfintyDropdown";

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const state = useSelector((state) => state);
  const { permissions } = state?.user;

  const fetchVendorList = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await ApiGet(`get-vendor?limit=10&page=${currentPage}`).then((res) => {
      if (res.code === 200) {
        setVendors(res?.data?.results || []);
        setCount(res?.data?.total);
      }
    });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  useEffect(() => {
    fetchVendorList();
  }, [currentPage]);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handelChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <AppBackButton to="/">Back</AppBackButton>
        <h3 className="font-semibold text-xl text-primary-500 text-center md:text-left">
          Vendor
        </h3>
      </div>
      <section className="mt-4">
        <div className="flex flex-col">
          {permissions?.add_vendor && (
            <div className="flex justify-end">
              <Button
                title="+ Add Vendor"
                attrs={{ onClick: handleModalOpen }}
              />
            </div>
          )}
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-x-auto mt-1">
                <table className="min-w-full border" id="table-to-xls">
                  <thead className="border-b">
                    <tr className="bg-primary-500 text-white">
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                        Vendor Name
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                        Vendor Code
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                        Code
                      </th>
                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                        Type
                      </th>
                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                        Doctor Consultation
                      </th>
                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                        Accidental Insurance
                      </th>
                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                        Hospi Cash
                      </th>
                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                        CLOAN
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendors?.map((record, index) => {
                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                            {record.name}
                          </td>
                          <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                            {record.vendor_code}
                          </td>
                          <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                            {record.code}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {record.type}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                            {`${record.doc}`}
                          </td>

                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                            {`${record.accidental}`}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                            {`${record.hospital_cash}`}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                            {`${record.cloan}`}
                          </td>
                        </tr>
                      );
                    })}
                    {vendors?.length === 0 ? (
                      <tr>
                        <td
                          colSpan="7"
                          height="50"
                          className="text-red-500 font-light text-center"
                        >
                          Your result is empty
                        </td>
                      </tr>
                    ) : (
                      false
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={count}
              limit={10}
              activePage={currentPage}
              onChangePage={(page) => handelChangePage(page)}
            />
          </div>
        </div>
      </section>

      <CustomModal
        open={open}
        setOpen={() => setOpen(false)}
        children={
          <AddVendorForm
            onClose={() => setOpen(false)}
            fetchData={fetchVendorList}
          />
        }
      />
    </>
  );
};

const AddVendorForm = ({ onClose, fetchData }) => {
  const [vendorType, setVendorType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({
    name: "",
    id: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    doc: false,
    hospital_cash: false,
    accidental: false,
    cloan: false,
    code: "",
    type: "",
    heading: "",
    vendor_code: "",
    specification_name: "",
  });

  const getVendorType = async () => {
    await ApiGet("get-category/vendor-type").then((res) => {
      if (res.code === 200) {
        const data = res?.data?.results?.map((item) => ({
          label: item?.title,
          value: item?.title,
        }));
        setVendorType(data);
      }
    });
  };
  useEffect(() => {
    getVendorType();
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    await ApiPost("create-vendor", formData).then((res) => {
      if (res.code === 200) {
        fetchData();
        onClose();
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
    setLoading(false);
  };

  const onChangeHandler = (e) => {
    const boolType = ["doc", "hospital_cash", "accidental", "cloan"];
    let { name, value } = e.target;
    if (/^[a-zA-Z0-9\s]*$/.test(value)) {
      if (boolType.includes(name)) {
        value = value === "true";
      }
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSelectType = (value) => {
    setFormData((prev) => ({ ...prev, type: value }));
  };

  const isDisable = () => {
    const { code, heading, name, specification_name, type, vendor_code } =
      formData;
    if (code && heading && name && specification_name && type && vendor_code) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <header className="bg-primary-500 text-white w-full p-2 flex justify-between">
        <h3>Add new vendor</h3>
        <button onClick={onClose}>
          <Close />
        </button>
      </header>

      <body className="p-4">
        <form
          onChange={onChangeHandler}
          onSubmit={handleSubmit}
          className="grid container grid-cols-1 md:grid-cols-2 gap-2 md:gap-4"
        >
          <InputGroup
            requiredMark="true"
            label="Name"
            name="name"
            attrs={{ value: formData.name }}
          />

          <div className="mb-5">
            <div style={{ width: "230px" }}>
              <InputSearchableDropdown
                data={vendorType}
                onChange={onSelectType}
                width={"100%"}
                value={formData.type}
              />
              {/* <InfintyDropdown
                label="Vendor type"
                request={ApiGet}
                endpoint={"get-category/vendor-type"}
                attrs={{
                  searchParam: "searchKey",
                  value: selectedVendor?.id || "",
                }}
                valueField="id"
                labelField="name"
                isCleanable={false}
                setValue={(data, item) => {
                  console.log("ddd", data, item);
                }}
                // customRenderMenu={(value, obj, menu, index) => {
                //   return (
                //     <div
                //       key={index}
                //       className="p-4 text-xs space-y-2 border-b-2 cursor-pointer hover:bg-red-500 hover:text-white"
                //       style={{ width: "100%" }}
                //       onClick={(e) => menu.props.onSelect(obj?.id, obj, e)}
                //     >
                //       <div className="flex items-center justify-between">
                //         <div className="font-bold text-sm">{value}</div>
                //       </div>
                //       <div className="flex items-center justify-between">
                //         {obj?.mobile && (
                //           <div>
                //             Mobile:{" "}
                //             <span className="font-medium">{obj.mobile}</span>
                //           </div>
                //         )}
                //       </div>
                //     </div>
                //   );
                // }}
                searchId={selectedVendor.id}
              /> */}
            </div>
          </div>

          <InputGroup
            requiredMark="true"
            label="Vendor code"
            name="vendor_code"
            attrs={{ value: formData.vendor_code }}
          />

          <InputGroup
            requiredMark="true"
            label="Specification name"
            name="specification_name"
            attrs={{ value: formData.specification_name }}
          />

          <InputGroup
            requiredMark="true"
            label="Heading"
            name="heading"
            attrs={{ value: formData.heading }}
          />

          <InputGroup
            requiredMark="true"
            label="Code"
            name="code"
            attrs={{ value: formData.code }}
          />

          <RadioInput
            requiredMark
            label="Doctor consultation"
            name="doc"
            options={[
              { label: "True", value: true },
              { label: "False", value: false },
            ]}
            value={formData.doc}
          />
          <RadioInput
            requiredMark
            label="Hospital cash"
            name="hospital_cash"
            options={[
              { label: "True", value: true },
              { label: "False", value: false },
            ]}
            value={formData.hospital_cash}
          />
          <RadioInput
            requiredMark
            label="Accidental insurance"
            name="accidental"
            options={[
              { label: "True", value: true },
              { label: "False", value: false },
            ]}
            value={formData.accidental}
          />
          <RadioInput
            requiredMark
            label="Credit life cover"
            name="cloan"
            options={[
              { label: "True", value: true },
              { label: "False", value: false },
            ]}
            value={formData.cloan}
          />
          <div className="col-span-2">
            <button
              type="submit"
              className={`${
                isDisable()
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-primary-500"
              } text-white rounded-lg float-right px-2 py-1`}
              disabled={isDisable() || loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </body>
    </div>
  );
};

export default VendorList;
