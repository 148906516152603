import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { ApiGet, ApiPost, setMessage, setError } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import AdminMain from "./admin/AdminMain";
import Button from "../components/Button";
import { SelectPicker } from "rsuite";
import DualList from "../components/ListBox/src";

class Groups extends Component {
  BasicBtn = React.createRef();
  state = {
    groupOptions: [],
    groupSelectedValue: {},

    options: [],
    selected: [],
    roles: [],
    filterValues: {},
    selectedLevel: "",
    team: "",
  };

  onChange = (selected, b) => {
    this.setState({
      selected: selected,
    });

    let request = {
      role_group_id: this.state.selectedLevel,
      deleted: b,
      role_master_ids: selected,
    };
    this.callAPI(request);
  };

  async callAPI(request) {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("admin/role-group-mapping", request);

    if (response.status === "success") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      setMessage(response.message);
    } else {
      setError(response.message);
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  }

  getGroups = async () => {
    const response = await ApiGet("admin/get-role-group");
    if (response.status === "success") {
      this.setState({
        groupOptions: response?.data,
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getRoles = async () => {
    const response = await ApiGet("roles");
    if (response.status === "success") {
      this.setState({
        options: response.data,
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getTeam = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(
      "admin/get-role-group",
      this.state.filterValues
    );
    if (response.status === "success") {
      this.setState({
        team: response.data[0],
        selected: response.data ? response?.data[0]?.selected_roles : [],
        selectedLevel: response.data ? response?.data[0]?.role_group_id : "",
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  setFilterValues = (values) => {
    this.setState({ filterValues: values });
  };

  componentDidMount() {
    this.getGroups();
    if (this.state.groupSelectedValue) {
      this.getRoles();
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (!_.isEqual(prevState.filterValues, this.state.filterValues)) {
      this.getTeam();
    }
  }

  isMobile = () => {
    return window.innerWidth <= 768;
  };

  render() {
    const { permissions } = this.props;
    const { groupOptions, options, selected, filterValues, team } = this.state;

    const modifiedGroupsList = [
      ...(Array.isArray(groupOptions)
        ? groupOptions?.map((e) => ({
            value: e.role_group_id,
            label: e.role_group_name,
          }))
        : []),
    ];

    return (
      <>
        {permissions?.group_assign ? (
          <>
            <div className="flex md:flex-2">
              <div className="w-11/12">
                <AppBackButton to="/">Back to Dashboard</AppBackButton>
              </div>
              {permissions?.add_group && (
                <div className="w-32">
                  <Link to="/add-group">
                    <Button
                      attrs={{ type: "button", onClick: this.submit }}
                      size="sm"
                      title={"Add New Group"}
                    />
                  </Link>
                </div>
              )}
            </div>
            <AppHeading className="text-center">
              Groups & Permissions
            </AppHeading>

            <div className="grid md:grid-cols-6 gap-3 p-2 mt-5">
              <div className="md:col-span-6">
                <SelectPicker
                  placeholder={"Select Group"}
                  data={modifiedGroupsList}
                  searchable={true}
                  onSelect={(e) => {
                    this.setState({
                      groupSelectedValue: e,
                    });
                    const selectedGroup = groupOptions?.find(
                      (group) => group?.role_group_id === e
                    );
                    const roleGroupNameObj = selectedGroup && {
                      role_group_name: selectedGroup?.role_group_name,
                    };
                    this.setFilterValues(roleGroupNameObj);
                  }}
                  onClean={() => {
                    this.setState({
                      groupSelectedValue: {},
                      selected: [],
                      filterValues: {},
                    });
                  }}
                  style={{
                    width: "100%",
                    color: "black",
                  }}
                />
              </div>
            </div>

            {filterValues && Object.keys(filterValues).length && team ? (
              <>
                <div className="grid md:grid-cols-3 gap-4 pt-5">
                  <div>
                    Description:{" "}
                    <span className="pl-5 font-medium">{team.description}</span>
                  </div>
                </div>

                <DualList
                  options={options}
                  onChange={this.onChange}
                  selected={selected}
                />
              </>
            ) : filterValues.search && !team ? (
              <h4 className="text-red-500	 mt-5">Reseller not found</h4>
            ) : (
              false
            )}
          </>
        ) : (
          <div className="text-red-500">
            <div>
              <AppBackButton to="/">Back</AppBackButton>
            </div>
            <h4>
              You don't have permission to view assigns groups & Permissions
            </h4>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return {
    loading,
    permissions: user.permissions,
  };
};

const GroupsConnect = connect(mapStateToProps)((props) => {
  return <Groups {...props} />;
});

export default withRouter(GroupsConnect);
