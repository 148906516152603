import { PencilIcon, PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { AppRow } from "../../components/AppComponents";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
import ProfileSidebar from "./ProfileSidebar";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { InputRadio } from "../../components/form/InputRadio";
import {
  ApiGet,
  ApiPost,
  removeData,
  setError,
  validateEmail,
} from "../../lib/AppHelper";
import { InputFile } from "../../components/form/InputFile";
import _ from "lodash";
import { InputDate } from "../../components/form/InputDate";
import { toast } from "react-toastify";
import {
  dateMinus18Years,
  datePlus100Years,
  govtIds,
} from "../../services/common/data";
import { InputSelect } from "../../components/form/InputSelect";
import {
  addTeamDocSize,
  getFileExtension,
  profileExtension,
} from "../../lib/constants";

export class BasicProfile extends Component {
  state = {
    edit: false,
    govDetails: {
      ...govtIds?.filter(
        (elm) => this.props.user?.banking?.government_id_type === elm.value
      )?.[0],
    },
    init_params: {
      name: this.props?.user?.name,
      dob: this.props?.user?.basic?.dob,
      mobile: this.props?.user?.mobile,
      email: this.props?.user?.email ? this.props.user.email : "",
      gender: this.props?.user?.basic?.gender,
      pin: this.props?.user?.basic?.pincode,
      // state: this.props?.user?.basic?.state,
      photo_base64: null,
      photo: "",
      government_id_type: this.props?.user?.basic.government_id_type,
      government_id: this.props?.user?.basic?.government_id,
      document_front: this.props?.user?.basic?.document_front_url,
      document_back: this.props?.user?.basic?.document_back_url,
      photo_url: this.props?.user?.basic?.photo_url,
    },
    params: {
      name: this.props?.user?.name,
      dob: this.props?.user?.basic.dob,
      mobile: this.props?.user?.mobile,
      email: this.props?.user?.email,
      gender: this.props?.user?.basic?.gender,
      pin: this.props?.user?.basic?.pincode,
      state: this.props?.user?.basic?.state,
      photo_base64: null,
      photo: "",
      photo_url: this.props?.user?.basic?.photo_url,
    },
    errors: {},
    pinLoader: false,
  };
  checked_pin = null;
  MobileInput = React.createRef();
  BasicBtn = React.createRef();
  pinRef = React.createRef();

  render() {
    const { edit, params, errors, init_params } = this.state;
    const { user } = this.props;
    const { permissions } = user;

    return (
      <>
        <AppRow>
          <div className="flex-1 max-w-min mr-5">
            <ProfileSidebar isDirty={edit} />
          </div>
          <div className="flex-auto">
            <div className="max-w-xl">
              <InputFile
                label="Your photo"
                error={errors.photo}
                file={params.photo || this.props.user?.basic?.photo_url}
                url={params.photo_url}
                placeholder={
                  !params?.photo &&
                  !params?.photo_url && (
                    <div className="text-center items-center flex-col inline-flex">
                      <PlusCircleIcon className="text-primary" width={24} />
                      <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                        Upload photo
                        <br />
                        (allowed types .jpeg, .jpg, .png)
                        <br />
                        (max size 5MB)
                        <br />
                      </p>
                    </div>
                  )
                }
                attrs={{
                  disabled: !edit,
                  onChange: this.onPhotoChange,
                  accept: "image/*,.jpeg*",
                  capture: "environment",
                }}
                onDocDelete={() => {
                  _.set(params, "photo", null);
                  _.set(params, "photo_url", null);
                  _.set(params, "photo_base64", null);
                  this.setState({ params: params });
                }}
                readOnly={!edit}
              />

              <InputGroup
                error={errors.mobile}
                label="Phone"
                prefix="+91"
                placeholder=""
                attrs={{
                  maxLength: 10,
                  value: params.mobile,
                  readOnly: !edit,
                  disabled: true,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (isValidInteger || e.target.value == "") {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "mobile"
                      );
                    }
                    this.setState({
                      errors: { ...errors, mobile: "" },
                    });
                  },
                  ref: this.MobileInput,
                }}
              />

              <InputGroup
                error={errors.name}
                label="Name"
                placeholder="Enter name..."
                starMark={edit}
                attrs={{
                  readOnly: !edit,
                  disabled: permissions?.reseller_name_read_only,
                  value: params.name,
                  onChange: (e) => {
                    if (
                      /^[a-zA-Z\s]+$/.test(e.target.value) ||
                      e.target.value === ""
                    )
                      this.onChangeHandler(e, "name");
                    this.setState({
                      errors: { ...errors, name: "" },
                    });
                  },
                }}
              />

              <InputDate
                error={errors.dob}
                label="Date of birth"
                placeholder="DOB"
                maxDate={dateMinus18Years()}
                // starMark={edit}
                minDate={datePlus100Years()}
                attrs={{
                  value: params.dob ? new Date(params.dob) : "",
                  readOnly: !edit,
                  onChange: (e) => {
                    this.onChangeHandlerDate(e, "dob");
                    this.setState({
                      errors: { ...errors, dob: "" },
                    });
                  },
                  value: params.dob ? new Date(params.dob) : null,
                }}
                calDefValue={dateMinus18Years()}
              />
              {/* <InputGroup label="Date of Birth" attrs={{ value: '05/06/2000', readOnly: true }} /> */}
              <br />
              <InputGroup
                error={errors.email}
                label="Email address"
                placeholder="Enter email..."
                starMark={edit && init_params.email}
                attrs={{
                  maxLength: 64,
                  value: params.email ? params.email : "",
                  readOnly: !edit,
                  onChange: (e) => {
                    this.onChangeHandler(e, "email");
                    this.setState({
                      errors: { ...errors, email: "" },
                    });
                  },
                }}
                validateError={(value) => {
                  if (value) {
                    if (validateEmail(value)) {
                      return false;
                    } else {
                      return "please enter correct email ID";
                    }
                  } else {
                    return "";
                  }
                }}
                isError={!edit && ""}
              />
              {!edit ? (
                <InputGroup
                  label="Gender"
                  placeholder="Gender.."
                  // starMark={edit}
                  attrs={{
                    value: params.gender ? user.basic.gender_text : "",
                    readOnly: true,
                    disabled: true,
                  }}
                />
              ) : (
                <div className="mb-12 md:mb-0">
                  <InputRadio
                    error={errors.gender}
                    className="h-10"
                    label="Gender"
                    name="gender"
                    // requiredMark={edit}
                    options={[
                      { label: "Male", value: "m" },
                      { label: "Female", value: "f" },
                    ]}
                    value={params.gender}
                    attrs={{
                      onChange: (e) => {
                        this.onChangeHandler(e, "gender");
                        this.setState({
                          errors: { ...errors, gender: "" },
                        });
                      },
                    }}
                  />
                </div>
              )}

              {permissions?.government_id_show_on_profile ? (
                <>
                  <InputSelect
                    width="full"
                    label="Govt ID Type"
                    attrs={{
                      value: init_params?.government_id_type,
                      readOnly: true,
                      disabled: true,
                    }}
                    // requiredMark={edit}
                    options={govtIds}
                  />
                  <InputGroup
                    label="Government ID"
                    placeholder="Enter Govt ID..."
                    starMark={edit}
                    attrs={{
                      disabled: true,
                      value: init_params?.government_id,
                      // readOnly: true,
                    }}
                  />
                  {init_params?.document_front && (
                    <InputFile
                      label="Attachment-front"
                      file={init_params?.document_front}
                      attrs={{
                        accept: "image/*,.pdf",
                        disable: true,
                      }}
                      url={init_params?.document_front}
                      readOnly={true}
                    />
                  )}
                  {init_params?.document_back && (
                    <InputFile
                      label="Attachment-back "
                      file={init_params?.document_back}
                      attrs={{
                        accept: "image/*,.pdf",
                        disable: true,
                      }}
                      url={init_params?.document_back}
                      readOnly={true}
                    />
                  )}
                </>
              ) : (
                false
              )}

              <InputGroup
                error={errors.pin}
                label="Pincode"
                placeholder="Enter pincode..."
                // requiredMark={edit}
                attrs={{
                  ref: this.pinRef,
                  value: params.pin,
                  readOnly: !edit,
                  onChange: (e) => {
                    if (e.target.value.length <= 6) {
                      this.setState({ errors: { pin: "" } });
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "pin"
                      );
                    }
                    if (e.target.value?.length === 6) this.checkPin();
                    this.setState({
                      errors: { ...errors, pin: "" },
                    });
                  },
                  // onBlur: this.checkPin,
                }}
                onEnter={this.checkPin}
                isError={!edit && ""}
                validateError={(value) => {
                  if (!value || value.length === 6) {
                    return false;
                  } else {
                    return "please enter valid pincode";
                  }
                }}
              />

              {params.state &&
              params?.pin?.length === 6 &&
              edit &&
              !this.state.pinLoader ? (
                <InputGroup
                  label="State"
                  placeholder="Enter state..."
                  attrs={{ value: params.state, readOnly: true }}
                />
              ) : (
                false
              )}

              <div className="mt-8">
                <div className="flex">
                  <div>
                    <Button
                      attrs={{ onClick: this.toggleEdit }}
                      title={!edit ? "Edit Profile" : "Cancel Edit"}
                      display="inline-block"
                      outline={true}
                    >
                      {edit ? (
                        <XIcon width={16} className="mr-1" />
                      ) : (
                        <PencilIcon width={16} className="mr-1" />
                      )}
                    </Button>
                  </div>
                  <div className="flex-1 pl-5">
                    {edit ? (
                      <Button
                        attrs={{
                          type: "button",
                          onClick: this.updateProfile,
                          ref: this.BasicBtn,
                          disabled: !this.isReady(),
                        }}
                        size="md"
                        title={"Update Profile"}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppRow>
      </>
    );
  }

  isReady = () => {
    let ready = false;
    const {
      params: { name, email, pin, state },
      init_params,
    } = this.state;
    if (name) {
      if (email && !validateEmail(email)) {
        ready = false;
      } else if (pin && (pin.length !== 6 || !state)) {
        ready = false;
      } else if (init_params?.email && !email && !validateEmail(email)) {
        ready = false;
      } else {
        ready = true;
      }
    }
    return ready;
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: false });
    AppStore.dispatch({ type: "PROGRESS", progress: 0 });
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  toggleEdit = () => {
    if (this.state.edit) {
      this.setState({
        edit: !this.state.edit,
        params: { ...this.state.params, ...this.state.init_params },
        errors: {},
        pinLoader: false,
      });
    } else {
      this.MobileInput.current?.focus();
      this.setState({
        edit: !this.state.edit,
      });
      this.checkPin();
    }
  };

  checkPin = async () => {
    if (!this.state.params.pin) return;
    if (
      this.state.params.pin === this.checked_pin ||
      this.state.params.pin.length < 6
    )
      return;
    this.checked_pin = this.state.params.pin;

    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ pinLoader: true });
    const response = await ApiGet(`check-pin/${this.state.params.pin}`);

    if (response.status === "success" && response.data !== null) {
      this.setState({ pinLoader: false, errors: { pin: "" } }, () => {
        this.onChangeHandler({ target: { value: response.data } }, "state");
      });
      this.BasicBtn.current?.focus();
    } else if (response.status === "error") {
      this.setState({ pinLoader: false, errors: response?.errors }, () => {
        this.onChangeHandler({ target: { value: null } }, "state");
      });
      this.pinRef.current.focus();
    } else {
      this.setState({ pinLoader: false, errors: response?.errors }, () => {
        this.onChangeHandler({ target: { value: null } }, "state");
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  updateProfile = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const { name, dob, photo, mobile, email, gender, pin, state } =
        this.state.params;

      const data = new FormData();
      data.append("mobile", mobile ? mobile : "");
      data.append("name", name ? name : "");
      data.append("dob", dob ? dob : "");
      data.append("gender", gender ? gender : "");
      data.append("email", email ? email : "");
      data.append("pin", pin ? pin : "");
      data.append("state", state ? state : "");
      data.append("photo", photo ? photo : "");

      const response = await ApiPost("register/basic", data);
      if (response.status === "error") {
        if (response.message) {
          toast.error(response.message);
        }
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        const response = await ApiGet("me");
        if (response.status === "success") {
          const basicDetails = response.data.basic;
          this.setState({
            params: {
              name: response?.data?.name,
              dob: basicDetails?.dob,
              mobile: response.data?.mobile,
              email: response?.data?.email,
              gender: basicDetails?.gender,
              pin: basicDetails?.pincode,
              // state: basicDetails?.state,
              photo_base64: null,
              photo: "",
              photo_url: basicDetails?.photo_url,
              ...this.state.params,
            },
            init_params: {
              name: response?.data?.name,
              dob: basicDetails?.dob,
              mobile: response.data?.mobile,
              email: response?.data?.email,
              gender: basicDetails?.gender,
              pin: basicDetails?.pincode,
              photo_base64: null,
              photo: "",
              government_id_type: basicDetails?.government_id_type,
              government_id: basicDetails?.government_id,
              document_front: basicDetails?.document_front_url,
              document_back: basicDetails?.document_back_url,
              photo_url: basicDetails?.photo_url,
            },
            pinLoader: false,
          });

          AppStore.dispatch({ type: "USER_UPDATED", user: response.data });
          toast.success("Basic profile updated successfully!");
        } else {
          await removeData("access_token");
          await removeData("auth_token");
          await setError(response.message);
        }
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ edit: false });
      }
    });
  };

  onPhotoChange = (event) => {
    const file = event?.target?.files?.[0];
    const extension = getFileExtension(file);
    if (file) {
      if (profileExtension.includes(extension)) {
        const fileSize = file?.size;
        if (fileSize < addTeamDocSize) {
          const { params } = this.state;
          _.set(params, "photo_base64", URL.createObjectURL(file));
          _.set(params, "photo", file);
          _.set(params, "photo_url", "");
          this.setState({
            params: params,
            errors: {
              photo: "",
            },
          });
        } else {
          this.setState({
            errors: {
              photo: "File size should be less than 5mb",
            },
          });
        }
      } else {
        this.setState({
          errors: {
            photo: "File must be  .jpeg, .jpg, .png",
          },
        });
      }
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <BasicProfile {...props} />;
});
