import _ from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
import { ApiPost, setMessage } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import AdminMain from "./AdminMain";

export class AddMFI extends Component {
  state = {
    errors: {},
    params: {
      mobile: "",
      name: "",
    },
  };

  MessageRef = React.createRef();

  render() {
    const { errors, params } = this.state;

    return (
      <>
        <AppBackButton to="/">Back to Dashboard</AppBackButton>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <AppHeading>Add MFI Reseller</AppHeading>
            <h4 className="mt-1 text-md mb-6 mt-6">Please enter details</h4>

            <InputGroup
              label="Mobile no"
              placeholder="0000000000"
              prefix="+91"
              attrs={{
                maxLength: 10,
                value: params.mobile,
                onChange: (e) => {
                  this.onChangeHandler(
                    { target: { value: e.target.value.replace(/\D/, "") } },
                    "mobile"
                  );
                },
                autoFocus: true,
              }}
              error={errors.mobile}
              onEnter={this.submit}
            />

            <InputGroup
              error={errors.name}
              label="MFI Name"
              placeholder="Company Pvt Ltd"
              attrs={{
                value: params.name,
                onChange: (e) => this.onChangeHandler(e, "name"),
              }}
              onEnter={this.submit}
            />

            <Button
              attrs={{ type: "button", onClick: this.submit }}
              size="md"
              title={"Submit"}
            />
          </div>
        </div>
      </>
    );
  }

  submit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiPost("create/mfi", this.state.params);

    if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (!_.isEmpty(response.errors)) {
        this.setState({ errors: response.errors });
      } else {
        this.MessageRef.current.show(response.message, "error");
      }
    } else {
      setMessage(response.data.message, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.props.history.push("/resellers");
      });
    }
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };
}

export default withRouter(AddMFI);
