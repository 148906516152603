import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import DualList from "../components/ListBox/src";
import "../components/ListBox/src/listbox.css";
import { ApiGet, ApiPost, setMessage } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import AdminMain from "./admin/AdminMain";
import Button from "../components/Button";
import { Filters } from "./Filters";

export class Roles extends Component {
  BasicBtn = React.createRef();

  state = {
    options: [],
    selected: [],
    roles: [],
    team: "",
    filterValues: {},
    permissions: [],
    levels: [
      { id: 1, name: "Self" },
      { id: 2, name: "Team" },
      { id: 3, name: "Company" },
      { id: 4, name: "All" },
    ],
    group_id: 1,
    selectedLevel: 1,
  };

  onChange = async (selected, b) => {
    this.setState({
      selected: selected,
    });

    let request = {
      roles: selected,
      deleted: b,
      user_id: this.state.team.id,
      group_id: !this.state.selectedLevel
        ? this.state.group_id
        : this.state.selectedLevel,
    };
    this.callAPI(request);
  };

  async callAPI(request) {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("add-team-role", request);

    if (response.status === "success") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      setMessage(response.message);
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  }

  onChangeHandler = async (e, key) => {
    this.setState({ group_id: e.target.value, selectedLevel: e.target.value });

    this.callAPI({
      roles: this.state.selected,
      deleted: "",
      user_id: this.state.team.id,
      group_id: e.target.value,
    });
  };

  componentDidMount() {
    this.getRoles();
  }

  componentDidUpdate(prevProp, prevState) {
    if (!_.isEqual(prevState.filterValues, this.state.filterValues)) {
      this.getTeam();
    }
  }

  getRoles = async () => {
    // AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("roles");

    if (response.status === "success") {
      this.setState({
        options: response.data,
      });
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getTeam = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("search-team", this.state.filterValues);
    if (response.status === "success") {
      this.setState({
        team: response.data,
        selected: response.data ? response.data.selected_roles : [],
        selectedLevel: response.data ? response.data.group_id : "",
      });
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  setFilterValues = (values) => {
    this.setState({ filterValues: values });
  };

  render() {
    const { permissions, user } = this.props;
    const { options, selected, filterValues, team, selectedLevel, levels } =
      this.state;

    return (
      <>
        {permissions?.role_assign ? (
          <>
            <div className="flex  md:flex-2">
              <div className="w-11/12">
                <AppBackButton to="/">Back</AppBackButton>
              </div>
              {permissions?.add_role && (
                <div className="w-32">
                  <Link to="/add-role">
                    <Button
                      attrs={{ type: "button", onClick: this.submit }}
                      size="sm"
                      title={"Add New Role"}
                    />
                  </Link>
                </div>
              )}
            </div>
            <AppHeading className="text-center">Roles & Permissions</AppHeading>
            <div className="grid md:grid-cols-1 mt-5">
              <Filters
                setValue={this.setFilterValues}
                filterValues={filterValues}
              />
            </div>
            {team ? (
              <>
                <div className="grid md:grid-cols-3 gap-4 pt-5">
                  <div>
                    Name: <span className="pl-5 font-medium">{team.name}</span>
                  </div>
                  <div>
                    Email:{" "}
                    <span className="pl-5 font-medium	">{team.email}</span>
                  </div>
                  <div>
                    Mobile:{" "}
                    <span className="pl-5 font-medium	">{team.mobile}</span>
                  </div>
                </div>

                <div className="row pt-5">
                  <div>
                    <label className="flex text-sm text-gray-700 justify-left pb-2">
                      Select Level
                    </label>
                    <select
                      disabled
                      value={selectedLevel}
                      onChange={(e) => this.onChangeHandler(e, "group_id")}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100 capitalize"
                    >
                      {levels?.map((option, index) => {
                        return (
                          <option value={option.id} key={index}>
                            {option.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <DualList
                  options={options}
                  onChange={this.onChange}
                  selected={selected}
                />
              </>
            ) : filterValues.search && !team ? (
              <h4 className="text-red-500	 mt-5">Reseller not found</h4>
            ) : (
              false
            )}
          </>
        ) : (
          <div className="text-red-500">
            <div>
              <AppBackButton to="/">Back</AppBackButton>
            </div>
            <h4>
              You don't have permission to view assigns roles and permissions
            </h4>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return {
    loading: loading,
    permissions: user.permissions,
    user: user,
  };
};

const RolesConnect = connect(mapStateToProps)((props) => {
  return <Roles {...props} />;
});

export default withRouter(RolesConnect);

// export default Roles;
