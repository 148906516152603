import React, { Component } from "react";
import { InputGroup, SelectPicker, Form, Schema, AutoComplete } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const showLabels = (arr, label) => {
  const matchedItem = arr.find(
    (item) => item.value.toLowerCase() === label.toLowerCase()
  );
  return matchedItem ? matchedItem?.name?.toLowerCase() : "";
};

const Field = React.forwardRef((props, ref) => {
  const { name, message, label, accepter, error, style, ...rest } = props;
  return (
    <Form.Group
      controlId={`${name}-10`}
      ref={ref}
      className={error ? "has-error" : ""}
      style={{ ...style }}
    >
      <Form.Control
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...rest}
      />
    </Form.Group>
  );
});

export class Filters extends Component {
  formRef = React.createRef();

  state = {
    formError: {},
    count: 0,
    formValue: {
      search_type: "name",
    },
    isSearchValue: false,
    checkTrigger: "change",
    model: Schema.Model({
      search_type: Schema.Types.StringType().isRequired(
        "This field is required."
      ),
      mobile: Schema.Types.NumberType(),
      name: Schema.Types.StringType(),
      // search: Schema.Types.StringType().isRequired("This field is required."),
    }),
    params: {},
    errors: {},
    option: {},
    handleSubmit: "",
    members: [],
    options: [
      {
        name: "Member Name",
        value: "name",
      },
      {
        name: "Mobile Number",
        value: "mobile",
      },
    ],
  };

  handleSubmit = (value) => {
    this.setState({ isSearchValue: true });

    if (!this.state.formValue?.search?.trim()) {
      return;
    }

    if (!this.formRef.current.check()) {
      return;
    }
    const { setValue } = this.props;
    const errorObj = this.state.formError;
    if (Object?.keys(errorObj)?.length > 0) return;
    setValue && setValue(this.state.formValue);
  };

  setFormValue = (e) => {
    this.setState({ formValue: e });
  };
  setFormError = (e) => {
    this.setState({ formError: e });
  };

  handleCancel = () => {
    const { setValue, onClear } = this.props;
    if (onClear) {
      this.setState({ formValue: { search_type: "name", search: "" } });
      onClear();
      return;
    }

    this.setState({ formValue: { search_type: "name", search: "" } });
    setTimeout(() => {
      setValue && setValue(this.state.formValue);
    }, 100);
  };
  handelInitalValue = () => {
    this.setState({ formValue: { search_type: "name", search: "" } });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.formValue.search_type !== this.state.formValue.search_type) {
      this.setState({
        ...this.state,
        formValue: {
          ...this.state.formValue,
          search: "",
        },
      });
    }
  }

  render() {
    const {
      options,
      model,
      formValue,
      formError,
      checkTrigger,
      members,
      count,
      isSearchValue,
    } = this.state;
    return (
      <div className="new-claims-filters">
        <div className="search">
          <Form
            formValue={formValue}
            ref={this.formRef}
            onChange={this.setFormValue}
            onCheck={this.setFormError}
            formError={formError}
            formDefaultValue={formValue}
            model={model}
            checkTrigger={checkTrigger}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid #E5E5EA",
                borderRadius: "12px",
              }}
            >
              <Field
                name="search_type"
                searchable={false}
                defaultValue="name"
                cleanable={false}
                data={options}
                accepter={SelectPicker}
                labelKey="name"
                valueKey="value"
                onSelect={(_v, item) => {
                  this.setState({ option: item });
                }}
                style={{
                  zIndex: 0,
                  borderRadius: "0px",
                }}
                className="newClaimFilter"
              />
              <Field
                autoComplete="off"
                name={formValue.search_type}
                accepter={AutoComplete}
                error={formError.search}
                style={{
                  width: "90%",
                  borderRadius: "0px !important",
                }}
                data={members}
                placeholder={`Search by ${showLabels(
                  this.state.options,
                  formValue.search_type
                )} ...`}
                maxLength={
                  formValue.search_type == "mobile"
                    ? 10
                    : formValue.search_type == "aadhaar_id"
                    ? 12
                    : 50
                }
                onChange={(e) => {
                  if (formValue.search_type == "mobile") {
                    this.setState({
                      formValue: {
                        ...formValue,
                        search: e.replace(/[^0-9 ]/g, ""),
                      },
                    });
                  } else {
                    this.setState({
                      formValue: { ...formValue, search: e },
                    });
                  }
                }}
                value={formValue.search}
              />

              <InputGroup.Button
                className="rounded-tr-lg rounded-br-lg"
                style={{
                  height: 41,
                  color: "#8E8E93",
                  boxShadow: "none",
                  outline: "none",
                }}
                type="submit"
                onClick={this.handleSubmit}
              >
                <SearchIcon />
              </InputGroup.Button>

              {this?.state?.formValue?.search ? (
                <InputGroup.Button
                  style={{
                    opacity: "0.7",
                    fontSize: "14px",
                    padding: "8px 12px 8px 0px",
                    color: "rgb(142, 142, 147)",
                  }}
                  type="button"
                  onClick={this.handleCancel}
                  className="rounded-tr-lg rounded-br-lg"
                >
                  <CloseOutlinedIcon />
                </InputGroup.Button>
              ) : (
                false
              )}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
