import { toast } from "react-toastify";

export let getLocData = {
  location_status: "",
  latitude: null,
  longitude: null,
  accuracy: null,
};

export const HandleGetLocation = () => {
  if ("geolocation" in navigator) {
    navigator.permissions.query({ name: "geolocation" }).then((pos) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User has granted permission, and we have their location
          var crd = position.coords;
          const data = {
            location_status: pos.state == "prompt" ? "" : pos.state,
            latitude: crd.latitude,
            longitude: crd.longitude,
            accuracy: crd.accuracy,
          };
          getLocData = data;
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // User declined the permission
            const data = {
              location_status: pos.state == "prompt" ? "" : pos.state,
              latitude: null,
              longitude: null,
              accuracy: null,
            };
            getLocData = data;
          } else {
            // Handle other errors if necessary
            // toast.error("Error getting location");
          }
        }
      );
    });
  } else {
    const data = {
      location_status: "Geolocation not supported by browser",
      latitude: null,
      longitude: null,
      accuracy: null,
    };
    getLocData = data;
  }
};

export const HandelGetNotification = () => {
  if ("Notification" in window && Notification?.permission === "default") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // User granted permission
        new Notification("Permission Granted", {
          body: "You can now receive notifications.",
        });
      } else {
        // User denied permission
        console.warn("Permission denied for notifications.");
      }
    });
  } else if ("Notification" in window && Notification.permission === "denied") {
    console.warn("Permission for notifications is denied.");
  } else {
    // Permission is already granted
    console.log("Notifications are supported and enabled!");
  }
};
