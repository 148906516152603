import Accidental from "./Accidental";
import CreditLife from "./CreditLife";
import DOC from "./DOC";
import Hospi from "./Hospi";
import NUTR from "./NUTR";
import SKIN from "./SKIN";

export const SubProducts = ({
  values,
  setFieldValue,
  loading,
  errors,
  touched,
  setFieldTouched,
  allValue,
}) => {
  return (
    <>
      {Object.keys(values).map((item, index) => {
        if (item === "DOC") {
          return (
            <DOC
              membersCovered={allValue?.membersCovered}
              values={values[item]}
              setFieldValue={(key, value) =>
                setFieldValue(`subProducts.${item}.${key}`, value)
              }
              errors={errors?.[item]}
              touched={touched?.[item]}
              setFieldTouched={(key) => setFieldTouched(`${item}.${key}`)}
            />
          );
        } else if (item === "HC") {
          return (
            <Hospi
              membersCovered={allValue?.membersCovered}
              values={values[item]}
              setFieldValue={(key, value) =>
                setFieldValue(`subProducts.${item}.${key}`, value)
              }
              errors={errors?.[item]}
              touched={touched?.[item]}
              setFieldTouched={(key) => setFieldTouched(`${item}.${key}`)}
            />
          );
        } else if (item === "AC") {
          return (
            <Accidental
              membersCovered={allValue?.membersCovered}
              values={values[item]}
              setFieldValue={(key, value) =>
                setFieldValue(`subProducts.${item}.${key}`, value)
              }
              errors={errors?.[item]}
              touched={touched?.[item]}
              setFieldTouched={(key) => setFieldTouched(`${item}.${key}`)}
            />
          );
        } else if (item === "CLOAN") {
          return (
            <CreditLife
              membersCovered={allValue?.membersCovered}
              values={values[item]}
              setFieldValue={(key, value) =>
                setFieldValue(`subProducts.${item}.${key}`, value)
              }
              errors={errors?.[item]}
              touched={touched?.[item]}
              setFieldTouched={(key) => setFieldTouched(`${item}.${key}`)}
            />
          );
        } else if (item === "NUTR") {
          return (
            <NUTR
              membersCovered={allValue?.membersCovered}
              values={values[item]}
              setFieldValue={(key, value) =>
                setFieldValue(`subProducts.${item}.${key}`, value)
              }
              errors={errors?.[item]}
              touched={touched?.[item]}
              setFieldTouched={(key) => setFieldTouched(`${item}.${key}`)}
            />
          );
        } else if (item === "SKIN") {
          return (
            <SKIN
              membersCovered={allValue?.membersCovered}
              values={values[item]}
              setFieldValue={(key, value) =>
                setFieldValue(`subProducts.${item}.${key}`, value)
              }
              errors={errors?.[item]}
              touched={touched?.[item]}
              setFieldTouched={(key) => setFieldTouched(`${item}.${key}`)}
            />
          );
        }
      })}
    </>
  );
};
