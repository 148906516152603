import React, { useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import InputLabel from "../form/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const animatedComponents = makeAnimated();

export default function MultiSelector({
  error,
  requiredMark,
  labelLink,
  label,
  options = [],
  onChange,
  selectedIds = [],
  loading = false,
  labelStyle,
  placeholder = "search & select items...",
}) {
  const [open, setOpen] = useState(false);
  return (
    <div id="custom-multi-select" className="mb-1 relative">
      {open && error && (
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className="bg-red-600 -top-10 absolute text-white text-sm p-3 rounded-md flex items-start gap-2"
        >
          <span>{error}</span>
        </div>
      )}

      <div className="flex gap-1 items-center">
        {error && (
          <FontAwesomeIcon
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            icon={faExclamationCircle}
            className="text-red-500"
          />
        )}
        <InputLabel
          style={labelStyle}
          label={label}
          labelLink={labelLink}
          requiredMark={requiredMark}
        />
      </div>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={options}
        value={options?.filter((opt) => selectedIds?.includes(opt?.value))}
        onChange={onChange}
        isLoading={loading}
        placeholder={placeholder}
        menuPortalTarget={document.body} // Keeps menu visible
        menuPosition="absolute" // Keeps menu aligned
        menuShouldScrollIntoView={false} // Prevents auto-scrolling
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }), // High z-index for visibility
          menu: (base) => ({ ...base, position: "absolute" }),
        }}
      />
    </div>
  );
}
