import React, { useCallback, useMemo, useState } from "react";
import Button from "../../components/common/Button";
import { inputTypes } from "./inputTypes";
import { postApiClaim } from "../../lib/AppHelper";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const BankDetailsForm = ({
  step,
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  isMobile,
  handleAddnew,
}) => {
  const [errors, setErrors] = useState({});

  const columns = useMemo(
    () => [
      {
        field: "bankId",
        label: "Bank name",
        type: "infintydropdown",
        labelField: "bankName",
        valueField: "id",
        options: state?.banks,
        requiredMark: true,
        visible: true,
        endpoint: "searchBanks",
        request: postApiClaim,
        searchParam: "bankName",
        searchId: state?.bankId,
        addNewBankTrue: true,
      },
      {
        field: "otherBankName",
        label: "Other bank Name",
        type: "text",
        requiredMark: true,
        placeholder: "Enter bank name",
        visible: ({ values }) => {
          return values?.bankId === 34 ? true : false;
        },
      },
      {
        field: "accountName",
        label: "Account holder name",
        requiredMark: true,
        visible: true,
      },
      {
        field: "accountNumber",
        label: "Account number",
        requiredMark: true,
        visible: true,
      },
      {
        field: "ifscCode",
        label: "IFSC code",
        requiredMark: true,
        visible: true,
      },
    ],
    [state]
  );

  const errorCheck = useCallback(
    (field, visible) => {
      let error;
      if (typeof visible === "function") {
        visible = visible({ values: state });
      }
      if (visible) {
        if (!state[field] || (state[field] && state[field].length < 1)) {
          error = true;
          setErrors((errors) => ({
            ...errors,
            [field]: "Please Fill this field",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [field]: void 0,
          }));
        }
      } else {
        setErrors((errors) => ({
          ...errors,
          [field]: void 0,
        }));
      }

      return error;
    },
    [state]
  );

  const onClick = useCallback(() => {
    let main = false;
    let error = false;
    columns.map((column) => {
      const { field, visible = true, required = true } = column;
      if (required) error = errorCheck(field, visible);
      if (error) {
        main = true;
      }
    });
    if (!main) return onSubmit();
  }, [columns, onSubmit, errorCheck]);

  return (
    <>
      <Accordion
        defaultExpanded
        sx={{
          outline: "none!important",
          boxShadow: "none!important",
          borderRadius: "24px!important",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          margin: "40px 0 24px 0!important",
          padding: "0 40px!important",
          backgroundColor: "#FAFAFA",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: "0", margin: "0" }}
        >
          <Typography variant="h6">Bank details</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0", margin: "0 0 20px 0" }}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {columns.map((column, index) => {
              const {
                label,
                field,
                requiredMark,
                type = "text",
                visible,
                options,
                request,
                endpoint,
                searchParam,
                searchId,
                addNewBankTrue,
                ...rest
              } = column;

              let RenderComponent = inputTypes[type];

              const isVisible =
                typeof visible === "function"
                  ? visible({ values: state })
                  : visible;
              if (!isVisible) {
                return null;
              }

              let fieldOptions = options;
              if (type === "dropdown") {
                fieldOptions =
                  field === "bankName" ? state?.banks || [] : options || [];
              }

              return (
                <div key={index}>
                  <RenderComponent
                    label={label}
                    requiredMark={requiredMark}
                    error={[errors[field]]}
                    state={value}
                    options={fieldOptions}
                    field={field}
                    setValue={(data, item) => {
                      setValue(data);
                      if (data?.bankId) {
                        setValue({
                          bankName: item.bankName,
                        });
                      }
                      if (data?.bankName === "other") {
                        setValue({
                          otherBankName: item?.name || null,
                        });
                      }
                      setErrors((errors) => ({
                        ...errors,
                        [field]: void 0,
                      }));
                    }}
                    attrs={{
                      onChange: (e) => {
                        if (field === "accountNumber") {
                          setValue({
                            [field]: e.target.value
                              .replace(/[^0-9]/g, "")
                              .slice(0, 16),
                          });
                        } else if (field === "accountName") {
                          setValue({
                            [field]: e.target.value
                              .replace(/[^a-zA-Z ]/g, "")
                              .slice(0, 34),
                          });
                        } else if (field === "otherBankName") {
                          setValue({
                            [field]: e.target.value
                              .replace(/[^a-zA-Z ]/g, "")
                              .slice(0, 30),
                          });
                        } else if (field === "ifscCode") {
                          setValue({
                            [field]: e.target.value
                              .replace(/[^a-zA-Z0-9]/g, "")
                              .slice(0, 11),
                          });
                        } else {
                          setValue({ [field]: e.target.value });
                        }
                        setErrors((errors) => ({
                          ...errors,
                          [field]: void 0,
                        }));
                      },
                      onBlur: (e) => {
                        errorCheck(field);
                      },
                      value: state[field],
                      searchParam: searchParam,
                      searchId: searchId,
                    }}
                    isAddNew={addNewBankTrue}
                    handelAddNew={handleAddnew}
                    request={request}
                    endpoint={endpoint}
                    {...rest}
                  />
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          onClick={onClick}
          style={{
            borderRadius: "12px",
            padding: "12px 32px",
            margin: "12px 0",
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};
