import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { setResellerId } from "./AppHelper";
import Layout from "../Layout";
import Main from "../screens/Main";
import AdminMain from "../screens/admin/AdminMain";

const mapStateToProps = (state) => {
  const { token, user, screenLayout } = state;
  if (user) {
    setResellerId(user.id);
  }
  return { token: token, user: user, screenLayout: screenLayout };
};

export default connect(mapStateToProps)(({ children, ...rest }) => {
  let registered = null;

  registered = rest.user?.banking ? registered : 3;
  registered = rest.user?.professional ? registered : 2;
  registered = rest.user?.basic ? registered : 1;

  const is_register = useRouteMatch("/register")?.isExact;

  return (
    <>
      {rest?.user?.is_admin ? (
        <AdminMain>
          <Route
            {...rest}
            render={({ location }) => {
              if (rest.token) {
                if (rest?.user?.is_admin) {
                  return children;
                } else if (is_register && registered === null) {
                  return <Redirect to={{ pathname: "/" }} />;
                } else if (registered === null) {
                  return children;
                } else if (is_register && registered !== null) {
                  return children;
                } else {
                  return <Redirect to={{ pathname: "/register" }} />;
                }
              } else {
                return <Redirect to={{ pathname: "/login" }} />;
              }
            }}
          />
        </AdminMain>
      ) : rest.screenLayout == "New Layout" && rest.user ? (
        <Layout user={rest.user} screenLayout={rest.screenLayout}>
          <Route
            {...rest}
            render={({ location }) => {
              if (rest.token) {
                if (rest.user.is_admin) {
                  return children;
                } else if (is_register && registered === null) {
                  return <Redirect to={{ pathname: "/" }} />;
                } else if (registered === null) {
                  return children;
                } else if (is_register && registered !== null) {
                  return children;
                } else {
                  return <Redirect to={{ pathname: "/register" }} />;
                }
              } else {
                return <Redirect to={{ pathname: "/login" }} />;
              }
            }}
          />
        </Layout>
      ) : (
        <Main>
          <Route
            {...rest}
            render={({ location }) => {
              if (rest.token) {
                if (rest.user.is_admin) {
                  return children;
                } else if (is_register && registered === null) {
                  return <Redirect to={{ pathname: "/" }} />;
                } else if (registered === null) {
                  return children;
                } else if (is_register && registered !== null) {
                  return children;
                } else {
                  return <Redirect to={{ pathname: "/register" }} />;
                }
              } else {
                return <Redirect to={{ pathname: "/login" }} />;
              }
            }}
          />
        </Main>
      )}
    </>
  );
});
