import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import ShimmerLoader from "../../components/common/ShimmerLoader";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import { AppStore } from "../../lib/AppStore";
import { ApiGet } from "../../lib/AppHelper";

const ProductsScreen = () => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      setLoading(true);
      if (user === null) return;
      if (user?.status !== 1) return;
      const response = await ApiGet("products");
      if (response.status === "success") {
        setProducts(response.data);
        AppStore.dispatch({
          type: "PRODUCTS",
          products: response.data,
          productLoading: false,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <div className="grid md:grid-cols-2 gap-2 mb-xs">
        <div>
          <AppBackButton to="/">Back</AppBackButton>
        </div>
      </div>
      <div className="flex flex-wrap justify-center mb-5 mt-0">
        <AppHeading color="black">Products</AppHeading>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {loading ? (
          <ShimmerLoader rows={10} />
        ) : products.length > 0 ? (
          <ProductCard data={products} />
        ) : (
          <div className="flex justify-center items-center font-medium text-red-500 h-52">
            {"No Products assigned yet"}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsScreen;

const ProductCard = ({ data = [] }) => {
  const [open, setOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState([]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-3">
        {data?.map((item, index) => {
          const amount = new Intl.NumberFormat("en-IN").format(
            item?.price || 0
          );
          const description = item?.product?.description;
          const cleanDescription = description
            ? description
                .replace(/<\/?li>/g, "")
                .split("\n")
                .map((i) => i.trim())
            : [];
          const visibleItems = cleanDescription.slice(0, 2);
          const remainingCount = cleanDescription.length - visibleItems.length;

          return (
            <div
              key={index}
              className="flex flex-col gap-4 px-6 py-3 rounded-xl h-full"
              style={{
                backgroundColor: "#FFFAFA",
                borderWidth: "1px",
                borderColor: "#E2E8F0",
              }}
            >
              <div className="flex flex-col gap-2 flex-grow">
                <div className="flex flex-col gap-0">
                  <p className="text-base font-medium">{item?.name}</p>
                  <p className="text-xl font-semibold">{`₹${amount}`}</p>
                </div>
                <div className="flex flex-col gap-2 text-sm">
                  {item.product?.max_members > 1 ? (
                    <li>{item.product?.max_members} Members</li>
                  ) : (
                    !(
                      item?.product?.max_members === 1 &&
                      item?.product?.product_lab_test?.auto_book
                    ) && <li>{item.product?.max_members} Member</li>
                  )}

                  {visibleItems.map((desc, idx) => (
                    <div key={idx} className="flex flex-col">
                      <li>{desc}</li>
                      {idx === visibleItems.length - 1 &&
                        remainingCount > 0 && (
                          <span
                            className="text-xs text-blue-500 cursor-pointer self-end"
                            onClick={() => {
                              setSelectedDescription(cleanDescription);
                              setOpen(true);
                            }}
                          >
                            +{remainingCount} more
                          </span>
                        )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-between items-center mt-auto pt-4 border-t border-gray-300">
                {item?.productTnc?.some((tnc) => tnc?.partner?.tnc_url) ? (
                  <Link
                    to={{
                      pathname: `/tnc`,
                      state: { product: item },
                    }}
                  >
                    <p
                      style={{
                        fontSize: "11px",
                        color: "#003CBC",
                        cursor: "pointer",
                      }}
                      className="underline truncate"
                    >
                      Terms and Conditions
                    </p>
                  </Link>
                ) : (
                  <div className="w-8"></div>
                )}

                <Link to={`/products/${item.id}`}>
                  <button className="text-xs bg-red-500 text-white px-3 py-1 rounded-md">
                    View Detail
                  </button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-80">
            <h3 className="text-lg font-semibold mb-2">All Updates</h3>
            <div className="max-h-60 overflow-auto">
              {selectedDescription?.map((elm, index) => (
                <li key={index} className="flex flex-col">
                  {elm}
                </li>
              ))}
            </div>
            <div className="mt-4 flex gap-2">
              <button
                className="w-full text-sm self-center bg-gray-200 text-black-700 py-2 rounded-md"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
