import React, { useEffect, useState } from "react";
import CustomAccordian from "../../../common/components/CustomAccordian";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Checkbox } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons";
import InputSelect from "../../../../../components/formikForm/InputSelect";
import {
  fetchProductBenefit,
  fetchVendorList,
  fieldLengthValidation,
  fieldValidation,
} from "../../../common/data/constants";
import MultiSelector from "../../../../../components/formikForm/MultiSelector";
import { memberCoveredOptions, tenureTypeOptions } from "../../../common/data";
import InputGroup from "../../../../../components/formikForm/InputGroup";
import InputRadio from "../../../../../components/formikForm/InputRadio";

const CreditLife = ({
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  membersCovered,
}) => {
  const [open, setOpen] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);

  const getVendorList = async () => {
    setVendorLoading(true);
    const list = await fetchVendorList("CLOAN");
    setVendorList(list);
    setVendorLoading(false);
  };

  const getBenefits = async () => {
    setLoading(true);
    const list = await fetchProductBenefit("CLOAN");
    if (list?.length) {
      const formattedOptions = list.map((benefit) => ({
        label: benefit?.description_text, // Adjust based on API response
        value: benefit?.id,
      }));
      setBenefit(formattedOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open && !vendorList?.length) {
      getVendorList();
    }
    if (open && !benefit?.length) {
      getBenefits();
    }
  }, [open]);

  const handleBenefitChange = (e) => {
    const data = e.map((item) => item?.value);
    setFieldValue("productBenefitId", data);
  };

  return (
    <CustomAccordian
      active={open}
      header={
        <div
          onClick={() => setOpen((prev) => !prev)}
          className="flex justify-between"
        >
          <div className="flex items-center">
            {open ? <ArrowDropDown className="rotate-180" /> : <ArrowRight />}

            <div
              onClick={(e) => {
                e?.stopPropagation();
              }}
            >
              <Checkbox
                name="Credit Life Loan"
                checked={values?.CLOAN}
                onChange={(e, v) => {
                  setFieldValue(`CLOAN`, v);
                  if (v && !open) {
                    setOpen(true);
                  }
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                icon={faLifeRing}
                style={{ color: "#A076F9" }}
                className="text-primary-200"
              ></FontAwesomeIcon>
              <span
                className="text-base text-gray-700"
                style={{ fontWeight: 550 }}
              >
                Credit Life Loan
              </span>
            </div>
          </div>
        </div>
      }
      children={
        <div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-2 items-center">
            <InputSelect
              error={touched?.vendorId && errors?.vendorId}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              label="Vendor Name "
              name={"vendorId"}
              value={values?.vendorId}
              helperText="Choose vendor for this subscription"
              onChange={(e) => {
                setFieldValue(`vendorId`, e.target.value);
                setFieldTouched("vendorId", true, false);
              }}
              options={vendorList || []}
              loading={vendorLoading}
            />
            <InputSelect
              error={touched?.tenureType && errors?.tenureType}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              label="Tenure Type"
              name={"tenureType"}
              value={values?.tenureType}
              helperText="Choose the duration type for the product."
              onChange={(e) => {
                setFieldValue(`tenureType`, e.target.value);
                setFieldTouched("tenureType", true, false);
              }}
              options={tenureTypeOptions}
            />

            <InputGroup
              error={touched?.tenure && errors?.tenure}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              disabled={!values?.tenureType}
              label="Tenure"
              name="tenure"
              value={values?.tenure}
              placeHolder="Enter tenure duration (e.g., 12 for 12 months)"
              helperText="The duration for which the product is active."
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  (e.target.value.length < fieldLengthValidation.number &&
                    fieldValidation.number.test(e.target.value))
                ) {
                  setFieldValue("tenure", e.target.value);
                  setFieldTouched("tenure", true, false);
                } else if (!values?.tenure) {
                  setFieldValue("tenure", "");
                }
              }}
            />
            <InputSelect
              error={touched?.membersCovered && errors?.membersCovered}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              label="Member Covered"
              name={"membersCovered"}
              value={values?.membersCovered}
              helperText="Members covered for this sub-product."
              onChange={(e) => {
                setFieldValue(`membersCovered`, e.target.value);
                setFieldTouched("membersCovered");
              }}
              options={memberCoveredOptions.filter(
                (item, index) => index < membersCovered
              )}
            />

            <InputRadio
              label="Amount Type"
              options={[
                { label: "Factor Amount", value: "factor" },
                { label: "Range Amount", value: "range" },
              ]}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              name={"amountType"}
              value={values.amountType}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "factor") {
                  setFieldValue(`lowerLimit`, "");
                  setFieldValue(`upperLimit`, "");
                } else {
                  setFieldValue("factorAmount", "");
                }
                setFieldValue(`amountType`, value);
                setFieldTouched("factorAmount", true, false);
              }}
            />

            {values.amountType === "factor" ? (
              <InputGroup
                error={touched?.factorAmount && errors?.factorAmount}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                label="Factor Amount"
                name="factorAmount"
                max={5}
                value={values?.factorAmount}
                onChange={(e) => {
                  if (
                    e.target.value == "" ||
                    (e.target.value <= 100 &&
                      /^\d*\.?\d*$/.test(e.target.value))
                  ) {
                    setFieldValue("factorAmount", e.target.value);
                    setFieldTouched("factorAmount", true, false);
                  } else if (!values?.factorAmount) {
                    setFieldValue("factorAmount", "");
                  }
                }}
                placeHolder="0"
                helperText="Enter factor amount for credit life product"
              />
            ) : (
              <>
                <InputGroup
                  error={touched?.lowerLimit && errors?.lowerLimit}
                  style={{ fontWeight: "550", fontSize: "12px" }}
                  requiredMark={true}
                  label="Lower Limit"
                  name="lowerLimit"
                  max={8}
                  value={values?.lowerLimit}
                  onChange={(e) => {
                    if (
                      e.target.value == "" ||
                      fieldValidation.number.test(e.target.value)
                    ) {
                      setFieldValue("lowerLimit", e.target.value);
                      setFieldTouched("lowerLimit", true, false);
                    } else if (!values?.lowerLimit) {
                      setFieldValue("lowerLimit", "");
                    }
                  }}
                  placeHolder="0"
                  helperText="Enter lower amount for credit life product"
                />
                <InputGroup
                  max={8}
                  error={touched?.upperLimit && errors?.upperLimit}
                  style={{ fontWeight: "550", fontSize: "12px" }}
                  requiredMark={true}
                  label="Upper Limit"
                  name="upperLimit"
                  value={values?.upperLimit}
                  onChange={(e) => {
                    if (
                      e.target.value == "" ||
                      fieldValidation.number.test(e.target.value)
                    ) {
                      setFieldValue("upperLimit", e.target.value);
                      setFieldTouched("upperLimit", true, false);
                    } else if (!values?.upperLimit) {
                      setFieldValue("upperLimit", "");
                    }
                  }}
                  placeHolder="0"
                  helperText="Upper amount is greater then lower amount"
                />
              </>
            )}
          </div>
          <div className="p-2 flex flex-col gap-1">
            <p className="font-medium">Product Benefit</p>
            <MultiSelector
              options={benefit}
              onChange={handleBenefitChange}
              selectedIds={values?.productBenefitId}
              loading={loading}
            />
          </div>
        </div>
      }
    />
  );
};

export default CreditLife;
