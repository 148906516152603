import React, { useEffect, useRef, useState } from "react";
import { JavaApiPost } from "../../lib/AppHelper";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";
import moment from "moment";
import ShimmerLoader from "../../components/common/ShimmerLoader";
import {
  getClaimStatusColor,
  getUpdateDescription,
} from "../NewHome/common/data";
import { useLocation } from "react-router-dom";
import { Pagination } from "rsuite";

const Notifications = () => {
  const location = useLocation();
  const key = location?.state?.data;

  const prevFilters = useRef();
  const user = useSelector((state) => state?.user);
  const userPermissions = user?.permissions;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    resellerId: user?.id,
    page: 1,
    size: 10,
    category: key ? key : "",
    sortBy: "desc",
  });

  const tabs = [
    {
      label: "All",
      value: "",
      permission: [],
    },
    {
      label: "Reimbursement",
      value: "Reimbursement",
      permission: ["claim_view", "partner_wise_reimbursement_view"],
    },
    {
      label: "Data Uploads",
      value: "Data Uploads",
      permission: [
        "upload_excel",
        "excel_uploader_history_table",
        "group_upload",
        "policy_uploader",
        "credit_upload",
        "emi_product_uploader",
      ],
    },
  ];

  const handleSort = () => {
    setFilters((state) => ({
      ...state,
      sortBy: state.sortBy == "asc" ? "desc" : "asc",
    }));
  };

  const handleChangePage = (pg) => {
    if (filters.page == pg) return;
    setFilters((state) => ({
      ...state,
      page: pg,
    }));
  };

  const getNotificationData = async (payload) => {
    if (JSON.stringify(prevFilters.current) === JSON.stringify(payload)) return;
    try {
      setLoading(true);
      let body = {
        ...payload,
        page: payload.page ? payload.page - 1 : 0,
      };
      const response = await JavaApiPost(
        "enrollment-service/api/get-task-notifications",
        body
      );
      if (response.code == 200) {
        setData(response?.responseObject);
        setTotal(response?.count);
      } else {
        setData([]);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
    prevFilters.current = payload;
  };

  useEffect(() => {
    getNotificationData(filters);
  }, [filters]);

  return (
    <>
      <div className="md:flex-row md:justify-center items-center">
        <div className="w-full md:flex-1 md:justify-start flex">
          <AppBackButton to="/" label="Back" color="#000000" />
        </div>
        <div className="w-full md:flex-1 flex justify-center">
          <AppHeading
            className=" text-2xl text-center font-medium mb-6"
            style={{ color: "#000000" }}
          >
            Recent updates
          </AppHeading>
        </div>
        <div className="hidden md:flex md:flex-1"></div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex justify-start  border-b border-b-[#0000003D]">
          <ul
            className="text-sm font-medium text-center flex gap-2 border-b border-b-[#0000003D] overflow-x-auto whitespace-nowrap scrollbar-hide"
            data-tabs="tabs"
            role="list"
          >
            {tabs.map((tab, index) => {
              const tabPermission = tab?.permission;
              let hasPermission = tabPermission.length
                ? tabPermission?.some((perm) => userPermissions?.[perm])
                : true;

              return (
                hasPermission && (
                  <li
                    className={`flex-auto text-gray-700 font-semibold inline-block w-full min-w-[120px] p-2 ${
                      filters.category === tab.value
                        ? "border-b-4 border-primary-500 text-primary-500"
                        : "bg-white-50"
                    } focus:outline-none`}
                    key={index}
                    onClick={() => {
                      setFilters((state) => ({
                        ...state,
                        category: tab.value,
                        page: 1,
                      }));
                    }}
                  >
                    <a
                      className="flex items-center justify-center w-full px-0 py-2 text-sm mb-0 transition-all ease-in-out border-0 rounded-md cursor-pointer text-slate-600 bg-inherit"
                      data-tab-target=""
                      role="tab"
                      aria-selected="true"
                    >
                      <span
                        className={`text-sm font-semibold ${
                          filters.category === tab.value
                            ? "text-primary-500"
                            : "text-gray-600"
                        }`}
                      >
                        {tab.label}
                      </span>
                    </a>
                  </li>
                )
              );
            })}
          </ul>
        </div>

        {/* Content */}
        <div className="flex flex-col md:block">
          <div className="overflow-x-auto">
            <table className="min-w-full border" id="table-to-xls">
              <thead className="bg-gray2 text-white h-8">
                <th className="text-xs font-sm p-3 whitespace-nowrap text-left">
                  Date
                  <span onClick={handleSort} className="cursor-pointer">
                    {filters.sortBy === "asc" ? (
                      <Tooltip title="Sort in ascending order" arrow>
                        <ArrowUpwardIcon
                          style={{ color: "white", fontSize: 16 }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Sort in descending order" arrow>
                        <ArrowDownwardIcon
                          style={{ color: "white", fontSize: 16 }}
                        />
                      </Tooltip>
                    )}
                  </span>
                </th>
                <th className="text-xs font-sm p-3 whitespace-nowrap text-left">
                  Category
                </th>
                <th className="text-xs font-sm p-3 whitespace-nowrap text-left">
                  Status
                </th>
                <th className="text-xs font-sm p-3 whitespace-nowrap text-left">
                  Description
                </th>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                {loading ? (
                  <tr>
                    <td colSpan={9}>
                      <ShimmerLoader rows={15} />
                    </td>
                  </tr>
                ) : data && data?.length ? (
                  data?.map((item, index) => {
                    let date = item.date
                      ? moment(item.date).format("MMMM Do YYYY")
                      : "";

                    let status = getClaimStatusColor(
                      item.category,
                      item?.status.replace(/[^a-zA-Z ]/g, "").toUpperCase()
                    );

                    let description = getUpdateDescription(item.category, item);

                    return (
                      <tr className={`border-b-2 bg-tableColumn`} key={index}>
                        <td
                          style={{ color: "#111827" }}
                          className="text-sm font-medium text-gray-900 p-3 whitespace-nowrap text-left"
                        >
                          {item?.date ? date : "--"}
                        </td>
                        <td
                          style={{
                            color:
                              item.category == "Reimbursement"
                                ? "#008000"
                                : item.category == "Data Uploads"
                                ? "#CC7822"
                                : "#A83CFD",
                          }}
                          className="text-xs font-medium text-gray-900 p-3 whitespace-nowrap text-left"
                        >
                          {item.category}
                        </td>
                        <td className="text-gray-900 p-3 whitespace-nowrap text-left capitalize">
                          <span
                            style={{
                              backgroundColor: status.backgroundColor,
                              color: status.color,
                              width: "fit-content",
                            }}
                            className="flex items-center gap-2 text-xs px-2 py-1 rounded-xl"
                          >
                            <div
                              style={{ backgroundColor: status.color }}
                              className="w-2 h-2 rounded-full"
                            ></div>
                            <span>{status.status.toLocaleLowerCase()}</span>
                          </span>
                        </td>
                        <td className="text-sm font-medium text-gray-900 p-3 whitespace-nowrap text-left">
                          {description}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      height="150"
                      className="text-red-500 font-light text-center border"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={4}
                size="xs"
                layout={["total", "-", "pager", "skip"]}
                total={total}
                limit={filters.size}
                activePage={filters.page}
                onChangePage={handleChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
