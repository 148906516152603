import React from "react";

const SwitchToggle = ({ value, setValue, handleValueChange, label }) => {
  const handleToggle = () => {
    const newValue = value === "New Layout" ? "Old Layout" : "New Layout";

    if (handleValueChange) {
      handleValueChange(newValue);
    }
  };

  return (
    <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
      <input
        type="checkbox"
        name="switchToggle"
        className="sr-only"
        checked={value === "New Layout"}
        onChange={handleToggle}
      />
      <span
        className={`slider mr-3 flex items-center rounded-full  p-1 duration-200`}
        style={{
          height: 12,
          width: 30,
          backgroundColor: value === "New Layout" ? "#db2228" : "lightGray",
        }}
      >
        <span
          style={{
            height: 8,
            width: 8,
            transform: value === "New Layout" ? `translate(${16}px)` : "",
          }}
          className={`dot rounded-full bg-white duration-200 ${
            value === "New Layout" ? "translate-x-6" : ""
          }`}
        ></span>
      </span>
      <span className="label flex items-center text-xs font-medium text-black">
        <span className="pr-1"> {value === "New Layout" ? "New" : "Old"} </span>
        {label}
      </span>
    </label>
  );
};

export default SwitchToggle;
