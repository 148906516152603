import React, { Component } from "react";
import { Disclosure } from "@headlessui/react";
import { DownloadIcon } from "@heroicons/react/outline";
import AppMenu from "../components/AppMenu";
import Logo from "../components/Logo";
import UserMenu from "../components/UserMenu";
import AppSpinner from "../components/AppSpinner";
import { connect } from "react-redux";
import { AppMessage } from "../components/AppMessage";
import { Link, withRouter } from "react-router-dom";
import Button from "../components/Button";
import { ApiGet, AppLogout, getError, getMessage } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import SimpleBackdrop from "../components/common/Backdrop";
import useOnlineStatus from "@rehooks/online-status";
import * as MyRsuite from "rsuite";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ApplicationViewIcon, NotificationIcon } from "../Layout/SidebarIcons";
import { FaBars } from "react-icons/fa";
import SwitchToggle from "./NewHome/common/SwitchToggle";
import BreadCrumb from "../components/common/BreadCrumb";

export function OnlineStatus() {
  const onlineStatus = useOnlineStatus();
  return (
    !onlineStatus && (
      <div
        className={`focus:outline-none focus:ring-0 relative py-3 pl-4 pr-10 leading-normal 
              ${onlineStatus ? "text-green-700" : "text-primary-700"} ${
          onlineStatus ? "bg-green-100" : "bg-primary-100"
        }`}
      >
        <h1>You are currently {onlineStatus ? "online" : "offline"}</h1>
      </div>
    )
  );
}

export class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisiable: false,
    };
    this.handleDragEnter = this.handleDragEnter.bind(this);
  }
  loadingRef = React.createRef();
  state = {
    open: false,
    isApplicationMenuOpen: false,
  };

  handleDragEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.current === 1)
      AppStore.dispatch({ type: "BACKDROP", backdrop: true });
  }

  renderIconButton(props, ref) {
    return (
      <MyRsuite.IconButton
        {...props}
        ref={ref}
        icon={<FaBars />}
        circle
        color="#e5e7eb"
      />
    );
  }
  toggleApplicationMenu = () => {
    this.setState({ isApplicationMenuOpen: !this.state.isApplicationMenuOpen });
  };

  render() {
    const {
      loading,
      progress,
      MessageRef = null,
      user,
      headerDownload,
      onDrop,
      onDragOver,
      onDragLeave,
      current,
      screenLayout,
    } = this.props;

    return (
      <>
        {this.props.backdrop && current === 1 && (
          <SimpleBackdrop
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
          />
        )}
        <div style={{ height: "100vh" }} onDragEnter={this.handleDragEnter}>
          <OnlineStatus />
          {loading ? (
            <div
              tabIndex={0}
              ref={this.loadingRef}
              className="fixed w-screen h-screen bg-primary-50 z-50 opacity-60 flex items-center justify-center"
            >
              <AppSpinner />
            </div>
          ) : (
            false
          )}
          {MessageRef !== null ? <AppMessage ref={MessageRef} /> : false}
          <div
            style={
              loading
                ? {
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    pointerEvents: "none",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                  }
            }
          >
            <Disclosure as="nav" className="border-b border-gray-400">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-8">
                    <div className="flex items-center justify-between h-16">
                      {/* aiqahealth logo */}
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <Link to="/">
                            <Logo />
                          </Link>
                        </div>
                        <AppMenu />
                      </div>

                      {/* Desktop Menu */}
                      {user ? (
                        user?.status === 1 && user.basic && user.banking ? (
                          <div className="hidden md:visible md:flex">
                            <div className="flex flex-1 items-center justify-between gap-3">
                              {user
                                ? user?.permissions?.center_coordinator ||
                                  user?.permissions?.aiqa_center ||
                                  user?.permissions?.hc_view
                                  ? null
                                  : user?.permissions?.dashboard_toggle && (
                                      <Tooltip
                                        title="Switch dashboard view"
                                        placement="bottom"
                                        arrow
                                      >
                                        <div className="flex justify-center items-center relative">
                                          <SwitchToggle
                                            value={screenLayout}
                                            handleValueChange={() => {
                                              AppStore.dispatch({
                                                type: "SCREEN_LAYOUT",
                                                screenLayout:
                                                  screenLayout == "Old Layout"
                                                    ? "New Layout"
                                                    : "Old Layout",
                                              });
                                              localStorage.setItem(
                                                "SCREEN_LAYOUT",
                                                screenLayout == "Old Layout"
                                                  ? "New Layout"
                                                  : "Old Layout"
                                              );
                                            }}
                                            label="Dashboard"
                                          />
                                        </div>
                                      </Tooltip>
                                    )
                                : null}

                              {user?.permissions?.download_archive ? (
                                <div className="cursor-pointer hover:text-dark-900 relative flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                                  <Tooltip
                                    title="Download Center"
                                    placement="bottom"
                                    arrow
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                        width: "40px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {headerDownload && (
                                        <CircularProgress
                                          sx={{ color: "#db2228" }}
                                          size={40}
                                          thickness="1.2"
                                        />
                                      )}
                                      <DownloadIcon
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          cursor: "pointer",
                                          height: "18px",
                                          width: "18px",
                                          color: "#e23744",
                                        }}
                                        onClick={() => {
                                          this.props.history.push(
                                            "/download-center"
                                          );
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              ) : (
                                false
                              )}

                              {user
                                ? user?.permissions?.center_coordinator ||
                                  user?.permissions?.aiqa_center ||
                                  user?.permissions?.hc_view
                                  ? null
                                  : user?.permissions?.recent_updates && (
                                      <div className="self-center">
                                        <Tooltip
                                          title="Updates"
                                          placement="bottom"
                                          arrow
                                        >
                                          <Link to="/notifications">
                                            <div className="hover:text-dark-900 relative flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                                              <NotificationIcon
                                                width={20}
                                                height={20}
                                                color={"#8E8E93"}
                                              />
                                            </div>
                                          </Link>
                                        </Tooltip>
                                      </div>
                                    )
                                : null}

                              <UserMenu />
                            </div>
                          </div>
                        ) : (
                          <Button
                            attrs={{
                              onClick: () => this.onLogout(),
                            }}
                            title="Logout"
                          />
                        )
                      ) : null}

                      {/* Mobile Menu */}
                      <div className="flex items-center gap-3 justify-between md:hidden">
                        {user ? (
                          user?.status === 1 && user.basic && user.banking ? (
                            <div
                              className="z-99999 flex h-10 w-10 items-center justify-center rounded-lg text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 lg:hidden bg-gray-100 dark:bg-gray-800 cursor-pointer"
                              onClick={this.toggleApplicationMenu}
                            >
                              <ApplicationViewIcon />
                            </div>
                          ) : (
                            <Button
                              attrs={{
                                onClick: () => this.onLogout(),
                              }}
                              title="Logout"
                            />
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="md:hidden">
                    <AppMenu mobile={true} />
                    {user && <UserMenu mobile={true} />}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            {user?.status === 1 && user.basic && user.banking ? (
              <div
                className={`${
                  this.state.isApplicationMenuOpen ? "flex" : "hidden"
                } items-center justify-between w-full gap-4 px-5 py-4 shadow-md md:hidden `}
              >
                <div className="flex items-center gap-2 2xsm:gap-3">
                  {user && (
                    <>
                      {user
                        ? user?.permissions?.center_coordinator ||
                          user?.permissions?.aiqa_center ||
                          user?.permissions?.hc_view
                          ? null
                          : user?.permissions?.dashboard_toggle && (
                              <Tooltip
                                title="Switch dashboard view"
                                placement="bottom"
                                arrow
                              >
                                <div className="flex justify-center items-center relative">
                                  <SwitchToggle
                                    value={screenLayout}
                                    handleValueChange={(e) => {
                                      AppStore.dispatch({
                                        type: "SCREEN_LAYOUT",
                                        screenLayout:
                                          screenLayout == "Old Layout"
                                            ? "New Layout"
                                            : "Old Layout",
                                      });
                                      localStorage.setItem(
                                        "SCREEN_LAYOUT",
                                        screenLayout == "Old Layout"
                                          ? "New Layout"
                                          : "Old Layout"
                                      );
                                    }}
                                    label="Dashboard"
                                  />
                                </div>
                              </Tooltip>
                            )
                        : null}

                      {user?.permissions?.download_archive ? (
                        <div className="cursor-pointer hover:text-dark-900 relative flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                          <Tooltip
                            title="Download Center"
                            placement="bottom"
                            arrow
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                width: "40px",
                                cursor: "pointer",
                              }}
                            >
                              {headerDownload && (
                                <CircularProgress
                                  sx={{ color: "#db2228" }}
                                  size={40}
                                  thickness="1.2"
                                />
                              )}
                              <DownloadIcon
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  cursor: "pointer",
                                  height: "18px",
                                  width: "18px",
                                  color: "#e23744",
                                }}
                                onClick={() => {
                                  this.props.history.push("/download-center");
                                }}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        false
                      )}

                      <div>
                        {user
                          ? user?.permissions?.center_coordinator ||
                            user?.permissions?.aiqa_center ||
                            user?.permissions?.hc_view
                            ? null
                            : user?.permissions?.recent_updates && (
                                <div className="hover:text-dark-900 relative flex h-11 w-11 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-whiteself-center">
                                  <Tooltip
                                    title="Updates"
                                    placement="bottom"
                                    arrow
                                  >
                                    <Link to="/notifications">
                                      <div className="self-center">
                                        <NotificationIcon color={"#8E8E93"} />
                                      </div>
                                    </Link>
                                  </Tooltip>
                                </div>
                              )
                          : null}
                      </div>
                    </>
                  )}
                </div>
                <UserMenu />
              </div>
            ) : null}
            {/* Logout Modal for users with status of 0 */}
            <MyRsuite.Modal
              maxWidth="md"
              open={this.state.open}
              onClose={this.handleClose}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MyRsuite.Modal.Header>
                <MyRsuite.Modal.Title style={{ fontWeight: "600" }}>
                  Logout
                </MyRsuite.Modal.Title>
              </MyRsuite.Modal.Header>
              <MyRsuite.Modal.Body>
                Are you sure you want to logout?
              </MyRsuite.Modal.Body>
              <MyRsuite.Modal.Footer>
                <MyRsuite.Button
                  onClick={this.handleLogout}
                  style={{ backgroundColor: "#db2228", color: "white" }}
                >
                  Yes
                </MyRsuite.Button>
                <MyRsuite.Button
                  onClick={this.handleClose}
                  style={{ backgroundColor: "lightGray", color: "black" }}
                >
                  Cancel
                </MyRsuite.Button>
              </MyRsuite.Modal.Footer>
            </MyRsuite.Modal>

            <div className="max-w-7xl flex-1 justify-center w-full m-auto p-8 mb-10">
              {/* {user?.status === 1 && user.basic && user.banking ? (
                <span>
                  <BreadCrumb />
                </span>
              ) : null} */}

              {this.props.children}
            </div>
            {this.props.history?.location?.pathname === "/subscribe" && (
              <div
                className="h-1 rounded-xl bg-primary transition-all border-primary"
                style={{ width: `${progress}%` }}
              ></div>
            )}
          </div>
        </div>
      </>
    );
  }

  onLogout = () => {
    this.setState({ open: true });
  };

  handleLogout = () => {
    this.setState();
    AppLogout();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.getProducts();
    this.checkMessages();
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.loading !== this.props.loading && this.props.loading) {
      this.loadingRef.current.focus();
    }
  }

  getProducts = async () => {
    const { user, products } = this.props;
    if (user === null) return;
    if (user?.status !== 1 || products?.length > 0) return;
    const response = await ApiGet("products");
    if (response.status === "success") {
      AppStore.dispatch({
        type: "PRODUCTS",
        products: response.data,
        productLoading: false,
      });
    }
  };

  checkMessages = async () => {
    const error = await getError();
    if (error && this.props.MessageRef?.current) {
      this.props.MessageRef?.current.show(error, "error");
    }

    const message = await getMessage();
    if (message && this.props.MessageRef?.current) {
      this.props.MessageRef?.current.show(message, "success");
    }
  };
}

const mapStateToProps = (state) => {
  const {
    loading,
    productLoading,
    user,
    progress,
    products,
    backdrop,
    headerDownload,
    screenLayout,
  } = state;
  return {
    loading: loading,
    productLoading: productLoading,
    user: user,
    progress: progress,
    products: products,
    backdrop: backdrop,
    headerDownload: headerDownload,
    screenLayout: screenLayout,
  };
};

const MainConnect = connect(mapStateToProps)((props) => {
  return <Main {...props} />;
});

export default withRouter(MainConnect);
