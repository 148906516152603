import React, { useState } from "react";
import { ApiGet } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import { Loader, Placeholder } from "rsuite";
import LabTestIcon from "../../../assets/productCreation/newLabTestIcon.png";
import CustomModal from "../common/components/CustomModal";
import ShimmerLoader from "../../../components/common/ShimmerLoader";
import CustomAccordian from "../common/components/CustomAccordian";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { FaMicroscope } from "react-icons/fa";

const LabTestDetail = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [labTestInfo, setLabTestInfo] = useState([]);

  const fetchLabTestInfo = async () => {
    setIsModalOpen(true);
    const response = await ApiGet(
      `admin/show-lab-test-parameter?lab_test_feature_id=${id}`
    );

    if (response.code == 200) {
      setIsModalOpen(true);
      setLabTestInfo(response?.data);
    } else {
      toast.error(response?.message);
    }
    setLoading(false);
  };

  const getLabTestInfo = () => {
    setLoading(true);
    fetchLabTestInfo();
  };

  return (
    <>
      <Tooltip title="Vendor details" placement="right" arrow>
        <div
          className="cursor-pointer underline text-blue-600"
          onClick={getLabTestInfo}
        >
          {id}
        </div>
      </Tooltip>

      <CustomModal
        title={
          <div className="flex items-center gap-2 ">
            <img style={{ width: "30px", height: "30px" }} src={LabTestIcon} />
            <p className="text-l font-bold">
              {`Lab Test ID - `}
              <span className="text-m font-semibold">{id} </span>
            </p>
          </div>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        body={
          loading ? (
            <div
              className="flex"
              style={{
                height: "300px",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Placeholder.Paragraph rows={8} />
              <Loader size="md" content="Loading..." />
            </div>
          ) : (
            <LabTestModal labTest={labTestInfo} />
          )
        }
      />
    </>
  );
};

export default LabTestDetail;

const LabTestModal = ({ labTest }) => {
  const [isActive, setIsActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState([]);

  const fetchBenefits = async (benefitId) => {
    const response = await ApiGet(
      `admin/show-lab-test-parameter-benefit?parameter_id=${benefitId}`
    );
    if (response.code == 200) {
      setBenefits(response.data);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const onBenefitPress = (index, benefitId) => {
    if (isActive !== index) {
      setIsActive(index); // Set the active index
      setLoading(true);
      fetchBenefits(benefitId);
    } else {
      setIsActive(null);
      setBenefits([]);
    }
  };
  return (
    <div className="bg-modalBg">
      {labTest.length ? (
        labTest.map((labTest, i) => {
          return (
            <CustomAccordian
              active={isActive == i}
              className={"border border-gray-200 mb-1"}
              header={
                <div
                  className="flex items-center gap-2 justify-between"
                  onClick={() => onBenefitPress(i, labTest.id)}
                >
                  <div className="flex items-center gap-2 ">
                    <FaMicroscope size={30} color="#FF6B6B" />
                    <p className="text-sm font-bold">
                      {labTest?.parameter_name}
                    </p>
                  </div>
                  <div>{isActive == i ? "-" : "+"}</div>
                </div>
              }
              children={
                <>
                  {loading ? (
                    <ShimmerLoader rows={2} />
                  ) : (
                    <div>
                      <ul style={{ marginLeft: "20px" }}>
                        {benefits.length ? (
                          benefits.map((benefit) => {
                            return (
                              <li className="flex gap-1">
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  style={{
                                    color: "green",
                                    fontSize: "14px",
                                    alignSelf: "center",
                                  }}
                                />
                                {benefit?.benefit}
                              </li>
                            );
                          })
                        ) : (
                          <div className="flex justify-center items-center">
                            <p
                              className="text-center ml-4"
                              style={{ fontSize: "16px" }}
                            >
                              No information found
                            </p>
                          </div>
                        )}
                      </ul>
                    </div>
                  )}
                </>
              }
            />
          );
        })
      ) : (
        <div>
          <p className="text-sm ml-4">No information found</p>
        </div>
      )}
    </div>
  );
};
