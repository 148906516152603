import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const HeaderCards = ({
  tab,
  headerData,
  setTabValue,
  setFilter,
  tabValue,
  permissions,
}) => {
  return (
    <>
      <div className="flex lg:hidden justify-end mb-6">
        {permissions?.claim_add ? (
          <Link to="/reimbursements/raise-reimbursement">
            <button
              title="Add Reimbursement"
              className="text-sm p-2 rounded-md flex items-center"
              style={{
                fontWeight: "600",
                color: "#ffffff",
                background: "#DB2228",
                border: "1px solid #DB2228",
              }}
            >
              <AddIcon />
              <p>Add Reimbursement</p>
            </button>
          </Link>
        ) : (
          false
        )}
      </div>

      <div className="sm:hidden">
        <label for="tabs" className="sr-only">
          Select tab
        </label>
        <select
          id="tabs"
          className="text-gray-900 rounded-t-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
          onChange={(e) => {
            const value = e.target.value;
            if (value === "open_claim") {
              setFilter("IN_PROGRESS");
            } else if (value === "closed_claim") {
              setFilter("SETTLED");
            } else {
              setFilter("");
            }
            setTabValue(value);
          }}
        >
          {tab?.map((item, index) => (
            <option value={item.value} key={index} className="font-bold">
              {item.label} &nbsp;&nbsp;count : {headerData[item.value]}
            </option>
          ))}
        </select>
      </div>

      <ul
        className="hidden text-sm font-medium text-center sm:flex gap-2 rtl:divide-x-reverse border-b border-b-[#0000003D]"
        id="fullWidthTab"
        data-tabs-toggle="#fullWidthTabContent"
        role="tablist"
      >
        <div className="flex flex-grow">
          {tab?.map((item, index) => {
            return (
              <li
                style={{ width: "189px", marginRight: "8px" }}
                key={index}
                onClick={() => {
                  if (item.value === "open_claim") {
                    setFilter("IN_PROGRESS");
                  } else if (item.value === "closed_claim") {
                    setFilter("SETTLED");
                  } else if (item.value === "draft_claim") {
                    setFilter("DRAFT");
                  } else {
                    setFilter("");
                  }
                  setTabValue(item.value);
                }}
              >
                <button
                  id="stats-tab"
                  data-tabs-target="#stats"
                  type="button"
                  role="tab"
                  aria-controls="stats"
                  aria-selected="true"
                  className={`text-gray-700 font-semibold inline-block w-full p-2 ${
                    tabValue === item.value
                      ? "border-b-4 border-primary-500 text-primary-500"
                      : "bg-white-50"
                  }  focus:outline-none`}
                >
                  {item.label}
                  <span
                    className={`pl-4 font-medium ${
                      tabValue === item.value
                        ? "text-primary-500"
                        : "text-gray-600"
                    }`}
                  >
                    {headerData[item.value] !== undefined &&
                    headerData[item.value] !== null
                      ? headerData[item.value]
                      : ""}
                  </span>
                </button>
              </li>
            );
          })}
        </div>

        <div className="hidden lg:flex ml-auto">
          {permissions?.claim_add ? (
            <Link to="/reimbursements/raise-reimbursement">
              <button
                title="Add Reimbursement"
                className="text-sm p-2 rounded-md flex items-center"
                style={{
                  fontWeight: "600",
                  color: "#ffffff",
                  background: "#DB2228",
                  border: "1px solid #DB2228",
                }}
              >
                <AddIcon />
                <p>Add Reimbursement</p>
              </button>
            </Link>
          ) : (
            false
          )}
        </div>
      </ul>
    </>
  );
};

export default HeaderCards;
