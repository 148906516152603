import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiGet } from "../../../lib/AppHelper";
import ShimmerLoader from "../../../components/common/ShimmerLoader";
import ReimbursementExpandableComponent from "./ReimbursementExpandableComponent";

const ReimbursementSubPolicyDetail = ({ applicationId, memberData = null }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const response = await ApiGet(`subscription/${applicationId}`);
    if (response.status === "success") {
      setData(response.data);
      setLoading(false);
    } else {
      toast.error(response.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <ShimmerLoader rows={4} />
      ) : (
        <ReimbursementExpandableComponent data={data} memberData={memberData} />
      )}
    </div>
  );
};

export default ReimbursementSubPolicyDetail;
