import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { AppBackButton } from "../../../components/AppComponents";
import { Link } from "react-router-dom";
import InProcessRecord from "./InProcessRecord";
import { Pagination } from "rsuite";
import {
  downloadClaimExcelFile,
  getApiClaim,
  getResellerId,
  postApiClaim,
} from "../../../lib/AppHelper";
import moment from "moment";
import { toast } from "react-toastify";
import { AppStore } from "../../../lib/AppStore";
import { InputSelect } from "../../../components/form/InputSelect";
import RangePicker from "../../../components/common/DateRangePicker";
import { RefreshIcon } from "@heroicons/react/outline";

const calculateDate = {
  today: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  yesterday: {
    startDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
  },
  week: {
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  month: {
    startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  year: {
    startDate: moment().subtract(1, "y").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  custom: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
};

const ReimbursementUploader = () => {
  const payloadRef = useRef(null);
  const MessageRef = useRef(null);
  const sortRef = useRef(null);
  const inprogressDataRef = useRef(null);
  const resellerId = getResellerId();
  const [filter, setFilter] = useState({
    resellerId: resellerId,
    page: 0,
    size: 10,
    sequenceType: "desc",
    ...calculateDate["week"],
  });
  const [total, setTotal] = useState(0);
  const [data, setData] = useState();
  const [InProcessData, setInProcessData] = useState([]);
  const [dateType, setDateType] = useState("week");

  const getData = async (parse) => {
    if (parse) {
      setFilter((prev) => ({ ...prev, page: 0 }));
    }
    if (JSON.stringify(filter) === payloadRef.current && !parse) {
      return;
    } else {
      sortRef.current = null;
      AppStore.dispatch({ type: "LOADING", loading: true });
      payloadRef.current = JSON.stringify(filter);
      const response = await postApiClaim("fetchBatchWiseData", filter);
      if (response.code === 200) {
        setData(response?.data?.content);
        setTotal(response?.count);
      } else {
        setData([]);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  const fetchInprogressData = async () => {
    const response = await getApiClaim(`getBatchWiseStatus/${resellerId}`);
    if (response.code === 200) {
      if (inprogressDataRef.current !== JSON.stringify(response?.data)) {
        inprogressDataRef.current = JSON.stringify(response?.data);
        getData(true);
      }
      setInProcessData(response?.data);
    } else {
      setInProcessData([]);
    }
  };

  useEffect(() => {
    fetchInprogressData();
    const intervalId = setInterval(fetchInprogressData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!sortRef.current) getData();
  }, [filter]);

  const downloadSuccessRecord = async (row) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { id } = row;
    if (id) {
      const res = await downloadClaimExcelFile(
        `/getSuccessClaimExcelData/${id}`,
        `${row.fileName}`.split(".")?.[0] + "_success_record.xlsx"
      );
      if (res) {
        toast.success("Success records file download");
      }
    } else {
      toast.error("Excel process id not found!");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const downloadFailedRecord = async (row) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { id } = row;
    if (id) {
      const res = await downloadClaimExcelFile(
        `/getFailureClaimExcelData/${id}`,
        `${row.fileName}`.split(".")?.[0] + "_failed_record.xlsx"
      );
      if (res) {
        toast.success("Failed records file download");
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      toast.error("Excel process id not found!");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const downloadTotalRecord = async (row) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { id } = row;
    if (id) {
      const res = await downloadClaimExcelFile(
        `/getTotalClaimExcelData/${id}`,
        `${row.fileName}`.split(".")?.[0] + "_total_record.xlsx"
      );
      if (res) {
        toast.success("Total records file download");
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      toast.error("Excel process id not found!");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const onPageChange = (page) => {
    setFilter((prev) => ({ ...prev, page: page - 1 }));
  };

  const onSelectHandle = (e) => {
    const type = e.target.value;
    const dates = calculateDate[type];
    setDateType(type);
    setFilter((prev) => ({
      ...prev,
      ...dates,
      page: 0,
    }));
  };

  const onSearchHandle = () => {
    getData();
  };

  const onResetHandle = () => {
    setFilter({
      resellerId: resellerId,
      page: 0,
      size: 10,
      sequenceType: "desc",
      ...calculateDate["week"],
    });
    setDateType("week");
  };

  const dateChangeHandler = (date, key) => {
    sortRef.current = true;
    if (key === "from") {
      setFilter((prev) => ({
        ...prev,
        startDate: moment(date).format("YYYY-MM-DD"),
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        endDate: moment(date).format("YYYY-MM-DD"),
      }));
    }
  };

  const handleSort = () => {
    setFilter((prev) => ({
      ...prev,
      sequenceType: prev.sequenceType === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <Link
          to="/reimbursement-uploader"
          className="self-end p-2 bg-red text-white rounded-md font-semibold text-xs"
        >
          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Upload file
        </Link>
      </div>
      <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700 mt-2"></hr>

      {InProcessData.length > 0 && <InProcessRecord data={InProcessData} />}

      <div className="flex flex-col mt-9 gap-2">
        <div className="flex justify-between items-center flex-wrap">
          <p className="text-base font-semibold">Reimbursements records</p>
          <div className="flex gap-2 items-center">
            {dateType === "custom" ? (
              <div className="flex items-center gap-2">
                <RangePicker
                  dateRange={filter}
                  hideLabel={true}
                  onDateChange={dateChangeHandler}
                />
                <button
                  onClick={onSearchHandle}
                  className="border border-primary-500 bg-primary-500 p-2 rounded-lg mt-2 text-white"
                >
                  Search
                </button>
                <button
                  onClick={onResetHandle}
                  className="border border-primary-500 p-2 rounded-lg mt-2"
                >
                  <RefreshIcon width={22} className="text-primary-500" />
                </button>
              </div>
            ) : (
              <div className="w-36">
                <InputSelect
                  className="h-10 -mb-2"
                  options={[
                    { label: "Today", value: "today" },
                    { label: "Yesterday", value: "yesterday" },
                    { label: "Last 7 days", value: "week" },
                    { label: "Last 30 days", value: "month" },
                    { label: "Last 1 year", value: "year" },
                    { label: "Custom", value: "custom" },
                  ]}
                  attrs={{ onChange: onSelectHandle, value: dateType }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full border" id="table-to-xls">
            <thead className="border-b bg-gray-700">
              <th className="text-xs text-center font-semibold p-2 text-white">
                File name
              </th>
              <th className="text-xs text-center font-semibold p-2 text-white">
                <div className="flex justify-center items-center gap-2">
                  Uploaded on
                  <div
                    className="flex flex-col cursor-pointer"
                    onClick={handleSort}
                  >
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      size="xs"
                      color={filter.sequenceType === "desc" ? "gray" : "white"}
                    />
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      size="xs"
                      color={filter.sequenceType === "asc" ? "gray" : "white"}
                    />
                  </div>
                </div>
              </th>
              <th className="text-xs text-center font-semibold p-2 text-white">
                Total record
              </th>
              <th className="text-xs text-center font-semibold p-2 text-white">
                Successful record
              </th>
              <th className="text-xs text-center font-semibold p-2 text-white">
                Failed record
              </th>
              <th className="text-xs text-center font-semibold p-2 text-white">
                Status
              </th>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                <>
                  {data?.map((item, index) => {
                    return (
                      <tr
                        className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                        key={item?.batchId}
                      >
                        <td className="text-xs text-center font-normal p-2">
                          {item?.fileName || "---"}
                        </td>
                        <td className="text-xs text-center font-normal p-2">
                          {item?.createdOn
                            ? moment(item?.createdOn).format("DD/MM/YYYY")
                            : "---"}
                        </td>
                        <td className="text-xs text-center font-normal p-2">
                          <p className="flex justify-center gap-1 items-center">
                            {item?.totalRecord}{" "}
                            {item?.totalRecord > 0 &&
                              item.status !== "PENDING" && (
                                <DownloadIcon
                                  onClick={() => downloadTotalRecord(item)}
                                />
                              )}
                          </p>
                        </td>
                        <td className="text-xs text-center font-normal p-2">
                          <p className="flex justify-center gap-1 items-center text-green-500">
                            {item?.successRecord}{" "}
                            {item?.successRecord > 0 &&
                              item.status !== "PENDING" && (
                                <DownloadIcon
                                  onClick={() => downloadSuccessRecord(item)}
                                />
                              )}
                          </p>
                        </td>
                        <td className="text-xs text-center font-normal p-2">
                          <p className="flex justify-center gap-1 items-center text-red-500">
                            {" "}
                            {item?.failRecord}{" "}
                            {item?.failRecord > 0 &&
                              item.status !== "PENDING" && (
                                <DownloadIcon
                                  onClick={() => downloadFailedRecord(item)}
                                />
                              )}
                          </p>
                        </td>
                        <td className="text-xs text-center font-normal p-2">
                          {item?.status || "---"}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    height="150"
                    className="text-red-500 font-large text-center"
                  >
                    No Data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data && data.length > 0 && (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={total}
              limit={filter.size}
              activePage={filter.page + 1}
              onChangePage={onPageChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

const DownloadIcon = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-3 cursor-pointer"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
      />
    </svg>
  );
};

export default ReimbursementUploader;
