import Select from "react-select";

export const CustomSelect = ({ label, onChange, value, data, disable }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "40px",
      minHeight: "40px",
      fontSize: "12px",
      borderColor: state.isFocused ? "#d1d5db" : "#d1d5db",
      boxShadow: "none",
      cursor: "default",
      "&:hover": {
        borderColor: "#d1d5db",
      },
      "&:focus-within": {
        borderColor: "#d1d5db",
        boxShadow: "none",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "40px",
      padding: "0px 8px",
      display: "flex",
      alignItems: "center",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      fontSize: "12px",
      width: "100%",
      minWidth: "160px",
      marginTop: "4px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      padding: "4px 8px",
      backgroundColor: state.isSelected ? "red" : "white",
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "12px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "4px",
      svg: {
        width: "12px",
        height: "12px",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "100px",
      overflowY: "auto",
      scrollbarWidth: "thin",
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: "transparent",
      cursor: "default",
    }),
  };

  return (
    <div className="relative">
      <label className="flex text-xs text-gray-700 font-bold justify-left pt-1 mb-1">
        {label}
      </label>
      <Select
        options={data}
        isSearchable={false}
        value={data.find((option) => option.value === value)}
        onChange={onChange}
        isDisabled={disable}
        styles={customStyles}
        classNamePrefix="select"
      />
    </div>
  );
};
