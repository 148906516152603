import { Field } from "formik";
import React, { useState } from "react";
import InputLabel from "../form/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function InputArea({
  label,
  value,
  name,
  requiredMark = "",
  onBlur,
  disabled = false,
  placeHolder,
  onChange,
  style = {},
  helperText = "",
  error,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="mb-1 relative">
      {open && error && (
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className="bg-red-600 -top-10 absolute text-white text-sm p-3 rounded-md flex items-start gap-2"
        >
          <span>{error}</span>
        </div>
      )}

      <div className="flex gap-1 items-center">
        {error && (
          <FontAwesomeIcon
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            icon={faExclamationCircle}
            className="text-red-500"
          />
        )}

        <InputLabel label={label} requiredMark={requiredMark} style={style} />
      </div>

      <Field
        name={name}
        placeholder={placeHolder}
        render={() => (
          <>
            <textarea
              placeholder={placeHolder}
              value={value}
              disabled={disabled}
              className={`block w-full px-3 py-2 border rounded-md shadow-sm 
                text-gray-700 bg-white
                 focus:outline-none 
                focus:ring-2 focus:ring-gray-300 focus:border-gray-200
                border-gray-200 disabled:bg-gray-200 transition`}
              onChange={onChange}
            />
            {helperText && (
              <p
                style={{ fontSize: "11px", fontStyle: "italic" }}
                className="text-gray-600 mt-1"
              >
                <span className="font-medium">* {helperText}</span>
              </p>
            )}
          </>
        )}
      />
    </div>
  );
}
