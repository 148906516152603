import { useCallback, useEffect, useRef, useState } from "react";
import SearchFilters from "./SearchFilters";
import { FilterIcon, SearchIcon } from "../../../Layout/SidebarIcons";
import {
  ApiPost,
  validateName,
  validateNumber,
  validateSpecialChar,
} from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { Loader } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIdBadge,
  faIdCard,
  faPhoneAlt,
  faUser,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";

const HomeSearchBar = () => {
  const initialState = {
    name: "",
    mobile: "",
    loan_code: "",
    insured_member_id: "",
    aadhaar_id: "",
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showValue, setShowValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [query, setQuery] = useState(initialState);
  const [errors, setErrors] = useState({
    mobile: "",
    loan_code: "",
    insured_member_id: "",
    aadhaar_id: "",
  });

  const history = useHistory();
  const divRef = useRef(null);

  const fetchData = async (data) => {
    if (validateFun(data)) {
      setLoader(true);
      setSelectedOption([]);
      let payload = {};
      Object.keys(data).map((item) => {
        if (data[item]) {
          payload[item] = data[item];
        }
      });
      await ApiPost("search-policy", {
        ...payload,
      })
        .then((res) => {
          if (res.status === "success") {
            setShowFilters(false);
            setSelectedOption(res?.data);
            if (res?.data.length == 0) {
              toast.error("No data found");
            }
            setIsOpen(true);
          }
        })
        .finally(() => setLoader(false));
    }
  };

  const debouncedSearch = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    if (!showFilters && query?.name?.length) {
      debouncedSearch(query);
    }
  }, [query]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (divRef.current && !divRef.current.contains(event.target)) {
  //       onOutsideClick();
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleShowValue = (data) => {
    let str = "";
    if (data.name) {
      str = str + `name : ${data.name}`;
    }
    if (data.mobile) {
      str =
        str != ""
          ? str + ` | mobile : ${data.mobile}`
          : str + `  mobile : ${data.mobile}`;
    }
    if (data.aadhaar_id) {
      str =
        str != ""
          ? str + ` | aadhar : ${data.aadhaar_id}`
          : str + ` aadhar : ${data.aadhaar_id}`;
    }
    if (data.loan_code) {
      str =
        str != ""
          ? str + ` | loan code : ${data.loan_code}`
          : str + `  loan code : ${data.loan_code}`;
    }
    if (data.insured_member_id) {
      str =
        str != ""
          ? str + ` | member id : ${data.insured_member_id}`
          : str + `  member id : ${data.insured_member_id}`;
    }
    return str;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setShowValue(handleShowValue(query));
    setSelectedOption([]);
    fetchData(query);
  };

  const validateFun = (query) => {
    let flag = false;
    if (query.name && query.name.length > 2) {
      flag = true;
    } else if (query.aadhaar_id && query.aadhaar_id.length === 12) {
      flag = true;
    } else if (query.mobile && query.mobile.length === 10) {
      flag = true;
    } else if (query.loan_code && query.loan_code.length > 3) {
      flag = true;
    } else if (query.insured_member_id && query.insured_member_id.length > 3) {
      flag = true;
    }

    return flag;
  };

  const filterOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "aadhaar_id") {
      if (value && value.length < 12) {
        setErrors({ ...errors, aadhaar_id: "aadhar must be 12 numbers" });
      } else {
        setErrors({ ...errors, aadhaar_id: "" });
      }
      if (value && (value.length > 12 || !validateNumber(value))) return;
    } else if (name === "mobile") {
      if (value && value.length < 10) {
        setErrors({ ...errors, mobile: "mobile must be 10 digits" });
      } else {
        setErrors({ ...errors, mobile: "" });
      }
      if (value && (value.length > 10 || !validateNumber(value))) return;
    } else if (name === "loan_code") {
      if (value && value.length < 3) {
        setErrors({
          ...errors,
          loan_code: "loan-code must atleast be 3 numbers",
        });
      } else {
        setErrors({ ...errors, loan_code: "" });
      }
      if ((value && value.length > 30) || !validateSpecialChar(value)) return;
    } else if (name === "insured_member_id") {
      if (value && value.length < 3) {
        setErrors({
          ...errors,
          insured_member_id: "insured member id must atleast be 3 numbers",
        });
      } else {
        setErrors({ ...errors, insured_member_id: "" });
      }
      if (value.length > 30 || !validateSpecialChar(value)) return;
    }
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: value,
    }));
  };

  const onClear = () => {
    setQuery({
      name: "",
      mobile: "",
      aadhaar_id: "",
      loanCode: "",
      insured_member_id: "",
    });
    setErrors({
      mobile: "",
      loan_code: "",
      insured_member_id: "",
      aadhaar_id: "",
    });
    setSelectedOption([]);
    setShowValue(null);
  };

  // const onOutsideClick = () => {
  //   onClear();
  // };

  return (
    <div className="relative w-full">
      <div
        ref={divRef}
        className="flex items-center border border-gray-200 bg-white rounded-lg"
      >
        <div className={`flex justify-between items-center w-full`}>
          <div className="absolute pl-2 rounded-r-full self-center">
            <SearchIcon color={"#8E8E93"} />
          </div>
          <input
            type="text"
            className="pl-10 text-sm focus:ring-0 rounded-full w-full outline-0 border-none"
            placeholder="Search by name..."
            value={showValue ? showValue : query.name}
            onChange={(e) => {
              setShowValue("");
              if (e.target?.value?.length === 0) {
                onClear();
              }
              if (validateName(e.target.value)) {
                setQuery((prevQuery) =>
                  showFilters
                    ? {
                        ...prevQuery,
                        name: e.target.value,
                      }
                    : { name: e.target.value }
                );
              }
            }}
          />
          {loader ? (
            <div className="absolute right-0 pr-4 flex gap-1 items-center">
              <Loader size="sm" />
            </div>
          ) : (
            <div className="absolute right-0 pr-4 flex gap-1 items-center">
              {(selectedOption?.length > 0 || showValue
                ? showValue
                : query.name) && (
                <Tooltip title="Clear search" placement="top" arrow>
                  <div
                    className="rounded-r-full border-primary focus:border-primary "
                    onClick={() => onClear()}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      className="text-red-500 cursor-pointer"
                      icon={faWindowClose}
                    />
                  </div>
                </Tooltip>
              )}
              <Tooltip title="Show search options" placement="top" arrow>
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="ml-2 p-2 border-none rounded-lg bg-white-500"
                >
                  <FilterIcon />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div
        className={`bg-white w-full absolute top-10 left-0 overflow-auto mt-1 scrollbar rounded-lg z-10 shadow-lg ${
          isOpen || showFilters ? "" : "hidden"
        } `}
        style={{
          maxHeight: "350px",
        }}
      >
        {showFilters ? (
          <div className="w-full bg-white">
            <SearchFilters
              values={query}
              errors={errors}
              disable={!validateFun(query)}
              onSubmit={handleSearch}
              onChangeHandler={filterOnChange}
              onClear={onClear}
              onClose={() => setShowFilters(false)}
            />
          </div>
        ) : (
          selectedOption &&
          selectedOption.map((item, index) => {
            return (
              <div
                index={index}
                className=" hover:bg-red-100 p-2 py-3 cursor-pointer border-b-2"
                onClick={() => {
                  history.push(`/subscription/${item.application_id}`);
                }}
              >
                <div className="mb-2">
                  <FontAwesomeIcon
                    className="text-red-500 mr-2"
                    icon={faUser}
                  />
                  <span className="font-semibold capitalize">
                    {item?.customer_name?.toLowerCase()} &nbsp;&nbsp;
                  </span>
                  <span>
                    {item?.policy_status == "lead" ? (
                      <span className="bg-yellow-100 font-semibold text-xs p-1 bol rounded">
                        Unpaid
                      </span>
                    ) : item?.end_date && moment(item.end_date).isBefore() ? (
                      <span className="bg-red-200 font-semibold p-1 text-xs rounded">
                        Expired
                      </span>
                    ) : (
                      <span className="bg-green-100 font-semibold p-1 text-xs rounded">
                        Active
                      </span>
                    )}
                  </span>
                </div>
                <div>
                  <ul className="block">
                    <li className="block md:inline-block border-r-0 md:border-r border-gray-300 pr-0 md:pr-5">
                      <FontAwesomeIcon
                        icon={faPhoneAlt}
                        className="mr-2 text-green-500"
                      />
                      <span>{item?.mobile}</span>
                    </li>
                    {item?.loan_code && (
                      <li className="block md:inline-block border-r-0 md:border-r border-gray-300 px-0 md:px-5">
                        <FontAwesomeIcon icon={faIdBadge} className="mr-2" />
                        Loan code {" : "}
                        <span>{item?.loan_code}</span>
                      </li>
                    )}
                    {item?.gov_id_type && item?.gov_id_number && (
                      <li className="block md:inline-block border-r-0 md:border-r border-gray-300 px-0 md:px-5">
                        <FontAwesomeIcon
                          icon={faIdCard}
                          className="mr-2 text-red-500"
                        />
                        <span>
                          {item?.gov_id_type && item?.gov_id_number && (
                            <>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                &nbsp;
                                {item?.gov_id_type ? item?.gov_id_type : ""}
                              </span>
                              &nbsp;
                              {typeof item?.gov_id_number === "string"
                                ? item?.gov_id_number.length > 9
                                  ? new Array(
                                      item?.gov_id_number?.length - 3
                                    ).join("x") +
                                    item?.gov_id_number
                                      ?.substr(
                                        item?.gov_id_number?.length - 4,
                                        4
                                      )
                                      .toUpperCase()
                                  : item?.gov_id_number?.toUpperCase()
                                : item?.gov_id_number}
                            </>
                          )}
                        </span>
                      </li>
                    )}
                    <li className="block md:inline-block border-r-0  border-gray-300 px-0 md:px-5">
                      <FontAwesomeIcon
                        icon={faIdCard}
                        className="mr-2 text-red-500"
                      />
                      Member id {" : "}
                      <span>{item?.insured_member_id}</span>
                    </li>
                  </ul>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default HomeSearchBar;
