import React, { useEffect, useRef, useState } from "react";
import LabTestIcon from "../../../assets/productCreation/newLabTestIcon.png";
import CustomDataTable from "../common/components/CustomDataTable";
import { ApiPost } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import { Button } from "rsuite";
import { Tooltip } from "@mui/material";
import PlusIcon from "@rsuite/icons/Plus";
import LabTestDetail from "./LabTestDetail";
import { CustomLabTestSearchFilter } from "../common/components/CustomSearchFilter";
import CustomModal from "../common/components/CustomModal";
import AddLabTest from "./LabTestCreation";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { addProductButtonStyles } from "../common/data";

const LabTestGrid = () => {
  const initialValue = {
    id: "",
    lab_test_name: "",
    technical_name: "",
    member_covered: "",
    lab_test_limit: "",
    status: "",
    rowsPerPage: 5,
    page: 1,
    limit: 5,
    orderBy: "DESC",
    shortBy: "",
    from: "",
    to: "",
  };
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchFilters, setSearchFilters] = useState(initialValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const payloadRef = useRef();

  const labTestGridColumns = [
    {
      name: (
        <Tooltip title="Lab test details" placement="right" arrow>
          <p className="w-full flex justify-center items-center">#</p>
        </Tooltip>
      ),
      sortable: true,
      sortField: "id",
      selector: (row) => row.id,
      width: "80px",
      center: true,
      sortFunction: () => {},
      cell: (row) => <LabTestDetail id={row.id} />,
    },
    { name: "Name", selector: (row) => row.lab_test_name },
    {
      name: "Usage",
      sortable: true,
      sortField: "lab_test_limit",
      sortFunction: () => {},
      selector: (row) => (row.lab_test_limit ? row.lab_test_limit : "-"),
      center: true,
      width: "100px",
    },
    {
      name: "Technical Name",
      selector: (row) => row?.technical_name,
    },
    {
      name: "Status",
      center: true,
      selector: (row) => {
        if (row?.status == "Active")
          return (
            <Tooltip title="Active" placement="right" arrow>
              <p className="w-full flex justify-center items-center">
                <FaCheckCircle style={{ color: "green" }} />
              </p>
            </Tooltip>
          );
        else if (row?.status == "Inactive") {
          return (
            <Tooltip title="Awaiting approval" placement="right" arrow>
              <p className="w-full flex justify-center items-center">
                <FaClock style={{ color: "#8B8000" }} />
              </p>
            </Tooltip>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const debouncedFetchLabTests = async (payload) => {
    const data = {
      ...payload,
      approved:
        payload.approved === ""
          ? ""
          : payload.approved === "active"
          ? true
          : false,
    };
    if (payloadRef.current === JSON.stringify(data)) {
      return;
    } else {
      setLoading(true);
      payloadRef.current = JSON.stringify(data);
      const response = await ApiPost("admin/get-all-lab-test", payload);
      if (response.code == 200) {
        setRowData(response.data?.results);
        setTotal(response.data?.total);
      } else {
        toast.error(response?.message);
        setRowData([]);
        setTotal(0);
      }
      setLoading(false);
    }
  };

  const debouncedHandleSort = ({ sortField }) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        shortBy: sortField,
        orderBy: prev.orderBy === "DESC" ? "ASC" : "DESC",
      };
      debouncedFetchLabTests(data);
      return data;
    });
  };

  const handlePageChange = (pg) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        page: pg,
      };
      debouncedFetchLabTests(data);
      return data;
    });
  };

  const handlePerRowsChange = (limit, page) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        page: page,
        limit: limit,
      };
      debouncedFetchLabTests(data);
      return data;
    });
  };

  const handleSearch = () => {
    debouncedFetchLabTests(searchFilters);
  };

  useEffect(() => {
    debouncedFetchLabTests(searchFilters);
  }, []);

  const handleReset = () => {
    setSearchFilters((prev) => {
      debouncedFetchLabTests(initialValue);
      return initialValue;
    });
  };

  return (
    <div className="relative shadow-md sm:rounded-lg w-full pb-2">
      <div className="flex justify-end"></div>
      <CustomDataTable
        title={
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center gap-2 ">
              <img
                alt=""
                style={{ width: "30px", height: "30px" }}
                src={LabTestIcon}
              />
              <p className="text-l font-bold"> Lab Tests</p>
            </div>
            <Button
              style={addProductButtonStyles}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <PlusIcon style={{ marginRight: 3, alignSelf: "center" }} />
              Add Lab test
            </Button>
          </div>
        }
        data={rowData}
        columns={labTestGridColumns}
        loading={loading}
        rowsPerPage={searchFilters.rowsPerPage}
        totalRows={total}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        handleSort={debouncedHandleSort}
        subHeader={true}
        subHeaderComponent={
          <CustomLabTestSearchFilter
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            handleSubmit={handleSearch}
            onResetHandle={handleReset}
          />
        }
      />
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          <div className="flex items-center gap-2 ">
            <img style={{ width: "30px", height: "30px" }} src={LabTestIcon} />
            <p className="text-l font-bold"> Add Lab Test</p>
          </div>
        }
        body={
          <AddLabTest
            setIsModalOpen={setIsModalOpen}
            fetchData={debouncedFetchLabTests}
          />
        }
      />
    </div>
  );
};

export default LabTestGrid;
