import React, { Component, Fragment } from "react";
import { AppBackButton, AppLabel } from "../../components/AppComponents";
import { AppDataTable } from "../../components/AppDataTable";
import { InputSelect } from "../../components/form/InputSelect";
import { ApiPost } from "../../lib/AppHelper";
import AdminMain from "./AdminMain";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { AppStore } from "../../lib/AppStore";
import { Link } from "react-router-dom";
import {
  GreenTick,
  InActiveIcon,
  verticalMenuIcon,
  WaitingHourGlass,
} from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const RESELLER_TYPES = [
  {
    value: "",
    label: "All",
  },
  {
    value: 1,
    label: "Individual",
  },
  {
    value: 2,
    label: "MLM",
  },
  {
    value: 5,
    label: "MLM Employee",
  },
  {
    value: 3,
    label: "MFI",
  },
  {
    value: 6,
    label: "MFI Employee",
  },
  {
    value: 4,
    label: "Coporate",
  },
];
const getStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <img
          src={GreenTick}
          alt="GreenTick"
          style={{ width: 20, height: 20 }}
        />
      );
    case 2:
      return (
        <img
          src={InActiveIcon}
          alt="InActiveIcon"
          style={{ width: 20, height: 20 }}
        />
      );
    case 3:
      return (
        <img
          src={WaitingHourGlass}
          alt="WaitingHourGlass"
          style={{ width: 20, height: 20 }}
        />
      );
    case 0:
    default:
      return (
        <img
          src={WaitingHourGlass}
          alt="WaitingHourGlass"
          style={{ width: 20, height: 20, marginBottom: 50 }}
        />
      );
  }
};
const ItemAction = ({
  item,
  changeResellerType,
  changeResellerStatus,
  renderSelector,
}) => {
  let options = false;
  options = (
    <>
      {item.type === 1 ? (
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => changeResellerType(item.id, 2)}
              className={`${
                active ? "bg-violet-500 text-primary" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
            >
              Make MLM
            </button>
          )}
        </Menu.Item>
      ) : (
        false
      )}
      {item.type === 2 ? (
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => changeResellerType(item.id, 1)}
              className={`${
                active ? "bg-violet-500 text-primary" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
            >
              Make Individual
            </button>
          )}
        </Menu.Item>
      ) : (
        false
      )}
      {item.status === 1 ? (
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => changeResellerStatus(item.id, 2)}
              className={`${
                active ? "bg-violet-500 text-primary" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
            >
              Block Account
            </button>
          )}
        </Menu.Item>
      ) : (
        false
      )}
      {item.status === 2 ? (
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => changeResellerStatus(item.id, 1)}
              className={`${
                active ? "bg-violet-500 text-primary" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
            >
              Unblock Account
            </button>
          )}
        </Menu.Item>
      ) : (
        false
      )}
      {item.status === 3 ? (
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => changeResellerStatus(item.id, 1)}
              className={`${
                active ? "bg-violet-500 text-primary" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
            >
              Approve Account
            </button>
          )}
        </Menu.Item>
      ) : (
        false
      )}
    </>
  );

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          {renderSelector ? (
            renderSelector
          ) : (
            <div className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-primary-300 rounded-md hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              Options
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </div>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="px-1 py-1 ">{options}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export class AdminResellers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
    };
  }
  DataTable = React.createRef();
  MessageRef = React.createRef();

  render() {
    const { type } = this.state;
    const id = this.props.match.params.resellerId;
    let params = {
      type,
    };
    if (id) {
      params = { ...params, id };
    }
    return (
      <>
        <AppBackButton to="/">Back to Dashboard</AppBackButton>
        <div className=" px-6 flex flex-col align-center justispace-y-6">
          <div>
            <AppDataTable
              ref={this.DataTable}
              MessageRef={this.MessageRef}
              endpoint={"resellers"}
              type={"card"}
              //   header={["Name", "Status", "Mobile", "Type", "Actions"]}
              render={(item) => {
                return (
                  <Link to={`/reseller/${item.id}`}>
                    <div
                      className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
                      style={{
                        cursor: "pointer",
                        width: 310,
                      }}
                    >
                      <article className="rounded-lg shadow-lg">
                        <body className="flex leading-tight p-2 md:p-2">
                          <p className="text-sm text-clip w-11/12  ...">
                            <h1 className="text-md ">{item.name}</h1>
                            <div className="flex row">
                              <a
                                className="flex items-center no-underline text-black"
                                href={`tel:+91${item.mobile}`}
                              >
                                <p className="text-sm py-2 pr-1">
                                  <FontAwesomeIcon
                                    icon={faPhoneSquare}
                                    className="text-primary"
                                  />
                                </p>
                                {item.mobile}
                              </a>
                            </div>
                            {item.status === 0 && <p>Profile not complete</p>}
                            <AppLabel
                              title={item.type_title}
                              color={item.type_color}
                            />
                          </p>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{getStatus(item.status)}</div>
                            <div style={{ alignSelf: "flex-end" }}>
                              {item.status !== 0 && (
                                <ItemAction
                                  item={item}
                                  changeResellerType={(id, type) =>
                                    this.changeResellerType(id, type)
                                  }
                                  changeResellerStatus={(id, type) =>
                                    this.changeResellerStatus(id, type)
                                  }
                                  renderSelector={
                                    <img
                                      src={verticalMenuIcon}
                                      style={{
                                        height: 20,
                                        width: 20,
                                        marginTop: 20,
                                      }}
                                    />
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </body>
                      </article>
                    </div>
                  </Link>
                );
              }}
              params={params}
              filters={
                <>
                  <div className="w-48">
                    <InputSelect
                      label="Reseller Type"
                      attrs={{
                        value: type,
                        onChange: (e) =>
                          this.setState({ type: e.target.value }),
                      }}
                      options={RESELLER_TYPES}
                    />
                  </div>
                </>
              }
            />
          </div>
        </div>
      </>
    );
  }

  changeResellerType = async (id, type) => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiPost("change-reseller-type", {
      id: id,
      type: type,
    });

    if (response.status === "success") {
      this.MessageRef.current.show(response.data.message, "success");
    } else {
      this.MessageRef.current.show(response.message, "error");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });

    this.DataTable.current.getItems();
  };

  changeResellerStatus = async (id, status) => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiPost("change-reseller-status", {
      id: id,
      status: status,
    });

    if (response.status === "success") {
      this.MessageRef.current.show(response.data.message, "success");
    } else {
      this.MessageRef.current.show(response.message, "error");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });

    this.DataTable.current.getItems();
  };
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};

const AdminResellersConnect = connect(mapStateToProps)((props) => {
  return <AdminResellers {...props} />;
});

export default withRouter(AdminResellersConnect);
