import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomDataTable from "../common/components/CustomDataTable";
import { ApiPost } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import ProductInfo from "./ProductInfo";
import ProductBox from "../../../assets/productCreation/pro.png";
import { Button, Modal } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import ProductCreate from "./ProductCreation";
import { CustomProductSearchFilter } from "../common/components/CustomSearchFilter";
import CustomModal from "../../admin/components/CustomModal";
import ProductViewToggle from "./ProductViewToggle";
import { addProductButtonStyles } from "../common/data";
import DraftProductGrid from "./DraftProductGrid";
import { Close } from "@mui/icons-material";

const ProductGrid = () => {
  const initialValue = {
    product_name: "",
    product_display_name: "",
    member_covered: "",
    max_price: "",
    min_price: "",
    rowsPerPage: 5,
    page: 1,
    limit: 5,
    orderBy: "DESC",
    shortBy: "",
    from: "",
    to: "",
    product_classification: "",
  };
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchFilters, setSearchFilters] = useState(initialValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [selectedView, setSelectedView] = useState("Products");

  const ProductToggleData = [
    { label: "Drafts", value: "Drafts" },
    { label: "Products", value: "Products" },
  ];

  const payloadRef = useRef();

  const productGridColumns = [
    {
      name: <p className="w-full flex justify-center items-center">#</p>,
      sortable: true,
      sortField: "product_id",
      selector: (row) => row?.product_id,
      width: "100px",
      center: true,
      sortFunction: () => {},
      cell: (row) => <ProductInfo id={row?.product_id} />,
    },
    {
      name: "Name",
      selector: (row) => row.product_name,
      width: "300px",
    },
    {
      name: "Price(₹)",
      sortable: true,
      sortField: "base_price",
      sortFunction: () => {},
      selector: (row) =>
        row.base_price == 0
          ? 0
          : !row.base_price
          ? "-"
          : row?.base_price.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            }),
      right: true,
      width: "130px",
    },
    {
      name: "Display Name",
      selector: (row) => row?.product_display_name,
      width: "250px",
    },
    {
      name: "Type",
      selector: (row) => row?.product_clasification,
      center: true,
    },
    {
      name: "Tenure",
      selector: (row) =>
        `${row?.tenure} 
          ${row?.tenure_type.toLowerCase()}`,
      center: true,
      width: "120px",
    },
  ];

  const debouncedFetchProducts = async (data, forceUpdate) => {
    if (payloadRef.current === JSON.stringify(data) && !forceUpdate) {
      return;
    } else {
      payloadRef.current = JSON.stringify(data);
      setLoading(true);
      const response = await ApiPost("admin/get-all-products", data);
      if (response.code == 200) {
        setRowData(response.data.results);
        setTotal(response.data?.total);
      } else {
        toast.error(response?.message);
        setRowData([]);
        setTotal(0);
      }
      setLoading(false);
    }
  };

  const debouncedHandleSort = ({ sortField }) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        shortBy: sortField,
        orderBy: prev.orderBy === "DESC" ? "ASC" : "DESC",
      };
      setLoading(true);
      debouncedFetchProducts(data);
      return data;
    });
  };

  const handlePageChange = (page) => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: page };
      debouncedFetchProducts(data);
      return data;
    });
  };

  const handlePerRowsChange = (limit, page) => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: page, limit: limit };

      debouncedFetchProducts(data);
      return data;
    });
  };

  const handleSearch = () => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: 1 };

      debouncedFetchProducts(data);
      return data;
    });
  };

  const onResetHandle = () => {
    setSearchFilters((prev) => {
      debouncedFetchProducts(initialValue);
      return initialValue;
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowExitPrompt(false);
    debouncedFetchProducts(searchFilters);
  };

  useEffect(() => {
    const handleBackEvent = (event) => {
      if (isModalOpen) {
        event.preventDefault();
        setShowExitPrompt(true);
        window.history.pushState(null, "", window.location.href); // Prevent actual back navigation
      }
    };

    if (isModalOpen) {
      window.history.pushState(null, "", window.location.href); // Add a fake entry to prevent back
      window.addEventListener("popstate", handleBackEvent);
    } else {
      window.removeEventListener("popstate", handleBackEvent);
    }

    return () => {
      window.removeEventListener("popstate", handleBackEvent);
    };
  }, [isModalOpen]);

  useEffect(() => {
    setLoading(true);
    debouncedFetchProducts(searchFilters);
  }, []);

  return (
    <div className="relative shadow-md sm:rounded-lg w-full pb-2">
      <div className="flex justify-start items-center">
        <ProductViewToggle
          toggleData={ProductToggleData}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      </div>

      {selectedView == "Products" ? (
        <CustomDataTable
          title={
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center gap-2 ">
                <img
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                  src={ProductBox}
                />
                <p className="text-l font-bold"> Products</p>
              </div>
              <Button
                style={addProductButtonStyles}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <>
                  <PlusIcon style={{ width: 14, height: 14 }} />
                  Add Product
                </>
              </Button>
            </div>
          }
          data={rowData}
          columns={productGridColumns}
          loading={loading}
          rowsPerPage={searchFilters.rowsPerPage}
          totalRows={total}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          handleSort={debouncedHandleSort}
          subHeader={true}
          subHeaderComponent={
            <CustomProductSearchFilter
              searchFilters={searchFilters}
              setSearchFilters={setSearchFilters}
              handleSubmit={handleSearch}
              onResetHandle={onResetHandle}
            />
          }
        />
      ) : (
        <DraftProductGrid fetchProduct={debouncedFetchProducts} />
      )}

      <CustomModal
        open={isModalOpen}
        setOpen={setShowExitPrompt}
        backDropClose={true}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "80%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "80%",
        }}
        borderRadius="16px"
      >
        <div className="w-full flex justify-between">
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              style={{ width: "30px", height: "30px" }}
              src={ProductBox}
            />
            <p className="text-l font-bold"> Create Product</p>
          </div>
          <Close
            onClick={() => setShowExitPrompt(true)}
            className="cursor-pointer"
          />
        </div>

        <Modal.Body>
          <ProductCreate
            setIsModalOpen={setIsModalOpen}
            fetchData={() => debouncedFetchProducts(searchFilters, true)}
          />
        </Modal.Body>
      </CustomModal>

      <CustomModal
        open={showExitPrompt}
        setOpen={setShowExitPrompt}
        backDropClose={true}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        borderRadius="16px"
      >
        <div className="w-full flex justify-between">
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              style={{ width: "30px", height: "30px" }}
              src={ProductBox}
            />
            <p className="text-l font-bold"> Close Product Creation?</p>
          </div>
          <Close
            onClick={() => setShowExitPrompt(false)}
            className="cursor-pointer"
          />
        </div>

        <Modal.Body>
          <div style={{ height: "auto" }}>
            <p className="text-sm font-medium text-gray-700">
              Are you sure? You have unsaved changes. Do you want to discard
              your changes?
            </p>
            <div className="flex justify-end gap-2 mt-4">
              <Button
                style={{
                  padding: 10,
                  width: "120px",
                  height: "40px",
                  marginTop: "20px",
                  backgroundColor: "lightgray",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                onClick={closeModal}
              >
                Exit
              </Button>
              <Button
                style={{
                  padding: 10,
                  width: "120px",
                  height: "40px",
                  marginTop: "20px",
                  backgroundColor: "#db2228",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setShowExitPrompt(false);
                }}
              >
                Keep on editing
              </Button>
            </div>
          </div>
        </Modal.Body>
      </CustomModal>
    </div>
  );
};

export default ProductGrid;
