import React, { useEffect, useState } from "react";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { DownloadIcon } from "@heroicons/react/outline";
import { InputFile } from "../../components/form/InputFile";
import pdfImg from "../../assets/png/pdfPng.png";
import {
  enrollmentDocumentGet,
  getLabTestReport,
  LabTestPostApi,
} from "../../lib/AppHelper";
import { truncateString } from "../admin/utils";
import { AppStore } from "../../lib/AppStore";

const CustomerLabTestDetails = () => {
  const MessageRef = React.useRef();
  const location = useLocation();
  const state = location.state;
  const [reportUrl, setReportUrl] = useState(null);
  const [prescription, setPrescription] = useState(null);

  const visibilityMark = async (id, type) => {
    const payload = {
      labTestId: id,
      visibleReport: type,
    };
    await LabTestPostApi("labtest/visibleReports", payload);
  };

  const getReport = async (endPoint, reportName) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const res = await getLabTestReport(endPoint);
    if (res instanceof Blob) {
      const blob = new Blob([res], { type: "text/plain" });
      const file = new File([blob], reportName, {
        type: "application/pdf",
        lastModified: Date.now(),
      });
      setReportUrl(file);
      visibilityMark(state?.id, "LabTest");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const getPrescription = async (item) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { appoinmentId, patientId } = item;
    if (appoinmentId && patientId) {
      const res = await enrollmentDocumentGet(
        `enrollment-service/prescription/${appoinmentId}/${patientId}`
      );
      if (res instanceof Blob) {
        const blob = new Blob([res], { type: "text/plain" });
        const file = new File([blob], `${item.name}_prescription.pdf`, {
          type: "application/pdf",
          lastModified: Date.now(),
        });
        setPrescription(file);
        visibilityMark(state?.id, "Prescription");
      }
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  useEffect(() => {
    if (state.reportUrl) {
      getReport(
        `labtest/download_lab_report/${state.id}/download`,
        `${state.name}_lab_test_report.pdf`
      );
    }
    if (state.prescriptionCreationDate) {
      getPrescription(state);
    }
  }, []);

  const reportDownloadHandler = () => {
    const url = URL.createObjectURL(reportUrl);
    const a = document.createElement("a");
    a.href = url;
    a.download = reportUrl.name;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const downloadPrescription = () => {
    const url = URL.createObjectURL(prescription);
    const a = document.createElement("a");
    a.href = url;
    a.download = prescription.name;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-full md:w-1/3">
          <AppBackButton to="/">Back</AppBackButton>
        </div>
        <div className="w-full md:w-1/3 text-center mb-5">
          <AppHeading>Customer Details</AppHeading>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center mt-2 gap-10">
        <div className="border w-full md:w-3/4 rounded-xl p-6 flex flex-col md:flex-row flex-wrap gap-2 justify-between">
          <div className="w-full md:w-2/5 flex justify-between">
            <label className="md:w-1/2">Name</label>
            <p className="font-medium md:w-1/2 break-words capitalize">
              {truncateString(state?.name, 30, "Member Empty")}
            </p>
          </div>
          <div className="w-full md:w-2/5 flex justify-between">
            <label className="md:w-2/3">Phone number</label>
            <p className="font-medium md:w-1/2">{state?.phoneNumber}</p>
          </div>
          <div className="w-full md:w-2/5 flex justify-between">
            <label className="md:w-1/2">Lab test name</label>
            <p className="font-medium md:w-1/2">{state?.productName}</p>
          </div>
          <div className="w-full md:w-2/5 flex justify-between">
            <label className="md:w-2/3">Sample collection date</label>
            <p className="font-medium md:w-1/2">
              {state.sampleCollectionDate
                ? moment(state.sampleCollectionDate).format("DD/MM/YYYY")
                : "---"}
            </p>
          </div>
        </div>
        <div className="w-3/4">
          <div className="p-3 flex justify-between bg-gray2 text-white">
            <p>Prescription</p>
            {prescription && (
              <button>
                <DownloadIcon
                  onClick={downloadPrescription}
                  className="h-6 w-6"
                />
              </button>
            )}
          </div>
          <div>
            <InputFile
              file={prescription}
              placeholder={
                !prescription && (
                  <div className="text-center items-center flex-col inline-flex ">
                    <img
                      src={pdfImg}
                      style={{ height: "200px" }}
                      alt="report"
                    />
                    <p>Prescription not found!</p>
                  </div>
                )
              }
              attrs={{
                disabled: true,
              }}
              readOnly={true}
            />
          </div>
        </div>
        <div className="w-3/4">
          <div className="p-3 flex justify-between bg-gray2 text-white">
            <p>Report</p>
            {reportUrl && (
              <button>
                <DownloadIcon
                  className="h-6 w-6"
                  onClick={reportDownloadHandler}
                />
              </button>
            )}
          </div>
          <div>
            <InputFile
              file={reportUrl}
              placeholder={
                !state?.reportUrl && (
                  <div className="text-center items-center flex-col inline-flex ">
                    <img
                      src={pdfImg}
                      style={{ height: "200px" }}
                      alt="report"
                    />
                    <p>Report not found!</p>
                  </div>
                )
              }
              attrs={{
                disabled: true,
              }}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerLabTestDetails;
