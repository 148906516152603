import React, { useEffect, useRef } from "react";
import { AppBackButton } from "../../components/AppComponents";
import Dashboard from "./Dashboard";
import { primaryColor } from "./constant";

const Index = () => {
  const MessageRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppBackButton to="/" />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <p
          className="text-center w-full m-2 font-bold text-xl "
          style={{ color: primaryColor }}
        >
          Sales Dashboard
        </p>
      </div>
      <Dashboard />
    </>
  );
};

export default Index;
