import { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Logo from "../components/Logo";
import { ApiGet, fetchPaytmPaymentGetApi } from "../lib/AppHelper";
import cancellation from "../../src/images/cancellation.png";
import cutation from "../../src/images/cutation.png";
import productBenefit from "../../src/images/product-benefit.png";
import rupees from "../../src/images/rupees.png";

const PaymentPage = () => {
  const location = useLocation();
  const encryptedKeyword = `${location.search}`.split("code=")[1];
  const [loading, setLoading] = useState(false);
  const [payButtonLoading, setPayButtonLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentFailedTimer, setPaymentFailedTimer] = useState(false);
  const [paymentFailedTimerValue, setPaymentFailedTimerValue] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [paytmOrderId, setPaytmOrderId] = useState(null);
  const paytmBaseUrl =
    process.env[
      `REACT_APP_PAYTM_INITIATE_BASE_URL_${process.env.REACT_APP_MODE}`
    ];

  const decryptData = (encryptedData, key, iv) => {
    try {
      const keyBytes = CryptoJS.enc.Utf8.parse(key);
      const ivBytes = CryptoJS.enc.Utf8.parse(iv);

      const decrypted = CryptoJS.AES.decrypt(encryptedData, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  const decryptedApplicationId = decryptData(
    encryptedKeyword,
    process.env[
      `REACT_APP_DECRYPTION_SECRET_KEY_${process.env.REACT_APP_MODE}`
    ],
    process.env[`REACT_APP_IV_${process.env.REACT_APP_MODE}`]
  );

  const handlePay = async () => {
    if (!isChecked) {
      return;
    }
    setPayButtonLoading(true);
    try {
      const response = await fetchPaytmPaymentGetApi(
        `paytm/initiatePaytmPayment?resellerId=${paymentData?.reseller_id}&code=${paymentData?.paymentCode}`
      );
      setPaytmOrderId(response?.responseObject?.orderId);

      if (response?.code === 200) {
        const txnToken = response?.responseObject?.body?.txnToken;
        const orderId = response?.responseObject?.orderId;
        const amount = response?.responseObject?.amount;
        await paytmScript(response?.responseObject?.merchantId);

        if (txnToken) {
          setTimeout(() => {
            initiatePaytmSDK(
              txnToken,
              orderId,
              amount,
              response?.responseObject?.merchantId
            );
          }, 1000);
        } else if (
          response?.responseObject !== null &&
          response?.responseObject?.policyId
        ) {
          window?.Paytm?.CheckoutJS?.close();
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setPaymentStatus("policy_already_created");
        }
      } else if (response?.code === 400 && response?.responseObject?.timer) {
        setPaymentStatus("multiple");
        setPaymentFailedTimer(true);
        setPaymentFailedTimerValue(response?.responseObject?.timer);
        setIsButtonDisabled(true);

        let timer = response?.responseObject?.timer;
        const interval = setInterval(() => {
          timer -= 1;
          setPaymentFailedTimerValue(timer);

          if (timer <= 0) {
            clearInterval(interval);
            setIsButtonDisabled(false);
          }
        }, 1000);
      } else {
        toast.error(response?.message);
        setPayButtonLoading(false);
      }
    } catch (error) {
      setPayButtonLoading(false);
    }
  };

  useEffect(() => {
    if (!decryptedApplicationId) return;

    const initialize = async () => {
      const response = await fetchPaytmPaymentGetApi(
        `paytm/fetchPaytmPaymentStatus?applicationId=${decryptedApplicationId}&orderId=${paytmOrderId}`
      );

      if (response?.code === 200) {
        if (
          response?.responseObject?.applicationId ||
          response?.responseObject?.resultStatus == "TXN_SUCCESS"
        ) {
          window?.Paytm?.CheckoutJS?.close();
          setPaymentStatus("success");
        } else if (response?.data !== null && response?.data?.policyId) {
          window?.Paytm?.CheckoutJS?.close();
          setPaymentStatus("policy_created");
        } else if (response?.responseObject?.resultCode == "400") {
          setPaymentStatus("pending");
        }
      } else {
        toast.error(response?.message || "Something went wrong!");
        clearInterval(interval);
      }
    };

    // Call initialize when the component mounts
    initialize();

    // Start the interval to poll the API
    const interval = setInterval(async () => {
      await initialize();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [decryptedApplicationId, paytmOrderId]);

  const initiatePaytmSDK = (txnToken, orderId, amount, merchantId) => {
    if (!window.Paytm || !window.Paytm.CheckoutJS) {
      return;
    }

    let config = {
      root: "",
      style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#0FB8C9",
        themeColor: "#ffffff",
        headerBackgroundColor: "#284055",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
          padding: "",
          backgroundColor: "",
        },
      },
      data: {
        orderId: orderId,
        token: txnToken,
        tokenType: "TXN_TOKEN",
        amount: amount,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: ["EMI"],
        },
        order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
      },
      website: "WEBSTAGING",
      flow: "DEFAULT",
      merchant: {
        mid: merchantId,
        name: "aiqahealth",
        redirect: false,
      },
      handler: {
        transactionStatus: (paymentStatus) => {
          if (paymentStatus?.STATUS === "TXN_SUCCESS") {
            setPaymentStatus("success");
          } else if (paymentStatus?.STATUS === "TXN_FAILURE") {
            setPaymentStatus("failed");
          }
          window?.Paytm?.CheckoutJS?.close();
        },

        notifyMerchant: (eventName) => {
          if (eventName === "APP_CLOSED") {
            (async () => {
              await fetchPaytmPaymentGetApi(
                `paytm/fetchPaytmPaymentStatus?applicationId=${decryptedApplicationId}&status=Failed&orderId=${orderId}`
              );
            })();
          }
        },
      },
    };

    if (window?.Paytm && window?.Paytm?.CheckoutJS) {
      window?.Paytm?.CheckoutJS?.init(config)
        .then(function onSuccess() {
          window?.Paytm?.CheckoutJS?.invoke();
          setPayButtonLoading(false);
        })
        .catch(function onError(error) {
          // console.error("Paytm CheckoutJS error:", error);
          window?.Paytm?.CheckoutJS?.close();
          setPayButtonLoading(false);
        });
    }
  };

  const fetchPaymentData = async () => {
    if (!decryptedApplicationId) return;

    setLoading(true);
    try {
      const response = await ApiGet(
        `payment/get-detail?id=${decryptedApplicationId}`
      );

      if (response?.status === "success") {
        setPaymentData(response.data);

        if (response?.data?.policy_id) {
          window?.Paytm?.CheckoutJS?.close();
          setPaymentStatus("policy_created");
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error fetching payment data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    if (seconds <= 0) return "0 seconds";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ${
        minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : ""
      } ${
        remainingSeconds > 0
          ? `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
          : ""
      }`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ${
        remainingSeconds > 0
          ? `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
          : ""
      }`;
    } else {
      return `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`;
    }
  };

  const onRetry = async () => {
    setPaymentStatus("");
    setPayButtonLoading(false);
  };

  const onRetryFailedTimmer = async () => {
    setPaymentStatus("");
    setPayButtonLoading(false);
    setPaymentFailedTimer(false);
    setPaymentFailedTimerValue(0);
    setIsButtonDisabled(false);
  };

  const paytmScript = async (merchantId) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = `${paytmBaseUrl}/merchantpgpui/checkoutjs/merchants/${merchantId}.js`;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (decryptedApplicationId) {
      fetchPaymentData();
    } else {
      setPaymentStatus("url_not_found");
    }
  }, [decryptedApplicationId]);

  return (
    <>
      <nav className="bg-white border-white-200 px-2 sm:px-4 pt-2.5 rounded dark:bg-white-800">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <hr />
      </nav>

      {loading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <CircularProgress style={{ color: "#db2228" }} />
        </div>
      ) : paymentStatus === "success" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-green-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">Thank you!</h2>
              <p className="text-gray-600">
                Your payment is received successfully.
              </p>
            </div>
          </div>
        </div>
      ) : paymentStatus === "failed" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-red-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">Failed!</h2>
              <p className="text-gray-600">
                Payment failed try after some time.
              </p>
              <button
                className="mt-4 px-6 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition"
                onClick={() => onRetry()}
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      ) : paymentStatus === "pending" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-yellow-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-yellow-500"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6l4 2"
                  />
                  <circle cx="12" cy="12" r="10" />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">Under Process!</h2>
              <p className="text-gray-600">
                Your payment is currently being processed. Please wait while
                this payment gets processed.
              </p>
            </div>
          </div>
        </div>
      ) : paymentStatus === "multiple" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-yellow-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 192 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M96 512c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm-48-144V56c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v312c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24z"
                  />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">
                Another Payment process got initiated !
              </h2>
              <p className="text-gray-600">
                If amount got deducted , Please contact our Support team.
              </p>
              <br />
              {paymentFailedTimerValue > 0 && (
                <p>
                  Please wait{" "}
                  <strong>{formatTime(paymentFailedTimerValue)}</strong> before
                  trying again.
                </p>
              )}

              <button
                className={`mt-4 px-6 py-2 text-white rounded-lg transition ${
                  isButtonDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
                onClick={() => onRetryFailedTimmer()}
                disabled={isButtonDisabled}
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      ) : paymentStatus === "url_not_found" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-red-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">URL Not Found</h2>
            </div>
          </div>
        </div>
      ) : paymentStatus === "policy_created" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-green-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">Thank you!</h2>
              <p>Subscription created successfully.</p>
            </div>
          </div>
        </div>
      ) : paymentStatus === "policy_already_created" ? (
        <div className="mx-4 mt-14">
          <div className="max-w-5xl mx-auto rounded-md bg-white border border-[rgba(0,0,0,.125)] shadow-md">
            <div className="bg-green-500 text-white rounded-t-lg flex flex-col items-center py-6">
              <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full">
                <svg
                  className="w-12 h-12 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-xl font-semibold">Thank you!</h2>
              <p>Subscription already created.</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container mx-auto bg-zinc-50"
          style={{
            fontFamily: "'Poppins', sans-serif",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="container mt-10" style={{ height: "auto" }}>
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4 py-4">
              <div className="rounded-xl border p-4 min-w-80 w-full mt-2 bg-white">
                <div className="pt-2 text-2xl font-bold text-slate-700">
                  Amount Payable{" "}
                  <span className="text-red-500">₹{paymentData?.amount}</span>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
                  {paymentData?.insured_member?.map((member, index) => {
                    return (
                      <div key={member?.id || index}>
                        <p className="text-md font-bold capitalize">
                          {member?.name?.toLowerCase()}
                        </p>
                        <div className="rounded-xl border p-4 shadow-md bg-red-100 mt-2">
                          <div className="grid grid-cols-3 gap-2">
                            <div>Name:</div>
                            <div className="col-span-2 capitalize">
                              {member?.name}
                            </div>
                            <div>Phone:</div>
                            <div className="col-span-2">{member?.mobile}</div>
                            <div>Gender:</div>
                            <div className="col-span-2 capitalize">
                              {member?.gender}
                            </div>
                            <div>D.O.B:</div>
                            <div className="col-span-2">
                              {moment(member.dob).format("DD-MM-YYYY")}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="block">
                  <div
                    className="text-justify pt-5"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "flex-start",
                      columnGap: "10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="focus:outline-none focus:ring-0"
                      style={{
                        height: "14px",
                        width: "14px",
                        boxShadow: "none",
                        marginTop: "5px",
                        borderRadius: "2px",
                      }}
                      name="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />

                    {paymentData?.termAndCondition && (
                      <label htmlFor="checkbox" className="cursor-pointer">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `<b>Disclosure: </b> ${paymentData?.termAndCondition}`,
                          }}
                        />
                      </label>
                    )}
                  </div>

                  <button
                    className={`mt-4 w-full text-white font-bold text-md px-4 py-2 rounded-lg shadow focus:outline-none flex justify-center
                ${
                  isChecked
                    ? "bg-red-500 hover:bg-red-700 active:bg-red-600 cursor-pointer"
                    : "bg-red-500 cursor-not-allowed opacity-50"
                }`}
                    onClick={handlePay}
                    disabled={!isChecked || payButtonLoading}
                  >
                    {payButtonLoading ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      "Pay"
                    )}
                  </button>
                </div>
              </div>

              <div className="rounded-xl border p-4 min-w-80 w-full mt-2 bg-white md:ml-2 lg:ml-2">
                <div className="text-md font-bold py-1">
                  <span>Your Plan: </span>
                  <span className="text-red-500">
                    {paymentData?.product_master?.product_display_name}
                  </span>
                </div>

                {paymentData?.product_benefits?.some(
                  (item) => item?.description_type === "Benefits"
                ) && (
                  <div>
                    <div className="flex gap-2 pt-1 items-center">
                      <img width="25px" alt="benefit" src={productBenefit} />
                      <div className="my-1 text-md font-bold">
                        Product Benefits
                      </div>
                    </div>
                    <ul className="list-disc pl-12">
                      {paymentData?.product_benefits
                        ?.filter((item) => item.description_type === "Benefits")
                        .map((item) => (
                          <li key={item?.id} className="text-justify">
                            {item?.description_text}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {paymentData?.product_benefits?.some(
                  (item) => item?.description_type === "deductible"
                ) && (
                  <div>
                    <div className="flex gap-2 pt-1 items-center">
                      <img width="25px" alt="benefit" src={rupees} />
                      <div className="my-1 text-md font-bold">Deductible</div>
                    </div>
                    <ul className="list-disc pl-12">
                      {paymentData?.product_benefits
                        ?.filter(
                          (item) => item?.description_type === "deductible"
                        )
                        .map((item) => (
                          <li key={item?.id} className="text-justify">
                            {item?.description_text}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {paymentData?.product_benefits?.some(
                  (item) => item.description_type === "Exclusions"
                ) && (
                  <div>
                    <div className="flex gap-2 pt-1 items-center">
                      <img width="25px" alt="exclusion" src={cutation} />
                      <div className="my-1 text-md font-bold">Exclusions</div>
                    </div>
                    <ul className="list-disc pl-12">
                      {paymentData?.product_benefits
                        ?.filter(
                          (item) => item?.description_type === "Exclusions"
                        )
                        .map((item) => (
                          <li key={item?.id} className="text-justify">
                            {item?.description_text}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {paymentData?.product_benefits?.some(
                  (item) => item?.description_type === "Cancellation & Refund"
                ) && (
                  <div>
                    <div className="flex gap-2 pt-1 items-center">
                      <img width="25px" alt="cancellation" src={cancellation} />
                      <div className="my-1 text-md font-bold">
                        Cancellation & Refund
                      </div>
                    </div>
                    <ul className="list-disc pl-12">
                      {paymentData?.product_benefits
                        ?.filter(
                          (item) =>
                            item?.description_type === "Cancellation & Refund"
                        )
                        .map((item) => (
                          <li key={item?.id} className="text-justify">
                            {item?.description_text}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentPage;
