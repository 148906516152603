import React, { useRef, useState } from "react";
import { Pagination, Toggle } from "rsuite";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { RefreshIcon } from "@heroicons/react/outline";
import { ApiGet, ApiPost } from "../../../lib/AppHelper.js";
import Button from "../../../components/Button.js";
import { InputGroup } from "../../../components/form/InputGroup.js";
import { AppStore } from "../../../lib/AppStore.js";
import { FaEdit } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";
import AdminMain from "../AdminMain.js";
import {
  AppBackButton,
  AppHeading,
} from "../../../components/AppComponents.js";

const SearchHeaders = [
  {
    name: "Name",
    searchType: "input",
    regex: /^[a-zA-Z0-9_ ]*$/,
    placeholder: "Enter name",
    key: "name",
    maxLength: 30,
  },
  {
    name: "Mobile",
    searchType: "input",
    placeholder: "Enter Mobile",
    regex: /^-?\d+$/,
    key: "mobile",
    maxLength: 10,
  },
];

const truncateString = (str, charLength = 17) => {
  if (!str) return;
  else if (str.length <= charLength)
    return <span>{str ? str : "Name empty"}</span>;
  else {
    const newString = str.substring(0, charLength) + "...";
    return (
      <Tooltip title={str} placement="left" arrow>
        <span>{newString ? newString : "Name empty"}</span>
      </Tooltip>
    );
  }
};

const validateEmail = (email) => {
  if (typeof email !== "string") {
    return false;
  }
  return email?.match(
    /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const ReimbursementGrid = () => {
  const payloadRef = useRef();
  const size = 10;
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [editingRow, setEditingRow] = useState(null);
  const [searchFilters, setSearchFilters] = useState({
    name: "",
    mobile: "",
  });
  const [emailError, setEmailError] = useState(false);

  const fetchData = async (pg = null) => {
    const start = size * (pg ? pg - 1 : page - 1);
    if (pg) setPage(pg);

    if (JSON.stringify(searchFilters) === JSON.stringify(payloadRef.current)) {
      return;
    }

    try {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("admin/search-partner", {
        size,
        page: pg || page,
        ...searchFilters,
      });

      if (response?.code === 200) {
        const responseData = response?.data?.data || [];
        setRowData(responseData);
        setTotalCount(response?.data?.total || 0);
        setEditingRow(null);
        payloadRef.current = searchFilters;
        // toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  const onChangeHandler = (value, key) => {
    setSearchFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    fetchData();
  };

  const handleClearFilters = () => {
    setPage(1);
    setRowData([]);
    setEditingRow(0);
    setEditingRow(null);
    setSearchFilters({
      name: "",
      mobile: "",
    });
    payloadRef.current = {};
  };

  const handelChangePage = (pg) => {
    if (page === pg) return;
    setPage(pg);
    fetchData(pg);
  };

  const handleKeyDown = (e, key) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleInputChange = (value, key, id) => {
    setRowData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, [key]: value } : item))
    );
  };

  const handleEdit = (id) => {
    setEditingRow(id);
  };

  const validateEmails = (value) => {
    if (!value) {
      setEmailError(false);
      return false;
    }

    const invalidEmails = value?.split(",")?.map((email) => email?.trim());
    const notValidEmail = invalidEmails?.filter(
      (email) => !validateEmail(email)
    );

    if (invalidEmails.length > 5) {
      toast.error("Only five email addresses are allowed.");
      setEmailError(true);
      return "Only five email addresses are allowed.";
    }

    if (notValidEmail.length > 0) {
      toast.error("Invalid email address.");
      setEmailError(true);
      return "Please enter a correct email ID.";
    }

    setEmailError(false);
    return false;
  };

  const handleSaveChange = async (data) => {
    try {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiPost("admin/update-claim-email", data);
      if (response.code === 200) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between items-center">
        <div className="w-full md:flex-1 md:justify-start flex">
          <AppBackButton>Back</AppBackButton>
        </div>
        <div className="w-full md:flex-1 flex justify-center">
          <AppHeading
            className="text-center font-medium mb-6"
            style={{ color: "#000000" }}
          >
            Reimbursement Mailer
          </AppHeading>
        </div>
        <div className="hidden md:flex md:flex-1"></div>
      </div>

      <div className="relative shadow-md sm:rounded-lg w-full p-4 pb-2">
        <div className="grid md:grid-cols-4 gap-4 md:mb-0 mb-5">
          {SearchHeaders.map((header, index) => (
            <InputGroup
              key={index}
              label={header.name}
              placeholder={header.placeholder}
              attrs={{
                maxLength: header?.maxLength ?? 12,
                value: searchFilters?.[header?.key] || "",
                onChange: (e) => {
                  const value = e.target.value;
                  if (header.regex.test(value) || value === "") {
                    onChangeHandler(value, header.key);
                  }
                },
                onKeyDown: handleKeyDown,
              }}
              className="w-full"
            />
          ))}

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              margin: "10px auto 0 0",
            }}
          >
            <Tooltip title={<span>Reset</span>} placement="bottom" arrow>
              <button
                className="hover:bg-primary-700 bg-red-500 text-white mb-6 border-0 rounded-lg"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
                onClick={handleClearFilters}
              >
                <RefreshIcon width={20} height={20} className="m-1" />
              </button>
            </Tooltip>
            <Button
              className="ml-4"
              attrs={{
                type: "button",
                onClick: handleSubmit,
                disabled:
                  (!searchFilters?.name || searchFilters.name.length < 3) &&
                  (!searchFilters?.mobile || searchFilters.mobile.length < 10),
              }}
              size="sm"
              title={"Search"}
            />
          </div>
        </div>

        <div className="flex pb-4 items-center justify-start gap-2">
          <span className="text-red-500 text-sm">Note:</span>{" "}
          <span className="text-xs">
            You can enter a maximum of 5 email addresses, separated by commas,
            in the "Reimbursement Email Daily" and "Reimbursement Email Weekly"
            fields.
          </span>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <table className="min-w-full border" id="table-to-xls">
              <thead className="bg-gray2 text-white h-8">
                <th className="text-sm font-sm p-2 whitespace-nowrap text-left">
                  Name
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-left">
                  Mobile
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-left">
                  Email
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-center">
                  Is daily
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-left">
                  Reimbursement email daily
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-center">
                  Is weekly
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-left">
                  Reimbursement email weekly
                </th>
                <th className="text-sm font-sm p-2 whitespace-nowrap text-center">
                  Action
                </th>
              </thead>
              <tbody>
                {rowData?.length ? (
                  rowData?.map((data, index) => {
                    return (
                      <tr className="border-b" key={index}>
                        <td className="text-gray-900 p-2 whitespace-nowrap text-left capitalize">
                          {truncateString(data?.name?.toLowerCase(), 22)}
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap text-left capitalize">
                          {data?.mobile}
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap text-left capitalize">
                          {data?.email?.toLowerCase()}
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap capitalize text-center">
                          <Toggle
                            checked={data?.is_daily ? true : false}
                            onChange={(e) =>
                              handleInputChange(e, "is_daily", data?.id)
                            }
                            readOnly={editingRow !== data?.id}
                          />
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap text-left capitalize">
                          <InputGroup
                            isStyle="0"
                            placeholder="Enter email"
                            attrs={{
                              value: data?.claim_email_daily,
                              onChange: (e) =>
                                handleInputChange(
                                  e.target.value.trim(),
                                  "claim_email_daily",
                                  data?.id
                                ),
                            }}
                            validateError={validateEmails}
                            disabled={
                              editingRow !== data?.id || !data?.is_daily
                            }
                          />
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap capitalize text-center">
                          <Toggle
                            checked={data?.is_weekly ? true : false}
                            onChange={(e) =>
                              handleInputChange(e, "is_weekly", data?.id)
                            }
                            readOnly={editingRow !== data?.id}
                          />
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap text-left capitalize">
                          <InputGroup
                            isStyle="0"
                            placeholder="enter email"
                            attrs={{
                              value: data?.claim_email_weekly,
                              onChange: (e) =>
                                handleInputChange(
                                  e.target.value,
                                  "claim_email_weekly",
                                  data?.id
                                ),
                            }}
                            validateError={validateEmails}
                            disabled={
                              editingRow !== data?.id || !data?.is_weekly
                            }
                          />
                        </td>
                        <td className="text-gray-900 p-2 whitespace-nowrap text-center capitalize">
                          <span>
                            {editingRow !== data?.id && (
                              <Tooltip
                                title={<span>Edit</span>}
                                placement="left"
                                arrow
                              >
                                <button onClick={() => handleEdit(data?.id)}>
                                  <FaEdit className="text-primary-500 cursor-pointer text-xl" />
                                </button>
                              </Tooltip>
                            )}

                            {editingRow === data?.id && (
                              <Tooltip
                                title={<span>Save</span>}
                                placement="left"
                                arrow
                              >
                                <button
                                  onClick={() => {
                                    handleSaveChange(data);
                                    setEditingRow(null);
                                  }}
                                  disabled={
                                    editingRow !== data?.id ||
                                    emailError ||
                                    (data?.is_daily &&
                                      !data?.claim_email_daily) ||
                                    (data?.is_weekly &&
                                      !data?.claim_email_weekly)
                                  }
                                >
                                  <FaRegSave
                                    className={`${
                                      editingRow !== data?.id ||
                                      emailError ||
                                      (data?.is_daily &&
                                        !data?.claim_email_daily) ||
                                      (data?.is_weekly &&
                                        !data?.claim_email_weekly)
                                        ? "cursor-not-allowed opacity-50 bg-[rgb(148,163,184)] text-xl"
                                        : " text-primary-500 cursor-pointer text-xl"
                                    }`}
                                  />
                                </button>
                              </Tooltip>
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      height="150"
                      className="text-red-500 font-large text-center"
                    >
                      No Data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={totalCount}
              limit={size}
              activePage={page}
              onChangePage={(page) => handelChangePage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReimbursementGrid;
