import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import CustomAccordian from "../../common/components/CustomAccordian";
import BasicDetails from "../../../../assets/productCreation/forms.png";
import AddOnBenefitsIcon from "../../../../assets/productCreation/offer.png";
import { Button, Loader } from "rsuite";
import LabTestBasicInfo from "./LabTestBasicInfo";
import ParameterInfo from "./ParameterInfo";
import { ApiPost } from "../../../../lib/AppHelper";
import { toast } from "react-toastify";
import * as Yup from "yup";

const AddLabTest = ({ setIsModalOpen, fetchData }) => {
  const initialValues = useRef({
    benefits_html: "",
    bg_url: "",
    lab_test_limit: "",
    status: false,
    lab_test_name: "",
    technical_name: "",
    pre_requirement: "",
    description_detail: "",
    parameter: [
      {
        parameter_name: "",
        parameter_benefit: [{ benefit_title: "" }],
      },
    ],
  });
  const [finalLoading, setFinalLoading] = useState(false);

  const addLabTest = async (values) => {
    const response = await ApiPost("admin/add-lab-test", values);
    if (response.status === "success") {
      toast.success("Lab test added successfully");
      setIsModalOpen(false);
      fetchData();
    } else {
      toast.error(
        response?.message ? response?.message : `Something went wrong`
      );
    }
    setFinalLoading(false);
  };

  const validationSchemaLabTest = Yup.object().shape({
    lab_test_limit: Yup.string().required("Number of usages is required"),
    lab_test_name: Yup.string().required("Lab test name is required"),
    technical_name: Yup.string().required("Technical name is required"),
    pre_requirement: Yup.string()
      .max(150, "Pre-requirement must be at most 150 characters long")
      .required("Pre-requirement is required"),
    parameter: Yup.array().of(
      Yup.object().shape({
        parameter_name: Yup.string().required("Parameter name is required"),
        parameter_benefit: Yup.array().of(
          Yup.object().shape({
            benefit_title: Yup.string().required("Benefit title is required"),
          })
        ),
      })
    ),
  });

  return (
    <div
      className="bg-modalBg"
      style={{ height: finalLoading ? "300px" : "auto" }}
    >
      {finalLoading ? (
        <div>
          <Loader
            style={{ color: "#db2228" }}
            size="md"
            center="true"
            speed="normal"
            content="Loading..."
          />
        </div>
      ) : (
        <Formik
          initialValues={initialValues.current}
          validationSchema={validationSchemaLabTest}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            initialValues.current = values;
            setFinalLoading(true);
            addLabTest(initialValues.current);
          }}
          validateOnBlur={true}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            touched,
            errors,
            isValid,
            dirty,
          }) => (
            <Form>
              <CustomAccordian
                active={true}
                header={
                  <>
                    <div className="flex items-center gap-2 ">
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={BasicDetails}
                      />
                      <div>
                        <p className="text-sm font-bold">
                          Basic Information
                          <span className="text-lg text-red-500">*</span>
                        </p>
                        <p
                          style={{
                            fontSize: "0.7rem",
                            color: "black",
                            lineHeight: "1.6",
                            fontSize: "12px",
                            fontStyle: "italic",
                          }}
                        >
                          Enter the essential details for the lab test, such as
                          its name, technical name, pre-requirements, and usage
                          limits. Ensure all mandatory fields marked with * are
                          completed accurately.
                        </p>
                      </div>
                    </div>
                  </>
                }
                children={
                  <LabTestBasicInfo
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                }
              />
              <CustomAccordian
                active={true}
                header={
                  <>
                    <div className="flex items-center gap-2 ">
                      <img
                        alt=""
                        style={{ width: "30px", height: "30px" }}
                        src={AddOnBenefitsIcon}
                      />
                      <div>
                        <p className="text-sm font-bold">
                          Parameters
                          <span className="text-lg text-red-500">*</span>
                        </p>
                        <p
                          style={{
                            fontSize: "0.7rem",
                            color: "black",
                            lineHeight: "1.6",
                            fontSize: "12px",
                            fontStyle: "italic",
                          }}
                        >
                          Add multiple parameters for this lab test.
                        </p>
                      </div>
                    </div>
                  </>
                }
                children={
                  <ParameterInfo
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors?.parameter}
                    touched={touched?.parameter}
                    setFieldTouched={setFieldTouched}
                  />
                }
              />

              <div className="flex justify-end mb-2">
                <Button
                  style={{
                    padding: 10,
                    width: "140px",
                    height: "40px",
                    marginTop: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    backgroundColor: isValid && dirty ? "#db2228" : "#ccc",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    cursor: isValid && dirty ? "pointer" : "not-allowed",
                  }}
                  type="submit"
                  disabled={!isValid || !dirty} // Disable button if form is invalid or unchanged
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddLabTest;
