import React, { Component } from "react";
import { ApiGet } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import { InputGroup } from "./form/InputGroup";
import Button from "./Button";
import _ from "lodash";
import { Pagination } from "rsuite";
export class AppDataElement extends Component {
  state = {
    items: [],
    pagination: {},
    limit: 12,
    page: 1,
    params: { ...this.props.params, q: "" },
  };

  render() {
    const { items = [], pagination, params } = this.state;

    return (
      <div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="flex justify-between">
                <div>{this.props.filters}</div>
                <div className="flex space-x-2 justify-end items-end">
                  <InputGroup
                    placeholder="Search..."
                    label=""
                    attrs={{
                      value: params.q,
                      onChange: (e) =>
                        this.onChangeHandler(e.target.value, "q"),
                    }}
                    onEnter={this.search}
                  />
                  <Button
                    className="mb-5"
                    title="Search"
                    attrs={{ onClick: () => this.search() }}
                  />
                  {params.q ? (
                    <Button
                      className="mb-5"
                      outline={true}
                      title="Clear"
                      attrs={{
                        onClick: () => {
                          this.onChangeHandler("", "q", () => {
                            this.search();
                          });
                        },
                      }}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>

              <>
                {items && items.length > 0 ? (
                  <div className="shadow border-b border-gray-200 sm:rounded-lg">
                    <div className="grid grid-cols-1 md:grid-cols-3 ml-2 mb-2">
                      {items.map((item) => {
                        return (
                          <div key={item.id}>
                            {this.props.render(item, this.getItems)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="w-full m-4 text-lg flex justify-center ">
                    {/* No teams found */}
                  </div>
                )}

                {items && items.length > 0 ? (
                  <div style={{ padding: 20 }}>
                    <Pagination
                      prev
                      next
                      first
                      last
                      ellipsis
                      boundaryLinks
                      maxButtons={2}
                      size="xs"
                      layout={["total", "-", "pager", "skip"]}
                      total={this.state.pagination.total}
                      limit={12}
                      activePage={this.state.page}
                      onChangePage={this.onPageChange}
                    />
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getItems();
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ page: page }, () => {
      this.getItems();
    });
  };

  getItems = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(this.props.endpoint, {
      page: this.state.page,
      ...this.state.params,
    });

    if (response.status === "success") {
      this.setState({
        items: response.data.data,
        pagination: {
          from: response.data.from,
          to: response.data.to,
          total: response.data.total,
          links: response.data.links,
          current: response.data.current_page,
          last: response.data.last_page,
        },
      });
    } else {
      this.props.MessageRef?.current?.show(response?.message, "error");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  onChangeHandler = (v, key, callback = () => { }) => {
    const params = this.state.params;
    params[key] = v;
    this.setState({ params: params }, () => callback());
  };

  search = () => {
    this.setState({ page: 1 }, () => {
      this.getItems();
    });
  };

  componentDidUpdate(prevProp, prevState) {
    if (!_.isEqual(prevProp.params, this.props.params)) {
      this.state.params.q = "";
      this.setState(
        { params: { ...this.props.params, q: this.state.params.q }, page: 1 },
        () => this.getItems()
      );
    }
  }
}

export default React.forwardRef((props, ref) => (
  <AppDataElement innerRef={ref} {...props} />
));
