import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DownloadIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal, Pagination } from "rsuite";
import {
  LastWeekDates,
  PaymentPartner,
  DateFilter,
  ViewData,
  WeekDates,
  salesPersonList,
} from "./constant";
import { AppBackButton, AppHeading } from "../../../components/AppComponents";
import {
  JavaApiGet,
  getApiClaimSales,
  getApiForDownloadCSVSalesReport,
  getResellerId,
  postApiForCommonDownloadedReportExcel,
} from "../../../lib/AppHelper";
import CustomSelect from "../../../components/CustomSelect";
import useMobileDetect from "../../../utils/useMobileDetect";
import { InputDate } from "../../../components/form/InputDate";
import { SelectPicker } from "rsuite";
import Button from "../../../components/Button";
import { AppStore } from "../../../lib/AppStore";
import { downloadExcelFile } from "../../../utils/commom";

const InsuranceFormatReport = () => {
  const user = useSelector((state) => state.user);
  const permissions = useSelector((state) => state.user?.permissions);
  const { isMobile } = useMobileDetect();
  const MessageRef = useRef();
  const downloadRef = useRef(null);

  const [requestFilter, setRequestFilter] = useState([]);
  const [list, setList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [productSpecificationList, setProductSpecificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);

  const size = 15;
  const modalSize = window.innerWidth <= 768 ? "xs" : "sm";
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [sequenceType, setSequenceType] = useState(null);

  const [filter, setFilter] = useState({
    duration: "thisMonth",
    from: WeekDates("month")[0],
    to: WeekDates("month")[1],
    viewData: "",
    salesPersonId: getResellerId(),
    vendor: "",
    productSpecification: "",
    paymentPartner: "ALL",
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchData = async () => {
    try {
      const [salesRequestFilterData, salesPerson] = await Promise.all([
        getApiClaimSales("getSalesRequestFilter").catch((error) => {
          console.error("Error fetching sales request filter data:", error);
          throw error;
        }),
        getApiClaimSales(`getSalesPerson/${getResellerId()}`).catch((error) => {
          console.error("Error fetching data from another API:", error);
          throw error;
        }),
      ]);
      setList(salesPerson?.data);
      setRequestFilter(salesRequestFilterData?.data);
      setFilter({
        ...filter,
        viewData: ViewData(salesRequestFilterData?.data)[0]?.value,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchVendorData = async () => {
    try {
      const response = await JavaApiGet(
        "enrollment-service/report/api/get_vendors"
      );
      if (response.code === 200) {
        const list = response?.responseObject;
        let vendorData = list.map((item) => ({ label: item, value: item }));
        setVendorList(vendorData);
      } else {
        setVendorList([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const fetchProductSpecificationData = async () => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/product_specifications?vendorCode=${
          filter.vendor === "ALL" ? "" : filter.vendor
        }`
      );
      if (response.code === 200) {
        const list = response?.responseObject;
        let productList = list.map((item) => ({
          label: item.name,
          value: item.specificationCode,
        }));
        setProductSpecificationList(productList);
      } else {
        setProductSpecificationList([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const downloadClick = () => {
    const {
      from,
      to,
      salesPersonId,
      vendor,
      viewData,
      duration,
      ...restFilter
    } = filter;

    const payload = {
      ...restFilter,
      startDate: filter.from,
      endDate: filter.to,
      startDate: filter?.from && moment(filter.from).format("YYYY-MM-DD"),
      endDate: filter?.to && moment(filter.to).format("YYYY-MM-DD"),
      resellerId: filter.salesPersonId,
      vendorCode: filter.vendor,
      dataFilterOn: filter?.viewData,
      reportName: "Insurance Format Report",
      paymentPartner:
        filter.paymentPartner === "ALL" ? "" : filter.paymentPartner,
    };
    DownloadReports(payload);
  };

  const checkDownloadStatus = async (reportId) => {
    if (reportId) {
      const res = await JavaApiGet(
        `enrollment-service/excel_report/check_insurance_record?reportId=${reportId}`
      );

      if (res?.responseObject?.status === "Complete") {
        clearInterval(downloadRef.current);
        toast.success(res?.message);
        AppStore.dispatch({ type: "LOADING", loading: false });
        fetchListData();
      } else {
        toast.error(res?.message);
      }
    }
  };

  const DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await postApiForCommonDownloadedReportExcel(
      "enrollment-service/excel_report/insurance_report",
      request,
      "InsuranceFormatReport.csv"
    );
    const contentType = response?.headers.get("Content-Type");

    if (contentType === "application/json") {
      const res = await response?.json();
      if (res?.code === 200) {
        toast.success(res?.message);
        AppStore.dispatch({ type: "LOADING", loading: false });
        setShowModal(res?.message);

        // Start polling the status every 3 seconds
        const reportId = res?.responseObject;
        downloadRef.current = setInterval(
          () => checkDownloadStatus(reportId),
          3000
        );
      }
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
      toast.error("An error occurred while downloading the report.");
    }
  };

  const handelChangePage = (pg) => {
    if (page === pg) return;
    setPage(pg);
    fetchListData(pg);
  };

  const fetchListData = async (pg = null, seq) => {
    if (pg) setPage(pg);

    try {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const res = await JavaApiGet(
        "enrollment-service/excel_report/insurance_records",
        {
          size,
          page: pg ? pg - 1 : page - 1,
          sequenceType: seq ? seq : sequenceType,
        }
      );

      if (res?.code === 200) {
        if (res?.responseObject?.length) {
          setTotalCount(res?.count || 0);
          setTableData(res?.responseObject);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  const downloadReport = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await getApiForDownloadCSVSalesReport(
      `enrollment-service/excel_report/download_excel/${id}`
    );
    const blob = await response.blob();
    downloadExcelFile(blob, "downloadReport.csv");
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const handleToggleSortDirection = () => {
    setSequenceType((prevSequenceType) => {
      let seq;
      if (prevSequenceType === null) {
        seq = "ASC";
      } else {
        seq = prevSequenceType === "ASC" ? "DESC" : "ASC";
      }
      fetchListData(null, seq);
      return seq;
    });
  };

  const resetFilters = () => {
    setFilter({
      duration: "today",
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      viewData: "",
      paymentPartner: "",
      salesPersonId: "",
      vendor: "",
      productSpecification: "",
    });
    setPage(1);
    setTotalCount(0);
    setTableData([]);
    setSequenceType(null);
    setError({});
    fetchListData();
  };

  useEffect(() => {
    fetchData();
    fetchVendorData();
    fetchListData();
  }, []);

  useEffect(() => {
    if (filter.vendor) fetchProductSpecificationData();
  }, [filter.vendor]);

  return (
    <>
      {permissions?.insurance_format_report ? (
        <>
          {loading && (
            <Modal
              open={showModal}
              onClose={handleCloseModal}
              backdrop="static"
              size={modalSize}
              className={`text-center ${modalSize ? "top-1/3" : "top-52"}`}
            >
              <Modal.Body>{showModal}</Modal.Body>
              <div className="w-20 mx-auto">
                <Button
                  attrs={{
                    type: "button",
                    onClick: handleCloseModal,
                  }}
                  size="sm"
                  title={"Ok"}
                />
              </div>
            </Modal>
          )}

          <div className="flex flex-col md:flex-row md:justify-between items-center">
            <div className="w-full md:flex-1 md:justify-start flex">
              <AppBackButton to="/">Back</AppBackButton>
            </div>
            <div className="w-full md:flex-1 flex justify-center">
              <AppHeading color="red" className="text-center font-medium mb-6">
                Insurance Format Report
              </AppHeading>
            </div>
            <div className="hidden md:flex md:flex-1"></div>
          </div>
          <div>
            <div
              className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4"
              style={{ marginBottom: "30px" }}
            >
              <div>
                <CustomSelect
                  label="Date filter"
                  value={filter.duration}
                  onChange={(e) => {
                    let value = e.target.value;
                    let dates;
                    if (value === "custom") {
                      setFilter((state) => ({ ...state, duration: value }));
                      return;
                    }
                    if (value === "today") dates = WeekDates("day");
                    if (value === "yesterday") dates = LastWeekDates("day");
                    if (value === "thisWeek") dates = WeekDates("week");
                    if (value === "previousWeek") dates = LastWeekDates("week");
                    if (value === "thisMonth") dates = WeekDates("month");
                    if (value === "previousMonth")
                      dates = LastWeekDates("month");
                    if (value === "thisYear") dates = WeekDates("year");
                    if (value === "previousYear") dates = LastWeekDates("year");
                    setFilter((state) => ({
                      ...state,
                      from: new Date(dates[0]),
                      to: new Date(dates[1]),
                      duration: value,
                    }));
                  }}
                  data={DateFilter}
                  style={{ width: isMobile() ? "100%" : "230px" }}
                />
              </div>
              {filter.duration === "custom" && (
                <>
                  <div>
                    <InputDate
                      label="From"
                      value={filter.from}
                      placeholder="from"
                      maxDate={new Date(moment().format("YYYY-MM-DD"))}
                      attrs={{
                        onChange: (e) => setFilter({ ...filter, from: e }),
                        value: new Date(filter.from),
                      }}
                    />
                  </div>
                  <div>
                    <InputDate
                      label="To"
                      placeholder="To"
                      maxDate={new Date(moment().format("YYYY-MM-DD"))}
                      minDate={new Date(filter.from)}
                      attrs={{
                        onChange: (e) => setFilter({ ...filter, to: e }),
                        value: new Date(filter.to),
                      }}
                    />
                  </div>
                </>
              )}
              <div>
                <CustomSelect
                  label="View data by"
                  value={filter.viewData}
                  onChange={(e) => {
                    setFilter({ ...filter, viewData: e.target.value });
                  }}
                  data={ViewData(requestFilter)}
                  style={{
                    width: isMobile() ? "100%" : "230px",
                    border: "",
                  }}
                />
                {error.viewData && filter.viewData === "" && (
                  <p className="error-message">Please select this field</p>
                )}
              </div>
              <div>
                <label className="flex text-sm text-gray-700 justify-left pt-1 mb-2">
                  Reseller name
                </label>
                <SelectPicker
                  data={salesPersonList(list)}
                  style={{ width: isMobile() ? "100%" : "230px" }}
                  onChange={(value) =>
                    setFilter({ ...filter, salesPersonId: value })
                  }
                  value={filter.salesPersonId}
                />

                {error.salesPerson && !filter?.salesPersonId && (
                  <p className="error-message">Please select this field</p>
                )}
              </div>
              <div>
                <CustomSelect
                  label="Vendor"
                  value={filter.vendor}
                  isMandatory={true}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFilter({ ...filter, vendor: value });
                  }}
                  data={vendorList}
                  style={{ width: isMobile() ? "100%" : "230px" }}
                />
              </div>
              <div>
                <CustomSelect
                  label="Product specification"
                  value={filter.productSpecification}
                  isMandatory={true}
                  disable={!filter.vendor}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFilter({ ...filter, productSpecification: value });
                  }}
                  data={productSpecificationList}
                  style={{ width: isMobile() ? "100%" : "230px" }}
                />
              </div>
              <div>
                <CustomSelect
                  label="Payment partner"
                  value={filter.paymentPartner}
                  onChange={(e) => {
                    setFilter({ ...filter, paymentPartner: e.target.value });
                  }}
                  data={PaymentPartner}
                  style={{ width: isMobile() ? "100%" : "230px" }}
                  isMandatory={false}
                />
              </div>
              <div className="flex flex-row Report-button items-end">
                <Tooltip
                  title={`${
                    loading
                      ? "Can't refresh during downloading"
                      : "Click here to refresh"
                  }`}
                  arrow
                >
                  <span>
                    <Button
                      size="sm"
                      title={""}
                      attrs={{
                        type: "button",
                        onClick: resetFilters,
                        disabled: loading,
                      }}
                      className={`${loading ? "disabled-button" : ""}`}
                    >
                      <RefreshIcon
                        sx={{
                          color: "#ffffff",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Button>
                  </span>
                </Tooltip>

                {user && (
                  <Tooltip
                    title={`${
                      loading
                        ? "Download already in progress"
                        : "Click here to download"
                    }`}
                    arrow
                  >
                    <span>
                      <Button
                        size="sm"
                        title={"Download"}
                        attrs={{
                          type: "button",
                          onClick: downloadClick,
                          disabled:
                            loading ||
                            !filter?.vendor ||
                            !filter?.productSpecification ||
                            !filter?.salesPersonId,
                        }}
                        className={`ml-4 ${loading ? "disabled-button" : ""}`}
                      />
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="flex flex-wrap justify-center mb-5 mt-0">
                <AppHeading color="red">Downloaded Reports</AppHeading>
              </div>
              <table className="min-w-full border" id="table-to-xls">
                <thead className="border-b">
                  <th className="text-sm text-left font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Report name
                  </th>
                  <th
                    className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white"
                    title="Click to sort by Created At"
                  >
                    Created at
                    {tableData?.length > 0 && (
                      <span
                        onClick={handleToggleSortDirection}
                        style={{ cursor: "pointer" }}
                        title={`Sort in ${
                          sequenceType === "DESC" ? "Ascending" : "Descending"
                        } order`}
                      >
                        {" "}
                        {sequenceType === "DESC" ? "▼" : "▲"}
                      </span>
                    )}
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Status
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Action
                  </th>
                </thead>
                <tbody>
                  {tableData?.length ? (
                    tableData?.map((data, index) => {
                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-left border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {data?.reportName}
                            <br />
                            <span>
                              {data?.filterValue ? (
                                (() => {
                                  const parsedFilter = JSON.parse(
                                    data.filterValue
                                  );

                                  return (
                                    <div className="text-gray-500 text-sm">
                                      {parsedFilter?.startDate && (
                                        <>
                                          {"Start date: "}
                                          {moment(
                                            parsedFilter.startDate
                                          ).format("DD/MM/YYYY")}
                                        </>
                                      )}
                                      {parsedFilter?.endDate && (
                                        <>
                                          {", "}
                                          {"End date: "}
                                          {moment(parsedFilter.endDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      )}
                                      {parsedFilter?.dataFilterOn && (
                                        <>
                                          {", "} <br />
                                          {"View data by: "}
                                          {parsedFilter?.dataFilterOn?.toLowerCase()}
                                        </>
                                      )}
                                      {parsedFilter?.vendorCode && (
                                        <>
                                          {", "} {"vendor: "}
                                          {parsedFilter?.vendorCode?.toLowerCase()}
                                        </>
                                      )}
                                      {parsedFilter?.productSpecification && (
                                        <>
                                          {", "} <br />
                                          {"Product Specification: "}
                                          {parsedFilter?.productSpecification?.toLowerCase()}
                                        </>
                                      )}
                                      {parsedFilter?.paymentPartner && (
                                        <>
                                          {", "} {"Payment Partner: "}
                                          {parsedFilter?.paymentPartner?.toLowerCase()}
                                        </>
                                      )}
                                    </div>
                                  );
                                })()
                              ) : (
                                <div className="text-gray-500 text-sm">N/A</div>
                              )}
                            </span>
                          </td>
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {data?.createdAt &&
                              moment(data?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td
                            className={`text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize ${
                              data?.status?.toLowerCase() === "pending"
                                ? "text-blue-500"
                                : data?.status?.toLowerCase() === "completed"
                                ? "text-green-500"
                                : data?.status?.toLowerCase() === "failed"
                                ? "text-red-500"
                                : "text-green-500"
                            }`}
                          >
                            {data?.status?.toLowerCase()}
                          </td>
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            <Tooltip title="Download" arrow>
                              <button onClick={() => downloadReport(data?.id)}>
                                <DownloadIcon
                                  color="green"
                                  height="25px"
                                  width="50px"
                                />
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        height="150"
                        className="text-red-500 font-large text-center"
                      >
                        No Data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={4}
                size="xs"
                layout={["total", "-", "pager", "skip"]}
                total={totalCount}
                limit={size}
                activePage={page}
                onChangePage={(page) => handelChangePage(page)}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <AppBackButton to="/">Back</AppBackButton>
          <div className="text-red-500">
            <h4>You do not have permission to view insurance format report.</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default InsuranceFormatReport;
