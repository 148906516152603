import { ErrorMessage, Field, useField } from "formik";
import React, { useState } from "react";
import InputLabel from "../form/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function InputGroup({
  type = "text",
  label,
  value,
  name,
  requiredMark = "",
  onBlur,
  disabled = false,
  placeHolder,
  onChange,
  min,
  max,
  validation = /^(?!0\d)\d*$/,
  style = {},
  helperText = "",
  error,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="mb-1 relative">
      {open && error && (
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className="bg-red-600 -top-10 absolute text-white text-sm p-3 rounded-md flex items-start gap-2"
        >
          <span>{error}</span>
        </div>
      )}

      <div className="flex gap-1 items-center">
        {error && (
          <FontAwesomeIcon
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            icon={faExclamationCircle}
            className="text-red-500"
          />
        )}

        <InputLabel label={label} requiredMark={requiredMark} style={style} />
      </div>

      <Field
        name={name}
        disabled={disabled}
        type={type}
        value={value}
        onBlur={onBlur}
        placeholder={placeHolder}
        render={({ field, form: { isSubmitting } }) => (
          <>
            <input
              {...field}
              placeholder={placeHolder}
              disabled={disabled}
              value={value}
              onChange={(e) => {
                if (e.target.value.length < 256) {
                  if (type == "number" && validation.test(e.target.value)) {
                    onChange(e);
                  } else {
                    onChange(e);
                  }
                }
              }}
              type={type}
              minLength={min}
              maxLength={max}
              className={`custom-input-type block w-full px-3 py-2 border rounded-md shadow-sm 
                text-gray-700 ${
                  disabled ? `bg-gray-200` : `bg-white`
                } focus:outline-none 
                focus:ring-2 focus:ring-gray-300 focus:border-gray-200
                border-gray-200 disabled:bg-gray-200 transition  ${
                  error ? "border-red-500" : ""
                }`}
            />
            {helperText && (
              <p
                style={{ fontSize: "11px", fontStyle: "italic" }}
                className="text-gray-400 mt-1"
              >
                <span className="font-medium">* {helperText}</span>
              </p>
            )}
          </>
        )}
      />
    </div>
  );
}
