import React, { Component } from "react";
import { connect } from "react-redux";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { withRouter } from "react-router";

export class Terms extends Component {
  BasicBtn = React.createRef();
  MessageRef = React.createRef();
  state = {};

  render() {
    return (
      <>
        {window.location.pathname === "/terms" ? (
          <>
            <AppBackButton to="/">Back</AppBackButton>
            <AppHeading>Terms & Conditions of Use</AppHeading>
            <div className="w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
              <div className="terms">
                <p className="text-justify">
                  These Terms and Conditions has been agreed understood an
                  acknowledged by the <strong>Reseller</strong> as a part and
                  parcel of other terms and conditions for association with{" "}
                  <strong>
                    HOSPIDO PRIVATE LIMITED (‘aiqa’ or ‘aiqahealth’)
                  </strong>
                  . Reseller understands and confirms the strict adherence to
                  the same for the performance and delivery of services to the
                  customers/ subscribers of aiqa.
                </p>

                <AppHeading className="pt-5">
                  1. RESELLER HEREBY REPRESENTS, WARRANTS, AND COVENANTS THAT:{" "}
                </AppHeading>
                <ul className="list-none pl-9 pt-5 ">
                  <li className="text-justify">
                    <b>1.1 </b>
                    <span>
                      {" "}
                      Reseller has the necessary qualification, power or
                      authority and the legal right(s) to enter this business
                      arrangement and shall remain in good standing during the
                      entire period and shall conduct the business in strict
                      adherence to the terms and conditions contained herein and
                      shall provide unprejudiced services to aiqa in respect of
                      all or any of the obligations mentioned herein.
                    </span>
                  </li>
                  <li className="text-justify">
                    <b>1.2 </b> Reseller has never been convicted of any crime
                    involving moral turpitude and is not disqualified and
                    remains fit and proper to deliver his/ her obligations and
                    services hereunder.
                  </li>
                  <li className="text-justify">
                    <b>1.3 </b> Reseller is not associated with or has been
                    simultaneously engaged by any other entity for providing
                    similar obligations/ services as are provided or plan to be
                    provided by aiqa to its subscribers/ customers. Further,
                    Reseller shall not engage himself/ herself with any other
                    entity providing similar services or Products without prior
                    written consent of aiqa.
                  </li>
                  <li className="text-justify">
                    <b>1.4 </b> Reseller shall only perform such services and
                    activities as are allowed and envisaged by aiqa and shall
                    never go beyond the given authorizations while delivering/
                    soliciting Services, as well as at the time of representing
                    aiqa and/ or its Services.
                  </li>
                  <li className="text-justify">
                    <b>1.5 </b> Reseller shall strictly adhere to and comply
                    with all applicable regulatory and other legal requirements
                    while delivering the Services to the subscribers/ customers.
                    Further Reseller shall conduct the business and deliver the
                    Services in a manner so as not to affect adversely the
                    business, good standing, and reputation of the aiqa.
                  </li>
                  <li className="text-justify">
                    <b>1.6 </b> Reseller shall not employ or make use of any
                    advertisement in which the aiqa’s (or its affiliate's) name,
                    or its registered trademarks are employed without the prior
                    written approval and consent of the aiqa.
                  </li>
                  <li className="text-justify">
                    <b>1.7 </b> Reseller shall act solely as an independent
                    service provider, subject to the control and guidance of the
                    aiqa. Nothing herein contained shall be construed to create
                    the relationship of employer and employee or Principal and
                    Agent, between Reseller and aiqa.
                  </li>
                  <li className="text-justify">
                    <b>1.8 </b> Reseller shall not further engage or employ
                    anyone as agents for soliciting the aiqa Services or
                    Products, without the prior written consent of aiqa.
                  </li>
                  <li className="text-justify">
                    <b>1.9 </b> Reseller shall not, without written permission
                    of aiqa, collect or provide receipt for Services or Products
                    or premiums received to/from the subscribers/ customers of
                    aiqa.
                  </li>
                  <li className="text-justify">
                    <b>1.10 </b> Reseller shall have no claim or shall not be
                    entitled to reimbursement for any expenses. Reseller shall
                    only be paid compensation for the discharge for his/ her
                    services and obligations, as may be mutually agreed.
                    Further, the compensation to be paid shall be subject to
                    deduction of applicable taxes.
                  </li>
                  <li className="text-justify">
                    <b>1.11 </b> Reseller shall, only on receipt of written
                    permission of aiqa, collect premiums as per directions and
                    guidelines of aiqa. All such premiums collected from the
                    subscribers/ customers by Reseller hereunder shall be
                    submitted to the aiqa on the same day of receipt.
                  </li>
                  <li className="text-justify">
                    <b>1.12 </b> Reseller shall ensure due compliance of KYC/AML
                    guidelines as may be notified concerned by regulatory
                    authorities from time to time.
                  </li>
                  <li className="text-justify">
                    <b>1.13 </b> Reseller shall maintain proper records and
                    reports of its activities conducted, in a manner as provided
                    by aiqa and shall submit the due report to aiqa in a time
                    bound manner as may be instructed. aiqa shall also have the
                    right to inspect and audit the books, records, and accounts
                    of Reseller. Further aiqa shall also have the right to
                    review of the performance of Reseller from time to time.
                  </li>
                </ul>

                <AppHeading className="pt-5">2. CONFIDENTIALITY:</AppHeading>
                <ul className="list-none pl-9 pt-5 ">
                  <li className="text-justify">
                    <b>2.1 </b> Reseller hereby understands, agrees and confirm
                    that all tangible and intangible information disclosed by
                    aiqa or its affiliates, directly or indirectly, including
                    all details, documents, data, records, reports, systems,
                    papers, notices, statements, business or financial
                    information and practices and trade secrets (all of which
                    are collectively referred to as{" "}
                    <strong>"Confidential Information"</strong>) shall be
                    treated as utmost confidential and Reseller agrees and
                    undertakes that the same shall be kept confidential and
                    shall not be disclosed, save as provided below, in whole or
                    in part to any person/s and/or used and/or be allowed to be
                    used for any purpose other than as may be necessary for the
                    due performance of obligations hereunder, except with
                    written authorization from aiqa.
                  </li>{" "}
                  <li className="text-justify">
                    <b>2.2 </b> Obligations under this clause shall continue to
                    apply in perpetuity even after the termination or expiry of
                    business relationship between aiqa and Reseller. In case of
                    any breach of this provision, aiqa shall have the right to
                    take legal action against the Reseller.
                  </li>
                </ul>

                <AppHeading className="pt-5">3. INDEMNITY: </AppHeading>
                <ul className="list-none pl-9 pt-5 ">
                  <li className="text-justify">
                    Reseller shall indemnify and keep indemnified and hold
                    harmless aiqa at all times and its affiliates, its directors
                    and officers from and against any and all losses, claims,
                    actions, proceedings, damages (including reasonable legal
                    and lawyer’s fees) which may be incurred by the aiqa on
                    account of (a) gross negligence or misconduct on the part of
                    the Reseller; (b) due to breach or non-compliance of any
                    terms and conditions contained herein; (c) breach due to
                    which any third party commences an action or makes a claim
                    against the aiqa or its affiliates or its officers, (d) any
                    loss caused to the aiqa due to breach of Confidentiality or
                    disclosure of any information by the Reseller.
                  </li>
                </ul>

                <AppHeading className="pt-5">
                  4. TERM AND TERMINATION::
                </AppHeading>
                <ul className="list-none pl-9 pt-5 ">
                  <li className="text-justify">
                    <b>4.1 </b> aiqa shall have the right to terminate this
                    arrangement with Reseller anytime by giving 7 days’ notice.
                  </li>{" "}
                  <li className="text-justify">
                    <b>4.2 </b> aiqa shall have the right to immediately
                    terminate this business arrangement with Reseller and have
                    legal action as well, in case of an occurrence of any of the
                    following events, and under such circumstances, aiqa shall
                    not be obligated to make any payments:
                  </li>
                  <ul className="list-none pl-9 pt-1 ">
                    <li className="text-justify">
                      <b>4.2.1 </b>4.2.1 Conviction of a felony.
                    </li>
                    <li className="text-justify">
                      <b>4.2.2 </b> Misappropriation of (or failure to remit)
                      any funds or property of aiqa.
                    </li>
                    <li className="text-justify">
                      <b>4.2.3 </b> Fails to comply with policies or directions
                      of the aiqa.
                    </li>
                    <li className="text-justify">
                      <b>4.2.4 </b> Furnish wrong information or conceals the
                      information or fails to disclose the material facts of the
                      Services or Products to the customer.
                    </li>
                    <li className="text-justify">
                      <b>4.2.5 </b> In the event of fraud or material breach of
                      any of the conditions or provisions contained herein.
                    </li>
                    <li className="text-justify">
                      <b>4.2.6 </b>Indulges in inducement in cash or kind with
                      the customer.
                    </li>
                    <li className="text-justify">
                      <b>4.2.7 </b> Acts in a manner prejudicial to the interest
                      of the aiqa or the customer.
                    </li>
                    <li className="text-justify">
                      <b>4.2.8 </b> Is found guilty of fraud or is charged or
                      convicted in any criminal act.
                    </li>
                    <li className="text-justify">
                      <b>4.2.9 </b>Indulges in any other misconduct.
                    </li>
                  </ul>
                </ul>

                <AppHeading className="pt-5">
                  5. Law and Arbitration:
                </AppHeading>
                <ul className="list-none pl-9 pt-5 ">
                  <li className="text-justify">
                    <b>5.1 </b>Any dispute, controversy or claims arising out of
                    terms and conditions contained herein or the breach,
                    termination, or invalidity thereof, shall be settled by
                    arbitration in accordance with the provisions of the
                    Arbitration and Conciliation Act, 1996.
                  </li>
                  <li className="text-justify">
                    <b>5.2 </b>The arbitral tribunal shall be composed of a sole
                    arbitrator mutually appointed by the Parties.
                  </li>
                  <li className="text-justify">
                    <b>5.3 </b>The place of arbitration shall be Gurugram. Any
                    award passed by an Arbitrator appointed, whether interim or
                    final, shall be made and binding on both the Parties.
                  </li>
                  <li className="text-justify">
                    <b>5.4 </b>The arbitral procedure shall be conducted in the
                    English language and any award or awards shall be rendered
                    in English. The procedural law of the arbitration shall be
                    Indian law.
                  </li>
                </ul>

                <AppHeading className="pt-5">6. MISCELLANEOUS:</AppHeading>
                <ul className="list-none pl-9 pt-5 ">
                  <li className="text-justify">
                    <b>6.1 </b>aiqa reserves the right to reject any and all
                    applications submitted by Reseller at its discretion.
                  </li>
                  <li className="text-justify">
                    <b>6.2 </b>Reseller confirms and undertakes to not violate
                    privacy covenants of subscribers or customers of aiqa
                    including prospective subscribers or customers, and in case
                    of any breach of privacy, Reseller shall be solely
                    responsible for losses arising out of the same.
                  </li>
                  <li className="text-justify">
                    <b>6.3 </b>Reseller shall strictly adhere to the information
                    and security measures for preventing any information
                    security breach pertaining to aiqa and/ or customer’s
                    information/ data and shall strictly adhere to the
                    appropriate security norms including but not limited to the
                    compliance of Information Technology Act and rules and
                    regulations made thereunder.
                  </li>
                  <li className="text-justify">
                    <b>6.4 </b>Reseller shall not share any information of the
                    customers/ subscribers and of aiqa with any third person
                    without the written permission of respective customers/
                    subscribers and of aiqa.
                  </li>
                  <li className="text-justify">
                    <b>6.5 </b> All intellectual property rights of brand,
                    products, or services, such as trademark or copyright or any
                    other right shall always vest exclusively with aiqa, and
                    Reseller agrees and undertakes not to set up any claim or
                    right under such Intellectual Property at any time during
                    and after the termination of business relationship.
                  </li>
                  <li className="text-justify">
                    <b>6.6 </b> Reseller shall immediately notify aiqa in
                    writing, and not later than 3 days of such change, of any
                    change of address and/ or communication.
                  </li>
                  <li className="text-justify">
                    <b>6.7 </b>Reseller has agreed to these terms and conditions
                    after due understanding of the same and has agreed to the
                    same out of his/ her own will and free consent. No
                    misrepresentations, inducements, promises, understandings,
                    conditions, indemnities, or warranties have been set forth
                    or relied upon by any Party hereto.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className="w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
            <div className="terms">
              <p className="text-justify">
                These Terms and Conditions has been agreed understood an
                acknowledged by the <strong>Reseller</strong> as a part and
                parcel of other terms and conditions for association with{" "}
                <strong>
                  HOSPIDO PRIVATE LIMITED (‘aiqa’ or ‘aiqahealth’)
                </strong>
                . Reseller understands and confirms the strict adherence to the
                same for the performance and delivery of services to the
                customers/ subscribers of aiqa.
              </p>

              <AppHeading className="pt-5">
                1. RESELLER HEREBY REPRESENTS, WARRANTS, AND COVENANTS THAT:{" "}
              </AppHeading>
              <ul className="list-none pl-9 pt-5 ">
                <li className="text-justify">
                  <b>1.1 </b>
                  <span>
                    {" "}
                    Reseller has the necessary qualification, power or authority
                    and the legal right(s) to enter this business arrangement
                    and shall remain in good standing during the entire period
                    and shall conduct the business in strict adherence to the
                    terms and conditions contained herein and shall provide
                    unprejudiced services to aiqa in respect of all or any of
                    the obligations mentioned herein.
                  </span>
                </li>
                <li className="text-justify">
                  <b>1.2 </b> Reseller has never been convicted of any crime
                  involving moral turpitude and is not disqualified and remains
                  fit and proper to deliver his/ her obligations and services
                  hereunder.
                </li>
                <li className="text-justify">
                  <b>1.3 </b> Reseller is not associated with or has been
                  simultaneously engaged by any other entity for providing
                  similar obligations/ services as are provided or plan to be
                  provided by aiqa to its subscribers/ customers. Further,
                  Reseller shall not engage himself/ herself with any other
                  entity providing similar services or Products without prior
                  written consent of aiqa.
                </li>
                <li className="text-justify">
                  <b>1.4 </b> Reseller shall only perform such services and
                  activities as are allowed and envisaged by aiqa and shall
                  never go beyond the given authorizations while delivering/
                  soliciting Services, as well as at the time of representing
                  aiqa and/ or its Services.
                </li>
                <li className="text-justify">
                  <b>1.5 </b> Reseller shall strictly adhere to and comply with
                  all applicable regulatory and other legal requirements while
                  delivering the Services to the subscribers/ customers. Further
                  Reseller shall conduct the business and deliver the Services
                  in a manner so as not to affect adversely the business, good
                  standing, and reputation of the aiqa.
                </li>
                <li className="text-justify">
                  <b>1.6 </b> Reseller shall not employ or make use of any
                  advertisement in which the aiqa’s (or its affiliate's) name,
                  or its registered trademarks are employed without the prior
                  written approval and consent of the aiqa.
                </li>
                <li className="text-justify">
                  <b>1.7 </b> Reseller shall act solely as an independent
                  service provider, subject to the control and guidance of the
                  aiqa. Nothing herein contained shall be construed to create
                  the relationship of employer and employee or Principal and
                  Agent, between Reseller and aiqa.
                </li>
                <li className="text-justify">
                  <b>1.8 </b> Reseller shall not further engage or employ anyone
                  as agents for soliciting the aiqa Services or Products,
                  without the prior written consent of aiqa.
                </li>
                <li className="text-justify">
                  <b>1.9 </b> Reseller shall not, without written permission of
                  aiqa, collect or provide receipt for Services or Products or
                  premiums received to/from the subscribers/ customers of aiqa.
                </li>
                <li className="text-justify">
                  <b>1.10 </b> Reseller shall have no claim or shall not be
                  entitled to reimbursement for any expenses. Reseller shall
                  only be paid compensation for the discharge for his/ her
                  services and obligations, as may be mutually agreed. Further,
                  the compensation to be paid shall be subject to deduction of
                  applicable taxes.
                </li>
                <li className="text-justify">
                  <b>1.11 </b> Reseller shall, only on receipt of written
                  permission of aiqa, collect premiums as per directions and
                  guidelines of aiqa. All such premiums collected from the
                  subscribers/ customers by Reseller hereunder shall be
                  submitted to the aiqa on the same day of receipt.
                </li>
                <li className="text-justify">
                  <b>1.12 </b> Reseller shall ensure due compliance of KYC/AML
                  guidelines as may be notified concerned by regulatory
                  authorities from time to time.
                </li>
                <li className="text-justify">
                  <b>1.13 </b> Reseller shall maintain proper records and
                  reports of its activities conducted, in a manner as provided
                  by aiqa and shall submit the due report to aiqa in a time
                  bound manner as may be instructed. aiqa shall also have the
                  right to inspect and audit the books, records, and accounts of
                  Reseller. Further aiqa shall also have the right to review of
                  the performance of Reseller from time to time.
                </li>
              </ul>

              <AppHeading className="pt-5">2. CONFIDENTIALITY:</AppHeading>
              <ul className="list-none pl-9 pt-5 ">
                <li className="text-justify">
                  <b>2.1 </b> Reseller hereby understands, agrees and confirm
                  that all tangible and intangible information disclosed by aiqa
                  or its affiliates, directly or indirectly, including all
                  details, documents, data, records, reports, systems, papers,
                  notices, statements, business or financial information and
                  practices and trade secrets (all of which are collectively
                  referred to as <strong>"Confidential Information"</strong>)
                  shall be treated as utmost confidential and Reseller agrees
                  and undertakes that the same shall be kept confidential and
                  shall not be disclosed, save as provided below, in whole or in
                  part to any person/s and/or used and/or be allowed to be used
                  for any purpose other than as may be necessary for the due
                  performance of obligations hereunder, except with written
                  authorization from aiqa.
                </li>{" "}
                <li className="text-justify">
                  <b>2.2 </b> Obligations under this clause shall continue to
                  apply in perpetuity even after the termination or expiry of
                  business relationship between aiqa and Reseller. In case of
                  any breach of this provision, aiqa shall have the right to
                  take legal action against the Reseller.
                </li>
              </ul>

              <AppHeading className="pt-5">3. INDEMNITY: </AppHeading>
              <ul className="list-none pl-9 pt-5 ">
                <li className="text-justify">
                  Reseller shall indemnify and keep indemnified and hold
                  harmless aiqa at all times and its affiliates, its directors
                  and officers from and against any and all losses, claims,
                  actions, proceedings, damages (including reasonable legal and
                  lawyer’s fees) which may be incurred by the aiqa on account of
                  (a) gross negligence or misconduct on the part of the
                  Reseller; (b) due to breach or non-compliance of any terms and
                  conditions contained herein; (c) breach due to which any third
                  party commences an action or makes a claim against the aiqa or
                  its affiliates or its officers, (d) any loss caused to the
                  aiqa due to breach of Confidentiality or disclosure of any
                  information by the Reseller.
                </li>
              </ul>

              <AppHeading className="pt-5">
                4. TERM AND TERMINATION::
              </AppHeading>
              <ul className="list-none pl-9 pt-5 ">
                <li className="text-justify">
                  <b>4.1 </b> aiqa shall have the right to terminate this
                  arrangement with Reseller anytime by giving 7 days’ notice.
                </li>{" "}
                <li className="text-justify">
                  <b>4.2 </b> aiqa shall have the right to immediately terminate
                  this business arrangement with Reseller and have legal action
                  as well, in case of an occurrence of any of the following
                  events, and under such circumstances, aiqa shall not be
                  obligated to make any payments:
                </li>
                <ul className="list-none pl-9 pt-1 ">
                  <li className="text-justify">
                    <b>4.2.1 </b>4.2.1 Conviction of a felony.
                  </li>
                  <li className="text-justify">
                    <b>4.2.2 </b> Misappropriation of (or failure to remit) any
                    funds or property of aiqa.
                  </li>
                  <li className="text-justify">
                    <b>4.2.3 </b> Fails to comply with policies or directions of
                    the aiqa.
                  </li>
                  <li className="text-justify">
                    <b>4.2.4 </b> Furnish wrong information or conceals the
                    information or fails to disclose the material facts of the
                    Services or Products to the customer.
                  </li>
                  <li className="text-justify">
                    <b>4.2.5 </b> In the event of fraud or material breach of
                    any of the conditions or provisions contained herein.
                  </li>
                  <li className="text-justify">
                    <b>4.2.6 </b>Indulges in inducement in cash or kind with the
                    customer.
                  </li>
                  <li className="text-justify">
                    <b>4.2.7 </b> Acts in a manner prejudicial to the interest
                    of the aiqa or the customer.
                  </li>
                  <li className="text-justify">
                    <b>4.2.8 </b> Is found guilty of fraud or is charged or
                    convicted in any criminal act.
                  </li>
                  <li className="text-justify">
                    <b>4.2.9 </b>Indulges in any other misconduct.
                  </li>
                </ul>
              </ul>

              <AppHeading className="pt-5">5. Law and Arbitration:</AppHeading>
              <ul className="list-none pl-9 pt-5 ">
                <li className="text-justify">
                  <b>5.1 </b>Any dispute, controversy or claims arising out of
                  terms and conditions contained herein or the breach,
                  termination, or invalidity thereof, shall be settled by
                  arbitration in accordance with the provisions of the
                  Arbitration and Conciliation Act, 1996.
                </li>
                <li className="text-justify">
                  <b>5.2 </b>The arbitral tribunal shall be composed of a sole
                  arbitrator mutually appointed by the Parties.
                </li>
                <li className="text-justify">
                  <b>5.3 </b>The place of arbitration shall be Gurugram. Any
                  award passed by an Arbitrator appointed, whether interim or
                  final, shall be made and binding on both the Parties.
                </li>
                <li className="text-justify">
                  <b>5.4 </b>The arbitral procedure shall be conducted in the
                  English language and any award or awards shall be rendered in
                  English. The procedural law of the arbitration shall be Indian
                  law.
                </li>
              </ul>

              <AppHeading className="pt-5">6. MISCELLANEOUS:</AppHeading>
              <ul className="list-none pl-9 pt-5 ">
                <li className="text-justify">
                  <b>6.1 </b>aiqa reserves the right to reject any and all
                  applications submitted by Reseller at its discretion.
                </li>
                <li className="text-justify">
                  <b>6.2 </b>Reseller confirms and undertakes to not violate
                  privacy covenants of subscribers or customers of aiqa
                  including prospective subscribers or customers, and in case of
                  any breach of privacy, Reseller shall be solely responsible
                  for losses arising out of the same.
                </li>
                <li className="text-justify">
                  <b>6.3 </b>Reseller shall strictly adhere to the information
                  and security measures for preventing any information security
                  breach pertaining to aiqa and/ or customer’s information/ data
                  and shall strictly adhere to the appropriate security norms
                  including but not limited to the compliance of Information
                  Technology Act and rules and regulations made thereunder.
                </li>
                <li className="text-justify">
                  <b>6.4 </b>Reseller shall not share any information of the
                  customers/ subscribers and of aiqa with any third person
                  without the written permission of respective customers/
                  subscribers and of aiqa.
                </li>
                <li className="text-justify">
                  <b>6.5 </b> All intellectual property rights of brand,
                  products, or services, such as trademark or copyright or any
                  other right shall always vest exclusively with aiqa, and
                  Reseller agrees and undertakes not to set up any claim or
                  right under such Intellectual Property at any time during and
                  after the termination of business relationship.
                </li>
                <li className="text-justify">
                  <b>6.6 </b> Reseller shall immediately notify aiqa in writing,
                  and not later than 3 days of such change, of any change of
                  address and/ or communication.
                </li>
                <li className="text-justify">
                  <b>6.7 </b>Reseller has agreed to these terms and conditions
                  after due understanding of the same and has agreed to the same
                  out of his/ her own will and free consent. No
                  misrepresentations, inducements, promises, understandings,
                  conditions, indemnities, or warranties have been set forth or
                  relied upon by any Party hereto.
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};

const TermsConnect = connect(mapStateToProps)((props) => {
  return <Terms {...props} />;
});

export default withRouter(TermsConnect);
