import React from "react";
import InputGroup from "../../../../components/formikForm/InputGroup";
import InputSelect from "../../../../components/formikForm/InputSelect";
import InputLabel from "../../../../components/form/InputLabel";
import { ErrorMessage, Field } from "formik";
import { fieldValidation } from "../../common/data/constants";

const LabTestBasicInfo = ({
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-7 p-2">
      <InputGroup
        error={touched?.lab_test_name && errors?.lab_test_name}
        label="Lab Test Name"
        style={{ fontWeight: "550", fontSize: "12px" }}
        value={values.lab_test_name}
        placeHolder="Enter the lab test name"
        helperText="Use up to 50 characters. Special characters are not allowed."
        name="lab_test_name"
        requiredMark="true"
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (e.target.value.length < 50 &&
              fieldValidation.name.test(e.target.value))
          ) {
            setFieldValue("lab_test_name", e.target.value);
            setFieldTouched("lab_test_name", true, false);
          }
        }}
      />

      <InputGroup
        error={touched?.technical_name && errors?.technical_name}
        label="Technical Name"
        style={{ fontWeight: "550", fontSize: "12px" }}
        value={values.technical_name}
        placeHolder="Enter the name to be displayed in the app"
        helperText="This is the name visible to customers. Max 50 characters."
        name="technical_name"
        requiredMark="true"
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (e.target.value.length < 50 &&
              fieldValidation.name.test(e.target.value))
          ) {
            setFieldValue("technical_name", e.target.value);
            setFieldTouched("technical_name", true, false);
          }
        }}
      />

      <InputSelect
        error={touched?.lab_test_limit && errors?.lab_test_limit}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark="true"
        label="Lab test limit"
        name="lab_test_limit"
        value={values.lab_test_limit}
        helperText="Choose how many times user can avail this lab test."
        onChange={(e) => {
          setFieldValue("lab_test_limit", e.target.value);
          setFieldTouched("lab_test_limit", true, false);
        }}
        options={[
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
        ]}
      />
      <div className="col-span-2 flex-shrink">
        <InputLabel
          label="Pre-Requirements"
          requiredMark="true"
          style={{ fontWeight: "550", fontSize: "12px" }}
        />

        <Field name="pre_requirement">
          {({ field, form }) => (
            <div>
              <textarea
                {...field}
                className={`custom-input-type block w-full px-3 py-2 border rounded-md shadow-sm 
                text-gray-700 bg-white
               focus:outline-none 
                focus:ring-2 focus:ring-gray-300 focus:border-gray-200
                border-gray-200 disabled:bg-gray-200 transition  ${
                  touched?.pre_requirement && errors?.pre_requirement
                    ? "border-red-500"
                    : ""
                }`}
                maxLength={150} // Limit input to 150 characters
                onChange={(e) => {
                  if (e.target.value.length <= 150) {
                    setFieldValue("pre_requirement", e.target.value);
                    setFieldTouched("pre_requirement", true, false);
                  }
                }}
              />
              {/* Character Counter */}
              <div className="flex flex-row-reverse justify-between items-center">
                <p className="text-xs text-gray-500 text-right mt-1">
                  {field.value?.length || 0}/150 characters
                </p>
                <p
                  style={{ fontSize: "11px", fontStyle: "italic" }}
                  className="text-gray-400 mt-1"
                >
                  <span className="font-medium">
                    * {"Add pre-requirements for this lab test"}
                  </span>
                </p>
              </div>
            </div>
          )}
        </Field>

        {/* Error Message */}
        <div className="text-xs text-red-600">
          <ErrorMessage name="pre_requirement" />
        </div>
      </div>
    </div>
  );
};

export default LabTestBasicInfo;
