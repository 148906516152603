import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default function CustomModal({
  children,
  setOpen,
  open,
  padding = 0,
  margin = 0,
  minWidth = "initial",
  maxWidth = "initial",
  borderRadius = "6px",
  backDropClose = false,
}) {
  const handleClose = (event, reason) => {
    if (backDropClose) {
      if (reason && reason == "backdropClick") {
        return;
      }
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: minWidth,
            maxWidth: maxWidth,
            borderRadius: borderRadius,
          },
        }}
      >
        <DialogContent
          style={{
            height: "100%",
            padding: padding,
            margin: margin,
            scrollbarWidth: "thin", // For Firefox
            "&::-webkit-scrollbar": {
              width: "6px", // Adjust this value to control width for WebKit browsers (Chrome, Safari)
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Customize thumb color
            },
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
