import React, { useRef, useState } from "react";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { useSelector } from "react-redux";
import ReimbursementSingle from "../raiseReimbursement/reimbursementSingle";
import ReimbursementUploader from "../raiseReimbursement/reimbursementBulkUploader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const tabs = [
  {
    key: "single",
    label: "Single",
    content: <ReimbursementSingle />,
    role: "claim_add",
  },
  {
    key: "bulk",
    label: "Bulk",
    content: <ReimbursementUploader />,
    role: "reimbursement_uploader",
  },
];

const RaiseReimbursement = () => {
  const location = useLocation();
  const { state } = location;
  const user = useSelector((state) => state.user);
  const permissions = user.permissions;
  const MessageRef = useRef();

  const [activeTab, setActiveTab] = useState(state?.key || tabs[0].key);

  const handleTabClick = (key) => {
    setActiveTab(key);
  };
  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between items-center">
        <div className="w-full md:flex-1 md:justify-start flex">
          <AppBackButton to="/" label="Back" color="#000000" />
        </div>
        <div className="w-full md:flex-1 flex justify-center">
          <AppHeading
            className="text-center font-medium mb-6"
            style={{ color: "#000000" }}
          >
            Raise reimbursement
          </AppHeading>
        </div>
        <div className="hidden md:flex md:flex-1"></div>
      </div>

      <div className="mb-6 flex justify-start border-b border-gray-200">
        {tabs.map(
          (tab) =>
            permissions[tab.role] && (
              <button
                key={tab.key}
                className="px-6 py-3 text-sm font-semibold transition-all duration-200 w-44 mr-2"
                style={{
                  color: activeTab === tab.key ? "#DB2228" : "#565656",
                  borderBottom:
                    activeTab === tab.key ? "4px solid #DB2228" : "#565656",
                }}
                onClick={() => handleTabClick(tab.key)}
              >
                {tab.label}
              </button>
            )
        )}
      </div>

      <div className="py-4 bg-white text-gray-600">
        {tabs.find((tab) => tab.key === activeTab)?.content}
      </div>
    </>
  );
};

export default RaiseReimbursement;
