import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomAccordian from "../../../common/components/CustomAccordian";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Checkbox } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import InputSelect from "../../../../../components/formikForm/InputSelect";
import InputGroup from "../../../../../components/formikForm/InputGroup";
import {
  fetchProductBenefit,
  fetchVendorList,
  fieldLengthValidation,
  fieldValidation,
} from "../../../common/data/constants";
import MultiSelector from "../../../../../components/formikForm/MultiSelector";
import {
  DotPulse,
  memberCoveredOptions,
  tenureTypeOptions,
} from "../../../common/data";
import { debounce } from "lodash";
import { ApiGet } from "../../../../../lib/AppHelper";
import { toast } from "react-toastify";

const SKIN = ({
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  membersCovered,
}) => {
  const [open, setOpen] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);

  const debounceCheckVendorPlanByFields = useCallback(
    debounce(async ({ tenureType, maxConsultantCount, tenure }) => {
      if (!tenure || !tenureType || !maxConsultantCount) {
        setPlanLoading(false);
        return;
      }

      setFieldValue("vendorPlanId", "");
      const response = await ApiGet(
        `admin/get-core-plan?tenureType=${tenureType}&maxConsultantCount=${maxConsultantCount}&consultationType=SKIN&tenure=${tenure}`
      );

      if (response.code === 200 && response?.data) {
        setPlanLoading(false);
        const { planId } = response?.data;
        setFieldValue("vendorPlanId", planId);
      } else {
        setPlanLoading(false);
        toast.info(
          "No sub-product found for the selected combination of fields. Please contact your support team or try again with different values."
        );
      }
    }, 1000),
    []
  );

  const debounceCheckVendorPlanById = useCallback(
    debounce(async (id) => {
      setFieldValue("tenureType", "");
      setFieldValue("maxConsultantCount", "");
      setFieldValue("tenure", "");
      const response = await ApiGet(
        `admin/get-core-plan?planId=${id}&consultationType=SKIN`
      );

      if (response.code === 200 && response?.data) {
        const { tenure, tenureType, maxConsultantCount } = response?.data;
        setFieldValue("tenureType", `${tenureType}`.toUpperCase());
        setFieldValue("maxConsultantCount", maxConsultantCount);
        setFieldValue("tenure", tenure);
        setPlanLoading(false);
      } else {
        setPlanLoading(false);
        toast.info(
          "No sub-product found for this vendor plan ID. Please contact your support team or try a different vendor plan ID."
        );
      }
    }, 1000),
    []
  );
  const checkVendorPlanByFields = (id) => {
    setPlanLoading(true);
    debounceCheckVendorPlanByFields(id);
  };

  const checkVendorPlanById = (id) => {
    if (id) {
      setPlanLoading(true);
      debounceCheckVendorPlanById(id);
    }
  };

  const getVendorList = async () => {
    setVendorLoading(true);
    const list = await fetchVendorList("SKIN");
    setVendorList(list);
    setVendorLoading(false);
  };

  const getBenefits = async () => {
    setLoading(true);
    const list = await fetchProductBenefit("SKIN");
    if (list?.length) {
      const formattedOptions = list.map((benefit) => ({
        label: benefit?.description_text, // Adjust based on API response
        value: benefit?.id,
      }));
      setBenefit(formattedOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open && !vendorList?.length) {
      getVendorList();
    }
    if (open && !benefit?.length) {
      getBenefits();
    }
  }, [open]);

  const handleBenefitChange = (e) => {
    const data = e.map((item) => item?.value);
    setFieldValue("productBenefitId", data);
  };

  return (
    <>
      <CustomAccordian
        active={open}
        header={
          <div
            onClick={() => setOpen((prev) => !prev)}
            className="flex justify-between"
          >
            <div className="flex items-center">
              {open ? <ArrowDropDown className="rotate-180" /> : <ArrowRight />}
              <div
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <Checkbox
                  name="Skin Consultation"
                  checked={values?.SKIN}
                  onChange={(e, v) => {
                    setFieldValue(`SKIN`, v);
                    if (v && !open) {
                      setOpen(true);
                    }
                  }}
                  className="cursor-pointer"
                />
              </div>
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={faSeedling}
                  style={{ color: "#A3FF1" }}
                ></FontAwesomeIcon>
                <span
                  className="text-base text-gray-700"
                  style={{ fontWeight: 550 }}
                >
                  Skin Consultation
                </span>
              </div>
            </div>
          </div>
        }
        children={
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-2 items-center">
              <InputSelect
                error={touched?.vendorId && errors?.vendorId}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                label="Vendor Name "
                name={"vendorId"}
                value={values?.vendorId}
                helperText="Choose vendor for this subscription"
                onChange={(e) => {
                  setFieldValue(`vendorId`, e.target.value);
                  setFieldTouched("vendorId");
                }}
                options={vendorList || []}
                loading={vendorLoading}
              />
              <InputSelect
                error={touched?.membersCovered && errors?.membersCovered}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                label="Member Covered"
                name={"membersCovered"}
                value={values?.membersCovered}
                helperText="Members covered for this sub-product."
                onChange={(e) => {
                  setFieldValue(`membersCovered`, e.target.value);
                  setFieldTouched("membersCovered");
                }}
                options={memberCoveredOptions.filter(
                  (item, index) => index < membersCovered
                )}
              />
            </div>
            <p className="p-2 flex">
              Skin Consultation Plan {planLoading ? <DotPulse /> : "Check"}
            </p>

            <div className="flex border p-2 rounded-lg gap-2">
              <div className="w-2/6">
                <InputGroup
                  error={touched?.vendorPlanId && errors?.vendorPlanId}
                  style={{ fontWeight: "550", fontSize: "12px" }}
                  requiredMark={true}
                  label="Vendor plan ID"
                  name="vendorPlanId"
                  value={values?.vendorPlanId}
                  helperText={`Subscription ID for Skin-consultation`}
                  onChange={(e) => {
                    setFieldValue("vendorPlanId", e.target.value);
                    setFieldTouched("vendorPlanId");

                    checkVendorPlanById(e.target.value);
                  }}
                />
              </div>

              <div className="w-full grid grid-cols-3 gap-2 border-l-2 border-gray-300 pl-3">
                <InputGroup
                  error={
                    touched?.maxConsultantCount && errors?.maxConsultantCount
                  }
                  style={{ fontWeight: "550", fontSize: "12px" }}
                  requiredMark={true}
                  label="Max Consultation"
                  name="maxConsultantCount"
                  value={values?.maxConsultantCount}
                  helperText={`Enter 99999
                    for unlimited consultation`}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      (e.target.value.length < fieldLengthValidation.number &&
                        fieldValidation.number.test(e.target.value))
                    ) {
                      setFieldValue("maxConsultantCount", e.target.value);
                      setFieldTouched("maxConsultantCount");
                      checkVendorPlanByFields({
                        tenureType: values.tenureType,
                        maxConsultantCount: e.target.value,
                        tenure: values.tenure,
                      });
                    }
                  }}
                />
                <InputSelect
                  error={touched?.tenureType && errors?.tenureType}
                  style={{ fontWeight: "550", fontSize: "12px" }}
                  requiredMark={true}
                  label="Tenure Type"
                  name={"tenureType"}
                  value={values?.tenureType}
                  helperText="Choose the duration type for the product."
                  onChange={(e) => {
                    setFieldValue(`tenureType`, e.target.value);
                    setFieldTouched("tenureType", true, false);
                    checkVendorPlanByFields({
                      tenureType: e.target.value,
                      maxConsultantCount: values.maxConsultantCount,
                      tenure: values.tenure,
                    });
                  }}
                  options={tenureTypeOptions}
                />

                <InputGroup
                  error={touched?.tenure && errors?.tenure}
                  style={{ fontWeight: "550", fontSize: "12px" }}
                  requiredMark={true}
                  disabled={!values?.tenureType}
                  label="Tenure"
                  name="tenure"
                  value={values?.tenure}
                  placeHolder="Enter tenure duration (e.g., 12 for 12 months)"
                  helperText="The duration for which the product is active."
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      (e.target.value.length < fieldLengthValidation.number &&
                        fieldValidation.number.test(e.target.value))
                    ) {
                      setFieldValue("tenure", e.target.value);
                      setFieldTouched("tenure", true, false);
                      checkVendorPlanByFields({
                        tenureType: values.tenureType,
                        maxConsultantCount: values.maxConsultantCount,
                        tenure: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="p-2 flex flex-col gap-1">
              <p className="font-medium">Product Benefit</p>
              <MultiSelector
                options={benefit}
                onChange={handleBenefitChange}
                selectedIds={values?.productBenefitId}
                loading={loading}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default SKIN;
