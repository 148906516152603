import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { ApiGet, SendTeamPerformanceReport } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { RefreshIcon } from "@heroicons/react/outline";
export class EmployeeReport extends Component {
  MessageRef = React.createRef();
  state = {
    reports: [],
    policyCount: 0,
    policyAmount: 0,
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      employee_id: "",
    },
    employees: [],
    toMaxDate: new Date(),
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, policyCount, policyAmount, employees } =
      this.state;
    const { user } = this.props;
    const { permissions } = user;
    return (
      <>
        {permissions && permissions?.team_performance_report_view ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/"> Back </AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "30%" }} className="float-right">
                  <div
                    style={{ fontSize: "12px" }}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                  >
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText="Download"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="grid md:grid-cols-5 gap-4"
              style={{ margin: "10px" }}
            >
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>

              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>
              <div
                className="flex flex-row Report-button"
                style={{ marginTop: "15px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                  style={{ height: "30px" }}
                >
                  <RefreshIcon width={25} height={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="sm"
                  title={"Search"}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    {reports ? (
                      <table className="min-w-full" id="table-to-xls">
                        <thead>
                          <tr>
                            <td>
                              <table className="min-w-full border text-center">
                                <thead className="border-b">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center"
                                    >
                                      Active Members
                                    </th>

                                    <th
                                      scope="col"
                                      className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center"
                                    >
                                      Total Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="border-b">
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                      {policyCount || 0}
                                    </td>

                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                      {policyAmount || 0}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table className="min-w-full">
                                <thead className="border-b">
                                  <tr>
                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                      Name
                                    </th>
                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                      Total Members
                                    </th>
                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                      Active Members
                                    </th>{" "}
                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                      Unpaid Members
                                    </th>
                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reports &&
                                    reports?.map((item, index) => {
                                      return (
                                        <tr className="border-b" key={index}>
                                          <td
                                            className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {item?.resellerName
                                              ? item.resellerName
                                              : "---"}
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item?.totalMembers
                                              ? item?.totalMembers
                                              : 0}
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item?.member ? item?.member : 0}
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.unpaidMembers
                                              ? item.unpaidMembers
                                              : 0}
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item?.amount ? item?.amount : 0}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  {reports?.length === 0 ? (
                                    <tr>
                                      <td
                                        colSpan="9"
                                        height="50"
                                        className="text-red-500 font-light text-center"
                                      >
                                        Your result is empty
                                      </td>
                                    </tr>
                                  ) : (
                                    false
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You don't have permission to view Team Performance Report</h4>
            </div>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.getReports();
    this.getTeam();
  }
  handleClick = () => {
    this.getReports(this.state.params);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request = "") => {
    let data = {
      startDate: request.from ? request.from : moment().format("YYYY-MM-DD"),
      endDate: request.to ? request.to : moment().format("YYYY-MM-DD"),
      resellerId: request.employee_id,
      page: 0,
      size: 10,
      mode: "",
    };
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await SendTeamPerformanceReport(
      "enrollment-service/stats_queries/teamPerformanceReport",
      data
    );
    if (response?.code === 200) {
      if (response?.responseObject) {
        let data = response?.responseObject[0];
        this.setState({
          ...this.state,
          reports: response?.responseObject,
          policyCount: data?.activeMember,
          policyAmount: data?.totalAmount,
        });
      } else {
        this.setState({
          ...this.state,
          reports: [],
          policyCount: 0,
          policyAmount: 0,
        });
      }
    } else {
      // this.MessageRef.current.show(response.message);
      this.setState({
        ...this.state,
        reports: [],
        policyCount: 0,
        policyAmount: 0,
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const EmployeeReportConnect = connect(mapStateToProps)((props) => {
  return <EmployeeReport {...props} />;
});

export default withRouter(EmployeeReportConnect);
