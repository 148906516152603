import {
  ActionClaimReportIcon,
  ActiveSubscriptionIcon,
  BoxIcon,
  BusinessReportIcon,
  CommissionDueReportIcon,
  CommissionReportIcon,
  CreditLifeUploaderIcon,
  CustomerLeadCaptureReportIcon,
  DashboardReportIcon,
  DownloadIcon,
  EMIUploaderIcon,
  ExcelUploaderIcon,
  ExpiredSubscriptionIcon,
  ExpiringSoonSubscriptionIcon,
  FullReportIcon,
  GroupUploaderIcon,
  HierarchySalesReportIcon,
  HomeIcon,
  InsuranceFormatReportIcon,
  InsuranceViewReportIcon,
  LAPUploaderIcon,
  PartnerReimbursementIcon,
  ProductSalesReportIcon,
  RecordReimbursementIcon,
  ReimbursementReportIcon,
  ReportIcon,
  ReportMasterIcon,
  ResellerAppointmentReportIcon,
  ResellerDataReportIcon,
  ResellerLeadCaptureReportIcon,
  ResellerProductSalesReportIcon,
  ResellerSalesReportIcon,
  SalesReportIcon,
  SalesReportLIcon,
  SubscriptionExpireReportIcon,
  SubscriptionIcon,
  SubscriptionReportIcon,
  SubscriptionSourceReportIcon,
  TeamIcon,
  TeamPerformanceReportIcon,
  UnpaidSubscriptionIcon,
  UpdateCustomerReportIcon,
  UploadDateReportIcon,
  UploadDateTeamReportIcon,
  UploaderIcon,
  VendorPolicyUploaderIcon,
  Wallet,
} from "../../../Layout/SidebarIcons";
import {
  checkReportPermission,
  fetchReportPermission,
} from "../../../utils/constants";

export const MenuItems = [
  {
    label: "Home",
    icon: (active) => <HomeIcon size={15} color={"#000000"} />,
    isSubMenu: false,
    to: "/",
    permission: true,
  },
  {
    label: "Subscriptions",
    icon: (active) => <SubscriptionIcon size={15} color={"#000000"} />,
    isSubMenu: true,
    permission: [
      "application_view",
      "unpaid_policy_view",
      "expire_policy_view",
      "expiring_soon_policy_view",
    ],
    childrenData: [
      {
        label: "Active",
        icon: (active) => <ActiveSubscriptionIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/subscriptions",
        permission: ["application_view"],
      },
      {
        label: "Unpaid",
        icon: (active) => <UnpaidSubscriptionIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/leads",
        permission: ["unpaid_policy_view"],
      },
      {
        label: "Expired",
        icon: (active) => <ExpiredSubscriptionIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/expired",
        permission: ["expire_policy_view"],
      },
      {
        label: "Expiring Soon",
        icon: (active) => <ExpiringSoonSubscriptionIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/expiring-soon",
        permission: ["expiring_soon_policy_view"],
      },
    ],
  },
  {
    label: "Reimbursements",
    icon: (active) => <Wallet size={15} color={"#000000"} />,
    isSubMenu: true,
    permission: ["claim_view", "partner_wise_reimbursement_view"],
    childrenData: [
      {
        label: "Record",
        icon: (active) => <RecordReimbursementIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/reimbursements",
        permission: ["claim_view"],
      },
      {
        label: "Partner",
        icon: (active) => <PartnerReimbursementIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/partner-wise-reimbursements",
        permission: ["partner_wise_reimbursement_view"],
      },
    ],
  },
  {
    label: "Uploader",
    icon: (active) => <UploaderIcon />,
    isSubMenu: true,
    permission: [
      "upload_excel",
      "excel_uploader_history_table",
      "group_upload",
      "policy_uploader",
      "credit_upload",
      "emi_product_uploader",
    ],
    childrenData: [
      {
        label: "Excel",
        icon: (active) => <ExcelUploaderIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/excel-history",
        state: {
          componentTo: "/excel-uploader",
          type: "normal uploader",
        },
        permission: ["upload_excel"],
        excelPermission: {
          role: ["excel_uploader_history_table"],
          to: "/excel-uploader",
        },
      },
      {
        label: "Group",
        icon: (active) => <GroupUploaderIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/group-excel-history",
        state: {
          componentTo: "/group-excel-uploader",
          type: "group uploader",
        },
        permission: ["group_upload"],
        excelPermission: {
          role: ["excel_uploader_history_table"],
          to: "/group-excel-uploader",
        },
      },
      {
        label: "Vendor Policy",
        icon: (active) => <VendorPolicyUploaderIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/policy-uploader",
        permission: ["policy_uploader"],
      },
      {
        label: "Credit Life",
        icon: (active) => <CreditLifeUploaderIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/credit-excel-history",
        state: {
          componentTo: "/credit-life-uploader",
          type: "credit uploader",
        },
        permission: ["credit_upload"],
        excelPermission: {
          role: ["excel_uploader_history_table"],
          to: "/credit-life-uploader",
        },
      },
      {
        label: "EMI",
        icon: (active) => <EMIUploaderIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/emi-excel-history",
        state: {
          componentTo: "/emi-uploader",
          type: "emi uploader",
        },
        permission: ["emi_product_uploader"],
        excelPermission: {
          role: ["excel_uploader_history_table"],
          to: "/emi-uploader",
        },
      },
      {
        label: "LAP",
        icon: (active) => <LAPUploaderIcon color={"#000000"} />,
        isSubMenu: false,
        to: "/lap-uploader",
        state: {
          componentTo: "/lap-uploader",
          type: "lap uploader",
        },
        permission: ["lap_upload"],
      },
    ],
  },
  {
    label: "Reports",
    icon: (active) => <ReportIcon size={15} color={"#000000"} />,
    isSubMenu: true,
    permission: [
      "action_claim_report",
      "business_report_view",
      "commission_report_view",
      "commission_due_report_view",
      "customer_lead_capture",
      "hierarchy_sales_report_view",
      "insurance_format_report",
      "insurance_reseller_view",
      "product_wise_sales_report",
      "claim_report_view",
      "report_360",
      "report_master",
      "aiqa_center",
      "appointment_booking",
      "data_report_view",
      "reseller_lead_capture",
      "reseller_product_wise_sale_report",
      "admin_dashboard",
      "sales_report",
      "label_sales_report",
      "policy_expire_report",
      "subscription_source_report",
      "subscription_report_view",
      "team_performance_report_view",
      "update_pm_jay_information",
      "upload_date_report",
      "upload_date_team_report",
    ],
    childrenData: [
      {
        label: "Action Claim",
        to: "/reports/action-claim-report",
        icon: (active) => <ActionClaimReportIcon />,
        isSubMenu: false,
        permission: ["action_claim_report"],
      },
      {
        label: "Business",
        to: "/reports/business",
        icon: (active) => <BusinessReportIcon />,
        isSubMenu: false,
        permission: ["business_report_view"],
      },
      {
        label: "Commission",
        to: "/reports/commissions",
        icon: (active) => <CommissionReportIcon />,
        isSubMenu: false,
        permission: ["commission_report_view"],
      },
      {
        label: "Commission Due Report",
        to: "/reports/due-commissions",
        icon: (active) => <CommissionDueReportIcon />,
        isSubMenu: false,
        permission: ["commission_due_report_view"],
      },
      {
        label: "Customer Lead Capture",
        to: "/reports/lead-capture",
        icon: (active) => <CustomerLeadCaptureReportIcon />,
        isSubMenu: false,
        permission: ["customer_lead_capture"],
      },
      {
        label: "Dashboard",
        to: "/reports/reseller-sales",
        icon: (active) => <DashboardReportIcon />,
        isSubMenu: false,
        permission: ["admin_dashboard"],
      },
      {
        label: "Hierarchy Sales",
        to: "/reports/hierarchy-sales-report",
        icon: (active) => <HierarchySalesReportIcon />,
        isSubMenu: false,
        permission: ["hierarchy_sales_report_view"],
      },
      {
        label: "Insurance Format",
        to: "/reports/insurance-format-report",
        icon: (active) => <InsuranceFormatReportIcon />,
        isSubMenu: false,
        permission: ["insurance_format_report"],
      },
      {
        label: "Insurance View",
        to: "/reports/insurance-view",
        icon: (active) => <InsuranceViewReportIcon />,
        isSubMenu: false,
        permission: ["insurance_reseller_view"],
      },
      {
        label: "Product Wise Sales",
        to: "/reports/product-wise-sales-report",
        icon: (active) => <ProductSalesReportIcon />,
        isSubMenu: false,
        permission: ["product_wise_sales_report"],
      },
      {
        label: "Reimbursements",
        to: "/reports/reimbursements",
        icon: (active) => <ReimbursementReportIcon />,
        isSubMenu: false,
        permission: ["claim_report_view"],
      },
      {
        label: "Report 360",
        to: "/reports/report360",
        icon: (active) => <FullReportIcon />,
        isSubMenu: false,
        permission: ["report_360"],
      },
      {
        label: "Report Master",
        to: "/reports/report-master",
        icon: (active) => <ReportMasterIcon />,
        isSubMenu: false,
        permission: ["report_master"],
      },
      {
        label: "Reseller Appointment",
        to: "/reports/reseller-appointment",
        icon: (active) => <ResellerAppointmentReportIcon />,
        isSubMenu: false,
        permission: ["aiqa_center", "appointment_booking"],
      },
      {
        label: "Reseller Data",
        to: "/reports/data-report",
        icon: (active) => <ResellerDataReportIcon />,
        isSubMenu: false,
        permission: ["data_report_view"],
      },
      {
        label: "Reseller Lead Capture",
        to: "/reports/reseller-lead-capture",
        icon: (active) => <ResellerLeadCaptureReportIcon />,
        isSubMenu: false,
        permission: ["reseller_lead_capture"],
      },
      {
        label: "Reseller Product Wise Sales",
        to: "/reports/reseller-product-wise-sales-report",
        icon: (active) => <ResellerProductSalesReportIcon />,
        isSubMenu: false,
        permission: ["reseller_product_wise_sale_report"],
      },
      {
        label: "Reseller Sales",
        to: "/reports/reseller-sales-report",
        icon: (active) => <ResellerSalesReportIcon />,
        isSubMenu: false,
        permission: ["admin_dashboard"],
      },
      {
        label: "Sales Report",
        to: "/reports/sales",
        icon: (active) => <SalesReportIcon />,
        isSubMenu: false,
        permission: ["sales_report"],
      },
      {
        label: "Sales Report(L1 & L2)",
        to: "/reports/level-sales-report",
        icon: (active) => <SalesReportLIcon />,
        isSubMenu: false,
        permission: ["label_sales_report"],
      },
      {
        label: "Subscription Expire",
        to: "/reports/policy-expire",
        icon: (active) => <SubscriptionExpireReportIcon />,
        isSubMenu: false,
        permission: ["policy_expire_report"],
      },
      {
        label: "Subscription Source",
        to: "/reports/subscription-source-report",
        icon: (active) => <SubscriptionSourceReportIcon />,
        isSubMenu: false,
        permission: ["subscription_source_report"],
      },
      {
        label: "Subscriptions Report",
        to: "/reports/subscriptions",
        icon: (active) => <SubscriptionReportIcon />,
        isSubMenu: false,
        permission: ["subscription_report_view"],
      },
      {
        label: "Team Performance",
        to: "/reports/emp-sales",
        icon: (active) => <TeamPerformanceReportIcon />,
        isSubMenu: false,
        permission: ["team_performance_report_view"],
      },
      {
        label: "Update Customer Information",
        to: "/reports/update-customer-info",
        icon: (active) => <UpdateCustomerReportIcon />,
        isSubMenu: false,
        permission: ["update_pm_jay_information"],
      },
      {
        label: "Upload Date",
        to: "/reports/upload-date-report",
        icon: (active) => <UploadDateReportIcon />,
        isSubMenu: false,
        permission: ["upload_date_report"],
      },
      {
        label: "Upload Date Team",
        to: "/reports/upload-date-team-report",
        icon: (active) => <UploadDateTeamReportIcon />,
        isSubMenu: false,
        permission: ["upload_date_team_report"],
      },
    ],
  },
  {
    label: "Team",
    icon: (active) => <TeamIcon size={15} color={"#000000"} />,
    isSubMenu: false,
    to: "/employees",
    permission: true,
  },
  {
    label: "Download",
    icon: (active) => <DownloadIcon size={15} color={"#000000"} />,
    isSubMenu: false,
    to: "/download-center",
    permission: ["download_archive"],
  },
  {
    label: "Products",
    icon: (active) => <BoxIcon size={15} color={"#000000"} />,
    isSubMenu: false,
    to: "/products",
    permission: true,
  },
];

export const DashboardStatsRetail = {
  activePolicy: {
    label: "Active Subscriptions",
    value: 0,
    loader: false,
    permission: ["application_view"],
    redirect: {
      to: "/subscriptions",
      iconText: "View",
    },
  },
  unsoldPolicy: {
    label: "Unpaid Subscriptions",
    value: 0,
    loader: false,
    permission: ["unpaid_policy_view"],
    redirect: {
      to: "/leads",
      iconText: "View",
    },
  },
  expirePolicy: {
    label: "Expired Subscriptions",
    value: 0,
    loader: false,
    permission: ["expire_policy_view"],
    redirect: {
      to: "/expired",
      iconText: "View",
    },
  },
  expiringSoonPolicy: {
    label: "Subscriptions Expiring Soon",
    value: 0,
    loader: false,
    permission: ["expiring_soon_policy_view"],
    redirect: {
      to: "/expiring-soon",
      iconText: "View",
    },
  },

  // teamCount: {
  //   label: "Team Count",
  //   value: 0,
  //   loader: false,
  //   permission: ["view_team", "reseller_add"],
  //   redirect: {
  //     to: "/employees",
  //     iconText: "View",
  //   },
  // },

  labTestCount: {
    label: "Lab Tests",
    value: 0,
    loader: false,
    permission: ["lab_test_subscription"],
    redirect: {
      to: "/lab-test",
      iconText: "View",
    },
  },
  salesAmount: {
    label: "Total Sales",
    value: 0,
    loader: false,
    permission: ["total_sale_view"],
    redirect: {
      to: null,
      iconText: "View",
    },
  },
};

export const DashboardGraphFilter = [
  {
    label: "This Month",
    value: "thisMonth",
  },
  {
    label: "Last 1 Month",
    value: "last1Month",
  },
  {
    label: "Last 3 Months",
    value: "last3Months",
  },
  {
    label: "Last 6 Months",
    value: "last6Months",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Last 1 Year",
    value: "last1Year",
  },
  {
    label: "Last 3 Years",
    value: "last3Years",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const UpdateStats = {
  reimbursements: {
    label: "Reimbursement",
    value: 0,
    notificationData: [],
    loader: false,
    styles: {
      backgroundColor: "rgba(73, 156, 218, 1)",
    },
    permission: ["claim_view", "partner_wise_reimbursement_view"],
    redirect: {
      to: "/notifications",
      iconText: "View",
    },
  },
  dataUpload: {
    label: "Data Uploads",
    value: 0,
    notificationData: [],
    loader: false,
    styles: {
      backgroundColor: "rgba(235, 176, 0, 1)",
    },
    permission: [
      "excel_uploader_history_table",
      "group_upload",
      "policy_uploader",
      "credit_upload",
      "emi_product_uploader",
    ],
    redirect: {
      to: "/notifications",
      iconText: "View",
    },
  },
};

export const RecentUpdatesPermissions = [
  "excel_uploader_history_table",
  "group_upload",
  "policy_uploader",
  "credit_upload",
  "emi_product_uploader",
  "claim_view",
  "partner_wise_reimbursement_view",
];

export const months = [
  "All",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ResellerReports = [
  {
    label: "Business Report",
    pathname: "/reports/business",
    permission: ["business_report_view"],
  },
  {
    label: "Reimbursements Report",
    pathname: "/reports/reimbursements",
    permission: ["claim_report_view"],
  },
  {
    label: "Commission Due Report",
    pathname: "/reports/due-commissions",
    permission: ["commission_due_report_view"],
  },
  {
    label: "Commission Report",
    pathname: "/reports/commissions",
    permission: ["commission_report_view"],
  },
  {
    label: "Customer Lead Capture Report",
    pathname: "/reports/lead-capture",
    permission: ["customer_lead_capture"],
  },
  {
    label: "Dashboard Report",
    pathname: "/reports/reseller-sales",
    permission: ["admin_dashboard"],
  },
  {
    label: "Reseller Appointment Report",
    pathname: "/reports/reseller-appointment",
    permission: ["aiqa_center", "appointment_booking"],
  },
  {
    label: "Reseller Data Report",
    pathname: "/reports/data-report",
    permission: ["data_report_view"],
  },
  {
    label: "Reseller Product Wise Sales Report",
    pathname: "/reports/reseller-product-wise-sales-report",
    permission: ["reseller_product_wise_sale_report"],
  },
  {
    label: "Reseller Lead Capture Report",
    pathname: "/reports/reseller-lead-capture",
    permission: ["reseller_lead_capture"],
  },
  {
    label: "Reseller Sales Report",
    pathname: "/reports/reseller-sales-report",
    permission: ["reseller_sales_report"],
  },
  {
    label: "Subscription Expire Report",
    pathname: "/reports/policy-expire",
    permission: ["policy_expire_report"],
  },
  {
    label: "Product Wise Sales Report",
    pathname: "/reports/product-wise-sales-report",
    permission: ["product_wise_sales_report"],
  },
  {
    label: "Sales Report",
    pathname: "/reports/sales",
    permission: ["sales_report"],
  },
  {
    label: "Sales Report(L1 & L2)",
    pathname: "/reports/level-sales-report",
    permission: ["label_sales_report"],
  },
  {
    label: "Subscriptions Report",
    pathname: "/reports/subscriptions",
    permission: ["subscription_report_view"],
  },
  {
    label: "Team Performance Report",
    pathname: "/reports/emp-sales",
    permission: ["team_performance_report_view"],
  },
  {
    label: "Subscription Source Report",
    pathname: "/reports/subscription-source-report",
    permission: ["subscription_source_report"],
  },
  {
    label: "Update Customer Information",
    pathname: "/reports/update-customer-info",
    permission: ["update_pm_jay_information"],
  },
  {
    label: "Upload Date Team Report",
    pathname: "/reports/upload-date-team-report",
    permission: ["upload_date_team_report"],
  },
  {
    label: "Upload Date Report",
    pathname: "/reports/upload-date-report",
    permission: ["upload_date_report"],
  },
  {
    label: "Hierarchy Sales Report",
    pathname: "/reports/hierarchy-sales-report",
    permission: ["hierarchy_sales_report_view"],
  },
  {
    label: "Insurance View Report",
    pathname: "/reports/insurance-view",
    permission: ["insurance_reseller_view"],
  },
  {
    label: "Report Master",
    pathname: "/reports/report-master",
    permission: ["report_master"],
  },
  {
    label: "Action Claim Report",
    pathname: "/reports/action-claim-report",
    permission: ["action_claim_report"],
  },
  {
    label: "Report 360",
    pathname: "/reports/report360",
    permission: ["report_360"],
  },
  {
    label: "Insurance Format Report",
    pathname: "/reports/insurance-format-report",
    permission: ["insurance_format_report"],
  },
];

export const getNumber = function (number, isAmount = true) {
  let amount = number;
  if (number >= 10000000) {
    amount = isAmount
      ? "₹" + (number / 10000000).toFixed(2) + " Cr"
      : (number / 10000000).toFixed(2) + " Cr";
  } else if (number >= 100000) {
    amount = isAmount
      ? "₹" + (number / 100000).toFixed(2) + " Lakh"
      : (number / 100000).toFixed(2) + " Lakh";
  } else {
    amount = new Intl.NumberFormat("en-IN").format(number || 0);
  }
  return amount.toString();
};

export const getCurrentFinancialYear = () => {
  const thisYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Get month (1-based)

  // Determine the starting year of the financial year
  const startYear = currentMonth >= 4 ? thisYear : thisYear - 1;
  const yearArray = [0, 1, 2].map(
    (count) =>
      `${startYear - count}-${(startYear - count + 1).toString().slice(-2)}`
  );

  return yearArray;
};

export function getGraphDateRange(range) {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  let startDate, endDate, type;

  if (range === "last3Years") {
    let startYear = currentMonth < 4 ? currentYear - 3 : currentYear - 2;
    let endYear = currentMonth < 4 ? currentYear : currentYear + 1;
    startDate = new Date(startYear, 3, 1); // 01-Apr
    endDate = new Date(endYear, 2, 31); // 31-Mar
    type = "yearly";
  } else if (range === "thisYear") {
    let startYear = currentMonth < 4 ? currentYear - 1 : currentYear;
    let endYear = startYear + 1;
    startDate = new Date(startYear, 3, 1);
    endDate = new Date(endYear, 2, 31);
    type = "monthly";
  } else if (range === "thisMonth") {
    startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    endDate = new Date();
    type = "weekly";
  } else if (range === "last1Month") {
    startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    type = "weekly";
  } else if (range === "last3Months") {
    startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    type = "monthly";
  } else if (range === "last6Months") {
    startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    type = "monthly";
  } else if (range === "last1Year") {
    let startYear = currentMonth < 4 ? currentYear - 2 : currentYear - 1;
    let endYear = startYear + 1;
    startDate = new Date(startYear, 3, 1);
    endDate = new Date(endYear, 2, 31);
    type = "monthly";
  } else {
    return { error: "Invalid range type" };
  }

  // Ensure endDate does not exceed today
  if (endDate > today) {
    endDate = today;
  }

  // Format date as "DD-MM-YYYY"
  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    type: type,
  };
}

export const formatDate = (date) => {
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export function getSearchByOptions(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in days
  const diffInTime = end - start;
  const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  let searchBy = [];

  if (diffInDays <= 7) {
    searchBy = [{ label: "All Dates", value: "daily" }];
  } else if (diffInDays >= 8 && diffInDays < 30) {
    searchBy = [{ label: "Week", value: "weekly" }];
  } else if (diffInDays >= 30 && diffInDays <= 90) {
    searchBy = [
      { label: "Week", value: "weekly" },
      { label: "Month", value: "monthly" },
    ];
  } else if (diffInDays > 90 && diffInDays <= 366) {
    searchBy = [{ label: "Month", value: "monthly" }];
  } else if (diffInDays > 366) {
    searchBy = [{ label: "Year", value: "yearly" }];
  }

  return searchBy;
}

export const getNotificationData = (type, data) => {
  let a = [];
  if (type == "reimbursements") {
    a = data.map((item) => `${item.value} added to ${item.status} `);
  } else if (type == "dataUpload") {
    a = data.map((item) => `${item.value} new data uploaded`);
  }
  return a;
};

export const getClaimStatusColor = (type, status) => {
  const claimStatus = [
    {
      status: "APPROVED",
      color: "#008000",
      backgroundColor: "#0080001F",
    },
    {
      status: "QUERY",
      color: "#F43985",
      backgroundColor: "#F439851F",
    },
    {
      status: "INPROGRESS",
      color: "#CC7822",
      backgroundColor: "#FEBE001F",
    },
    {
      status: "SETTLED",
      color: "#008000",
      backgroundColor: "#0080001F",
    },
    {
      status: "REJECTED",
      color: "#db2228",
      backgroundColor: "#db22281F",
    },
    {
      status: "PENDING",
      color: "#CC7822",
      backgroundColor: "#FEBE001F",
    },
  ];

  const uploadStatus = [
    {
      status: "SUCCESS",
      color: "#008000",
      backgroundColor: "#0080001F",
    },
    {
      status: "FAILURE",
      color: "#db2228",
      backgroundColor: "#db22281F",
    },
  ];

  if (type == "Reimbursement") {
    return (
      claimStatus.find((item) => item.status === status) || {
        status: "INPROGRESS",
        color: "#CC7822",
        backgroundColor: "#FEBE001F",
      }
    );
  } else if (type == "Data Uploads") {
    return (
      uploadStatus.find((item) => item.status === status) || {
        status: "INPROGRESS",
        color: "#CC7822",
        backgroundColor: "#FEBE001F",
      }
    );
  }
};

export const getUpdateDescription = (type, values) => {
  if (type == "Reimbursement" && values?.claimId && values?.status) {
    return `Reimbursement ID ${
      values?.claimId
    } was ${values?.status.toLowerCase()}.`;
  } else if (type == "Data Uploads") {
    return `Total: ${values?.total}, Successful: ${values?.success}, Failed: ${values?.failure}`;
  } else {
    return "-";
  }
};
