import React from "react";
import { Label } from "./constant";

const Cards = ({
  label,
  newCount,
  totalCount,
  setFilter,
  filter,
  windowSize,
}) => {
  if (!Label[label]) return <></>;

  const isSelected = filter === (label === "ALL" ? "" : label);
  const cardStyle = {
    height: "60px",
    width:
      windowSize[0] < 450 ? "47.5%" : windowSize[0] < 800 ? "31.8%" : "189px",
    cursor: "pointer",
    borderRadius: "4px",
    border: isSelected ? "1px solid rgb(219, 34, 40)" : "1px solid #00000029",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "relative",
    color: "black",
  };

  const handleClick = () => {
    if (!isSelected) {
      localStorage.setItem("card_Filter", label === "ALL" ? "" : label);
      setFilter(label === "ALL" ? "" : label);
    }
  };

  return (
    <div style={cardStyle} onClick={handleClick}>
      <span
        style={{
          fontSize: "14px",
          fontWeight: "600",
          textTransform: "capitalize",
        }}
      >
        {Label[label]}
      </span>
      <span
        className={`font-medium ${
          filter === label ? "black" : "text-gray-600"
        }`}
      >
        {totalCount}
      </span>
      {newCount > 0 && label === "QUERY" && (
        <span
          style={{
            color: "#ffffff",
            background: "#DB2228",
            padding: "2px 8px",
            borderRadius: "12px",
            fontSize: "10px",
            position: "absolute",
            top: 5,
            right: windowSize[0] < 380 ? 5 : 20,
          }}
        >
          {newCount && `${newCount}+`}
        </span>
      )}
    </div>
  );
};

export default Cards;
