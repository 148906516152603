import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { withRouter } from "react-router-dom";
import { getFileType } from "../utils/commom";
import { CircularProgress, Dialog } from "@mui/material";
import PdfViewer from "../utils/view_downloadPrescription/PDFViewer";
import { CloseOutlined } from "@mui/icons-material";
import { MinusIcon, PlusIcon, DownloadIcon } from "@heroicons/react/outline";

const ProductTnc = ({ location }) => {
  const [product, setProduct] = useState(null);
  const [selectedSpecification, setSelectedSpecification] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(null);
  const [isViewOpenType, setIsViewOpenType] = useState(null);
  // const [loadingImage, setLoadingImage] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const MessageRef = useRef(null);

  useEffect(() => {
    if (location?.state?.product) {
      setProduct(location.state.product);
    }
  }, [location]);

  const documentViewHandler = (data) => {
    if (!data?.partner?.tnc_heading || !data?.partner?.tnc_url) return;
    const fileUrl = data.partner.tnc_url;
    const fileType = getFileType(fileUrl);

    setIsModalOpen(true);
    setIsViewOpen(fileUrl);
    setIsViewOpenType(fileType);
    // setLoadingImage(fileType.startsWith("image/"));
    setSelectedSpecification(data?.partner?.tnc_heading);
  };

  const documentViewCloseHandler = () => {
    setIsModalOpen(false);
    setIsViewOpen(null);
    setIsViewOpenType(null);
    // setLoadingImage(false);
    setSelectedSpecification(null);
    setZoomLevel(1);
  };

  const handleDownload = async () => {
    if (!isViewOpen || !selectedSpecification) return;

    try {
      const response = await fetch(isViewOpen);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const fileExtension = isViewOpenType?.startsWith("image/")
        ? ".jpg"
        : isViewOpenType?.startsWith("application/pdf")
        ? ".pdf"
        : "";

      const sanitizedFileName = selectedSpecification;
      const fileName = `${sanitizedFileName}${fileExtension}`;

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  if (!product) return null;

  return (
    <>
      <AppBackButton to="/">Back</AppBackButton>

      <div className="w-full px-6 flex">
        <div className="flex-1">
          <AppHeading>{product?.name}</AppHeading>

          {Array.isArray(product?.productTnc) &&
            product?.productTnc.length > 0 && (
              <>
                <p className="pt-10 font-medium text-lg">
                  Terms & Conditions :
                </p>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pt-10">
                  {product.productTnc.map(
                    (productTnc, index) =>
                      productTnc?.partner?.tnc_heading && (
                        <div
                          key={index}
                          className="flex items-center justify-center h-24 rounded-xl bg-primary-50 border border-primary p-4 cursor-pointer text-blue-500 underline"
                          onClick={() => documentViewHandler(productTnc)}
                        >
                          {productTnc?.partner?.tnc_heading}
                        </div>
                      )
                  )}
                </div>
              </>
            )}
        </div>
      </div>

      <Dialog
        maxWidth="lg"
        open={isModalOpen}
        onClose={documentViewCloseHandler}
      >
        <div className="w-full bg-red-600 p-4 flex flex-wrap md:flex-nowrap justify-between items-center gap-3">
          <p className="text-white text-sm md:text-base">
            Terms & Conditions ({selectedSpecification})
          </p>

          <div className="flex items-center gap-3 flex-wrap md:flex-nowrap">
            {(isViewOpenType?.startsWith("image/") ||
              isViewOpenType?.startsWith("application/pdf")) && (
              <ZoomControl zoom={zoomLevel} setZoom={setZoomLevel} />
            )}

            <div className="flex items-center gap-2 bg-white text-red-600 p-1 rounded-lg">
              <button onClick={handleDownload} className="p-2">
                <DownloadIcon className="cursor-pointer w-5 h-5" />
              </button>
            </div>

            <CloseOutlined
              className="cursor-pointer text-white w-5 h-5"
              onClick={documentViewCloseHandler}
            />
          </div>
        </div>

        <div className="overflow-y-auto p-4">
          {isViewOpenType?.startsWith("image/") && (
            <div className="w-full flex justify-center items-center">
              {/* {loadingImage && (
                <div className="h-40 w-auto flex justify-center items-center">
                  <CircularProgress />
                </div>
              )} */}
              <img
                src={isViewOpen}
                alt="Image Preview"
                className="transition-transform duration-300 object-contain"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: "top left",
                  transition: "transform 0.3s ease-in-out",
                }}
                // onLoad={() => setLoadingImage(false)}
              />
            </div>
          )}

          {isViewOpenType?.startsWith("application/pdf") && (
            <div className="flex justify-center items-center">
              <PdfViewer myFile={isViewOpen} scale={zoomLevel} />
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({ loading: state.loading });

export default withRouter(connect(mapStateToProps)(ProductTnc));

const ZoomControl = ({ zoom, setZoom }) => {
  const zoomLevels = [1, 1.25, 1.5, 1.75, 2, 2.5, 3];

  const handleZoomIn = () => {
    setZoom((prev) => {
      const nextLevel = zoomLevels.find((level) => level > prev);
      return nextLevel || prev;
    });
  };

  const handleZoomOut = () => {
    setZoom((prev) => {
      const prevLevel = [...zoomLevels].reverse().find((level) => level < prev);
      return prevLevel || prev;
    });
  };

  return (
    <div className="flex items-center gap-2 bg-white text-red-600 p-1 rounded-lg">
      <button onClick={handleZoomOut} className="pl-2">
        <MinusIcon className="w-5 h-5" />
      </button>
      <div className="px-2 py-1 bg-white rounded text-lg font-semibold">
        {Math.round(zoom * 100)}%
      </div>
      <button onClick={handleZoomIn} className="pr-2">
        <PlusIcon className="w-5 h-5" />
      </button>
    </div>
  );
};
