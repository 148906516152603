import React from "react";
import { Panel } from "rsuite";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";

const CustomAccordian = ({
  key,
  active,
  loading = false,
  header,
  children,
  className,
  bordered = false,
  emptyMessage = "No panels available",
  ...props
}) => {
  return (
    <Panel
      key={key}
      collapsible
      className={`border border-gray-400 mb-0 bg-white ${className}`}
      header={header}
      expanded={active}
      bordered={bordered}
      {...props}
    >
      {loading ? <ShimmerLoader rows={10} /> : children}
    </Panel>
  );
};

export default CustomAccordian;
