import moment from "moment";
import React, { Component } from "react";
import { AppBackButton, AppHeading } from "../../../components/AppComponents";
import { InputDate } from "../../../components/form/InputDate";
import Button from "../../../components/Button";
import { RefreshIcon } from "@heroicons/react/outline";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppStore } from "../../../lib/AppStore";
import {
  JavaApiGet,
  JavaApiPost,
  downloadCSVFileForJavaEndPoint,
} from "../../../lib/AppHelper";
import { Pagination } from "rsuite";
import {
  insuranceTableHeaders,
  insuranceType,
  vendorListIcon,
} from "../../../services/common/data";
import { InputGroup } from "../../../components/form/InputGroup";
import { InputSelect } from "../../../components/form/InputSelect";
import InputLabel from "../../../components/form/InputLabel";
import { AutoComplete } from "rsuite";
import { debounce } from "lodash";

class InsuranceResellerView extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 5,
    data: [],
    count: 0,
    layout: ["total", "-", "pager", "skip"],
    params: {
      uploadStartDate: "",
      uploadEndDate: "",
      page: 1,
      applicationId: "",
      mobile: "",
      resellerId: null,
      customerName: "",
      productName: "",
      insuredMemberId: "",
      insuranceType: "",
      loanCode: "",
      vendorName: "",
      vendorPolicyId: "",
      policyStartDate: null,
      policyEndDate: null,
      sequenceType: "DESC",
    },
    resellerName: "",
    insuranceTypeList: [],
    uploadStartDateError: false,
    uploadEndDateError: false,
    searchValue: [],
  };

  onChangeHandlerDate(value, key) {
    const { params } = this.state;
    params[key] = value ? moment(value).format("YYYY-MM-DD") : "";
    if (key === "uploadStartDate") {
      this.setState({
        params: { ...params, page: 1 },
        uploadStartDateError: false,
      });
    }
    if (key === "uploadEndDate") {
      this.setState({
        params: { ...params, page: 1 },
        uploadEndDateError: false,
      });
    }
  }

  onChangeHandler(value, key) {
    const { params } = this.state;
    params[key] = value;
    this.setState({ params: params });

    if (key === "resellerName") {
      this.setState({
        resellerName: value,
      });
      this.debouncedGetResellerName(value);
    }
    if (key === "uploadStartDate")
      this.setState({
        uploadStartDateError: false,
      });
    if (key === "uploadEndDate")
      this.setState({
        uploadEndDateError: false,
      });
  }

  handleToggleSortDirection = () => {
    this.setState(
      (prevState) => ({
        params: {
          ...prevState.params,
          sequenceType:
            prevState.params.sequenceType === "ASC" ? "DESC" : "ASC",
        },
      }),
      () => {
        this.getInsuranceDetails();
      }
    );
  };

  handleKeyDown = (e) => {
    if (!this.state.params?.resellerId && this.state.resellerName) {
      return;
    }
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  render() {
    const { params, data, insuranceTypeList } = this.state;
    const { user } = this.props;
    const { permissions } = user;

    const modifiedInsuranceTypeList = [
      { value: "", label: "Select your option" },
      ...(Array.isArray(insuranceTypeList)
        ? insuranceTypeList
            .filter((insuranceType) => insuranceType)
            .map((insuranceType) => ({
              value: insuranceType,
              label: insuranceType,
            }))
        : []),
    ];

    return (
      <>
        {permissions && permissions?.insurance_reseller_view ? (
          <>
            <div className="grid md:grid-cols-2 gap-2 mb-xs">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "30%" }} className="float-right">
                  <Button
                    className={`mr-5 ${
                      !this.applyDownloadFilter() ? "disabled-button" : ""
                    }`}
                    attrs={{
                      type: "button",
                      onClick: this.downloadClick,
                      disabled: !this.applyDownloadFilter(),
                    }}
                    size="sm"
                    title={"Download"}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-center mb-5 mt-0">
              <AppHeading color="red">Insurance view</AppHeading>
            </div>
            <div>
              <div className="grid md:grid-cols-4 gap-2">
                <InputDate
                  label="Uploaded from"
                  placeholder="DD-MM-YYYY"
                  requiredMark={this.state?.uploadStartDateError}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) =>
                      this.onChangeHandlerDate(e, "uploadStartDate"),
                    value: params.uploadStartDate
                      ? new Date(params.uploadStartDate)
                      : null,
                    onKeyDown: (e) => this.handleKeyDown(e),
                  }}
                  isError={this.state?.uploadStartDateError}
                />
                <InputDate
                  label="Uploaded till"
                  placeholder="DD-MM-YYYY"
                  requiredMark={this.state.uploadEndDateError}
                  minDate={new Date(params.uploadStartDate)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) =>
                      this.onChangeHandlerDate(e, "uploadEndDate"),
                    value: params.uploadEndDate
                      ? new Date(params.uploadEndDate)
                      : null,
                    onKeyDown: (e) => this.handleKeyDown(e),
                  }}
                  isError={this.state.uploadEndDateError}
                />

                {insuranceTableHeaders
                  .filter((header) => header.isShown)
                  .map((header, index) => {
                    return (
                      <div key={index}>
                        {header?.searchType ? (
                          header?.searchType === "autocomplete" ? (
                            <div className="mb-5">
                              <InputLabel
                                label={header.name}
                                requiredMark={
                                  !params.resellerId && this.state.resellerName
                                }
                              />
                              <AutoComplete
                                style={{
                                  marginTop: "0.5rem",
                                  width: "100%",
                                  border:
                                    !params.resellerId &&
                                    this.state.resellerName
                                      ? "1px solid red"
                                      : "",
                                  borderRadius:
                                    !params.resellerId &&
                                    this.state.resellerName
                                      ? "14px"
                                      : "",
                                }}
                                maxLength={
                                  header?.maxLength ? header.maxLength : 12
                                }
                                placeholder={header.placeholder}
                                data={this.state.searchValue?.map((item) => ({
                                  label: item.resellerName,
                                  value: item.resellerId,
                                }))}
                                onInput={(e, v) => {
                                  this.onChangeHandler("", "resellerId");
                                  this.onChangeHandler(
                                    e.target.value,
                                    header.key
                                  );
                                }}
                                onSelect={(value, obj) => {
                                  this.setState({
                                    params: {
                                      ...this.state.params,
                                      resellerId: value,
                                    },
                                    resellerName: obj.label,
                                  });
                                }}
                                value={this.state.resellerName}
                                onKeyDown={(e) => this.handleKeyDown(e)}
                              />
                            </div>
                          ) : header?.searchType === "input" ? (
                            <InputGroup
                              label={header.name}
                              placeholder={header.placeholder}
                              attrs={{
                                maxLength: header?.maxLength
                                  ? header.maxLength
                                  : 12,
                                value: params?.[header?.key],
                                onChange: (e) => {
                                  const value = e.target?.value;
                                  if (
                                    header.regex.test(value) ||
                                    value === ""
                                  ) {
                                    this.onChangeHandler(value, header.key);
                                  }
                                },
                                onKeyDown: (e) => this.handleKeyDown(e),
                              }}
                              className="w-full sm:w-auto"
                            />
                          ) : header.searchType === "date" ? (
                            <InputDate
                              maxDate={
                                header?.key === "policyStartDate" && new Date()
                              }
                              attrs={{
                                onChange: (e) => {
                                  this.onChangeHandler(e, header.key);
                                },
                                value: params?.[header?.key]
                                  ? new Date(params?.[header?.key])
                                  : null,
                                onKeyDown: (e) => this.handleKeyDown(e),
                              }}
                              className="w-full sm:w-auto"
                            />
                          ) : header.searchType === "select" ? (
                            <div className="w-full">
                              <InputSelect
                                options={modifiedInsuranceTypeList}
                                label={"Insurance type"}
                                attrs={{
                                  value: params?.[header.key] || "",
                                  onChange: (e) =>
                                    this.onChangeHandler(
                                      e.target.value,
                                      header.key
                                    ),
                                  onKeyDown: (e) => this.handleKeyDown(e),
                                }}
                                className="w-full"
                              />
                            </div>
                          ) : (
                            false
                          )
                        ) : (
                          false
                        )}
                      </div>
                    );
                  })}
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px auto 0 0",
                  }}
                >
                  <button
                    className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                      backgroundColor: !this.applyFilters()
                        ? "lightGray"
                        : "#db2228",
                      cursor: !this.applyFilters() ? "not-allowed" : "pointer",
                    }}
                    onClick={this.handleClearFilters}
                    disabled={!this.applyFilters()}
                  >
                    <RefreshIcon width={20} height={20} className="m-1" />
                  </button>
                  <Button
                    className={`ml-5 ${
                      !this.applyFilters() ? "disabled-button" : ""
                    }`}
                    attrs={{
                      type: "button",
                      onClick: this.handleClick,
                      disabled:
                        (!params.resellerId && this.state.resellerName) ||
                        !this.applyFilters(),
                    }}
                    size="sm"
                    title={"Search"}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <table className="min-w-full border" id="table-to-xls">
                  <thead className="border-b">
                    {insuranceTableHeaders.map((header, index) => {
                      return (
                        <th
                          key={index}
                          className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white"
                        >
                          {header.name}
                          {header.key === "createdOn" && data?.length > 0 && (
                            <span
                              onClick={this.handleToggleSortDirection}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              {this.state.params.sequenceType === "DESC"
                                ? "▼"
                                : "▲"}
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </thead>
                  <tbody>
                    {data?.length ? (
                      data?.map((details, index) => {
                        const vendorIcon = vendorListIcon.find(
                          (vendor) => vendor.value === details.vendorName
                        );

                        return (
                          <tr className="border-b" key={index}>
                            <td className="text-left border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {moment(details.createdOn).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-left border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.resellerName}
                            </td>
                            <td className=" text-left border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.productName}
                            </td>
                            <td className="text-left border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.customerName}
                            </td>
                            <td className="text-right border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.mobile}
                            </td>
                            <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.insuredMemberId}
                            </td>
                            <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.insuranceType}
                            </td>
                            <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.loanCode}
                            </td>
                            <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {vendorIcon ? (
                                <img
                                  src={vendorIcon.img}
                                  alt={vendorIcon.label}
                                  style={{ width: "160px" }}
                                />
                              ) : (
                                details.vendorName
                              )}
                            </td>
                            <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                              {details.vendorPolicyId}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="9"
                          height="150"
                          className="text-red-500 font-large text-center"
                        >
                          No Data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div style={{ padding: 20 }}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={2}
                  size="xs"
                  layout={this.state.layout}
                  total={this.state.count}
                  limit={this.state.limit}
                  activePage={this.state.params.page}
                  onChangePage={this.onPageChange}
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>
                You do not have permission to view insurance reseller view.
              </h4>
            </div>
          </div>
        )}
      </>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      limit: 5,
      data: [],
      count: 0,
      insuranceTypeList: [],
      layout: ["total", "-", "pager", "skip"],
      params: {
        page: 1,
        uploadStartDate: "",
        uploadEndDate: "",
        applicationId: "",
        mobile: "",
        resellerId: "",
        customerName: "",
        productName: "",
        insuredMemberId: "",
        insuranceType: "",
        loanCode: "",
        vendorName: "",
        vendorPolicyId: "",
        policyStartDate: null,
        policyEndDate: null,
        sequenceType: "DESC",
      },
      results: [],
      uploadStartDateError: false,
      uploadEndDateError: false,
      searchValue: [],
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.debouncedGetResellerName = debounce(this.getResellerName, 1000);
  }

  componentDidMount() {
    // this.getInsuranceDetails(1);
    this.getInsuranceType();
  }

  handleClick = () => {
    const { params } = this.state;
    let uploadStartDateError = false;
    let uploadEndDateError = false;

    if (
      params.resellerId ||
      params.productName ||
      params.insuranceType ||
      params.vendorName ||
      params.uploadStartDate ||
      params.uploadEndDate
    ) {
      if (!params.uploadStartDate) {
        uploadStartDateError = true;
      }
      if (!params.uploadEndDate) {
        uploadEndDateError = true;
      }

      if (uploadStartDateError || uploadEndDateError) {
        this.setState({ uploadStartDateError, uploadEndDateError });
        return;
      } else {
        this.setState({
          uploadStartDateError: false,
          uploadEndDateError: false,
        });
      }
    } else {
      this.setState({ uploadStartDateError: false, uploadEndDateError: false });
    }

    this.getInsuranceDetails(1);
    this.setState({ page: 1 });
  };

  getInsuranceType = async () => {
    JavaApiGet("enrollment-service/api/insuredMember/insurance_type").then(
      (res) => {
        this.setState({ insuranceTypeList: res?.responseObject?.partnerName });
      }
    );
  };

  getResellerName = async (value) => {
    if (!value) {
      return;
    }
    JavaApiGet(`enrollment-service/reseller/getResellerName/${value}`).then(
      (res) => {
        this.setState({
          searchValue: res?.responseObject?.map((item) => item),
        });
      }
    );
  };

  getInsuranceDetails = async (Page) => {
    const { page } = this.state;
    const pageValue = Page ? Page - 1 : page - 1;
    const { params, limit } = this.state;
    AppStore.dispatch({ type: "LOADING", loading: true });

    const data = {
      uploadStartDate: params.uploadStartDate,
      uploadEndDate: params.uploadEndDate,
      applicationId: params.applicationId,
      mobile: params.mobile,

      resellerId: params.resellerId,
      customerName: params.customerName,
      productName: params.productName,
      insuredMemberId: params.insuredMemberId,
      insuranceType: params.insuranceType,
      loanCode: params.loanCode,
      vendorName: params.vendorName,
      vendorPolicyId: params.vendorPolicyId,
      policyStartDate: params.policyStartDate
        ? moment(params.policyStartDate).format("YYYY-MM-DD")
        : null,
      policyEndDate: params.policyEndDate
        ? moment(params.policyEndDate).format("YYYY-MM-DD")
        : null,
      page: pageValue,
      size: limit,
      sequenceType: params.sequenceType,
    };
    JavaApiPost(
      "enrollment-service/api/insuredMember/insurancePolicyDetails",
      data
    ).then((res) => {
      this.setState({
        data: res?.responseObject ? res?.responseObject : [],
        count: res?.count,
      });
      AppStore.dispatch({ type: "LOADING", loading: false });
    });
  };

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getInsuranceDetails(this.state.params.page);
    });
  };

  applyFilters = () => {
    let isAvailable = false;
    const { params } = this.state;

    const keys = [
      "uploadStartDate",
      "uploadEndDate",
      "applicationId",
      "mobile",
      "resellerId",
      "customerName",
      "productName",
      "insuredMemberId",
      "insuranceType",
      "loanCode",
      "vendorName",
      "vendorPolicyId",
      "policyStartDate",
      "policyEndDate",
    ];
    keys.map((item) => {
      if (params[item]) {
        isAvailable = true;
      }
    });

    return isAvailable;
  };

  applyDownloadFilter = () => {
    const { params } = this.state;

    return (
      params.uploadStartDate &&
      params.uploadEndDate &&
      this.state.data?.length > 0
    );
  };

  downloadClick = async () => {
    let payload = {
      uploadStartDate: this.state.params.uploadStartDate,
      uploadEndDate: this.state.params.uploadEndDate,
      sequenceType: this.state.params.sequenceType,
      resellerId: this.state.params.resellerId,
      productName: this.state.params.productName,
      customerName: this.state.params.customerName,
      mobile: this.state.params.mobile,
      insuredMemberId: this.state.params.insuredMemberId,
      insuranceType: this.state.params.insuranceType,
      loanCode: this.state.params.loanCode,
      vendorName: this.state.params.vendorName,
      vendorPolicyId: this.state.params.vendorPolicyId,
    };

    AppStore.dispatch({ type: "LOADING", loading: true });

    await downloadCSVFileForJavaEndPoint(
      "enrollment-service/api/insuredMember/insurancePolicyExcelData",
      payload,
      "insuranceView.csv"
    );

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  handleSubmit = () => {
    this.handleClick();
    if (
      this.state.params.uploadEndDate !== "" &&
      this.state.params.uploadEndDate !== ""
    ) {
      this.getInsuranceDetails(1);
    }
  };

  handleClearFilters = () => {
    const { params } = this.state;

    params["uploadStartDate"] = "";
    params["uploadEndDate"] = "";
    params["page"] = 1;
    params["applicationId"] = "";
    params["mobile"] = "";
    params["resellerId"] = "";
    params["customerName"] = "";
    params["productName"] = "";
    params["insuredMemberId"] = "";
    params["insuranceType"] = "";
    params["loanCode"] = "";
    params["productName"] = "";
    params["vendorName"] = "";
    params["vendorPolicyId"] = "";

    this.setState({ params: params });
    this.setState({
      data: [],
      resellerName: "",
      uploadStartDateError: false,
      uploadEndDateError: false,
    });
    // this.getInsuranceDetails(1);
    // this.setState({ page: 1 });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default withRouter(connect(mapStateToProps)(InsuranceResellerView));
