import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import {
  ApiGet,
  downloadExcelFile,
  getData,
  getResellerId,
} from "../../lib/AppHelper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { InputDate } from "../../components/form/InputDate";
import { IconButton, Pagination } from "rsuite";
import { Modal } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { PRIMARY_COLOR } from "../../utils/constants";

export class BusinessReport extends Component {
  MessageRef = React.createRef();
  loc = this.props.location?.state?.id;
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    policyCount: 0,
    policyAmount: 0,
    permissions: "",
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      page: 1,
      limit: 10,
      employee_id: this.props.location?.state?.id || "",
    },
    toMaxDate: new Date(),
    open: false,
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    params["months"] = "";
    params["days"] = "";
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["page"] = 1;
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
    this.getReports();
  };

  onClickExport = () => {
    this.setState({ ...this.state, open: !this.state.open });
  };

  render() {
    const { params, reports, permissions } = this.state;
    return (
      <>
        <div className="grid md:grid-cols-2 gap-2">
          <AppBackButton to="/">Back</AppBackButton>
          <div className="text-right">
            <div className="float-right flex gap-2 md:mr-5">
              <IconButton
                appearance="primary"
                style={{ backgroundColor: PRIMARY_COLOR }}
                icon={
                  <FileDownloadIcon
                    style={{ backgroundColor: PRIMARY_COLOR }}
                  />
                }
                onClick={this.onClickExport}
              >
                Export
              </IconButton>
            </div>
          </div>
        </div>
        <div className="ml-6 font-bold text-lg">Hierarchy sales report</div>
        {permissions?.hierarchy_sales_report_view ? (
          <>
            <div
              className="grid grid-cols-1 gap-2 mt-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {reports?.map((item, index) => {
                const path = "/reports/hierarchy-sales-report/" + item.id;
                return (
                  <article
                    key={index}
                    style={{ width: "24rem" }}
                    className={`overflow-hidden rounded-lg shadow-lg p-3 mt-3 ${
                      item.direct_team ? "cursor-pointer" : ""
                    }`}
                    onClick={() => {
                      if (item.direct_team > 0) {
                        this.props.history.push(path, { id: item.id });
                      }
                    }}
                  >
                    <header className="flex w-75 justify-between leading-tight p-2 md:p-4">
                      <div>
                        <h1 className="md:text-lg text-sm font-bold capitalize">
                          {item.name ? item.name : ""}
                          <span className="font-normal text-xs text-center">
                            {item.reseller_category
                              ? `(${item.reseller_category})`
                              : ""}
                          </span>
                        </h1>
                        <p className="hidden md:block lg:block">
                          <small>
                            <span className="pr-1">
                              <FontAwesomeIcon
                                icon={faPhoneSquare}
                                className="text-primary rotate-45"
                              />
                            </span>
                            {item?.mobile ? item?.mobile : ""}
                          </small>
                        </p>
                      </div>
                      {/* <p className="text-grey-darker text-sm">
                        {moment(
                          item?.created_at ? item?.created_at : ""
                        ).format("DD, MMM YYYY")}
                      </p> */}
                      <p>
                        <span className="text-xs text-center font-semibold inline-block py-1 px-2 md:uppercase rounded-xl text-white bg-red-400 uppercase last:mr-0 mr-1 ">
                          <p>Teams</p>
                          {item?.direct_team ? item?.direct_team : 0}
                        </span>
                      </p>
                    </header>

                    <body className="flex items-center justify-between leading-tight p-1 md:p-2">
                      <span className="text-xs text-center font-semibold inline-block py-1 px-2  md:uppercase rounded-xl text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1 w-32">
                        <p>paid subscription</p>
                        {item?.paid_policy ? item?.paid_policy : 0}
                      </span>

                      <span className="text-xs text-center font-semibold inline-block py-1 px-2  md:uppercase rounded-xl text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1 w-32">
                        <p>unpaid subscription</p>
                        {item.unpaid_policy ? item.unpaid_policy : 0}
                      </span>
                    </body>
                    {/* <body className="flex justify-around items-center mt-1">
                      <p>
                        <span className="text-xs text-center font-semibold inline-block py-1 px-2  md:uppercase rounded-xl text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1">
                          <p>paid policy amount</p>
                          {item.paid_amount ? item.paid_amount : 0}
                        </span>
                      </p>

                      <p>
                        <span className="text-xs text-center font-semibold inline-block py-1 px-2  md:uppercase rounded-xl text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1">
                          <p>unpaid policy amount</p>
                          {item.unpaid_amount ? item.unpaid_amount : 0}
                        </span>
                      </p>
                    </body> */}
                  </article>
                );
              })}
              {reports?.length === 0 ? (
                <tr>
                  <td
                    colSpan="9"
                    height="50"
                    className="text-red-500 font-light text-center"
                  >
                    Your result is empty
                  </td>
                </tr>
              ) : (
                false
              )}
            </div>
          </>
        ) : (
          <div className="p-10 text-red-500">
            <h4>You do not have permission to view business report</h4>
          </div>
        )}
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={2}
            size="xs"
            layout={this.state.layout}
            total={this.state.total}
            limit={this.state.limit}
            activePage={this.state.params?.page}
            onChangePage={this.onPageChange}
          />
        </div>

        <Modal size="xs" open={this.state.open} onClose={this.onClickExport}>
          <Modal.Header className="mb-4 flex justify-center mt-1">
            <Modal.Title style={{ fontWeight: "bold" }}>
              Export sales report dump.
            </Modal.Title>
          </Modal.Header>
          <hr />

          <Modal.Body>
            <>
              <div className="flex justify-around z-10">
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: params.from ? new Date(params.from) : new Date(),
                  }}
                />

                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: params.to ? new Date(params.to) : new Date(),
                  }}
                />
              </div>
              <div className="float-right mt-5 mr-8">
                <IconButton
                  appearance="primary"
                  style={{ backgroundColor: PRIMARY_COLOR }}
                  icon={
                    <FileDownloadIcon
                      style={{ backgroundColor: PRIMARY_COLOR }}
                    />
                  }
                  onClick={this.downloadClick}
                >
                  Export
                </IconButton>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  downloadClick = () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      reportType: "download",
      employee_id: getResellerId(),
    };
    this.DownloadReports(payload);
    this.setState({ ...this.state, open: false });
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadExcelFile(
      "report/hierarchy-sales-report",
      request,
      "hierarchySalesReport.xlsx"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  componentDidMount() {
    this.getReports();
    this.getPermissions();
  }

  getPermissions = async () => {
    const permission = await getData("permissions");
    this.setState({ permissions: permission });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location?.state?.id &&
      this.state.params.employee_id != nextProps.location?.state?.id
    ) {
      let id = nextProps.location.state.id || "";
      this.setState(
        ({ params }) => ({
          params: {
            ...params,
            employee_id: id,
            page: 1,
          },
        }),
        this.getReports
      );
    } else if (
      !nextProps.location?.state?.id &&
      this.state.params.employee_id != ""
    ) {
      this.setState(
        ({ params }) => ({
          params: {
            ...params,
            employee_id: "",
            page: 1,
          },
        }),
        this.getReports
      );
    }
  }

  getReports = async (request = "") => {
    request = this.state.params;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/hierarchy-sales-report", request);

    if (response.status === "success") {
      if (response?.data?.records) {
        const items = Object.values(response.data?.records);
        this.setState({ reports: items });
        this.setState({ total: response?.data?.total });
      } else {
        this.state.reports = [];
      }
    } else {
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  handleClick = () => {
    this.getReports(this.state.params);
  };
}
export default withRouter(BusinessReport);
