import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FaMicroscope } from "react-icons/fa";
import { Button, Input } from "rsuite";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";
import CustomAccordian from "../../common/components/CustomAccordian";
import checkImage from "../../../../assets/productCreation/check.png";
import { fieldValidation } from "../../common/data/constants";
import { Divider } from "@mui/material";

const ParameterInfo = ({
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
}) => {
  const [data, setData] = useState(values?.parameter);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const handleData = () => {
    setData((prevData) => [
      ...prevData,
      { parameter_name: "", parameter_benefit: [] },
    ]);
  };

  const handleChange = (value, index) => {
    let temp = [...data];
    temp[index].parameter_name = value;
    setData(temp);
    setFieldValue("parameter", temp);
  };

  const handleAddBenefit = (index) => {
    let temp = [...data];
    temp[index].parameter_benefit = [
      ...(temp[index].parameter_benefit || []),
      { benefit_title: "" },
    ];
    setData(temp);

    setFieldValue("parameter", temp);
    setTimeout(() => setIsActive(index), 50);
  };

  const handleBenefitsChange = (value, index, benefitRowId) => {
    let temp = [...data];
    temp[index].parameter_benefit[benefitRowId].benefit_title = value;
    setData(temp);
    setFieldValue("parameter", temp);
  };

  const handleRemoveBenefit = (index, benefitRowId) => {
    let temp = [...data];
    temp[index].parameter_benefit.splice(benefitRowId, 1);
    setData(temp);
    setFieldValue("parameter", temp);
  };

  const handleRemoveRow = (index) => {
    let temp = data.filter((_, i) => i !== index);
    setData(temp);
    setFieldValue("parameter", temp);
  };

  return (
    <div className="p-4">
      {/* Accordions */}
      {data?.map((item, i) => (
        <div className="border p-2">
          <div className="flex items-center w-full p-2 gap-4 ">
            <FaMicroscope size={30} color="#FF6B6B" />

            {/* Parameter Name Input */}
            <div className="flex flex-col w-1/3 relative mb-6">
              <label>Parameter Name</label>
              <Input
                placeholder="Enter parameter name"
                maxLength={64}
                minLength={3}
                value={item.parameter_name}
                onChange={(value) => {
                  if (
                    value === "" ||
                    (value.length < 50 && fieldValidation.name.test(value))
                  ) {
                    handleChange(value, i);
                    setFieldTouched(
                      `parameter[${i}].parameter_name`,
                      true,
                      false
                    );
                  }
                }}
                className="text-sm p-1"
                style={{ borderRadius: "5px" }}
              />

              {/* Parameter Name Validation Error */}
              {errors?.[i]?.parameter_name && touched?.[i]?.parameter_name && (
                <p className="text-red-500 text-xs mt-2 absolute -bottom-5">
                  {errors?.[i]?.parameter_name}
                </p>
              )}
            </div>

            <div className="flex items-center gap-2 ">
              <Button
                size="xs"
                appearance="ghost"
                color="red"
                disabled={data.length == 1}
                onClick={() => handleRemoveRow(i)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <Button
                size="xs"
                appearance="ghost"
                onClick={() => handleAddBenefit(i)}
              >
                Add benefit
              </Button>
            </div>

            {/* Helper Text */}
          </div>
          <Divider />
          <div className="p-2">
            {item?.parameter_benefit?.length > 0 ? (
              <ul className="ml-4 space-y-2">
                {item?.parameter_benefit?.map((benefit, benefitRowId) => (
                  <li key={benefitRowId} className="flex items-center gap-2">
                    <img src={checkImage} className="w-5 h-5" alt="Check" />

                    {/* Benefit Title Input */}
                    <div className="flex flex-col w-1/2 relative mb-6">
                      <label>Benefit name</label>
                      <Input
                        placeholder="Add benefit name for this parameter"
                        value={benefit?.benefit_title}
                        maxLength={64}
                        minLength={3}
                        onChange={(value) => {
                          if (
                            value === "" ||
                            (value.length < 50 &&
                              fieldValidation.name.test(value))
                          ) {
                            handleBenefitsChange(value, i, benefitRowId);
                            setFieldTouched(
                              `parameter[${i}].parameter_benefit[${benefitRowId}].benefit_title`,
                              true,
                              false
                            );
                          }
                        }}
                        className="text-sm p-1"
                      />

                      {/* Benefit Validation Error */}
                      {errors?.[i]?.parameter_benefit?.[benefitRowId]
                        ?.benefit_title &&
                        touched?.[i]?.parameter_benefit?.[benefitRowId]
                          ?.benefit_title && (
                          <p className="text-red-500 text-xs mt-2 absolute -bottom-5">
                            {
                              errors?.[i]?.parameter_benefit[benefitRowId]
                                ?.benefit_title
                            }
                          </p>
                        )}
                    </div>

                    <Button
                      size="xs"
                      appearance="ghost"
                      color="red"
                      disabled={data[i]?.parameter_benefit.length == 1}
                      onClick={() => handleRemoveBenefit(i, benefitRowId)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-sm text-center">
                No benefits added
              </p>
            )}
          </div>
        </div>
      ))}

      {/* Add Parameter Button */}
      <div className="flex justify-center mb-4">
        <Button
          appearance="primary"
          color="red"
          size="md"
          onClick={handleData}
          className="flex items-center gap-2 mt-2"
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Parameter
        </Button>
      </div>
    </div>
  );
};

export default ParameterInfo;
