import React, { Component } from "react";
import { AppBackButton } from "../../components/AppComponents";
import Table from "../../components/common/Table";
import {
  JavaApiPost,
  JavaApiPostFileUpload,
  deleteResellerDocument,
  getResellerDocument,
  getResellerId,
} from "../../lib/AppHelper";
import CustomModal from "../admin/components/CustomModal";
import { FileUploader } from "../../components/form/FileUploader";
import { toast } from "react-toastify";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CircularProgress, Dialog } from "@mui/material";
import PdfViewer from "../../utils/view_downloadPrescription/PDFViewer";
import { CloseOutlined, Delete } from "@mui/icons-material";
import { EyeIcon } from "@heroicons/react/outline";
import { dateTimeFormate, getFileType } from "../../utils/commom";

class DocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      count: 0,
      open: false,
      params: { file: null },
      errors: { file: null },
      isViewOpen: false,
      isDeleteOpen: false,
      isViewLoading: false,
      isDeleteLoading: false,
      isAddLoading: false,
    };
  }
  MessageRef = React.createRef();
  resellerId = getResellerId();

  onDocumentUpload = () => {
    if (this.state.params.file) {
      this.setState((state) => {
        return {
          ...state,
          isAddLoading: true,
        };
      });
      const file = this.state.params.file;
      AppStore.dispatch({ type: "LOADING", loading: true });
      JavaApiPostFileUpload(
        `enrollment-service/resellerDocuments/uploadResellerDocuments/${this.resellerId}`,
        file
      ).then((res) => {
        if (res.code == 200) {
          AppStore.dispatch({ type: "LOADING", loading: false });
          toast.success("Attachment uploaded successfully");
          this.setClose();
          this.fetchUploadedDocuments();
        } else {
          toast.error("Something went wrong");
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
        this.setState((state) => {
          return {
            ...state,
            isAddLoading: false,
            params: { file: null },
            errors: { file: null },
          };
        });
      });
    }
  };

  fetchUploadedDocuments = (page) => {
    const pageValue = page ? page - 1 : this.state.page - 1;
    AppStore.dispatch({ type: "LOADING", loading: true });
    JavaApiPost(
      "enrollment-service/resellerDocuments/getAllResellerDocumentList",
      {
        page: pageValue,
        limit: this.state.limit,
        resellerId: this.resellerId,
      }
    ).then((res) => {
      this.setState({ data: res?.responseObject, count: res?.count });
      AppStore.dispatch({ type: "LOADING", loading: false });
    });
  };

  componentDidMount() {
    this.fetchUploadedDocuments();
  }

  View = (row, role) => {
    return (
      <div className="flex justify-center">
        <div className="gap-2 flex">
          {this.state.isViewLoader == row.id ? (
            <div className="h-6 w-6 flex justify-center items-center">
              <CircularProgress style={{ padding: "12px", display: "flex" }} />
            </div>
          ) : (
            <EyeIcon
              onClick={() => this.documentViewHandler(row)}
              className="h-6 w-6 text-blue-500"
            />
          )}
          {role && (
            <Delete
              onClick={() => this.onDeleteOpen(row)}
              className="h-6 w-6 text-red-500"
            />
          )}
        </div>
      </div>
    );
  };

  addButton = () => {
    return (
      <button
        onClick={this.setOpen}
        className="bg-primary-500 text-white rounded-md p-1 px-2"
      >
        Add Document
      </button>
    );
  };

  setClose = () => {
    this.setState((state) => {
      return {
        ...state,
        open: false,
      };
    });
  };

  setOpen = () => {
    this.setState((state) => {
      return {
        ...state,
        open: true,
      };
    });
  };

  setParams = (field, value) => {
    this.setState((state) => {
      let params = state.params;
      return {
        ...state,
        params: { ...params, [field]: value },
      };
    });
  };

  setErrors = (field, errorMessage) => {
    this.setState((state) => {
      let errors = state.errors;
      return {
        ...state,
        errors: { ...errors, [field]: errorMessage },
      };
    });
  };

  documentViewHandler = async (data) => {
    let id = data.id;
    this.setState((state) => {
      return {
        ...state,
        isViewLoader: id,
      };
    });
    let fileType = getFileType(data.documentPath);

    let response = await getResellerDocument(id);
    if (response) {
      let res = await response.blob();
      const file = new File([res], data?.documentPath, { type: fileType });
      this.setState((state) => {
        return {
          ...state,
          isViewOpen: file,
          isViewOpenType: fileType,
          isViewLoader: false,
        };
      });
    }
  };

  documentViewCloseHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        isViewOpen: !this.state.isViewOpen,
      };
    });
  };

  onDeleteOpen = (data) => {
    this.setState((state) => {
      return {
        ...state,
        isDeleteOpen: data.id,
      };
    });
  };

  onDeleteClose = () => {
    this.setState((state) => {
      return {
        ...state,
        isDeleteOpen: false,
      };
    });
  };

  onDocumentDeleteHandler = async () => {
    this.setState((state) => {
      return {
        ...state,
        isDeleteLoading: true,
      };
    });
    let response = await deleteResellerDocument(this.state.isDeleteOpen);
    if (response.code == 200) {
      this.fetchUploadedDocuments();
      toast.success("Attachment deleted successfully");
      this.onDeleteClose();
      this.setState((state) => {
        return {
          ...state,
          isDeleteLoading: false,
        };
      });
    } else {
      toast.error("Something went wrong");
      this.setState((state) => {
        return {
          ...state,
          isDeleteLoading: false,
        };
      });
    }
  };

  viewCreatedOn = (data, key) => {
    return <div>{dateTimeFormate(data[key])}</div>;
  };

  setErrors = (field, errorMessage) => {
    this.setState((state) => {
      let errors = state.errors;
      return {
        ...state,
        errors: { ...errors, [field]: errorMessage },
      };
    });
  };

  onAddClose = () => {
    this.setState((state) => {
      return {
        ...state,
        open: false,
        params: { file: null },
        errors: { file: null },
      };
    });
  };

  onPageChange = (page) => {
    this.setState((state) => {
      return {
        ...state,
        page: page,
      };
    });
    this.fetchUploadedDocuments(page);
  };

  render() {
    const { permissions } = this.props.user;
    const headers = [
      { label: "Id", dataKey: "id" },
      {
        label: "Created On",
        dataKey: "createdOn",
        cell: (row) => this.viewCreatedOn(row, "createdOn"),
      },
      {
        label: "Acton",
        cell: (row) => this.View(row, permissions?.reseller_document_delete),
      },
    ];
    return (
      <>
        <AppBackButton to="/">Back</AppBackButton>
        {permissions && permissions.reseller_document_upload && (
          <div className="flex justify-end">{this.addButton()}</div>
        )}

        <Table
          headers={headers}
          data={this.state.data}
          limit={this.state?.limit}
          page={this.state.page}
          total={this.state?.count}
          onPageChange={this.onPageChange}
        />

        <CustomModal open={this.state.open} setOpen={() => this.onAddClose()}>
          <div className="relative transform overflow-hidden rounded-lg bg-white  shadow-xl transition-all">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <div
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Upload document
                  </div>
                  <div className="mt-2 flex justify-center flex-col w-full items-center content-center">
                    <FileUploader
                      label="Attachments"
                      field={"file"}
                      params={this.state.params}
                      setState={this.setParams}
                      error={this.state.errors.file}
                      setErrors={this.setErrors}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                onClick={() => this.onDocumentUpload()}
              >
                {this.state.isAddLoading ? (
                  <div className="h-6 w-14 flex  justify-center items-center">
                    <CircularProgress
                      color="inherit"
                      style={{ padding: "12px", display: "flex" }}
                    />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => this.onAddClose()}
              >
                Cancel
              </button>
            </div>
          </div>
        </CustomModal>

        <Dialog
          maxWidth={"lg"}
          aria-labelledby="customized-dialog-title"
          open={this.state.isViewOpen}
        >
          <div
            style={{
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: "111",
              backgroundColor: "#db2228",
            }}
          >
            <p>
              <CloseOutlined
                className="text-white m-4 cursor-pointer float-right"
                onClick={this.documentViewCloseHandler}
              />
            </p>
          </div>

          {`${this.state?.isViewOpenType}`.startsWith("image/") && (
            <img
              src={
                this?.state?.isViewOpen &&
                URL.createObjectURL(this.state.isViewOpen)
              }
              alt="Image Preview"
              style={{ height: "80vh", width: "80vw" }}
            />
          )}
          {`${this.state?.isViewOpenType}`.startsWith("application/pdf") && (
            <PdfViewer
              myFile={
                this?.state?.isViewOpen &&
                URL.createObjectURL(this?.state?.isViewOpen)
              }
            />
          )}
        </Dialog>

        <CustomModal
          open={this.state.isDeleteOpen}
          setOpen={this.onDeleteClose}
        >
          <div className="relative transform overflow-hidden rounded-lg bg-white  shadow-xl transition-all">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              Are you sure you want to delete this attachment!
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                onClick={this.onDocumentDeleteHandler}
              >
                {this.state.isDeleteLoading ? (
                  <div className="h-6 w-14 flex  justify-center items-center">
                    <CircularProgress
                      color="inherit"
                      style={{ padding: "12px", display: "flex" }}
                    />
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={this.onDeleteClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const CustomerInformationConnect = connect(mapStateToProps)((props) => {
  return <DocumentUpload {...props} />;
});

export default withRouter(CustomerInformationConnect);
