import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Pagination } from "rsuite";
import { DownloadIcon } from "@heroicons/react/outline";
import { DateFilter, WeekDates, LastWeekDates } from "./constant";
import {
  JavaApiPost,
  downloadCSVFileForJavaEndPoint,
} from "../../../lib/AppHelper";
import { AppStore } from "../../../lib/AppStore";
import useMobileDetect from "../../../utils/useMobileDetect";
import { AppBackButton, AppHeading } from "../../../components/AppComponents";
import CustomSelect from "../../../components/CustomSelect";
import { InputDate } from "../../../components/form/InputDate";
import LibertyLogo from "../../../images/vendorIcons/Liberty-logo.png";
import AckoLogo from "../../../images/vendorIcons/acko-logo.png";
import AdityaBirlaCapitalLogo from "../../../images/vendorIcons/aditya-birla-capital-logo.png";
import AiqaHealthLogo from "../../../images/vendorIcons/aiqa-health-logo.png";
import CareLogo from "../../../images/vendorIcons/care-logo.png";
import { toast } from "react-toastify";

const transformImageIcon = (name) => {
  switch (name) {
    case "LAC":
    case "LHC":
      return LibertyLogo;
    case "ACH":
    case "AAC":
      return AckoLogo;
    case "HCH":
    case "HC":
    case "AC":
      return CareLogo;
    case "ABHC":
    case "ABAC":
    case "ABCL":
      return AdityaBirlaCapitalLogo;
    case "Claim":
      return AiqaHealthLogo;
    default:
      return "";
  }
};

const transformSpecification = (specification) => {
  switch (specification) {
    case "URBAN":
      return "URBAN";
    case "LAC":
    case "AC":
    case "AAC":
    case "ABAC":
      return "Accidental insurance";
    case "ACH":
    case "HCH":
    case "HC":
    case "LHC":
    case "ABHC":
      return "Hospi cash";
    case "AOC":
      return "AOC";
    case "DOC":
      return "Doctor consultation";
    case "SKIN":
      return "Dermatologist Consultation";
    case "DENT":
      return "Dentist Consult";
    case "NUTR":
      return "Nutritionist";
    case "OP":
      return "OPD cash";
    case "CLOAN":
      return "Critical life cover";
    default:
      return specification;
  }
};

const calculateDurationString = (days) => {
  const today = moment();
  const futureDate = today.clone().add(days, "days");
  const duration = moment.duration(futureDate.diff(today));
  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths() % 12);
  const daysLeft = duration.days();

  let str = "";
  if (years > 0) str += `${years} Years, `;
  if (months > 0) str += `${months} Months, `;
  if (daysLeft > 0 || str === "") str += `${daysLeft} Days`;

  return str;
};

const convertDaysToString = (days) => calculateDurationString(days);

const ReportMaster = () => {
  const { user } = useSelector((state) => state);
  const { permissions } = user;
  const { isMobile } = useMobileDetect();
  const MessageRef = React.createRef();

  const [filter, setFilter] = useState({
    duration: "thisMonth",
    from: WeekDates("month")[0],
    to: WeekDates("month")[1],
  });

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [params, setParams] = useState({
    page: page,
    size: limit,
  });
  const [data, setData] = useState([]);

  const getPartnerReportDetails = async (Page) => {
    const pageValue = Page ? Page - 1 : page - 1;

    const data = {
      page: pageValue,
      size: limit,
    };

    AppStore.dispatch({ type: "LOADING", loading: true });

    await JavaApiPost(
      "enrollment-service/api/partner/partner_report_details",
      data
    ).then((res) => {
      setData(res?.responseObject ? res?.responseObject : []);
      setCount(res?.count);
      AppStore.dispatch({ type: "LOADING", loading: false });
    });
  };

  const onPageChange = (page) => {
    if (page === false) return false;
    setPage(page);
    getPartnerReportDetails(page);
  };

  useEffect(() => {
    getPartnerReportDetails();
  }, []);

  const handleDownloadChange = async (id, daysLimit) => {
    const startDate = moment(filter.from);
    const endDate = moment(filter.to);
    const dateDifference = endDate.diff(startDate, "days") + 1;
    const durationString = calculateDurationString(daysLimit);

    if (dateDifference > daysLimit) {
      const message = `Date range cannot be greater than ${durationString}`;
      toast.error(message);
      return;
    }

    AppStore.dispatch({ type: "LOADING", loading: true });

    const payload = {
      id: id,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      datesMandatory: true,
    };

    await downloadCSVFileForJavaEndPoint(
      `enrollment-service/api/partner/download_reports`,
      payload,
      "report.csv"
    );

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  return (
    <>
      {permissions && permissions?.report_master ? (
        <>
          <div className="grid md:grid-cols-2 gap-2 mb-xs">
            <div>
              <AppBackButton to="/">Back</AppBackButton>
            </div>
          </div>
          <div className="flex flex-wrap justify-center mb-5 mt-0">
            <AppHeading color="red">Report Master</AppHeading>
          </div>

          <div
            className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 gap-4"
            style={{ marginBottom: "30px" }}
          >
            <div>
              <CustomSelect
                label="Date Filter"
                value={filter.duration}
                onChange={(e) => {
                  let value = e.target.value;
                  let dates;
                  if (value === "custom") {
                    setFilter((state) => ({ ...state, duration: value }));
                    return;
                  }
                  if (value === "today") dates = WeekDates("day");
                  if (value === "yesterday") dates = LastWeekDates("day");
                  if (value === "thisWeek") dates = WeekDates("week");
                  if (value === "previousWeek") dates = LastWeekDates("week");
                  if (value === "thisMonth") dates = WeekDates("month");
                  if (value === "previousMonth") dates = LastWeekDates("month");
                  setFilter((state) => ({
                    ...state,
                    from: new Date(dates[0]),
                    to: new Date(dates[1]),
                    duration: value,
                  }));
                }}
                data={DateFilter}
                style={{ width: isMobile() ? "300px" : "230px" }}
              />
            </div>
            {filter.duration === "custom" && (
              <>
                <div>
                  <InputDate
                    label="From"
                    value={filter.from}
                    placeholder="from"
                    maxDate={new Date(moment().format("YYYY-MM-DD"))}
                    attrs={{
                      onChange: (e) => setFilter({ ...filter, from: e }),
                      value: new Date(filter.from),
                    }}
                  />
                </div>
                <div>
                  <InputDate
                    label="To"
                    placeholder="To"
                    maxDate={new Date(moment().format("YYYY-MM-DD"))}
                    minDate={new Date(filter.from)}
                    attrs={{
                      onChange: (e) => setFilter({ ...filter, to: e }),
                      value: new Date(filter.to),
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <table className="min-w-full border" id="table-to-xls">
                <thead className="border-b">
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Company Name
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Specification
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Report Name
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Access Days Limit
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Download
                  </th>
                </thead>
                <tbody>
                  {data?.length ? (
                    data?.map((details, index) => {
                      const logo = transformImageIcon(details.partnerName);

                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            <div className="flex justify-around items-center">
                              {logo ? (
                                <>
                                  <img
                                    src={logo}
                                    alt={details.partnerName}
                                    style={{ width: "100px" }}
                                  />
                                  {details.partnerName}
                                </>
                              ) : (
                                <span>{details.partnerName}</span>
                              )}
                            </div>
                          </td>
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {transformSpecification(details.specification)}
                          </td>
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {details.reportName}
                          </td>
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {convertDaysToString(details.daysLimit)}
                          </td>
                          <td className="text-center border-r px-4 py-2 ">
                            <button
                              onClick={() =>
                                handleDownloadChange(
                                  details.id,
                                  details.daysLimit
                                )
                              }
                            >
                              <DownloadIcon
                                color="#e23744"
                                height="25px"
                                width="25px"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        height="150"
                        className="text-red-500 font-large text-center"
                      >
                        No Data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={2}
                size="xs"
                layout={["total", "-", "pager", "skip"]}
                total={count}
                limit={limit}
                activePage={page}
                onChangePage={onPageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <AppBackButton to="/">Back</AppBackButton>
          <div className="text-red-500">
            <h4>You do not have permission to view report master.</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportMaster;
