import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Modal, Pagination } from "rsuite";
import { Link } from "react-router-dom";
import { AppStore } from "../../../lib/AppStore";
import { ApiGet } from "../../../lib/AppHelper";
import { truncateString } from "../../admin/utils";
import CustomModal from "../../admin/components/CustomModal";
import searchIcon from "../reimbursementSingle/assets/search.png";
import { NewClaimsFilters } from "../../rewampClaims/component/NewClaimsFilters";
import Button from "../../../components/common/Button";
import ReimbursementSubPolicyDetail from "./ReimbursementSubPolicyDetail";

const ReimbursementSingle = () => {
  const limit = 15;
  const FilterChildRef = useRef(null);
  const lastFilter = useRef(null);
  const [filterValues, setFilterValues] = useState({});
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [open, setOpen] = useState(false);

  const handelChangePage = (pg) => {
    if (page === pg) return;
    setPage(pg);
    fetchData(pg);
  };

  const fetchData = async (pg = null) => {
    if (!filterValues || Object.keys(filterValues).length === 0) {
      return;
    }

    const start = limit * (pg ? pg - 1 : page - 1);
    if (pg) setPage(pg);

    try {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const res = await ApiGet("subscription-details", {
        limit,
        page: pg || page,
        ...filterValues,
      });

      if (res.status === "error") {
        setErrorMessage(res.message || "Error fetching data.");
      } else {
        setTotalCount(res?.data?.total || 0);
        setTableData(res?.data?.results || []);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setErrorMessage("An unexpected error occurred. Please try again.");
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
      lastFilter.current = filterValues;
    }
  };

  const onClear = () => {
    setFilterValues({});
    setTableData([]);
  };

  const onRaiseReimbursement = async (applicationId) => {
    if (!applicationId) {
      return;
    }

    setOpen(applicationId);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (JSON.stringify(lastFilter.current) !== JSON.stringify(filterValues)) {
      fetchData(1);
    } else {
      fetchData();
    }
  }, [filterValues]);

  return (
    <>
      <div
        style={{
          padding:
            windowSize[0] < 400
              ? "20px 10px 20px"
              : filterValues && Object.keys(filterValues).length
              ? "0 0 24px 0"
              : "40px",
          margin:
            windowSize[0] < 400
              ? "0"
              : windowSize[0] < 800
              ? "0 20px"
              : filterValues && Object.keys(filterValues).length
              ? ""
              : "0 128px",
          backgroundColor:
            filterValues && Object.keys(filterValues).length ? "" : "#F4F4F4",
          borderRadius:
            filterValues && Object.keys(filterValues).length ? "" : "24px",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            borderRadius: "12px",
            flex: windowSize[0] < 800 ? "unset" : "6",
            width: windowSize[0] < 800 ? "100%" : "auto",
          }}
        >
          {filterValues && Object.keys(filterValues).length ? (
            <label className="flex text-sm text-gray-700 justify-left pb-2">
              Search
            </label>
          ) : null}

          <NewClaimsFilters
            setValue={setFilterValues}
            filterValues={filterValues}
            ref={FilterChildRef}
            onClear={onClear}
          />
        </div>

        {!(filterValues && Object.keys(filterValues).length) ? (
          <div className="flex gap-10 justify-center items-center md:mx-4 lg:mx-28 mt-10">
            <img
              src={searchIcon}
              alt="searchIcon"
              className="md:h-48 md:w-48 h-24 w-24"
            />
            <span
              style={{
                color: "#3F3F3F",
                fontWeight: "400",
                fontSize: windowSize[0] < 400 ? "12px" : "15px",
              }}
            >
              Use the search bar to find the customer you want to raise a
              reimbursement for.
            </span>
          </div>
        ) : null}
      </div>

      {Object.keys(filterValues).length === 0 ? null : tableData &&
        tableData?.length === 0 ? (
        <div className="text-center">
          <p className="text-xl">You do not have any item</p>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <table className="min-w-full border" id="table-to-xls">
              <thead className="bg-gray2 text-white">
                <tr>
                  <th className="text-sm p-2 text-left text-white font-semibold">
                    Customer name
                  </th>
                  <th className="text-sm p-2 text-left text-white font-semibold">
                    Loan code / <br />
                    reference number
                  </th>
                  <th className="text-sm p-2 text-left text-white font-semibold">
                    Product name
                  </th>
                  <th className="text-sm p-2 text-left text-white font-semibold">
                    Phone
                  </th>
                  <th className="text-sm p-2 text-left text-white font-semibold">
                    Subscription <br />
                    start date
                  </th>
                  <th className="text-sm p-2 text-left text-white font-semibold">
                    Subscription <br />
                    end date
                  </th>
                  <th className="text-sm p-2 text-center text-white font-semibold">Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => (
                  <tr
                    className={`${index % 2 === 0 ? "" : "bg-gray-100"}`}
                    key={index}
                  >
                    <td
                      className="text-left p-2 text-sm capitalize"
                      style={{ color: "blue", fontWeight: 600 }}
                    >
                      <Link to={`/subscription/${item.application_id}`}>
                        {truncateString(
                          item?.customer_name
                            ? item?.customer_name?.toLowerCase()
                            : item?.name?.toLowerCase(),
                          16,
                          "Member Empty"
                        )}
                      </Link>
                    </td>
                    <td className="text-left p-2 text-sm">
                      {item?.loan_code || "N/A"}
                    </td>
                    <td className="text-left p-2 text-sm">
                      {truncateString(
                        item.product_display_name
                          ? item.product_display_name
                          : item.product_name,
                        28,
                        "Product Empty"
                      )}
                    </td>
                    <td className="text-left p-2 text-sm">
                      {item?.mobile || "N/A"}
                    </td>
                    <td className="text-left p-2 text-sm">
                      {(item?.start_date || item.application_date) &&
                        moment(
                          item.start_date
                            ? item.start_date
                            : item.application_date
                        ).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-left p-2 text-sm">
                      {(item?.end_date || item.application_date) &&
                        moment(
                          item.end_date ? item.end_date : item.application_date
                        ).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-center p-2 text-sm">
                      <Button
                        type="secondary"
                        onClick={() => {
                          if (item?.eligible_for_claim?.toUpperCase() === "Y") {
                            onRaiseReimbursement(item.application_id);
                          }
                        }}
                        style={{ borderRadius: "4px", padding: "8px" }}
                        disabled={
                          item?.eligible_for_claim?.toUpperCase() !== "Y"
                        }
                      >
                        Raise reimbursement
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {tableData && tableData?.length > 0 ? (
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={4}
                size="xs"
                layout={["total", "-", "pager", "skip"]}
                total={totalCount}
                limit={limit}
                activePage={page}
                onChangePage={(page) => handelChangePage(page)}
              />
            </div>
          ) : null}
        </div>
      )}

      <CustomModal
        open={open}
        setOpen={setOpen}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        borderRadius="16px"
      >
        <Modal.Header onClick={() => setOpen(false)}>
          <Modal.Title style={{ fontWeight: "600" }}>
            Sub Policy Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReimbursementSubPolicyDetail
            applicationId={open}
            setOpen={setOpen}
          />
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default ReimbursementSingle;
