import React, { useState } from "react";
import InputLabel from "../form/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function InputSelect({
  label,
  style,
  requiredMark,
  name,
  value,
  options,
  onChange,
  error = "",
  helperText = "",
  loading = false,
  disabled,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="mb-1 relative">
      {/* Error Message Tooltip */}
      {error && open && (
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className="bg-red-600 -top-10 left-5 absolute text-white text-sm p-3 rounded-md flex items-start gap-2"
        >
          <FontAwesomeIcon icon={faExclamationCircle} className="text-white" />
          <span>{error}</span>
        </div>
      )}

      {/* Label with Error Indicator */}
      <div className="flex gap-1 items-center">
        {error && (
          <FontAwesomeIcon
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            icon={faExclamationCircle}
            className="text-red-500"
          />
        )}
        <InputLabel label={label} style={style} requiredMark={requiredMark} />
      </div>

      {/* Native Select Input */}
      <select
        disabled={disabled || loading}
        name={name}
        value={value}
        onChange={onChange}
        className={`block w-full px-3 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-gray-200 border-gray-200 disabled:bg-gray-200 transition ${
          error ? "border-red-500" : ""
        }`}
      >
        {loading ? (
          <option>Loading...</option>
        ) : (
          <>
            {" "}
            <option value="">Select</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </>
        )}
      </select>

      {/* Helper Text */}
      {helperText && (
        <p
          style={{ fontSize: "11px", fontStyle: "italic" }}
          className="text-gray-400 mt-1"
        >
          <span className="font-medium">* {helperText}</span>
        </p>
      )}
    </div>
  );
}
