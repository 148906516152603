import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ClaimStatus } from "../../../components/AppComponents";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ClaimPng from "../../../assets/Claims/claim.png";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EventNoteIcon from "@mui/icons-material/EventNote";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import {
  TimerCount,
  formatMoney,
  getMessage,
  maskString,
  truncateString,
} from "./constant";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { Placeholder } from "rsuite";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { ROLE_STATUS_MAPPING } from "../../../lib/AppHelper";
import { govtIDMaskString } from "../../../utils/commom";

export const ClaimCard = ({ item, filter, setOpen, windowSize }) => {
  const [copyText, setCopyText] = useState("");
  const {
    claim_is_new,
    sub_claims,
    claim_id,
    name,
    claim_status,
    claim_reason,
    bill_amount,
    hospital_name,
    loan_code,
    mobile,
    start_date,
    claim_register_date,
    hospital_address,
    hospital_city,
    hospital_state,
    view_status,
    gov_id_number,
    gov_id_type,
  } = item;
  let new_start_date = start_date
    ? moment(start_date).format("DD-MM-YYYY")
    : "";
  let countTime = TimerCount(claim_register_date);
  const loading = useSelector((state) => state.loading);
  if (loading) return <Placeholder.Graph active width={330} height={440} />;

  async function writeClipboardText(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error.message);
    }
  }
  return (
    <div className="p-2 sm:w-80 lg:w-96">
      <article
        className={`rounded-lg shadow-lg border-red ${
          claim_status === "PARTIAL_SETTLE" ? "bg-green-50" : ""
        }`}
        style={{
          height: windowSize[0] < 800 ? 440 : 410,
          minWidth: 330,
        }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingTop: "20px",
            paddingRight: "20px",
            fontWeight: "700",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#DB2228",
          }}
        >
          <div className="flex gap-1">
            <Link to={"/reimbursements/" + claim_id}>#{claim_id}</Link>
            <Tooltip title={"Edit claim"} placement="right" arrow>
              <p className="text-grey-darker text-sm ">
                {ROLE_STATUS_MAPPING[sub_claims[0]?.["actual_status"]] ===
                  "Queried" ||
                sub_claims[0]?.["actual_status"] === "DRAFT" ||
                sub_claims[0]?.["actual_status"] === "PARTIAL_SETTLE" ? (
                  <Link
                    to={{
                      pathname: `reimbursementEdit/${claim_id}/${sub_claims[0]?.["sub_claim_id"]}`,
                      state: { start_date: new_start_date },
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    <FontAwesomeIcon icon={faEdit} style={{ color: "black" }} />
                  </Link>
                ) : (
                  false
                )}
              </p>
            </Tooltip>
          </div>
          {claim_is_new &&
            !view_status &&
            (filter === "SETTLED" ||
              filter === "REJECTED" ||
              filter === "DRAFT" ||
              claim_status === "PARTIAL_SETTLE") && (
              <div
                style={{
                  background: "#db2228",
                  cursor: "pointer",
                  color: "white",
                  padding: "2px 5px",
                  borderRadius: "5px",
                }}
              >
                New
              </div>
            )}
        </div>
        <header className="flex items-center justify-between leading-tight p-2 md:px-2 py-1">
          <h1
            className="text-md text-black font-bold capitalize mr-4 pl-1"
            style={{
              width: "60%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {truncateString(name, 17)}
          </h1>

          <p className="text-grey-darker text-sm text-center">
            <ClaimStatus status={claim_status} />
          </p>
        </header>
        <hr className="w-11/12 m-auto" />
        <body
          className="text-sm items-center justify-between leading-tight px-4 py-1 "
          style={{ height: "280px", overflow: "auto", overflowX: "hidden" }}
          id="style-1"
        >
          {sub_claims[0]?.["product_display_name"] && (
            <div className="flex gap-2 mt-1 mb-1 items-center">
              <p className="pt-2 flex gap-1">
                Plan:{" "}
                <span
                  style={{
                    fontWeight: "600",
                    width: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {truncateString(sub_claims[0]?.["product_display_name"], 30)}
                </span>
              </p>
            </div>
          )}
          {claim_register_date && (
            <p className="pt-2 ">
              <EventNoteIcon style={{ color: "grey", width: "20px" }} /> Claim
              register date:&nbsp;
              <span style={{ fontWeight: "600" }}>
                {moment(claim_register_date).format("DD-MM-YYYY")}
              </span>
            </p>
          )}
          {claim_reason && (
            <p className="pt-2 flex gap-1">
              <img src={ClaimPng} height={20} width={20} />
              Hospitalisation:{" "}
              <span
                style={{
                  fontWeight: "600",
                  width: "300px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {truncateString(claim_reason, 20)}
              </span>
            </p>
          )}
          {hospital_name && (
            <p className="pt-2 flex items-center">
              <LocalHospitalIcon style={{ color: "#Db2228", width: "20px" }} />
              &nbsp;Hospital:&nbsp;{" "}
              <span
                style={{
                  fontWeight: "600",
                  width: "250px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {truncateString(hospital_name, 20)}
              </span>
              {hospital_address && (
                <Tooltip
                  title={
                    `${hospital_address}  ${hospital_city} , ${hospital_state}` ||
                    "hospital address"
                  }
                  placement="right"
                  arrow
                >
                  <InfoOutlinedIcon
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                  />
                </Tooltip>
              )}
            </p>
          )}
          {bill_amount && (
            <p className="pt-2 ">
              <CurrencyRupeeIcon style={{ color: "#DB2228", width: "20px" }} />
              &nbsp;Claim amount:
              <span style={{ fontWeight: "600" }}>
                {" " + formatMoney(bill_amount)} /-
              </span>
            </p>
          )}
          {loan_code && (
            <p className="pt-2 flex items-center">
              <CreditScoreIcon style={{ color: "darkblue", width: "20px" }} />
              &nbsp;Loan&nbsp;Code:&nbsp;
              <span
                style={{
                  fontWeight: "600",
                  width: "250px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {truncateString(loan_code, 20)}
              </span>
              <Tooltip
                title={
                  copyText === "loanCode"
                    ? "Copied..."
                    : "Copy Loan code/Reference number"
                }
                placement="right"
                arrow
              >
                <ContentCopyIcon
                  style={{
                    fontSize: "15px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCopyText("loanCode");
                    writeClipboardText(loan_code);
                  }}
                />
              </Tooltip>
            </p>
          )}
          {gov_id_number && (
            <p className="pt-2 flex items-center">
              <BadgeOutlinedIcon style={{ color: "grey", width: "20px" }} />
              &nbsp;<span className="capitalize">{gov_id_type}</span>:&nbsp;
              <span style={{ fontWeight: "600" }}>
                {govtIDMaskString(gov_id_number)}
              </span>
            </p>
          )}
          {mobile && (
            <p className="pt-2 flex items-center ">
              <LocalPhoneIcon style={{ color: "green", width: "20px" }} />{" "}
              &nbsp;Phone:&nbsp;
              <span style={{ fontWeight: "600", width: "250px" }}>
                {mobile}
              </span>
              <Tooltip
                title={
                  copyText === "mobile" ? "Copied..." : "Copy Mobile number"
                }
                placement="right"
                arrow
              >
                <ContentCopyIcon
                  style={{
                    fontSize: "15px",
                    marginLeft: "10px",
                    cursor: "pointer",
                    marginBottom: "3px",
                  }}
                  onClick={() => {
                    setCopyText("mobile");
                    writeClipboardText(mobile);
                  }}
                />
              </Tooltip>
            </p>
          )}

          {sub_claims?.map((subClaim, index) => {
            const { remarks, claim_approved_amount } = subClaim;
            return (
              <>
                <div
                  key={index}
                  className={"mt-1 align-center"}
                  style={{ alignItems: "center" }}
                >
                  {claim_approved_amount && (
                    <p className="pt-2 ">
                      <CurrencyRupeeIcon
                        style={{ color: "#DB2228", width: "20px" }}
                      />
                      Approved Amount:
                      <span className="pl-2">
                        <span style={{ fontWeight: "600" }}>
                          {" " + formatMoney(claim_approved_amount)} /-
                        </span>
                      </span>
                    </p>
                  )}
                </div>
                {remarks ? (
                  <p className="mt-1 text-justify">
                    <EditNoteIcon />
                    Remarks:{" "}
                    <span style={{ fontWeight: "600", wordWrap: "break-word" }}>
                      {remarks}
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </body>
        <hr className="w-11/12 m-auto" />
        <footer className="pb-4 my-2 flex justify-end text-right items-center ">
          <Link
            to={"/reimbursements/" + claim_id}
            style={{ paddingBottom: "20px" }}
          >
            <button className="text-xs mr-3 bg-transparent font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded pb-2 button-hover">
              View
            </button>
          </Link>
        </footer>
      </article>
    </div>
  );
};
