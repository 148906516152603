import React, { useEffect, useRef, useState } from "react";
import { ApiGet } from "../../../lib/AppHelper";
import { Loader, Placeholder } from "rsuite";
import { toast } from "react-toastify";
import { Divider } from "rsuite";
import ProductBox from "../../../assets/productCreation/pro.png";
import ProductIcon from "../../../assets/productCreation/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import {
  faUser,
  faList,
  faCreditCard,
  faClock,
  faTh,
  faHandshake,
  faAlignLeft,
  faIdCard,
  faCheck,
  faTimes,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../common/components/CustomModal";
import CustomAccordian from "../common/components/CustomAccordian";
import { Tooltip } from "@mui/material";
import { PlusCircleIcon } from "@heroicons/react/outline";

const ProductInfo = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productInfo, setProductInfo] = useState();

  const fetchProductInfo = async () => {
    setIsModalOpen(true);
    const response = await ApiGet(`admin/get-product?productId=${id}`);
    if (response.code == 200) {
      setIsModalOpen(true);
      setProductInfo(response?.data);
    } else {
      toast.error(response?.message);
    }
    setLoading(false);
  };

  const getProductInfo = () => {
    setLoading(true);
    fetchProductInfo();
  };

  return (
    <>
      <Tooltip title="Product details" placement="right" arrow>
        <div
          className="cursor-pointer underline text-blue-600"
          onClick={getProductInfo}
        >
          {id}
        </div>
      </Tooltip>

      <CustomModal
        title={
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              style={{ width: "30px", height: "30px" }}
              src={ProductBox}
            />
            <p className="text-l font-bold">
              {`PROD${
                productInfo?.product_id ? productInfo?.product_id : id
              }  `}
              <span className="text-m font-semibold">
                {productInfo?.product_clasification
                  ? `(${
                      productInfo?.product_clasification
                        ? productInfo?.product_clasification
                        : ""
                    })`
                  : ""}
              </span>
            </p>
          </div>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        body={
          loading ? (
            <div
              className="flex"
              style={{
                height: "300px",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Placeholder.Paragraph rows={8} />
              <Loader size="md" content="Loading..." />
            </div>
          ) : (
            <ProductDetail id={id} loading={loading} product={productInfo} />
          )
        }
      />
    </>
  );
};

export default ProductInfo;

const ProductDetail = ({ product }) => {
  const productBasicInfo = {
    name: product?.product_display_name,
    price:
      product?.base_price == 0
        ? 0
        : !product?.base_price
        ? "-"
        : product?.base_price?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          }),
    category: product?.product_clasification,
    tenure: `${product?.tenure ? product?.tenure : "-"} ${
      product?.tenure_type ? product?.tenure_type : ""
    }`,
    description: product?.description
      ? product?.description
          .replace(/<\/?li>/g, "")
          .split("\n")
          .map((item) => item.trim())
      : [],
    memberCovered: product?.max_member_covered,
    payment_partner: product?.payment_partner,
  };
  const resellerInfo = product?.all_reseller_product_mappings?.map(
    (item, i) => {
      return {
        id: item?.reseller?.id,
        reseller_name: item?.reseller?.name,
        reseller_type: item?.reseller_type,
        mobile: item?.reseller?.mobile,
        price: item?.selling_amount,
        status: item?.status,
        canDownloadInvoice: item?.invoice_on_reg,
      };
    }
  );
  const comboProducts = product?.product_mappings || [];
  const benefits = product?.product_benefits || [];

  const firstDivRef = useRef(null);
  const [firstDivHeight, setFirstDivHeight] = useState(315);

  useEffect(() => {
    // Measure the height of the first div
    if (firstDivRef.current) {
      setFirstDivHeight(firstDivRef.current.offsetHeight);
    }

    // Optional: Recalculate height on window resize
    const handleResize = () => {
      if (firstDivRef.current) {
        setFirstDivHeight(firstDivRef.current.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [firstDivRef?.current]);

  return (
    <div className="bg-modalBg">
      <div className="p-2 flex flex-wrap md:flex-nowrap gap-2">
        <div ref={firstDivRef} className="flex-none ">
          <BasicInfo product={productBasicInfo} />
        </div>
        <div className="hidden max-h-50 md:block w-px bg-gray-300"></div>
        <div className="flex-auto">
          <span className="ml-2 mb-2 text-md font-bold">
            {"Assigned Resellers"}
          </span>
          <div
            className="overflow-y-auto"
            style={{ height: `${firstDivHeight}px` }}
          >
            <ResellerCard resellers={resellerInfo} />
          </div>
        </div>
      </div>

      <Divider />

      {comboProducts.length > 0 && (
        <div className="p-4">
          <div className="flex items-center gap-2 mb-2 ">
            <span className="text-l font-bold">{"Sub Products"}</span>
          </div>
          {comboProducts.length > 0 ? (
            comboProducts.map((product, index) => (
              <div key={index} className="mb-4">
                <CustomAccordian
                  active={true}
                  header={
                    <h2 className="text-md font-semibold">
                      {product?.sub_product_name}
                    </h2>
                  }
                  children={<ComboProductCard product={product} />}
                />
              </div>
            ))
          ) : (
            <p className="text-center">No combo products available.</p>
          )}
        </div>
      )}

      {benefits.length > 0 && (
        <div className="p-3">
          <Divider />
          <CustomAccordian
            active={true}
            children={
              <ul className="list-none p-2">
                {benefits.length > 0 ? (
                  benefits.map((benefit, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-red-500 font-bold text-xl mr-2">
                        &#8226;
                      </span>
                      <span>{benefit?.description_text}</span>
                    </li>
                  ))
                ) : (
                  <p className="text-center">No product benefits found.</p>
                )}
              </ul>
            }
            header={
              <h2 className="text-md font-semibold">{"Product Benefits"}</h2>
            }
            defaultExpanded={true}
          />
        </div>
      )}

      {product?.product_lab_test?.length > 0 && (
        <div className="border p-4 rounded-md">
          <label className="font-semibold">Lab Test Information</label>
          <div className="mt-5">
            <LabTestModal labTest={product.product_lab_test} />
          </div>
        </div>
      )}
    </div>
  );
};

const BasicInfo = ({ product }) => {
  const [more, setMore] = useState(false);
  const itCanOverflow = product?.description?.length > 1;
  return (
    <>
      <div className="flex items-center gap-2 ">
        <span className="ml-2 mb-2 text-md font-bold">
          {"Basic Information"}
        </span>
      </div>
      <div className="max-w-xs mx-auto bg-white shadow-md rounded-lg border border-gray-200">
        {product?.name ? (
          <div className="bg-[#db2228] text-black text-center mr-2 ml-2 py-3 rounded-t-lg flex items-center justify-start space-x-2">
            <img
              alt=""
              style={{ width: "40px", height: "40px" }}
              src={ProductIcon}
            />
            <h2 className="text-md font-semibold">{product?.name}</h2>
          </div>
        ) : (
          ""
        )}

        <div className="p-4 text-gray-700 space-y-3">
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={faRupeeSign}
              className="text-red-300"
            ></FontAwesomeIcon>
            <p className="text-sm font-medium">
              <span className="font-semibold">Price:</span> {product?.price}
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faTh} className="text-indigo-500" />
            <p className="text-sm font-medium">
              <span className="font-semibold">Category:</span>{" "}
              {product?.category ? product.category : "-"}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="text-blue-500"
            ></FontAwesomeIcon>
            <p className="text-sm font-medium">
              <span className="font-semibold">Member Covered:</span>{" "}
              {product?.memberCovered ? product.memberCovered : "-"}
            </p>
          </div>
          {product?.payment_partner && (
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faHandshake} />
              <p className="text-sm font-medium">
                <span className="font-semibold">Payment Partner:</span>
                {"-"}
                {product.payment_partner ? product?.payment_partner : "-"}
              </p>
            </div>
          )}

          <div className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={faClock}
              className="text-blue-500"
              size="sm"
            />
            <p className="text-sm font-medium">
              <span className="font-semibold">Tenure:</span>{" "}
              {product?.tenure ? product?.tenure : "-"}
            </p>
          </div>

          {product.description?.length > 0 && (
            <div className="flex items-start space-x-2">
              <FontAwesomeIcon
                icon={faAlignLeft}
                className="text-red-300"
              ></FontAwesomeIcon>
              <p className="text-sm font-medium">
                <span className="font-semibold">Description:</span>{" "}
                {product.description?.length > 0 ? (
                  itCanOverflow ? (
                    <div className="">
                      {more ? (
                        product.description?.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))
                      ) : (
                        <li key={0}>{`${product.description[0]}`}</li>
                      )}
                      <p
                        onClick={() => setMore(!more)}
                        className={`${
                          !more && "hidden"
                        }pointer-events-auto ml-2 text-blue underline cursor-pointer`}
                      >
                        {more ? "show less" : "show more"}
                      </p>
                    </div>
                  ) : (
                    <li key={0}>{`${product.description[0]}`}</li>
                  )
                ) : (
                  ""
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ResellerCard = ({ resellers }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4  ">
      {resellers?.length > 0 ? (
        resellers.map((reseller, index) => (
          <div
            key={index}
            className="bg-white border border-gray-200 shadow-md rounded-lg p-4 flex flex-col space-y-2 mb-4"
          >
            <div className="flex justify-between items-center w-full">
              {reseller?.reseller_type && (
                <span
                  className="text-xs font-medium flex items-center px-2 py-1 rounded-md"
                  style={{
                    color: reseller?.status == "1" ? "#CC7822" : "gray",
                    backgroundColor: "#FEBE001F",
                    fontWeight: 600,
                    textTransform: "none",
                  }}
                >
                  {reseller?.reseller_type}
                </span>
              )}

              <span
                className="text-xs font-medium flex items-center px-2 py-1 rounded-md text-right"
                style={{
                  color: reseller?.status == "1" ? "green" : "gray",
                  backgroundColor:
                    reseller?.status == "1" ? "#0080001F" : "#CCA0EF1F",
                  fontWeight: 600,
                  textTransform: "none",
                }}
              >
                {reseller?.status == "1" ? "Active" : "Inactive"}
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                icon={faIdCard}
                className="text-green-300"
              ></FontAwesomeIcon>
              <p className="text-gray-600">
                <span className="text-xs font-medium text-gray-700 ">
                  Reseller ID:
                </span>
                <span className="ml-1 text-sm font-semibold">
                  {reseller?.id ? reseller?.id : "-"}
                </span>
              </p>
            </div>
            {reseller?.reseller_name && (
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-indigo-500"
                ></FontAwesomeIcon>
                <h3 className="text-xs font-semibold text-gray-800">
                  {reseller?.reseller_name}
                </h3>
              </div>
            )}

            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                icon={faRupeeSign}
                className="text-red-300"
              ></FontAwesomeIcon>
              <p className="text-gray-600">
                <span className="text-xs font-medium text-gray-700">
                  Price:
                </span>
                <span className="ml-1 text-sm font-semibold">
                  {reseller?.price ? reseller?.price : "-"}
                </span>
              </p>
            </div>

            {reseller.canDownloadInvoice && (
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                  icon={faFileDownload}
                  className="text-blue-300"
                ></FontAwesomeIcon>
                <p className="text-gray-600">
                  <span className="text-xs font-medium text-gray-700">
                    Invoice Download Available:
                  </span>
                  {reseller?.canDownloadInvoice == "Y" ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ alignSelf: "center", marginLeft: "5px" }}
                      className="text-green-800"
                      size="sm"
                    />
                  ) : reseller?.canDownloadInvoice == "N" ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-red-800"
                      style={{ alignSelf: "center", marginLeft: "5px" }}
                      size="sm"
                    />
                  ) : null}
                </p>
              </div>
            )}
          </div>
        ))
      ) : (
        <p
          className="text-center text-gray-500 col-span-full"
          style={{ alignSelf: "center" }}
        >
          No resellers assigned.
        </p>
      )}
    </div>
  );
};

const ComboProductCard = ({ product }) => {
  return (
    <>
      <div className="ml-2 flex flex-wrap gap-2 sm:flex-nowrap sm:gap-4">
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon
            icon={faRupeeSign}
            className="text-red-300"
          ></FontAwesomeIcon>
          <p className="text-xs font-medium">
            <span className="font-semibold">Price:</span>{" "}
            {product?.sub_product?.base_price}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon
            icon={faUser}
            className="text-indigo-500"
          ></FontAwesomeIcon>
          <p className="text-xs font-medium">
            <span className="font-semibold">Vendor Name:</span>{" "}
            {product?.sub_product?.vendor_name}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          {/* <FaIdCard style={{ color: "#db2228" }} /> */}
          <FontAwesomeIcon
            icon={faCreditCard}
            className="text-blue-500"
          ></FontAwesomeIcon>
          <p className="text-xs font-medium">
            <span className="font-semibold">Member Covered:</span>{" "}
            {product?.sub_product?.max_member_covered}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon
            icon={faList}
            className="text-red-300"
          ></FontAwesomeIcon>
          <p className="text-xs font-medium">
            <span className="font-semibold">Product Specification:</span>{" "}
            {product?.sub_product?.product_specification}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon icon={faClock} className="text-blue-500" size="sm" />
          <p className="text-xs font-medium">
            <span className="font-semibold">Tenure:</span>{" "}
            {`${
              product?.sub_product?.tenure
            } ${product?.sub_product?.tenure_type.toLowerCase()}`}
          </p>
        </div>
      </div>

      {product?.sub_product?.is_accidental && (
        <p className="flex p-4">
          <p className="text-md font-semibold mr-2">Max claim amount: </p>{" "}
          <span>
            {product?.sub_product?.max_claim_amount?.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })}
          </span>
          {product?.sub_product?.eligible_for_claim == "N" ? (
            <p className="flex ml-4">
              <p className="text-md font-semibold">Eligible for claim: </p>{" "}
              <FontAwesomeIcon
                icon={faTimes}
                className="text-red-800"
                style={{ alignSelf: "center", marginLeft: "5px" }}
                size="sm"
              />
            </p>
          ) : (
            <p className="flex ml-4">
              <p className="text-md font-semibold">Eligible for claim: </p>{" "}
              <FontAwesomeIcon
                icon={faCheck}
                style={{ alignSelf: "center", marginLeft: "5px" }}
                className="text-green-800"
                size="sm"
              />
            </p>
          )}
        </p>
      )}
      {product?.sub_product?.is_hospicash && (
        <div className="p-4" style={{ marginBottom: "20px" }}>
          {/* Hcash */}
          {product?.sub_product?.hcash_slab1_days ||
          product?.sub_product?.hcash_slab2_days ||
          product?.sub_product?.hcash_slab3_days ||
          product?.sub_product?.hcash_slab4_days ? (
            <>
              <h2
                className="text-md font-semibold"
                style={{ marginBottom: "5px", color: "black" }}
              >
                {"H cash"}
              </h2>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table
                      className="min-w-full border-solid border border-slate-400"
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead className="border-b">
                        <tr
                          className="border border-slate-300"
                          style={{ backgroundColor: "#393939", color: "#fff" }}
                        >
                          <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                            Slabs (days)
                          </th>
                          <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                            Amounts
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product?.sub_product?.hcash_slab1_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab1_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab1_amount || "-"}
                            </td>
                          </tr>
                        )}
                        {product?.sub_product?.hcash_slab2_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab2_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab2_amount || "-"}
                            </td>
                          </tr>
                        )}
                        {product?.sub_product?.hcash_slab3_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab3_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab3_amount || "-"}
                            </td>
                          </tr>
                        )}
                        {product?.sub_product?.hcash_slab4_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab4_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hcash_slab4_amount || "-"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {/* Hospi Cash */}
          {product?.sub_product?.hospi_slab1_days ||
          product?.sub_product?.hospi_slab2_days ||
          product?.sub_product?.hospi_slab3_days ? (
            <>
              {" "}
              <h2
                className="text-md font-semibold"
                style={{ marginBottom: "10px", color: "black" }}
              >
                {"Hospi Cash"}
              </h2>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table
                      className="border border-slate-400"
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead className="border-b">
                        <tr
                          className="border border-slate-300"
                          style={{ backgroundColor: "#393939", color: "#fff" }}
                        >
                          <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                            Slabs (days)
                          </th>
                          <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                            Amounts
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product?.sub_product?.hospi_slab1_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hospi_slab1_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hospi_slab1_amount || "-"}
                            </td>
                          </tr>
                        )}
                        {product?.sub_product?.hospi_slab2_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hospi_slab2_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hospi_slab2_amount || "-"}
                            </td>
                          </tr>
                        )}
                        {product?.sub_product?.hospi_slab3_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hospi_slab3_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.hospi_slab3_amount || "-"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {/* ICU slab */}
          {product?.sub_product?.icu_slab1_days ||
          product?.sub_product?.icu_slab2_days ? (
            <>
              {" "}
              <h2
                className="text-md font-semibold"
                style={{ marginBottom: "10px", color: "black" }}
              >
                {"ICU"}
              </h2>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table
                      className="border border-slate-400"
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead className="border-b">
                        <tr
                          className="border border-slate-300"
                          style={{ backgroundColor: "#393939", color: "#fff" }}
                        >
                          <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                            Slabs (days)
                          </th>
                          <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                            Amounts
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product?.sub_product?.icu_slab1_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.icu_slab1_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.icu_slab1_amount || "-"}
                            </td>
                          </tr>
                        )}
                        {product?.sub_product?.icu_slab2_days && (
                          <tr className="border border-slate-300">
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.icu_slab2_days || "-"}
                            </td>
                            <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                              {product?.sub_product?.icu_slab2_amount || "-"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

const LabTestModal = ({ labTest }) => {
  return (
    <div className="bg-modalBg">
      {labTest?.length ? (
        labTest?.map((labTest, i) => {
          return (
            <CustomAccordian
              className={"border border-gray-200 mb-1"}
              header={
                <div className="flex items-center gap-2 justify-between w-full">
                  <div className="flex justify-between items-center gap-2 w-full">
                    <p className="text-sm font-bold">
                      {labTest?.lab_test_feature?.lab_test_name}
                    </p>
                    <PlusCircleIcon className="w-5 h-5" />
                  </div>
                </div>
              }
              children={
                <div>
                  <ul style={{ marginLeft: "20px" }}>
                    {labTest?.lab_test_feature?.lab_test_parameter?.length ? (
                      labTest?.lab_test_feature?.lab_test_parameter.map(
                        (benefit) => {
                          return (
                            <li className="flex gap-1 items-center">
                              <img
                                className="h-10 w-10"
                                src={benefit.icon_url}
                                alt=""
                              />
                              {benefit?.parameter_name}
                            </li>
                          );
                        }
                      )
                    ) : (
                      <div className="flex justify-center items-center">
                        <p
                          className="text-center ml-4"
                          style={{ fontSize: "16px" }}
                        >
                          No information found
                        </p>
                      </div>
                    )}
                  </ul>
                </div>
              }
            />
          );
        })
      ) : (
        <div>
          <p className="text-sm ml-4">No information found</p>
        </div>
      )}
    </div>
  );
};
