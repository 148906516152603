import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import { ApiGet, getData } from "../../lib/AppHelper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { InputDate } from "../../components/form/InputDate";
import { InputSelect } from "../../components/form/InputSelect";
import Button from "../../components/Button";
import { RefreshIcon } from "@heroicons/react/solid";

export class BusinessReport extends Component {
  MessageRef = React.createRef();
  loc = this.props.location?.state?.id;
  state = {
    reports: [],
    policyCount: 0,
    policyAmount: 0,
    permissions: "",
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      days: 30,
      months: "",
      team_id: this.props.location?.state?.id || "",
    },
    employees: [],
    toMaxDate: new Date(),
    dates: [
      {
        value: 1,
        label: "Today",
      },
      {
        value: 2,
        label: "Yesterday",
      },
      {
        value: 7,
        label: "Weekly",
      },
      {
        value: 30,
        label: "Monthly",
      },
    ],
  };

  onChangeHandler = (e, key) => {
    let { params } = this.state;

    if (key == "months" && params.months == e) {
      params[key] = "";
    } else if (key == "from" || key == "to") {
      params = { months: "", days: "" };
      params[key] = e;
    } else {
      params = { months: "", days: "", from: "", to: "" };
      params[key] = e;
    }

    if (key != "from" && key != "to") {
      this.setState(
        ({ params: prevParams }) => ({
          params: { ...prevParams, ...params },
        }),
        this.getReports
      );
    } else {
      this.setState({ params: params });
    }
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === 'from') {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, 'd')).toISOString().split("T")[0];
        params['to'] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params['months'] = '';
    params['days'] = '';
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params['to'] = moment().format("YYYY-MM-DD");
    params['from'] = moment().format("YYYY-MM-DD");
    params['months'] = '';
    params['days'] = '';
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, dates, permissions, toMaxDate } = this.state;

    return (
      <>
        <div>
          <AppBackButton to="/">Back</AppBackButton>
        </div>
        {permissions && permissions.business_report_view ? (
          <>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-2 pb-5">
              <div>
                <InputSelect
                  label="Search By"
                  defaultValue={params.days}
                  attrs={{
                    onChange: (e) =>
                      this.onChangeHandler(e.target.value, "days"),
                  }}
                  options={dates}
                ></InputSelect>
              </div>
              <div style={{ paddingTop: "28px" }}>
                <button
                  onClick={(e) => this.onChangeHandler(3, "months")}
                  className={`bg-${params.months == 3 ? "green" : "red"
                    }-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button`}
                >
                  3m
                </button>
                <button
                  onClick={(e) => this.onChangeHandler(6, "months")}
                  className={`bg-${params.months == 6 ? "green" : "red"
                    }-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button`}
                  type="button"
                >
                  6m
                </button>
                <button
                  onClick={(e) => this.onChangeHandler(12, "months")}
                  className={`bg-${params.months == 12 ? "green" : "red"
                    }-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button`}
                  type="button"
                >
                  12m
                </button>
              </div>

              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: params.from ? new Date(params.from) : new Date()
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: params.to ? new Date(params.to) : new Date()
                  }}
                />
              </div>

              {/* {params.from || params.to ? ( */}
              <div style={{ maxWidth: "50%", paddingTop: "22px" }} className="flex flex-row">
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
              {/* ) : (
                false
              )} */}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              {reports.map((item, index) => {
                const path = "/reports/business/" + item.id;
                return (
                  <div key={index}>
                    <article
                      key={index}
                      onClick={() => {
                        if (item.direct_team > 0) {
                          this.props.history.push(path, { id: item.id });
                        }
                      }}
                      className="overflow-hidden rounded-lg shadow-lg cursor-pointer"
                    >
                      <header className="flex w-75 items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="md:text-lg text-sm font-bold capitalize">
                          {item.name}
                        </h1>
                        <p className="hidden md:block lg:block">
                          <small className="pl-2">
                            <span className="pr-1">
                              <FontAwesomeIcon
                                icon={faPhoneSquare}
                                className="text-primary"
                              />
                            </span>
                            {item.mobile}
                          </small>
                        </p>
                        <p className="text-grey-darker text-sm">
                          {moment(item.created_at).format("DD, MMM YYYY")}
                        </p>
                      </header>

                      <body className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <div>
                          <p>
                            <span className="text-xs font-semibold inline-block py-1 px-2 capitalize md:uppercase rounded-full text-white bg-red-400 uppercase last:mr-0 mr-1">
                              Teams: {item.direct_team}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="ml-2 text-sm"></p>
                          <p>
                            <span className="text-xs font-semibold inline-block py-1 px-2 capitalize md:uppercase rounded-full text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1">
                              sold: {item.total_pol ? item.total_pol : 0}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="ml-2 text-sm"></p>
                          <p>
                            <span className="text-xs font-semibold inline-block py-1 px-2 capitalize md:uppercase rounded-full text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1">
                              Rs: {item.total_amt ? item.total_amt : 0}
                            </span>
                          </p>
                        </div>
                      </body>
                    </article>
                  </div>
                );
              })}
              {reports.length === 0 ? (
                <tr>
                  <td
                    colSpan="9"
                    height="50"
                    className="text-red-500 font-light text-center"
                  >
                    Your result is empty
                  </td>
                </tr>
              ) : (
                false
              )}
            </div>
          </>
        ) : (
          <div className="p-10 text-red-500">
            <h4>You do not have permission to view business report</h4>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.getReports();
    this.getPermissions();
  }

  getPermissions = async () => {
    const permission = await getData("permissions");
    this.setState({ permissions: permission });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location?.state?.id &&
      this.state.params.team_id != nextProps.location?.state?.id
    ) {
      let id = nextProps.location.state.id || "";
      this.setState(
        ({ params }) => ({
          params: {
            ...params,
            team_id: id,
          },
        }),
        this.getReports
      );
      // this.getReports({ team_id: id });
    } else if (
      !nextProps.location?.state?.id &&
      this.state.params.team_id != ""
    ) {
      this.setState(
        ({ params }) => ({
          params: {
            ...params,
            team_id: "",
          },
        }),
        this.getReports
      );
    }
  }

  getReports = async (request = "") => {
    request = this.state.params;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/business", request);
    if (response.status === "success") {
      if (response.data) {
        const items = Object.values(response.data.items);
        this.setState({ reports: items });
      } else {
        this.state.reports = [];
      }
    } else {
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  handleClick = () => {
    this.getReports(this.state.params);
  };
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};
export default withRouter(BusinessReport);
