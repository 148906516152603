import React from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { withRouter } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const Breadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;

  const pathnames = pathname.split("/").filter((x) => x);
  let finalPathnames = [];

  if (pathname !== "/") {
    finalPathnames.push({ label: "Home", value: "/" });
  }

  const mappedPaths = pathnames.map((path, index) => {
    const fullPath = "/" + pathnames.slice(0, index + 1).join("/");
    return {
      label: path.charAt(0).toUpperCase() + path.slice(1),
      value: fullPath,
    };
  });

  finalPathnames = [...finalPathnames, ...mappedPaths];

  return (
    <div className="flex flex-row items-center px-4 py-2">
      {pathname !== "/" && (
        <div
          onClick={history.goBack}
          className="flex items-center cursor-pointer"
        >
          <ArrowLeftIcon width={12} />
        </div>
      )}

      <MUIBreadcrumbs
        maxItems={4}
        aria-label="breadcrumb"
        style={{ fontSize: "12px" }}
        className="px-2 py-0"
        separator=">"
      >
        {finalPathnames.map((item, index) => {
          const routeTo = item.value;
          const isLastLink = index === finalPathnames.length - 1;

          return (
            <p
              key={routeTo}
              onClick={!isLastLink ? () => history.push(routeTo) : undefined}
              style={{
                cursor: !isLastLink ? "pointer" : "default",
                textTransform: "capitalize",
                color: isLastLink ? "#94A3B8" : "#003CBC",
                fontSize: "12px",
              }}
              className={`font-medium ${!isLastLink ? "hover:underline" : ""}`}
            >
              {item.label}
            </p>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default withRouter(Breadcrumbs);
