import React, { useEffect, useState } from "react";
import { Button, IconButton, Modal } from "rsuite";
import CustomModal from "../../admin/components/CustomModal";
import ProductCreate from "./ProductCreation";
import ProductBox from "../../../assets/productCreation/pro.png";
import EditIcon from "@rsuite/icons/legacy/Edit";
import TrashIcon from "@rsuite/icons/legacy/Trash";
import { ApiGet } from "../../../lib/AppHelper";
import { toast } from "react-toastify";

const DraftProduct = ({ draftProductData = {}, fetchProduct }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowExitPrompt(false);
    setShowDeletePrompt(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    const res = await ApiGet(
      `admin/delete-product-in-draft?id=${draftProductData.id}`
    );
    if (res.code === 200) {
      toast.success(res.message);
      fetchProduct();
      closeModal();
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleBackEvent = (event) => {
      if (isModalOpen) {
        event.preventDefault();
        setShowExitPrompt(true);
        window.history.pushState(null, "", window.location.href); // Prevent actual back navigation
      }
    };

    if (isModalOpen) {
      window.history.pushState(null, "", window.location.href); // Add a fake entry to prevent back
      window.addEventListener("popstate", handleBackEvent);
    } else {
      window.removeEventListener("popstate", handleBackEvent);
    }

    return () => {
      window.removeEventListener("popstate", handleBackEvent);
    };
  }, [isModalOpen]);

  return (
    <div className="flex gap-2">
      <IconButton
        icon={<EditIcon style={{ color: "white" }} />} // White icon
        onClick={onEdit}
        appearance="ghost"
        size="xs"
        style={{
          padding: "6px 10px",
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#db2228", // Red background
          transition: "0.2s",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#b71c1c"; // Darker red on hover
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#db2228"; // Default red
        }}
      />

      <IconButton
        icon={<TrashIcon style={{ color: "#db2228" }} />} // Red icon
        onClick={() => setShowDeletePrompt(true)}
        appearance="ghost"
        size="xs"
        style={{
          padding: "4px 6px",
          border: "1px solid #db2228", // Red border
          borderRadius: "5px",
          color: "#db2228", // Red text
          backgroundColor: "white", // White background
          transition: "0.2s",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#f8d7da"; // Light red on hover
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "white"; // Back to white
        }}
      />

      <CustomModal
        open={showDeletePrompt}
        setOpen={setShowDeletePrompt}
        backDropClose={true}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "50%",
          md: "50%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "80%",
        }}
        borderRadius="16px"
      >
        <Modal.Header onClick={() => setShowDeletePrompt(false)}>
          <Modal.Title>
            {" "}
            <p className="text-l font-bold">Delete Draft?</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-sm font-medium text-gray-700">
            Are you sure you want to delete this draft? This action cannot be
            undone.
          </p>
          <div className="flex justify-end gap-2 mt-4">
            <Button
              onClick={closeModal}
              style={{
                padding: 10,
                width: "120px",
                height: "40px",
                marginTop: "20px",
                backgroundColor: "lightgray",
                color: "black",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleDelete}
              style={{
                padding: 10,
                width: "120px",
                height: "40px",
                marginTop: "20px",
                backgroundColor: "#db2228",
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>

      <CustomModal
        open={isModalOpen}
        setOpen={setShowExitPrompt}
        backDropClose={true}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "80%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "80%",
        }}
        borderRadius="16px"
      >
        <Modal.Header onClick={() => setShowExitPrompt(true)}>
          <Modal.Title style={{ fontWeight: "600" }}>
            <div className="flex items-center gap-2 ">
              <img style={{ width: "30px", height: "30px" }} src={ProductBox} />
              <p className="text-l font-bold"> Create Product</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductCreate
            draftId={draftProductData.id}
            draftProductData={draftProductData?.data}
            setIsModalOpen={setIsModalOpen}
            fetchData={fetchProduct}
          />
        </Modal.Body>
      </CustomModal>

      <CustomModal
        open={showExitPrompt}
        setOpen={setShowExitPrompt}
        backDropClose={true}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        borderRadius="16px"
      >
        <Modal.Header onClick={() => setShowExitPrompt(false)}>
          <Modal.Title style={{ fontWeight: "600" }}>
            <div className="flex items-center gap-2 ">
              <img style={{ width: "30px", height: "30px" }} src={ProductBox} />
              <p className="text-l font-bold"> Close Product Creation?</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "auto" }}>
            <p className="text-sm font-medium text-gray-700">
              You have unsaved changes. Do you want to discard your changes?
            </p>
            <div className="flex justify-end gap-2 mt-4">
              <Button
                style={{
                  padding: 10,
                  width: "120px",
                  height: "40px",
                  marginTop: "20px",
                  backgroundColor: "lightgray",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                onClick={closeModal}
              >
                Exit
              </Button>
              <Button
                style={{
                  padding: 10,
                  width: "120px",
                  height: "40px",
                  marginTop: "20px",
                  backgroundColor: "#db2228",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setShowExitPrompt(false);
                  fetchProduct();
                }}
              >
                Keep on editing
              </Button>
            </div>
          </div>
        </Modal.Body>
      </CustomModal>
    </div>
  );
};

export default DraftProduct;
