import React, { useEffect, useState } from "react";
import { FaBox, FaUserTie, FaFlask, FaPlusCircle } from "react-icons/fa";
import AdminMain from "../admin/AdminMain";
import { AppBackButton } from "../../components/AppComponents";
import SideBar from "./sideBar/SideBar";
import { useSelector } from "react-redux";
import { getData } from "../../lib/AppHelper";
import ProductGrid from "./productGrid/ProductGrid";
import VendorGrid from "./vendorGrid/VendorGrid";
import LabTestGrid from "./labTestGrid/LabTestGrid";
import AddOnBenefits from "./addOnGrid/AddOnBenefits";

const ProductCreation = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useSelector((state) => state);
  const [permissions, setPermissions] = useState();

  const productMenu = [
    {
      label: "Products",
      icon: (active) => (
        <FaBox style={{ color: active ? "white" : "#393939" }} />
      ),
      isSubMenu: false,
      component: <ProductGrid />,
    },
    {
      label: "Vendors",
      icon: (active) => (
        <FaUserTie style={{ color: active ? "white" : "#393939" }} />
      ),
      isSubMenu: false,
      component: <VendorGrid />,
    },
    // {
    //   label: "Add-on Benefits",
    //   icon: (active) => (
    //     <FaPlusCircle style={{ color: active ? "white" : "#393939" }} />
    //   ),
    //   isSubMenu: false,
    //   component: <AddOnBenefits />,
    // },
    {
      label: "Lab Tests",
      icon: (active) => (
        <FaFlask style={{ color: active ? "white" : "#393939" }} />
      ),
      component: <LabTestGrid />,
    },
  ];

  useEffect(() => {
    (async () => {
      const permission = await getData("permissions");
      if (permission && permission.get_all_products) {
        setPermissions(permission);
      }
    })();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <AppBackButton>Back</AppBackButton>
        <h3 className="font-semibold text-xl text-gray2-500 text-center md:text-left">
          Product Catalogue
        </h3>
      </div>
      <SideBar
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        menueData={productMenu}
      />
    </>
  );
};

export default ProductCreation;
