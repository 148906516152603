import { AppStore } from "./AppStore";
import { toast } from "react-toastify";
import moment from "moment";
import { getLocData } from "./SessionInformation";
import { Encryption, getAuthToken } from "../helpers/Helper";
import axios from "axios";
import { RESELLER_ENCRYPTION_KEY } from "./constants";

const environment = process.env.REACT_APP_MODE;

//environment ...prod, dev, uat,  local

let API_ENDPOINT = "";
let PAYTM_PAYMENT_API_ENDPOINT = "";
let JAVA_ENDPOINT = "";
let PYTHON_ENDPOINT = "";
let API_CLAIM_ENDPOINT = "";
let CARE_TOKEN = "";
let API_CLAIM_SALES_ENDPOINT = "";
let FCM_TOKEN;
let CLAIM_ENDPOINT;
let CLIENT_ACCESS_KEY;
let CLIENT_ACCESS_SECRET;

if (environment == "local") {
  // Local details
  API_ENDPOINT = "http://127.0.0.1:8000/api";
  PAYTM_PAYMENT_API_ENDPOINT =
    "https://vendor-uat.aiqahealth.com/vendor-service";
  JAVA_ENDPOINT = "https://enrollment-uat.aiqahealth.com/";
  API_CLAIM_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/api`;
  API_CLAIM_SALES_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/resellers/sales`;
  PYTHON_ENDPOINT = "https://doctor-uat.aiqahealth.com/doctor/api";
  CARE_TOKEN = "22926aebbf37e0f5248119f4249487e7801d71d2";
  FCM_TOKEN =
    "BIH33oQ7lm3SY_YVyuCYqTcBWJOwnkJPyj-L1wrQzrr0K3d2HG46WA9D1ROTIAr2RbKCkATcV5cW54a7MTGpwlI";
  CLAIM_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/`;
  CLIENT_ACCESS_KEY =
    "$2a$10$Qr/ZRNpl6D//HN4yJq7zdu62e.ghDZURi4RSa.tRlMTghXySaa7De";
  CLIENT_ACCESS_SECRET =
    "$2a$10$mthPTZ4X5PTJh9S3VJlRheBebDckmQc33TMazcRY6gVYEwESsBOie";
} else if (environment == "prod") {
  // PRODUCTION DETAILS
  API_ENDPOINT = "https://partner-api.aiqahealth.com/api";
  PAYTM_PAYMENT_API_ENDPOINT =
    "https://vendor-prod.aiqahealth.com/vendor-service";
  JAVA_ENDPOINT = "https://enrollment-prod.aiqahealth.com/";
  API_CLAIM_ENDPOINT = `https://claim-prod.aiqahealth.com/claim-service/api`;
  API_CLAIM_SALES_ENDPOINT = `https://claim-prod.aiqahealth.com/claim-service/resellers/sales`;
  PYTHON_ENDPOINT = "https://doctoradmin.aiqahealth.com/doctor/api";
  CARE_TOKEN = "93150cbe39b223d1d7d96bdfbd98589b0aabcbea";
  FCM_TOKEN =
    "BIH33oQ7lm3SY_YVyuCYqTcBWJOwnkJPyj-L1wrQzrr0K3d2HG46WA9D1ROTIAr2RbKCkATcV5cW54a7MTGpwlI";
  CLAIM_ENDPOINT = `https://claim-prod.aiqahealth.com/claim-service/`;
  CLIENT_ACCESS_KEY =
    "$2a$10$Zdkn71ZFjlgOClL3X.Cm..zTRJ1lqJ4xC8fMsh68Wd3UvqUEAhs3e";
  CLIENT_ACCESS_SECRET =
    "$2a$10$vKzwnm080xzOupTBh1WD7ulAmBEdNy69LbTXLt0b4J.G1Pl6TlVgW";
} else if (environment == "dev") {
  // DEV DETAILS
  API_ENDPOINT = "https://dev-partner-api.aiqahealth.com/api";
  PAYTM_PAYMENT_API_ENDPOINT =
    "https://vendor-uat.aiqahealth.com/vendor-service";
  JAVA_ENDPOINT = "https://dev.aiqahealth.com/";
  API_CLAIM_ENDPOINT = `${JAVA_ENDPOINT}claim-service/api`;
  API_CLAIM_SALES_ENDPOINT = `${JAVA_ENDPOINT}claim-service/resellers/sales`;
  PYTHON_ENDPOINT = "https://docadmint.aiqahealth.in/doctor/api";
  CARE_TOKEN = "22926aebbf37e0f5248119f4249487e7801d71d2";
  FCM_TOKEN =
    "BIH33oQ7lm3SY_YVyuCYqTcBWJOwnkJPyj-L1wrQzrr0K3d2HG46WA9D1ROTIAr2RbKCkATcV5cW54a7MTGpwlI";
  CLAIM_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/`;
  CLIENT_ACCESS_KEY =
    "$2a$10$Qr/ZRNpl6D//HN4yJq7zdu62e.ghDZURi4RSa.tRlMTghXySaa7De";
  CLIENT_ACCESS_SECRET =
    "$2a$10$mthPTZ4X5PTJh9S3VJlRheBebDckmQc33TMazcRY6gVYEwESsBOie";
} else if (environment == "uat") {
  // UAT DETAILS
  API_ENDPOINT = "https://partner-uat.aiqahealth.com/api";
  PAYTM_PAYMENT_API_ENDPOINT =
    "https://vendor-uat.aiqahealth.com/vendor-service";
  JAVA_ENDPOINT = "https://enrollment-uat.aiqahealth.com/";
  API_CLAIM_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/api`;
  PYTHON_ENDPOINT = "https://doctor-uat.aiqahealth.com/doctor/api";
  CARE_TOKEN = "22926aebbf37e0f5248119f4249487e7801d71d2";
  API_CLAIM_SALES_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/resellers/sales`;
  FCM_TOKEN =
    "BIH33oQ7lm3SY_YVyuCYqTcBWJOwnkJPyj-L1wrQzrr0K3d2HG46WA9D1ROTIAr2RbKCkATcV5cW54a7MTGpwlI";
  CLAIM_ENDPOINT = `https://claim-uat.aiqahealth.com/claim-service/`;
  CLIENT_ACCESS_KEY =
    "$2a$10$Qr/ZRNpl6D//HN4yJq7zdu62e.ghDZURi4RSa.tRlMTghXySaa7De";
  CLIENT_ACCESS_SECRET =
    "$2a$10$mthPTZ4X5PTJh9S3VJlRheBebDckmQc33TMazcRY6gVYEwESsBOie";
}

let resellerId = void 0;
const claim_doc_api = (id, claimId) =>
  `${API_CLAIM_ENDPOINT}/multipleDocs?referenceType=${"CLAIM"}&referenceId=${claimId}&documentTypeId=${id}&resellerId=${getResellerId()}`;

const claim_doc_put_api = (ClaimId, docTypeId, subClaimId) =>
  `${API_CLAIM_ENDPOINT}/docs?referenceType=Claim&claimId=${ClaimId}&resellerId=${getResellerId()}&documentTypeId=${docTypeId}&subClaimId=${subClaimId}`;

const comment_doc_put_api = (
  ClaimId,
  subClaimId,
  ticketId,
  commentId,
  createdBy
) =>
  `${API_CLAIM_ENDPOINT}/upload-ticket-docs?referenceId=${ClaimId}&subReferenceId=${subClaimId}&ticketId=${ticketId}&commentId=${commentId}&referenceType=Claim&createdBY=${createdBy}`;

const comment_doc_get_api = (documentID) =>
  `${API_CLAIM_ENDPOINT}/get-ticket-docs/${documentID}`;

export const getClaimHeaders = async ({ json, resellerEncryptionToken }) => {
  let myHeaders = new Headers();
  myHeaders.append(
    "X-RESELLER-Client-Id",
    "b4c83e9a-e5f7-4aea-a03d-3ef209f002ac"
  );
  myHeaders.append(
    "X-RESELLER-Client-Secret",
    "m0AZYjzGcXl8JEDvbmYRz2W2yZrI24co"
  );
  myHeaders.append("resellerId", String(getResellerId()));
  if (json) {
    myHeaders.append("Content-Type", "application/json");
  }
  if (resellerEncryptionToken) {
    myHeaders.append("token", `${resellerEncryptionToken}`);
  }
  const resellerToken = localStorage.getItem("access_token");
  myHeaders.append("Authorization", `Token ${CARE_TOKEN}`);
  const auth_token = await getData("auth_token");
  if (resellerToken && auth_token) {
    myHeaders.append("ResellerToken", resellerToken);
    myHeaders.append("Auth-Token", auth_token);
  }
  return myHeaders;
};

export const claimHeader = async ({ json, resellerEncryptionToken }) => {
  let myHeaders = new Headers();
  myHeaders.append("client-access-key", CLIENT_ACCESS_KEY);
  myHeaders.append("client-access-secret", CLIENT_ACCESS_SECRET);
  myHeaders.append("resellerId", String(getResellerId()));
  if (json) {
    myHeaders.append("Content-Type", "application/json");
  }
  if (resellerEncryptionToken) {
    myHeaders.append("token", `${resellerEncryptionToken}`);
  }
  const resellerToken = localStorage.getItem("access_token");
  myHeaders.append("Authorization", `Token ${CARE_TOKEN}`);
  const auth_token = await getData("auth_token");
  if (resellerToken && auth_token) {
    myHeaders.append("Auth-Token", auth_token);
  }
  return myHeaders;
};

const ApiURL = (endpoint) => {
  return `${API_ENDPOINT}/${endpoint}`;
};

const PaytmPaymentApiURL = (endpoint) => {
  return `${PAYTM_PAYMENT_API_ENDPOINT}/${endpoint}`;
};

const JAVAURL = (endpoint) => {
  return `${JAVA_ENDPOINT}${endpoint}`;
};

const LabTestUrl = (endpoint) => {
  return `${CLAIM_ENDPOINT}${endpoint}`;
};

const PythonCareUrl = (endpoint) => {
  return `${PYTHON_ENDPOINT}/${endpoint}`;
};

const ClaimApiURL = (endpoint) => {
  return `${API_CLAIM_ENDPOINT}/${endpoint}`;
};

const InsuranceApiURL = (endpoint) => {
  return `${JAVA_ENDPOINT}${endpoint}`;
};

const SalesReportApiURL = (endpoint) => {
  return `${API_CLAIM_SALES_ENDPOINT}/${endpoint}`;
};

const JavaApiURL = (endpoint) => {
  return `${JAVA_ENDPOINT + endpoint}`;
};

const excelUpload = (endpoint) => {
  return `${JAVA_ENDPOINT}${endpoint}`;
};

const AsyncStorage = {
  setItem: async function (key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await null;
    return localStorage.getItem(key);
  },
  removeItem: async function (key) {
    await null;
    return localStorage.removeItem(key);
  },
};

//  if token mismatch then logout
const checkToken = async (json) => {
  if (json.message === "Unauthenticated.") {
    toast.info("Your session is expired. Please log in again", {
      position: "bottom-right",
      autoClose: 5000,
      pauseOnHover: true,
    });
    await AppLogout();
  }
};

export async function ApiPostWithOutToken(
  endpoint,
  Params = {},
  authToken,
  authT2Token,
  AbortController = null
) {
  if (Params instanceof FormData) {
    Params.append("accuracy", JSON.stringify(getLocData.accuracy));
    Params.append("latitude", JSON.stringify(getLocData.latitude));
    Params.append("longitude", JSON.stringify(getLocData.longitude));
    Params.append(
      "location_status",
      JSON.stringify(getLocData.location_status)
    );
  } else {
    Params = { ...Params, ...getLocData };
  }
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    if (Params instanceof FormData) {
      // MyHeaders.append("Content-Type", `multipart/form-data`);
    } else {
      MyHeaders.append("Content-Type", `application/json`);
      MyHeaders.append("cache-control", `no-cache`);
    }

    const token = authToken;
    if (token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", authT2Token);
    }

    let RequestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(ApiURL(endpoint), RequestOptions);

    let json = await response.json();

    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function ApiPost(endpoint, Params = {}, AbortController = null) {
  if (Params instanceof FormData) {
    Params.append("accuracy", JSON.stringify(getLocData.accuracy));
    Params.append("latitude", JSON.stringify(getLocData.latitude));
    Params.append("longitude", JSON.stringify(getLocData.longitude));
    Params.append(
      "location_status",
      JSON.stringify(getLocData.location_status)
    );
  } else {
    Params = { ...Params, ...getLocData };
  }
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    if (Params instanceof FormData) {
      // MyHeaders.append("Content-Type", `multipart/form-data`);
    } else {
      MyHeaders.append("Content-Type", `application/json`);
      MyHeaders.append("cache-control", `no-cache`);
    }

    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }

    let RequestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(ApiURL(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();

    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function ApiGet(endpoint, Params = {}, AbortController = null) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);

    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }

    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    var url = new URL(ApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );

    response = await fetch(url, RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    if (endpoint === "me") {
      await setData("reporting_email", json.data?.reporting_data?.email);
      await setData("reporting_name", json.data?.reporting_data?.name);
      await setData("reseller_pin", json.data?.basic?.pincode);
      await setData("reseller_language", json.data?.basic?.language);
      localStorage.setItem("resellerName", json.data?.name);
    }
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function JavaApiGet(
  endpoint,
  Params = {},
  AbortController = null,
  resellerEncryptionToken = false
) {
  try {
    let RequestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true, resellerEncryptionToken }),
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    var url = new URL(JavaApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );

    response = await fetch(url, RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    if (json.status === "error" && json.code === 401) {
      await AppLogout();
    }
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function JavaApiPut(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let RequestOptions = {
      method: "PUT",
      headers: await getClaimHeaders({ json: true }),
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(JavaApiURL(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();

    if (json.status === "error" && json.code === 401) {
      await AppLogout();
    }
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function JavaApiPost(
  endpoint,
  Params = {},
  AbortController = null,
  resellerEncryptionToken = false
) {
  try {
    let RequestOptions = {
      method: "POST",
      headers: await getClaimHeaders({
        json: true,
        resellerEncryptionToken,
      }),
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(JavaApiURL(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();

    if (json.status === "error" && json.code === 401) {
      await AppLogout();
    }
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function JavaApiPostGovtId(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let myHeaders = new Headers();
    const token = await getData("access_token");
    if (token !== null && token !== "") {
      myHeaders.append("Authorization", `Bearer ${token}`);
    }
    myHeaders.append(
      "X-RESELLER-Client-Id",
      "b4c83e9a-e5f7-4aea-a03d-3ef209f002ac"
    );
    myHeaders.append(
      "X-RESELLER-Client-Secret",
      "m0AZYjzGcXl8JEDvbmYRz2W2yZrI24co"
    );
    myHeaders.append("resellerId", String(getResellerId()));
    myHeaders.append("Accept", `application/json`);
    if (Params instanceof FormData) {
      // MyHeaders.append("Content-Type", `multipart/form-data`);
    } else {
      myHeaders.append("Content-Type", `application/json`);
      myHeaders.append("cache-control", `no-cache`);
    }

    let RequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };

    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(JavaApiURL(endpoint), RequestOptions);

    let json = await response.json();

    if (json.status === "error" && json.code === 401) {
      await AppLogout();
    }
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const uploadApiDocuments = async (
  endpoint,
  formData,
  AbortController = null
) => {
  try {
    const auth_token = await getData("auth_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", auth_token);
    myHeaders.append("Auth-Token", auth_token);

    const RequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await await fetch(JavaApiURL(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export async function downloadPDFFile(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/pdf`);
    MyHeaders.append("cache-control", `no-cache`);
    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(JavaApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );
    await fetch(url, RequestOptions)
      .then(async (response) => {
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          window.location.replace("/logout");
          return false;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        // Create blob link to download
        const urls = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = urls;
        link.setAttribute("download", `invoice.pdf`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    return 0;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function downloadExcelFile(
  endpoint,
  Params = {},
  reportName,
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/xlsx`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(ApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );
    await fetch(url, RequestOptions)
      .then(async (response) => {
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          window.location.replace("/logout");
          return false;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        // Create blob link to download
        const urls = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = urls;
        link.setAttribute("download", reportName);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    return 0;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function postApiForCommonDownloadedReportExcel(
  endpoint,
  Params = {},
  reportName,
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
      MyHeaders.append("token", getResellerEncryptionToken());
    }
    let RequestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: JSON.stringify(Params),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(JAVAURL(endpoint));

    return await fetch(url, RequestOptions).then(async (response) => {
      if (response.status == 401) {
        toast.error("You have been logged out.");
        await removeData("is_admin");
        await removeData("access_token", () => {
          AppStore.dispatch({ type: "SIGN_OUT" });
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
        await removeData("auth_token");
        return false;
      } else if (response?.status == 200) {
        return response;
      }
    });
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function getApiForDownloadCSVSalesReport(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    let RequestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(JavaApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );
    return await fetch(url, RequestOptions).then(async (response) => {
      if (response.status == 401) {
        toast.error("You have been logged out.");
        await removeData("is_admin");
        await removeData("access_token", () => {
          AppStore.dispatch({ type: "SIGN_OUT" });
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
        await removeData("auth_token");
        return false;
      } else {
        return response;
      }
    });
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function downloadCSVFile(
  endpoint,
  Params = {},
  reportName,
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/xlsx`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    let RequestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(ClaimApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );
    await fetch(url, RequestOptions)
      .then(async (response) => {
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          return false;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        // Create blob link to download
        const urls = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = urls;
        link.setAttribute("download", reportName);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    return 0;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function downloadCSVFileForJavaEndPoint(
  endpoint,
  Params = {},
  reportName,
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/xlsx`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    let RequestOptions = {
      method: "POST",
      headers: await getClaimHeaders({ json: true }),
      body: JSON.stringify(Params),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(InsuranceApiURL(endpoint));
    // Object.keys(Params).forEach((key) =>
    //   url.searchParams.append(key, Params[key])
    // );
    await fetch(url, RequestOptions)
      .then(async (response) => {
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          return false;
        } else if (response.status == 200) {
          toast.success("Report successfully downloaded.");
          return response.blob();
        }
      })
      .then((blob) => {
        if (blob) {
          // Create blob link to download
          const urls = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = urls;
          link.setAttribute("download", reportName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          toast.info("No data found");
        }
      });
    return 0;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function downloadCSVFileForClaimPoint(
  endpoint,
  Params = {},
  reportName,
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/xlsx`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    let RequestOptions = {
      method: "POST",
      headers: await getClaimHeaders({ json: true }),
      body: JSON.stringify(Params),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(ClaimApiURL(endpoint));
    // Object.keys(Params).forEach((key) =>
    //   url.searchParams.append(key, Params[key])
    // );
    await fetch(url, RequestOptions)
      .then(async (response) => {
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          return false;
        } else if (response.status == 200) {
          toast.success("Report successfully downloaded.");
          return response.blob();
        }
      })
      .then((blob) => {
        if (blob) {
          // Create blob link to download
          const urls = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = urls;
          link.setAttribute("download", reportName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          toast.error("No data found");
        }
      });
    return 0;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const setData = async (key, value, callback = () => {}) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify({ value: value }));
  } catch (e) {}
  callback();
  return true;
};

export const removeData = async (key, callback = () => {}) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (e) {}
  callback();
};

export const getData = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value === null) return null;
    const json = JSON.parse(value);
    return json.value;
  } catch (e) {
    // saving error
  }
};

export const pullData = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value === null) return null;
    const json = JSON.parse(value);
    await removeData(key);
    return json.value;
  } catch (e) {
    // saving error
  }
};

export const setError = async (
  message,
  callback = () => {},
  position = "bottom-right",
  autoClose = 5000
) => {
  // await setData("@error", message, callback);
  toast.error(message, {
    position: position,
    autoClose: autoClose,
  });
  setTimeout(() => {
    callback && callback();
  }, 500);
};

export const getError = async () => {
  const error = await pullData("@error");
  return error;
};

export const setMessage = async (
  message,
  callback = () => {},
  position = "bottom-right"
) => {
  // await setData("@message", message, callback);
  toast.success(message, {
    position: position,
    autoClose: 5000,
  });
  setTimeout(() => {
    callback && callback();
  }, 500);
};

export const setInfoMessage = async (
  message,
  callback = () => {},
  position = "bottom-right"
) => {
  toast.info(message, {
    position,
    autoClose: 5000,
  });
  setTimeout(() => {
    callback && callback();
  }, 500);
};

export const getMessage = async () => {
  const message = await pullData("@message");
  return message;
};

export const AppLogout = async () => {
  const response = await ApiPost("logout");
  AppStore.dispatch({ type: "LOADING", loading: true });
  await removeData("is_admin");
  await removeData("access_token", () => {
    AppStore.dispatch({ type: "SIGN_OUT" });
    AppStore.dispatch({ type: "LOADING", loading: false });
  });
  await removeData("auth_token");
  localStorage.clear();
  return true;
};

export const setResellerId = (id) => {
  resellerId = id;
};

export const getResellerId = () => {
  return resellerId;
};

export async function ApiPostClientId(endpoint, data) {
  try {
    let raw = JSON.stringify(data);

    let requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({ json: true }),
      body: raw,
      redirect: "follow",
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function ApiPutClientId(endpoint, data) {
  try {
    let raw = JSON.stringify(data);

    let requestOptions = {
      method: "PUT",
      headers: await getClaimHeaders({ json: true }),
      body: raw,
      redirect: "follow",
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const uploadClaimDocuments = async (docId, claimId, file) => {
  try {
    var formdata = new FormData();
    file.forEach((f, index) => {
      formdata.append("file", f);
    });

    // formdata.append("file", file, file.name);

    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };

    let response = await fetch(claim_doc_api(docId, claimId), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const putClaimDocuments = async (
  docId,
  claimId,
  subClaimId = 1,
  file
) => {
  try {
    var formdata = new FormData();
    const filesLength = file.length;
    for (var i = 0; i < filesLength; i++) {
      formdata.append("file", file[i]);
    }

    var requestOptions = {
      method: "PUT",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };

    let response = await fetch(
      claim_doc_put_api(claimId, docId, subClaimId),
      requestOptions
    );
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }

    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const putCommentDocuments = async (
  createdBy,
  docId,
  claimId,
  subClaimId = 1,
  masterId,
  file
) => {
  try {
    var formdata = new FormData();
    file.forEach((f, index) => {
      formdata.append("file", f); // Append each file to the FormData object
    });

    // formdata.append("file", file);

    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };

    let response = await fetch(
      comment_doc_put_api(claimId, subClaimId, masterId, docId, createdBy),
      requestOptions
    );
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }

    // let json = await response.json();
    // checkToken(json);
    return response;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const getDocuments = async (docId) => {
  try {
    var requestOptions = {
      method: "GET",
      headers: await getClaimHeaders({}),
      redirect: "follow",
    };

    let response = await fetch(comment_doc_get_api(docId), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.blob();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const submitClaim = async (endpoint) => {
  try {
    var requestOptions = {
      method: "PUT",
      headers: await getClaimHeaders({}),
      redirect: "follow",
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const removeUploadedFile = async (endpoint) => {
  try {
    var requestOptions = {
      method: "DELETE",
      headers: await getClaimHeaders({}),
      redirect: "follow",
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const getApiClaim = async (endpoint) => {
  try {
    var requestOptions = {
      method: "GET",
      headers: await getClaimHeaders({}),
      redirect: "follow",
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const postApiClaim = async (endpoint, data) => {
  let raw;
  if (data instanceof FormData) {
    raw = data;
  } else {
    raw = JSON.stringify(data);
  }

  try {
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({
        json: data instanceof FormData ? false : true,
      }),
      redirect: "follow",
      body: raw,
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateMobile = (mobile) => {
  return mobile.match(/^(\+\d{1,3}[- ]?)?\d{10}$/);
};

export const validateName = (name) => {
  return name.match(/^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/);
};

export const validateNumber = (number) => {
  var regexp = /^\d+(\.\d{1,2})?$/;
  return regexp.test(number);
};

export const validateDate = (date) => {
  if (moment().diff(date, "years") >= 100) return false;
  return true;
};

export const validateAadhaar = (aadhaar) => {
  const aadhaarRegex = /^[2-9]{1}[0-9]{11}$/;
  return aadhaarRegex.test(aadhaar);
};

export const validateSpecialChar = (value) => {
  const regex = /^[a-zA-Z0-9 _-]*$/;
  return regex.test(value);
};

export const validatePolicyPuchDate = (dob, min, max) => {
  const today = moment();
  const minYear = moment(min, "YYYY-MM-DD");
  const maxYear = moment(max, "YYYY-MM-DD");
  const minYearDifference = today.diff(minYear, "years");
  const maxYearDifference = today.diff(maxYear, "years");
  if (min && max && dob) {
    if (new Date(dob) > new Date(min)) {
      return `Date of Birth (min age ${minYearDifference}, max age ${maxYearDifference}) is required`;
    } else if (new Date(dob) < new Date(max)) {
      return `Date of Birth (min age ${minYearDifference}, max age ${maxYearDifference}) is required`;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const validatePassword = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?])[a-zA-Z\d!@#$%^&*?]+$/;
  return passwordRegex.test(password);
};

export const ROLE_STATUS_MAPPING = {
  DRAFT: "DRAFT",
  QUERY: "Queried",
  REJECT: "Rejected",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  Approved: "Approved",
  NEW: "In Progress",
  PENDING: "In Progress",
  IN_PROGRESS: "In Progress",
  IO_IN_PROCESS: "In Progress",
  IO_APPROVED: "In Progress",
  IO_REJECTED: "Rejected",
  IO_RE_SUBMIT: "Queried",
  DOC_IN_PROCESS: "In Progress",
  DOC_APPROVED: "In Progress",
  DOC_REJECTED: "Rejected",
  DOC_RE_SUBMIT: "Queried",
  DOC_SPL_IN_PROCESS: "In Progress",
  DOC_SPL_APPROVED: "In Progress",
  DOC_SPL_REJECTED: "Rejected",
  DOC_SPL_RE_SUBMIT: "Queried",
  FO_IN_PROCESS: "In Progress",
  FO_APPROVED: "Approved",
  FO_REJECTED: "Rejected",
  FO_RE_SUBMIT: "Queried",
  SETTLED: "Settled",
  PARTIAL_SETTLE: "Partial Settle",
};

export const ACTION_CLAIM_STATUS = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "IN_PROGRESS",
    label: "IN PROGRESS",
  },

  {
    value: "REJECTED",
    label: "REJECTED",
  },
  {
    value: "QUERY",
    label: "QUERY",
  },
  {
    value: "SETTELED",
    label: "SETTLED",
  },
  {
    value: "Approved",
    label: "APPROVED",
  },
];

export const CLAIM_STATUS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "IN_PROGRESS",
    label: "IN PROGRESS",
  },

  {
    value: "REJECT",
    label: "REJECTED",
  },
  {
    value: "QUERY",
    label: "QUERY",
  },
  {
    value: "SETTELED",
    label: "SETTLED",
  },
  {
    value: "Approved",
    label: "APPROVED",
  },
];

// 'In Progress',
// 'DRAFT',
// 'Approved',
// 'Query',
// 'Settled',
// 'Reject',

// api call for excel upload
export async function GetUserDashboardData(endpoint, AbortController = null) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    let token = getResellerEncryptionToken();
    MyHeaders.append("Token", token);
    let auth_token = await getData("auth_token");
    MyHeaders.append("Auth-Token", auth_token);
    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(JAVAURL(endpoint));
    response = await fetch(url, RequestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function GetPmjayApiData(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    let token = getResellerEncryptionToken();
    MyHeaders.append("Token", token);
    const auth_token = await getData("auth_token");
    MyHeaders.append("Auth-Token", auth_token);
    let RequestOptions = {
      method: "post",
      headers: MyHeaders,
      body: JSON.stringify(Params),
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(JAVAURL(endpoint));
    response = await fetch(url, RequestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const pmjayDataSave = async (parems) => {
  try {
    var formdata = new FormData();
    formdata.append("mobile", parems?.mobile);
    formdata.append("aadhaarNumber", parems?.aadhaarNumber);
    formdata.append("pmjayId", parems?.pmjayId);
    formdata.append("loanCode", parems?.loanCode);
    formdata.append("consentText", parems?.consentText);
    formdata.append("aadhaarImage", parems?.aadhaarImage);
    formdata.append("pmjayImage", parems?.pmjayImage);
    formdata.append("loanCodeImage", parems?.loanCodeImage);
    formdata.append("consentImage", parems?.consentImage);
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    let token = getResellerEncryptionToken();
    requestOptions.headers.set("token", `${token}`);
    let response = await fetch(
      excelUpload(
        "enrollment-service/pmjay_reseller_mapping/saveLoanCodePmjayId"
      ),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const pmjayDataUpdate = async (parems) => {
  try {
    var formdata = new FormData();
    formdata.append("mobile", parems?.mobile);
    formdata.append("id", parems?.id);
    formdata.append("aadhaarNumber", parems?.aadhaarNumber);
    formdata.append("pmjayId", parems?.pmjayId);
    formdata.append("loanCode", parems?.loanCode);
    formdata.append("consentText", parems?.consentText);
    formdata.append("aadhaarImage", parems?.aadhaarImage);
    formdata.append("pmjayImage", parems?.pmjayImage);
    formdata.append("loanCodeImage", parems?.loanCodeImage);
    formdata.append("consentImage", parems?.consentImage);
    var requestOptions = {
      method: "put",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    let token = getResellerEncryptionToken();

    requestOptions.headers.set("token", `${token}`);
    let response = await fetch(
      excelUpload(
        "enrollment-service/pmjay_reseller_mapping/updateLoanCodePmjayIdDetails"
      ),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const getDocumentJava = async (parems) => {
  try {
    var requestOptions = {
      method: "get",
      headers: await getClaimHeaders({ json: true }),
      redirect: "follow",
    };
    let token = getResellerEncryptionToken();
    requestOptions.headers.set("token", `${token}`);
    var url = new URL(
      excelUpload("enrollment-service/pmjay_reseller_mapping/downloadImage")
    );
    Object.keys(parems).forEach((key) =>
      url.searchParams.append(key, parems[key])
    );

    let response = await fetch(url, requestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const excelUploder = async (
  token,
  file,
  emailRecipients,
  uploadedBy
) => {
  try {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("emailRecipients", emailRecipients);
    formdata.append("excelType", "normal uploader");
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    requestOptions.headers.set("token", `${token}`);
    if (uploadedBy) {
      requestOptions.headers.set("uploadedBy", uploadedBy);
    }
    let response = await fetch(
      excelUpload("enrollment-service/uploader/parseResellerExcelData"),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const excelGroupUploder = async (
  token,
  file,
  emailRecipients,
  group_id,
  uploadedBy
) => {
  try {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("emailRecipients", emailRecipients);
    formdata.append("groupId", group_id);
    formdata.append("excelType", "group uploader");
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({
        json: false,
        resellerEncryptionToken: token,
      }),
      body: formdata,
      redirect: "follow",
    };
    if (uploadedBy) {
      requestOptions.headers.set("uploadedBy", uploadedBy);
    }
    let response = await fetch(
      excelUpload("enrollment-service/uploader/parseResellerExcelData"),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const excelPolicyUploader = async (
  file,
  emailRecipients,
  vendorCode,
  code
) => {
  try {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("emailRecipients", emailRecipients);
    formdata.append("vendorCode", vendorCode);
    formdata.append("code", code);
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    requestOptions.headers.set("token", `${getResellerEncryptionToken()}`);
    let auth_token = await getData("auth_token");
    if (auth_token) {
      requestOptions.headers.set("Authorization", auth_token);
      requestOptions.headers.set("auth-token", auth_token);
    }
    let response = await fetch(
      excelUpload("enrollment-service/api/policy/uploadPolicyData"),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const excelCreditLifeUploder = async (
  token,
  file,
  emailRecipients,
  uploadedBy
) => {
  try {
    // let raw = JSON.stringify(file);
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("emailRecipients", emailRecipients);
    formdata.append("excelType", "credit uploader");
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    requestOptions.headers.set("token", `${token}`);
    if (uploadedBy) {
      requestOptions.headers.set("uploadedBy", uploadedBy);
    }
    let response = await fetch(
      excelUpload(
        "enrollment-service/uploader/parseResellerCreditLifeExcelData"
      ),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const excelLoanAgainstPropertyUploder = async (
  token,
  file,
  emailRecipients,
  uploadedBy
) => {
  try {
    // let raw = JSON.stringify(file);
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("emailRecipients", emailRecipients);
    formdata.append("excelType", "lap uploader");
    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    requestOptions.headers.set("token", `${token}`);
    if (uploadedBy) {
      requestOptions.headers.set("uploadedBy", uploadedBy);
    }
    let response = await fetch(
      excelUpload("enrollment-service/uploader/parseLapFrequencyExcelData"),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const emiUploader = async (token, file, emailRecipients) => {
  try {
    // let raw = JSON.stringify(file);
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("emailRecipients", emailRecipients);
    formdata.append("excelType", "emi uploader");

    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    requestOptions.headers.set("token", `${token}`);
    let response = await fetch(
      excelUpload("enrollment-service/uploader/parseEmiFrequencyExcelData"),
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const getVendorCodesJava = async () => {
  try {
    var requestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
      redirect: "follow",
    };
    let token = getResellerEncryptionToken();
    let auth_token = await getData("auth_token");
    requestOptions.headers.set("token", `${token}`);
    if (auth_token) {
      requestOptions.headers.set("Authorization", auth_token);
      requestOptions.headers.set("auth-token", auth_token);
    }
    requestOptions.headers.set("token", `${token}`);
    var url = new URL(
      excelUpload("enrollment-service/api/policy/getVendorCodes")
    );

    let response = await fetch(url, requestOptions);

    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const getVendorCodeBenefitJava = async (payload) => {
  try {
    var requestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
      redirect: "follow",
    };
    let token = getResellerEncryptionToken();
    let auth_token = await getData("auth_token");
    requestOptions.headers.set("token", `${token}`);
    if (auth_token) {
      requestOptions.headers.set("Authorization", auth_token);
      requestOptions.headers.set("auth-token", auth_token);
    }
    var url = new URL(
      excelUpload(`enrollment-service/api/policy/getVendorBenefits/${payload}`)
    );
    let response = await fetch(url, requestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export async function PythonCareApiPost(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    const auth_token = await getData("auth_token");
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("Authorization", `Token ${CARE_TOKEN}`);
    MyHeaders.append("Auth-Token", auth_token);

    let RequestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    response = await fetch(PythonCareUrl(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function PythonCareApiGet(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    const auth_token = getAuthToken() || (await getData("auth_token"));
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("Authorization", `Token ${CARE_TOKEN}`);

    // MyHeaders.append(
    //   "Authorization",
    //   `Token a6f651b31c862a071b839f96e269f81dace97c39`
    // );
    MyHeaders.append("Auth-Token", auth_token);
    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = PythonCareUrl(endpoint + "?");
    Object.keys(Params).forEach((key) => {
      url += key + "=" + Params[key] + "&";
    });
    response = await fetch(url, RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const getReportingEmail = () => {
  const reportingEmail = localStorage.getItem("reporting_email");
  const json = JSON.parse(reportingEmail);
  return json.value;
};

export const getResellerEncryptionToken = () => {
  const resellerEncryptionToken = Encryption(
    getResellerId(),
    RESELLER_ENCRYPTION_KEY
  );
  return resellerEncryptionToken;
};

export const getRsellerPinCode = () => {
  const resellerPin = localStorage.getItem("reseller_pin");
  const json = JSON.parse(resellerPin);
  return json.value;
};

export const getResellerLanguage = () => {
  const resellerLanguage = localStorage.getItem("reseller_language");
  const json = JSON.parse(resellerLanguage);
  return json.value;
};

export const checkScope = (scope) => {
  const accessToken = localStorage.getItem("access_token");
  const json = JSON.parse(accessToken);
  const token = json.value;
  var base64Payload = token.split(".")[1];
  var payload = Buffer.from(base64Payload, "base64");
  let tokenDecode = JSON.parse(payload.toString());
  if (tokenDecode?.scopes) {
    let scopes = tokenDecode.scopes.includes(scope);
    if (scopes) return true;
    else return false;
  }
  return false;
};

export async function getMedicineData(endpoint) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = localStorage.getItem("token");
    const auth_token = await getData("auth_token");
    MyHeaders.append("Authorization", `Token ${token}`);
    MyHeaders.append("Auth-Token", auth_token);
    let response;
    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };
    response = await fetch(JAVAURL(endpoint), RequestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function postMedicineData(id, Params = {}) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    const token = localStorage.getItem("token");
    const auth_token = await getData("auth_token");
    MyHeaders.append("Authorization", `Token ${token}`);
    MyHeaders.append("Auth-Token", auth_token);

    let response;
    let RequestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: JSON.stringify({ prescribedMedicinesDtos: Params }),
    };
    response = await fetch(
      JAVAURL(`enrollment-service/api/prescription/medications/${id}/handover`),
      RequestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

// get appointment
export const fetchAllAppointment = async (page, startDate, endDate) => {
  const resellerId = getResellerId();
  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("Authorization", `Token ${CARE_TOKEN}`);
  // myHeaders.append(
  //   "authorization",
  //   "Token a6f651b31c862a071b839f96e269f81dace97c39"
  // );
  const auth_token = await getData("auth_token");
  myHeaders.append("Auth-Token", auth_token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `${PythonCareUrl(
      `reseller-todays-appointments/?page=${page}&status=&from_date=${startDate}&to_date=${endDate}&ordering=-call_join_time%2Cappointment_date%2Cappointment_time&created_from=reseller&created_by=${resellerId}`
    )}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

// previous report fetch api
export const fetchDownloadPrescription = async (user, patient) => {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/pdf`);
    MyHeaders.append("cache-control", `no-cache`);
    MyHeaders.append(
      "X-RESELLER-Client-Id",
      `b4c83e9a-e5f7-4aea-a03d-3ef209f002ac`
    );
    MyHeaders.append("X-RESELLER-Client-Secret", `no-cache`);
    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };
    return await fetch(
      `${JAVA_ENDPOINT}enrollment-service/prescription/${user}/${patient}`,
      RequestOptions
    )
      .then(async (response) => {
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          window.location.replace("/logout");
          return false;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        return blob;
      });
  } catch (err) {
    console.log(err, "ERROR");
    toast.error("something went wrong");
  }
};

export const postJoinCallAction = async (data) => {
  try {
    const auth_token = await getData("auth_token");
    const res = await axios.post(
      `${PythonCareUrl(`eclinic-status-update/`)}`,
      data,
      {
        headers: {
          Authorization: `Token ${CARE_TOKEN}`,
          // Authorization: "Token a6f651b31c862a071b839f96e269f81dace97c39",
          "Auth-Token": auth_token,
        },
      }
    );
    let response = JSON.parse(JSON.stringify(res));
    if (response.status === 200) {
      toast.success("Calling in progress...");
    }
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
  } catch (err) {
    toast.error("something went wrong, try again...");
  }
};

export async function JavaApiPostFileUpload(
  endpoint,
  params = {},
  AbortController = null
) {
  var formdata = new FormData();
  formdata.append("file", params);
  try {
    let RequestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    response = await fetch(JavaApiURL(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();

    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const getResellerDocument = async (id, value) => {
  try {
    var requestOptions = {
      method: "get",
      headers: await getClaimHeaders({
        json: true,
        resellerEncryptionToken: true,
      }),
      redirect: "follow",
    };
    var url = new URL(
      excelUpload(
        `enrollment-service/resellerDocuments/download_reseller_documents/${id}/download`
      )
    );
    let response = await fetch(url, requestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    } else if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const deleteResellerDocument = async (id) => {
  try {
    var requestOptions = {
      method: "DELETE",
      headers: await getClaimHeaders({
        json: true,
        resellerEncryptionToken: true,
      }),
      redirect: "follow",
    };
    var url = new URL(
      excelUpload(`enrollment-service/resellerDocuments/delete_documents/${id}`)
    );
    let response = await fetch(url, requestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }

    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export async function SendTeamPerformanceReport(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);
    let token = getResellerEncryptionToken();
    MyHeaders.append("Token", token);
    let auth_token = await getData("auth_token");
    MyHeaders.append("Auth-Token", auth_token);
    let RequestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: Params instanceof FormData ? Params : JSON.stringify(Params),
    };
    let response;
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(JAVAURL(endpoint));
    response = await fetch(url, RequestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const getApiClaimSales = async (endpoint) => {
  try {
    var requestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
      redirect: "follow",
    };

    let response = await fetch(SalesReportApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export async function postApiClaimSales(endpoint, data) {
  try {
    let raw = JSON.stringify(data);

    let requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({ json: true }),
      body: raw,
      redirect: "follow",
    };

    let response = await fetch(SalesReportApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const resellerChildById = async (data) => {
  try {
    let payload = JSON.stringify(data);
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);

    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);

    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    var requestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: payload,
    };
    let response = await fetch(
      `${JAVA_ENDPOINT}enrollment-service/stats_queries/api/getHeraricyForResellerId`,
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logout");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const resellerPolicyCount = async (data) => {
  try {
    let payload = JSON.stringify(data);
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);

    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);

    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    var requestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: payload,
    };
    let response = await fetch(
      `${JAVA_ENDPOINT}enrollment-service/stats_queries/api/getCountOfTotalPolicy`,
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logout");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const salesResellerData = async (data) => {
  try {
    let payload = JSON.stringify(data);
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);

    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);

    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }
    var requestOptions = {
      method: "POST",
      headers: MyHeaders,
      body: payload,
    };
    let response = await fetch(
      `${JAVA_ENDPOINT}enrollment-service/stats_queries/api/salesReport`,
      requestOptions
    );
    if (response.status == 401) {
      toast.error("You have been logout");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const inventoryStockInOutUploader = async (data, url) => {
  try {
    // let raw = JSON.stringify(file);
    var formdata = new FormData();
    formdata.append("file", data.file);
    formdata.append("emailRecipients", data.emailRecipients);
    formdata.append("stockType", data.stockType);
    formdata.append("resellerId", data.resellerId);

    var requestOptions = {
      method: "POST",
      headers: await getClaimHeaders({}),
      body: formdata,
      redirect: "follow",
    };
    requestOptions.headers.set("token", `${data.token}`);
    let response = await fetch(ClaimApiURL(url), requestOptions);
    if (response.status == 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      window.location.replace("/logout");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export async function downloadJavaEndPointWithSecreteKey(
  endpoint,
  reportName = "download.xlsx",
  AbortController = null
) {
  try {
    let RequestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(`${JAVA_ENDPOINT}${endpoint}`);
    return await fetch(url, RequestOptions)
      .then(async (response) => {
        const contentType = response.headers.get("content-type");
        if (response.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          return false;
        } else if (contentType === "application/json") {
          const res = await response.json();
          if (res.code === 200) {
            toast.info(res?.message);
          } else {
            toast.error(res?.message);
          }
          return false;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        if (blob) {
          // Create blob link to download
          const urls = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = urls;
          link.setAttribute("download", reportName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          return blob;
        }
      });
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export const enrollmentDocumentGet = async (endpoint) => {
  let RequestOptions = {
    method: "GET",
    headers: await getClaimHeaders({ json: true }),
  };
  return await fetch(`${JAVA_ENDPOINT}${endpoint}`, RequestOptions)
    .then(async (response) => {
      if (response.status === 401) {
        toast.error("You have been logged out.");
        await removeData("is_admin");
        await removeData("access_token", () => {
          AppStore.dispatch({ type: "SIGN_OUT" });
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
        await removeData("auth_token");
      } else return response.blob();
    })
    .then((blob) => blob)
    .catch((error) => console.error(error));
};

export const enrollmentHeader = async () => {
  const auth_token = await getData("auth_token");
  const token = await getData("access_token");
  let encrypToken = getResellerEncryptionToken();
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("auth-token", auth_token);
  myHeaders.append("token", encrypToken);
  myHeaders.append("Authorization", token);
  return myHeaders;
};

export const enrollmentGetApi = async (data) => {
  try {
    const myHeaders = await enrollmentHeader();
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    let response = await fetch(`${JAVA_ENDPOINT}${data}`, requestOptions);
    if (response.status == 401) {
      toast.error("You have been logout");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export const enrollmentPostApi = async (endpoint, data) => {
  let raw = JSON.stringify(data);
  try {
    const myHeaders = await enrollmentHeader();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    let response = await fetch(`${JAVA_ENDPOINT}${endpoint}`, requestOptions);
    if (response.status == 401) {
      toast.error("You have been logout");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
      return false;
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const deleteClaimDoc = async (endpoint) => {
  try {
    var requestOptions = {
      method: "DELETE",
      headers: await getClaimHeaders({}),
      redirect: "follow",
    };

    let response = await fetch(ClaimApiURL(endpoint), requestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
};

export async function downloadClaimExcelFile(
  endpoint,
  reportName = "download.xlsx",
  AbortController = null
) {
  try {
    let RequestOptions = {
      method: "GET",
      headers: await getClaimHeaders({ json: true }),
    };
    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }
    var url = new URL(`${API_CLAIM_ENDPOINT}${endpoint}`);
    return await fetch(url, RequestOptions)
      .then(async (response) => {
        const contentType = response?.headers?.get("content-type");
        if (response?.status == 401) {
          toast.error("You have been logged out.");
          await removeData("is_admin");
          await removeData("access_token", () => {
            AppStore.dispatch({ type: "SIGN_OUT" });
            AppStore.dispatch({ type: "LOADING", loading: false });
          });
          await removeData("auth_token");
          return false;
        } else if (contentType === "application/json") {
          const res = await response.json();
          if (res.code === 200) {
            toast.info(res?.message);
          } else {
            toast.error(res?.message);
          }
          return false;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        if (blob) {
          const urls = window.URL.createObjectURL(
            new Blob([blob], { type: "application/xlsx" })
          );
          const link = document.createElement("a");
          link.href = urls;
          link.setAttribute("download", reportName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return blob;
        }
      });
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function LabTestPostApi(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let RequestOptions = {
      method: "POST",
      headers: await claimHeader({ json: true }),
      body: JSON.stringify(Params),
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(LabTestUrl(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let json = await response.json();

    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function getLabTestReport(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let RequestOptions = {
      method: "GET",
      headers: await claimHeader({ json: false }),
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    response = await fetch(LabTestUrl(endpoint), RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      AppStore.dispatch({ type: "SIGN_OUT" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    let blob = await response.blob();
    return blob;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}

export async function fetchPaytmPaymentGetApi(
  endpoint,
  Params = {},
  AbortController = null
) {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append("Accept", `application/json`);
    MyHeaders.append("Content-Type", `application/json`);
    MyHeaders.append("cache-control", `no-cache`);

    const token = await getData("access_token");
    const auth_token = await getData("auth_token");
    if (token && auth_token) {
      MyHeaders.append("Authorization", `Bearer ${token}`);
      MyHeaders.append("Auth-Token", auth_token);
    }

    let RequestOptions = {
      method: "GET",
      headers: MyHeaders,
    };

    let response;

    if (AbortController !== null) {
      RequestOptions.signal = AbortController.signal;
    }

    var url = new URL(PaytmPaymentApiURL(endpoint));
    Object.keys(Params).forEach((key) =>
      url.searchParams.append(key, Params[key])
    );

    response = await fetch(url, RequestOptions);
    if (response.status === 401) {
      toast.error("You have been logged out.");
      await removeData("is_admin");
      await removeData("access_token", () => {
        AppStore.dispatch({ type: "SIGN_OUT" });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      await removeData("auth_token");
    }
    let json = await response.json();
    checkToken(json);
    return json;
  } catch (e) {
    if (e.name !== "AbortError") {
      return { status: "error", code: 0, message: e };
    } else {
      return { status: "aborted" };
    }
  }
}
