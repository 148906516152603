import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { ApiGet, downloadExcelFile } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { Pagination } from "rsuite";
import { RefreshIcon } from "@heroicons/react/solid";
import { date } from "yup";

export class ResellerAppointmentReport extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      page: 1,
      count: 10,
      employee_id: "",
      status: "",
    },
    employees: [],
    toMaxDate: new Date(),
    status: [
      {
        value: "missed",
        label: "Missed",
      },
      {
        value: "pending",
        label: "Pending",
      },
      {
        value: "completed",
        label: "Completed",
      },
      {
        value: "cancelled",
        label: "Cancelled",
      },
      {
        value: "call_initiated",
        label: "Call Initiated",
      },
    ],
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, employees, toMaxDate, status } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    const tableHeaders = [
      " Reseller Name",
      "Patient Name",
      "Patient Mobile",
      "Doctor Name",
      "Status",
      "Created On",
      "Appointment Date & Time",
      "Doctor Join Time",
      "Doctor Join Time TAT",
      "Prescription Fill Date & Time",
      "Prescription Send Date & Time",
      "Prescription Send TAT (Send time - Appointment time)",
      "Application TAT ",
    ];
    return (
      <>
        {(permissions && permissions?.aiqa_center) ||
        permissions?.appointment_booking ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/"> Back </AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "20%" }} className="float-right">
                  <Button
                    attrs={{ type: "button", onClick: this.downloadClick }}
                    size="md"
                    title={"Download"}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 gap-4">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Status
                </label>
                <select
                  onChange={(e) => {
                    this.onChangeHandler(e, "status");
                    this.onPageChange(1);
                  }}
                  value={params.status}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">All</option>
                  {status.map((option, index) => {
                    return (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>
              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm font-light dark:border-neutral-500">
                      <thead className="border-b font-medium dark:border-neutral-500">
                        <tr>
                          {tableHeaders &&
                            tableHeaders.map((header, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  className="text-xs border-r font-large px-4 py-2 text-center"
                                >
                                  {header}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((item, index) => {
                          return (
                            <tr className="border-b" key={index}>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500 capitalize">
                                {item?.reseller_name}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500 capitalize ">
                                {item?.patient_name}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                {item?.mobile}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500 capitalize">
                                {item?.doctor_name}
                              </td>
                              <td
                                className={`border-r px-4 py-2 font-medium dark:border-neutral-500 capitalize ${item?.status}`}
                              >
                                {item?.status &&
                                item?.status == "call_initiated"
                                  ? "Call Initiated"
                                  : item?.status}
                              </td>
                              <td
                                style={{ width: "120px" }}
                                className="border-r px-4 py-2 font-medium dark:border-neutral-500"
                              >
                                {item?.created_on
                                  ? moment(item?.created_on).format(
                                      "DD-MM-YYYY h:mm A"
                                    )
                                  : "-"}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                <p>
                                  {item?.appointment_date
                                    ? moment(item?.appointment_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </p>
                                <p>
                                  {item?.appointment_time
                                    ? moment(item?.appointment_time, [
                                        "HH:mm",
                                      ]).format("hh:mm A")
                                    : ""}
                                </p>
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                {item?.doctor_join_time
                                  ? moment(item?.doctor_join_time).format(
                                      "hh:mm A"
                                    )
                                  : ""}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                {this.appointmentTat(item)}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                <p>
                                  {item?.prescription_fill_date
                                    ? moment(
                                        item?.prescription_fill_date
                                      ).format("DD-MM-YYYY")
                                    : "-"}
                                </p>
                                <p>
                                  {item?.prescription_fill_time
                                    ? moment(item?.prescription_fill_time, [
                                        "HH:mm",
                                      ]).format("hh:mm A")
                                    : ""}
                                </p>
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                <p>
                                  {item?.prescription_send_date
                                    ? moment(
                                        item?.prescription_send_date
                                      ).format("DD-MM-YYYY")
                                    : "-"}
                                </p>
                                <p>
                                  {item?.prescription_send_time
                                    ? moment(item?.prescription_send_time, [
                                        "HH:mm",
                                      ]).format("hh:mm A")
                                    : ""}
                                </p>
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                {this.tat(item)}
                              </td>
                              <td className="border-r px-4 py-2 font-medium dark:border-neutral-500">
                                {this.applicationTat(item)}
                              </td>
                            </tr>
                          );
                        })}
                        {reports.length === 0 ? (
                          <tr>
                            <td
                              colSpan={tableHeaders.length}
                              height="50"
                              className="text-red-500 font-light text-center"
                            >
                              Your result is empty
                            </td>
                          </tr>
                        ) : (
                          false
                        )}
                      </tbody>
                    </table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.params.page}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to view Appointments Report</h4>
            </div>
          </div>
        )}
      </>
    );
  }

  tat = (item) => {
    if (item.status != "completed") return "-";
    let date1 = moment(
      new Date([item?.appointment_date, item?.appointment_time])
    );
    let date2 = moment(
      new Date([item?.prescription_send_date, item?.prescription_send_time])
    );
    if (date1 >= date2) {
      return "Sent Earlier";
    }
    var duration = moment.duration(date2.diff(date1));
    var days = parseInt(duration.asDays());
    var Hours = parseInt(duration.asHours());
    var Minutes = parseInt(duration.minutes());
    var Seconds = parseInt(duration.seconds());
    let tat = "";
    if (days > 0) {
      tat += days + " Days and ";
    }
    if (Hours > 0) {
      tat += Hours + " Hours and ";
    }
    if (Minutes > 0) {
      tat += Minutes + " Minutes and ";
    }
    if (Seconds > 0) {
      tat += Seconds + " Seconds";
    }
    return tat;
  };

  applicationTat = (item) => {
    if (item.status != "completed") return "-";
    let date1 = moment(new Date([item?.created_on]));
    let date2 = moment(
      new Date([item?.prescription_send_date, item?.prescription_send_time])
    );
    if (date1 >= date2) {
      return "Sent Earlier";
    }
    return this.tatCalculation(date1, date2);
  };
  appointmentTat = (item) => {
    // if (item.status != "completed") return "-";
    let date1 = moment(new Date([item?.created_on]));
    let date2 = moment(new Date([item?.doctor_join_time]));
    if (date1 >= date2) {
      return "Sent Earlier";
    }
    return this.tatCalculation(date1, date2);
  };

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  tatCalculation = (date1, date2) => {
    var duration = moment.duration(date2.diff(date1));
    var days = parseInt(duration.asDays());
    var Hours = parseInt(duration.asHours());
    var Minutes = parseInt(duration.minutes());
    var Seconds = parseInt(duration.seconds());
    let tat = "";
    if (days > 0) {
      tat += days + " Days and ";
    }
    if (Hours > 0) {
      tat += Hours + " Hours and ";
    }
    if (Minutes > 0) {
      tat += Minutes + " Minutes and ";
    }
    if (Seconds > 0) {
      tat += Seconds + " Seconds";
    }
    return tat;
  };

  componentDidMount() {
    this.getReports(this.state.params);
    this.getTeam();
  }

  handleClick = () => {
    this.setState({ params: { ...this.state.params, page: 1 } });
    this.getReports(this.state.params);
  };

  downloadClick = () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      reportType: "download",
      employee_id: this.state.params.employee_id,
    };
    this.DownloadReports(payload);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/reseller-appointment", request);
    if (response.status === "success") {
      if (response?.data?.data) {
        const items = Object.values(response?.data?.data);
        this.setState({ reports: items });
        this.setState({ total: response?.data?.total });
      } else {
        this.setState({ reports: [] });
      }
    } else {
      // this.MessageRef.current.show(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadExcelFile(
      "report/reseller-appointment",
      request,
      "appointments.xlsx"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ResellerAppointmentReportConnect = connect(mapStateToProps)((props) => {
  return <ResellerAppointmentReport {...props} />;
});

export default withRouter(ResellerAppointmentReportConnect);
