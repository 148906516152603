import React, { useEffect, useState } from "react";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";
import { ApiGet, ApiPost } from "../../../../lib/AppHelper";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Button, CheckPicker, Loader } from "rsuite";
import InputGroup from "../../../../components/formikForm/InputGroup";
import InputLabel from "../../../../components/form/InputLabel";
import {
  fieldLengthValidation,
  fieldValidation,
  isValidFileType,
  MAX_FILE_SIZE,
} from "../../common/data/constants";
import InputFile from "../../../../components/formikForm/InputFile";

const AddVendor = ({ setIsModalOpen, fetchData }) => {
  const initialValues = {
    name: "",
    specification: [],
    status: false,
    icon: null,
  };

  const [loading, setLoading] = useState(false);
  const [initalLoading, setInitialLoading] = useState(false);
  const [specification, setSpecification] = useState([]);

  const fetchSpecification = async () => {
    const response = await ApiGet("admin/get-product-specification");
    if (response.status === "success") {
      setSpecification(response?.data);
    } else {
      toast.error(
        response?.message ? response?.message : "Something went wrong"
      );
    }
    setInitialLoading(false);
  };

  const addVendor = async (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    values.specification.forEach((spec) => {
      formData.append("specification[]", spec);
    });
    formData.append("status", values.status);
    // formData.append("icon", values.icon);

    const response = await ApiPost("admin/add-vendor", formData);
    if (response.code == 200) {
      setIsModalOpen(false);
      fetchData();
      toast.success("Vendor request added successfully");
    } else {
      setIsModalOpen(false);
      toast.error(
        response?.message ? response?.message : "Something went wrong"
      );
    }
    setLoading(true);
  };

  const validationSchemaVendor = Yup.object().shape({
    name: Yup.string().required("Vendor name is required"),

    specification: Yup.array()
      .min(1, "Choose atleast one specification")
      .required("Specification is required"),
    // icon: Yup.mixed()
    //   .nullable() // Allow null values (optional)
    //   .test(
    //     "fileFormat",
    //     "Not a valid image type",
    //     (value) => !value || isValidFileType(value.name.toLowerCase(), "image") // Only validate if value exists
    //   )
    //   .test(
    //     "fileSize",
    //     "Max allowed size is 5MB",
    //     (value) => !value || value.size <= MAX_FILE_SIZE // Only validate if value exists
    //   ),
  });

  useEffect(() => {
    setInitialLoading(true);
    fetchSpecification();
  }, []);

  return (
    <>
      {initalLoading ? (
        <ShimmerLoader rows={20} />
      ) : loading ? (
        <div
          className="bg-modalBg"
          style={{ height: loading ? "300px" : "auto" }}
        >
          <Loader
            style={{ color: "#db2228" }}
            size="md"
            center="true"
            speed="normal"
            content="Loading..."
          />
        </div>
      ) : (
        <Formik
          validationSchema={validationSchemaVendor}
          initialValues={initialValues}
          // onSubmit={(values) => {}}
          enableReinitialize
          onSubmit={(values) => {
            addVendor(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputGroup
                label="Vendor Name"
                style={{ fontWeight: "550", fontSize: "12px" }}
                value={values.name}
                max={50}
                name="name"
                requiredMark="true"
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    (e.target.value.length < fieldLengthValidation.name &&
                      fieldValidation.name.test(e.target.value))
                  ) {
                    setFieldValue("name", e.target.value);
                    setFieldTouched("name", true, false);
                  }
                }}
                error={touched.name && errors.name}
              />
              <div className="relative mb-2">
                <div className="flex flex-col">
                  <InputLabel
                    style={{
                      marginBottom: "5px",
                      fontWeight: "550",
                      fontSize: "12px",
                    }}
                    starMark={true}
                    label={"Specification"}
                  />
                  <CheckPicker
                    data={specification}
                    value={values.specification}
                    onSelect={(e) => {
                      setFieldValue(`specification`, e);
                      setFieldTouched("specification", true, false);
                    }}
                    onBlur={() => {
                      setFieldTouched("specification", true);
                    }}
                  />
                </div>

                <div className="text-xs text-red-600">
                  <span>{errors.specification}</span>
                </div>
              </div>

              {/* <InputFile
                name="icon"
                label="Vendor Icon"
                requiredMark={false}
                value={values.icon} // Controlled value from Formik
                accept="image/jpeg, image/jpg"
                onChange={(e) => setFieldValue("icon", e.target.value)}
                // errors={errors.icon}
                // onBlur={() => {
                //   setFieldTouched("icon", true);
                // }}
              /> */}

              <div className="flex justify-end mb-2">
                <Button
                  style={{
                    padding: 10,
                    width: "140px",
                    height: "40px",
                    marginTop: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    backgroundColor: isValid && dirty ? "#db2228" : "#ccc",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    cursor: isValid && dirty ? "pointer" : "not-allowed",
                  }}
                  type="submit"
                  disabled={!isValid || !dirty} // Disable button if form is invalid or unchanged
                >
                  {/* <PlusIcon style={{ marginRight: 3, alignSelf: "center" }} /> */}
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddVendor;
