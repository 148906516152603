import InputArea from "../../../../components/formikForm/InputArea";
import InputGroup from "../../../../components/formikForm/InputGroup";
import InputSelect from "../../../../components/formikForm/InputSelect";
import { memberCoveredOptions, tenureTypeOptions } from "../../common/data";
import {
  fieldLengthValidation,
  fieldValidation,
} from "../../common/data/constants";

export const ProductBasicInfo = ({
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-2">
      <InputGroup
        error={touched?.productName && errors?.productName}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Product Name"
        name="productName"
        value={values?.productName}
        placeHolder="Enter the official product name"
        helperText="Use up to 50 characters. Special characters are not allowed."
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (e.target.value.length < 50 &&
              fieldValidation.name.test(e.target.value))
          ) {
            setFieldValue("productName", e.target.value);
            setFieldTouched("productName", true, false);
          }
        }}
      />
      <InputGroup
        error={touched?.productDisplayName && errors?.productDisplayName}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Product Display Name"
        name="productDisplayName"
        value={values?.productDisplayName}
        placeHolder="Enter the name to be displayed in the app"
        helperText="This is the name visible to customers. Max 50 characters."
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (e.target.value.length < fieldLengthValidation.name &&
              fieldValidation.name.test(e.target.value))
          ) {
            setFieldValue("productDisplayName", e.target.value);
            setFieldTouched("productDisplayName", true, false);
          }
        }}
      />
      <InputSelect
        error={touched?.membersCovered && errors.membersCovered}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Member Covered"
        name={"membersCovered"}
        value={values?.membersCovered}
        helperText="Choose how many members are covered under this product."
        onChange={(e) => {
          setFieldValue(`membersCovered`, e.target.value);
          setFieldTouched("membersCovered", true, false);
        }}
        options={memberCoveredOptions}
      />
      <InputSelect
        error={touched.tenureType && errors.tenureType}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Tenure Type"
        name={"tenureType"}
        value={values?.tenureType}
        helperText="Choose the duration type for the product (e.g., months, years)."
        onChange={(e) => {
          setFieldValue(`tenureType`, e.target.value);
          setFieldTouched("tenureType", true, false);
        }}
        options={tenureTypeOptions}
      />

      <InputGroup
        error={touched.tenure && errors.tenure}
        requiredMark={true}
        disabled={!values?.tenureType}
        label="Tenure"
        name="tenure"
        value={values?.tenure}
        placeHolder="Enter tenure duration (e.g., 12 for 12 months)"
        helperText="The duration for which the product is active."
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (e.target.value.length < fieldLengthValidation.number &&
              fieldValidation.number.test(e.target.value))
          ) {
            setFieldValue("tenure", e.target.value);
            setFieldTouched("tenure", true, false);
          } else if (!values?.tenure) {
            setFieldValue("tenure", 0);
          }
        }}
      />

      <InputSelect
        error={touched?.sellableToIndividual && errors?.sellableToIndividual}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Show in aiqahealth mobile app"
        name={"sellableToIndividual"}
        value={values?.sellableToIndividual}
        helperText="Select 'Yes' to display this product in the mobile app."
        onChange={(e) => {
          setFieldValue(`sellableToIndividual`, e.target.value);
          setFieldTouched("sellableToIndividual", true, false);
        }}
        options={[
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ]}
      />
      <InputGroup
        error={touched.productBasePrice && errors.productBasePrice}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Product Base Price"
        name="productBasePrice"
        value={values?.productBasePrice}
        placeHolder="Enter base price (₹)"
        helperText="Base price before applying GST."
        onChange={(e) => {
          if (
            e.target.value === "" ||
            fieldValidation.number.test(e.target.value)
          ) {
            if (e.target.value === "") {
              setFieldValue("productMRP", 0);
            }
            setFieldValue("productBasePrice", Number(e.target.value));
            setFieldTouched("productBasePrice", true, false);
            if (e.target.value > 0) {
              let productMRP =
                parseInt((e.target.value * values.gst || 1) / 100) +
                parseInt(e.target.value);
              setFieldValue("productMRP", Math.round(productMRP));
            }
          } else if (!values.productBasePrice) {
            setFieldValue("productBasePrice", "");
          }
        }}
      />
      <InputGroup
        error={touched.gst && errors.gst}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="GST"
        name="gst"
        value={values?.gst}
        placeHolder="Enter GST percentage (e.g., 18)"
        helperText="Applicable GST in percentage."
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (e.target.value.length < fieldLengthValidation.number &&
              fieldValidation.gst.test(e.target.value))
          ) {
            setFieldValue("gst", Number(e.target.value));
            setFieldTouched("gst", true, false);
            const gstPercentage = parseFloat(e.target.value); // Convert to a number
            const basePrice = parseFloat(values.productBasePrice); // Convert to a number

            if (basePrice > 0 && gstPercentage > 0) {
              let productMRP = Math.round(
                basePrice + (basePrice * gstPercentage) / 100
              );
              setFieldValue("productMRP", productMRP); // Keep two decimal places
            } else {
              setFieldValue("productMRP", basePrice || 0);
            }
          }
        }}
      />
      <InputGroup
        error={touched.productMRP && errors.productMRP}
        style={{ fontWeight: "550", fontSize: "12px" }}
        // requiredMark={true}
        label="Product MRP"
        name="productMRP"
        disabled={true}
        value={values?.productMRP}
        placeHolder="Auto-calculated MRP"
        helperText="MRP is calculated based on base price + GST."
      />

      <InputSelect
        error={touched?.productTypeCategory && errors?.productTypeCategory}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Product Type Category"
        name={"productTypeCategory"}
        value={values?.productTypeCategory}
        helperText="Choose the category that best describes this product."
        onChange={(e) => {
          setFieldValue(`productTypeCategory`, e.target.value);
          setFieldTouched("productTypeCategory", true, false);
        }}
        options={[
          { label: "MFI", value: "MFI" },
          { label: "Other", value: "OTHERS" },
        ]}
      />
      <InputSelect
        error={touched.paymentPartner && errors.paymentPartner}
        style={{ fontWeight: "550", fontSize: "12px" }}
        requiredMark={true}
        label="Payment Partner"
        name={"paymentPartner"}
        value={values?.paymentPartner}
        helperText="Choose the payment provider for transactions."
        onChange={(e) => {
          setFieldValue(`paymentPartner`, e.target.value);
          setFieldTouched("paymentPartner", true, false);
        }}
        options={[
          { label: "DOC", value: "DOC" },
          { label: "HOSPIDO", value: "HOSPIDO" },
        ]}
      />

      <InputArea
        error={touched?.productDescription && errors?.productDescription}
        style={{ fontWeight: "550", fontSize: "12px" }}
        value={values?.productDescription}
        label={"Product Description"}
        onChange={(e) => {
          if (e.target.value === "" || e.target.value.length < 150)
            setFieldValue(`productDescription`, e.target.value);
        }}
        placeHolder="Enter description"
        requiredMark={true}
      />
    </div>
  );
};
