import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Function to generate chart data
const generateChartData = (
  data,
  borderColor,
  chartRef,
  topBaseGraphColor,
  bottomBaseGraphColor
) => {
  const labels = data.map((entry) => entry.duration); // Set `duration` as X-axis labels
  const values = data.map((entry) => entry.value); // Set `policyCount` as Y-axis values

  const dataset = {
    labels, // X-axis labels
    datasets: [
      {
        label: "Policy Count", // Dataset label
        data: values, // Y-axis values
        borderColor: borderColor || "rgba(50, 185, 101, 0.9)",
        borderWidth: 2,
        pointRadius: 3,
        backgroundColor: "transparent",
        borderDash: [5, 5], // Dashed line style
        fill: true,
      },
    ],
  };

  // Apply gradient if canvas is available
  if (chartRef?.current) {
    dataset.datasets[0].backgroundColor = createGradient(
      chartRef.current.ctx,
      topBaseGraphColor,
      bottomBaseGraphColor
    );
  }

  return dataset;
};

// Function to create gradient for graph background
const createGradient = (ctx, topBaseGraphColor, bottomBaseGraphColor) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 150);
  gradient.addColorStop(0, topBaseGraphColor || "rgba(50, 185, 101, 0.9)");
  gradient.addColorStop(1, bottomBaseGraphColor || "rgba(50, 185, 101, 0)");
  return gradient;
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => `Values: ${tooltipItem.raw}`,
      },
    },
  },
  scales: {
    x: {
      title: {
        display: false,
        text: "Duration", // X-axis label
      },
    },
    y: {
      title: {
        display: false,
        text: "Values", // Y-axis label
      },
      beginAtZero: true,
    },
  },
};

const Graph = ({
  topBaseGraphColor,
  bottomBaseGraphColor,
  borderColor,
  data = [],
}) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });

  useEffect(() => {
    if (!chartRef.current) return;

    // Generate chart data dynamically
    const newChartData = generateChartData(
      data,
      borderColor,
      chartRef,
      topBaseGraphColor,
      bottomBaseGraphColor
    );

    setChartData(newChartData);
  }, [data]);

  return (
    <div>
      <Line data={chartData} options={options} ref={chartRef} height={170} />
    </div>
  );
};

export default Graph;
