import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { ResellerReports } from "../NewHome/common/data";

export class Report extends Component {
  state = {
    permissions: false,
  };

  render() {
    const { permissions } = this.props.user;
    return (
      <>
        <AppBackButton to="/">Back</AppBackButton>
        <Grid container spacing={2}>
          {[...ResellerReports]
            .sort((a, b) => a.label.localeCompare(b.label)) // Sorting before mapping
            .map((item, index) => {
              const reportPermission = item?.permission;
              let hasPermission = reportPermission?.length
                ? reportPermission?.some((perm) => permissions?.[perm])
                : true;

              return (
                hasPermission && (
                  <Grid key={index} item xs={6} md={3}>
                    <Link to={item.pathname}>
                      <Card style={styles.parentDiv}>
                        <CardContent>
                          <p style={{ fontSize: 14, textAlign: "center" }}>
                            {item?.label}
                          </p>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                )
              );
            })}
          {/* {permissions?.business_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/business">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Business Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.claim_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/reimbursements">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Reimbursements Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.commission_due_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/due-commissions">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Commission Due Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.commission_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/commissions">
                <Card
                  style={{
                    borderRadius: "10px",
                    height: "100px",
                    border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Commission Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.customer_lead_capture ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/lead-capture">
                <Card style={styles.parentDiv}>
                  <CardContent style={{ textAlign: "center" }}>
                    <p style={{ fontSize: 14 }}>Customer Lead Capture Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.admin_dashboard ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/reseller-sales">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Dashboard Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.aiqa_center || permissions?.appointment_booking ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/reseller-appointment">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Reseller Appointment Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.data_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/data-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Reseller Data Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.reseller_product_wise_sale_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/reseller-product-wise-sales-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>
                      Reseller Product Wise Sales Report
                    </p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.reseller_lead_capture ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/reseller-lead-capture">
                <Card style={styles.parentDiv}>
                  <CardContent style={{ textAlign: "center" }}>
                    <p style={{ fontSize: 14 }}>Reseller Lead Capture Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.reseller_sales_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/reseller-sales-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Reseller Sales Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.label_sales_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/level-sales-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Sales Report(L1 & L2)</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.policy_expire_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/policy-expire">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Subscription Expire Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.product_wise_sales_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/product-wise-sales-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Product Wise Sales Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.sales_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/sales">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Sales Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}

          {permissions?.subscription_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/subscriptions">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Subscriptions Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.team_performance_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/emp-sales">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Team Performance Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.subscription_source_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/subscription-source-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Subscription Source Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.update_pm_jay_information ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/update-customer-info">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Update Customer Information</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.upload_date_team_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/upload-date-team-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Upload Date Team Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.upload_date_report ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/upload-date-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Upload Date Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.hierarchy_sales_report_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/hierarchy-sales-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Hierarchy Sales Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.insurance_reseller_view ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/insurance-view">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Insurance View Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.report_master ? (
            <Grid item xs={6} md={3}>
              <Link to="/reports/report-master">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Report Master</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.action_claim_report && (
            <Grid item xs={6} md={3}>
              <Link to="/reports/action-claim-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Action Claim Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          )}
          {permissions?.report_360 && (
            <Grid item xs={6} md={3}>
              <Link to="/reports/report360">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Report 360</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          )}
          {permissions?.insurance_format_report && (
            <Grid item xs={6} md={3}>
              <Link to="/reports/insurance-format-report">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Insurance Format Report</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          )} */}
        </Grid>
      </>
    );
  }
}

const styles = {
  parentDiv: {
    borderRadius: "10px",
    height: "100px",
    border: "1px solid red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ReportConnect = connect(mapStateToProps)((props) => {
  return <Report {...props} />;
});

export default withRouter(ReportConnect);
