import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "../../components/Button";
import Cards from "./Cards";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import uploadCard from "../../assets/sidebar/uploadCard.png";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import GraphCard from "./DataGraphs/GraphCard";
import RecentUpdate from "./RecentUpdate";
import {
  getResellerId,
  GetUserDashboardData,
  LabTestPostApi,
  JavaApiPost,
  JavaApiGet,
} from "../../lib/AppHelper";
import {
  DashboardStatsRetail,
  getNumber,
  DashboardGraphFilter,
  getGraphDateRange,
  getSearchByOptions,
  formatDate,
  UpdateStats,
  RecentUpdatesPermissions,
} from "./common/data";
import HomeSearchBar from "./HomeSearchBar/HomeSearchBar";
import moment from "moment";
import { WeekDates } from "../admin/components/constant";
import { CustomSelect } from "./common/CustomSelect";
import { CustomInputDate } from "./common/CustomInputDate";
import { UploadIcon } from "../../Layout/SidebarIcons";
import { Tooltip } from "@mui/material";

const NewHome = ({ user }) => {
  const MessageRef = useRef(null);
  const prevFiltersRef = useRef(null);
  const [stats, setStats] = useState(DashboardStatsRetail);
  const [updatesData, setUpdatesData] = useState(UpdateStats);
  const [subscriptionData, setSubscriptionData] = useState({
    label: "Subscriptions",
    value: 0,
    loader: false,
    type: "yearly",
    data: [],
    permission: ["sale_month_year_wise"],
    redirect: {
      to: "/subscriptions",
    },
    styles: {
      topBaseGraphColor: "rgba(50, 185, 101, 0.9)",
      bottomBaseGraphColor: "rgba(50, 185, 101, 0)",
      borderColor: "rgba(50, 185, 101, 1)",
    },
    description: "Subscription data",
  });
  const [salesData, setSalesData] = useState({
    label: "Sales",
    value: 0,
    loader: false,
    type: "yearly",
    data: [],
    permission: ["sale_month_year_wise"],
    redirect: {
      to: "/subscriptions",
    },
    styles: {
      topBaseGraphColor: "rgba(204, 160, 239, 0.4)",
      bottomBaseGraphColor: "rgba(204, 160, 239, 0)",
      borderColor: "rgba(204, 160, 239, 1)",
    },
    description: "Sales data",
  });

  const [filter, setFilter] = useState({
    resellerId: user?.id,
    duration: "thisMonth",
    from: WeekDates("month")[0],
    to: WeekDates("month")[1],
    perspective: "weekly",
  });

  const fetchData = async (key, endpoint) => {
    setStats((prev) => ({ ...prev, [key]: { ...prev[key], loader: true } }));
    const response = await GetUserDashboardData(endpoint);
    if (response.code === 200) {
      setStats((prev) => ({
        ...prev,
        [key]: { ...prev[key], value: response.responseObject, loader: false },
      }));
    } else {
      MessageRef.current?.show(response.message);
      setStats((prev) => ({ ...prev, [key]: { ...prev[key], loader: false } }));
    }
  };

  const getLabTestCount = async () => {
    setStats((prev) => ({
      ...prev,
      labTestCount: { ...prev.labTestCount, loader: true },
    }));
    let payload = { startIndex: 0, endIndex: 10, resellerId: getResellerId() };
    const res = await LabTestPostApi(
      `labtest/fetch_labtest_with_child/ALL`,
      payload
    );
    setStats((prev) => ({
      ...prev,
      labTestCount: {
        ...prev.labTestCount,
        value: res.count || 0,
        loader: false,
      },
    }));
  };

  const getPolicyAmount = async (filters) => {
    setSalesData((prevState) => ({
      ...prevState,
      loader: true,
    }));

    let payload = {
      resellerId: user?.id,
      startDate: filters.from,
      endDate: filters.to,
      type: filters.perspective,
    };

    try {
      const response = await JavaApiPost(
        "enrollment-service/policy/getResellerPolicyAmount",
        payload
      );

      setSalesData((prevState) => ({
        ...prevState,
        value: response?.count,
        data: response?.responseObject,
      }));
    } catch (error) {
      setSubscriptionData((prevState) => ({
        ...prevState,
        loader: false,
      }));
      setSalesData((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
    setSubscriptionData((prevState) => ({
      ...prevState,
      loader: false,
    }));
    setSalesData((prevState) => ({
      ...prevState,
      loader: false,
    }));
  };

  const getPolicyCount = async (filters) => {
    setSubscriptionData((prevState) => ({
      ...prevState,
      loader: true,
    }));

    let payload = {
      resellerId: user?.id,
      startDate: filters.from,
      endDate: filters.to,
      type: filters.perspective,
    };

    try {
      const response = await JavaApiPost(
        "enrollment-service/policy/getResellerPolicyCount",
        payload
      );

      setSubscriptionData((prevState) => ({
        ...prevState,
        value: response?.count,
        data: response?.responseObject,
      }));
    } catch (error) {
      setSubscriptionData((prevState) => ({
        ...prevState,
        loader: false,
      }));
      setSalesData((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
    setSubscriptionData((prevState) => ({
      ...prevState,
      loader: false,
    }));
    setSalesData((prevState) => ({
      ...prevState,
      loader: false,
    }));
  };

  const getUpdates = async (type) => {
    const response = await JavaApiGet(
      `enrollment-service/api/get-recent-task-notifications/${user.id}/${type}`
    );
    let result = [];
    if (response.code == 200) {
      let data = response?.responseObject;
      result = Object.entries(data).map(([key, value]) => ({
        status: key,
        value: value,
      }));
    } else {
      result = [];
    }
    if (type == "Reimbursement") {
      setUpdatesData((prev) => ({
        ...prev,
        reimbursements: {
          ...prev.reimbursements,
          loader: false,
          notificationData: result,
        },
      }));
    } else if (type == "Data_Uploads") {
      setUpdatesData((prev) => ({
        ...prev,
        dataUpload: {
          ...prev.dataUpload,
          loader: false,
          notificationData: result,
        },
      }));
    }
  };

  const fetchGraphData = (filters) => {
    if (JSON.stringify(filters) === JSON.stringify(prevFiltersRef.current)) {
      return;
    }
    prevFiltersRef.current = filters;
    getPolicyCount(filters);
    getPolicyAmount(filters);
  };

  const fetchDashboardStats = () => {
    fetchData("salesAmount", "enrollment-service/stats_queries/getSaleAmount");
    if (user?.permissions?.unpaid_policy_view) {
      fetchData(
        "unsoldPolicy",
        "enrollment-service/stats_queries/getUnsoldPolicy"
      );
    }
    if (user?.permissions?.application_view) {
      fetchData(
        "activePolicy",
        "enrollment-service/stats_queries/getActivePolicy"
      );
    }
    if (user?.permissions?.expire_policy_view) {
      fetchData(
        "expirePolicy",
        "enrollment-service/stats_queries/expirePolicy"
      );
    }
    if (user?.permissions?.expiring_soon_policy_view) {
      fetchData(
        "expiringSoonPolicy",
        "enrollment-service/stats_queries/expiringSoonPolicy"
      );
    }
    if (user?.permissions?.lab_test_subscription) {
      getLabTestCount();
    }
    if (user?.permissions?.sale_month_year_wise) {
      fetchGraphData(filter);
    }
    // For the recent updates section

    //reimbursements
    if (
      user?.permissions?.claim_view ||
      user?.permissions?.partner_wise_reimbursement_view
    ) {
      setUpdatesData((prev) => ({
        ...prev,
        reimbursements: {
          ...prev.reimbursements,
          loader: true,
        },
      }));
      getUpdates("Reimbursement");
    }
    //data uploads
    if (
      user?.permissions?.excel_uploader_history_table ||
      user?.permissions?.group_upload ||
      user?.permissions?.policy_uploader ||
      user?.permissions?.credit_upload ||
      user?.permissions?.emi_product_uploader
    ) {
      setUpdatesData((prev) => ({
        ...prev,
        dataUpload: {
          ...prev.dataUpload,
          loader: true,
        },
      }));
      getUpdates("Data_Uploads");
    }
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <div className="px-2 pt-0 pb-2">
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 w-full items-center justify-end">
          {user?.permissions?.universal_search && (
            <div className="flex-1">
              <HomeSearchBar />
            </div>
          )}
          {user?.permissions?.application_add && (
            <Tooltip title="Add member" placement="bottom" arrow>
              <Link to="/subscribe">
                <Button
                  size="xs"
                  display="inline-block"
                  title=""
                  className="sm:px-6 self-center rounded-full p-2"
                >
                  <span className="hidden sm:inline text-xs">
                    Add New Member
                  </span>
                  <span className="sm:hidden text-lg">+</span>
                </Button>
              </Link>
            </Tooltip>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.keys(stats).map((key) => {
              const statPermission = stats[key]?.permission;
              let hasPermission = statPermission.length
                ? statPermission?.some((perm) => user?.permissions?.[perm])
                : true;

              return (
                hasPermission && (
                  <Cards
                    primaryText={stats?.[key].label}
                    loader={stats?.[key].loader}
                    secondaryText={getNumber(stats?.[key]?.value)}
                    icon={<ArrowForwardOutlinedIcon sx={{ fontSize: 12 }} />}
                    redirect={stats?.[key]?.redirect}
                    iconText={stats?.[key]?.redirect?.iconText}
                    iconColor="rgba(42, 181, 20, 1)"
                  />
                )
              );
            })}
            <div className="col-span-1">
              <Cards
                primaryText="Upload data"
                icon={<CloudDownloadOutlinedIcon />}
                redirect={{ to: "", iconText: "Upload" }}
                Image={uploadCard}
                ImageHelperText={
                  <span
                    style={{
                      margin: "auto",
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                    onClick={() => console.log("ppppppp")}
                    className="flex justify-center items-center gap-1 text-xs font-semibold text-red-600"
                  >
                    Upload <UploadIcon />
                  </span>
                }
                iconColor="#Db2228"
              />
            </div>

            {user?.permissions?.recent_updates &&
              RecentUpdatesPermissions.some(
                (perm) => user?.permissions?.[perm]
              ) && (
                <div className="col-span-1 md:col-span-2">
                  <RecentUpdate
                    data={updatesData}
                    userPermissions={user?.permissions}
                  />
                </div>
              )}
          </div>

          {user?.permissions?.sale_month_year_wise && (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
              <CustomSelect
                label="Date Filter"
                value={filter?.duration}
                onChange={(e) => {
                  let value = e?.value;
                  let dates;
                  if (value === "custom") {
                    let type = getSearchByOptions(
                      WeekDates("month")[0],
                      WeekDates("month")[1]
                    );
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: WeekDates("month")[0],
                        to: WeekDates("month")[1],
                        duration: value,
                        perspective: "weekly",
                      };
                      fetchGraphData(data);
                      return data;
                    });
                    return;
                  } else if (value === "last3Years") {
                    dates = getGraphDateRange("last3Years");
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  } else if (value === "thisYear") {
                    dates = getGraphDateRange("thisYear");
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  } else if (value === "thisMonth") {
                    dates = getGraphDateRange("thisMonth");

                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  } else if (value === "last1Month") {
                    dates = getGraphDateRange("last1Month");
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  } else if (value === "last3Months") {
                    dates = getGraphDateRange("last3Months");
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  } else if (value === "last6Months") {
                    dates = getGraphDateRange("last6Months");
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  } else if (value === "last1Year") {
                    dates = getGraphDateRange("last1Year");
                    setFilter((state) => {
                      const data = {
                        ...state,
                        from: dates.startDate,
                        to: dates.endDate,
                        duration: value,
                        perspective: dates.type,
                      };
                      fetchGraphData(data);
                      return data;
                    });
                  }
                }}
                data={DashboardGraphFilter}
              />
              {filter.duration === "custom" && (
                <CustomInputDate
                  label="From"
                  style={{ fontWeight: "bold" }}
                  value={filter.from}
                  minDate={
                    new Date(moment().subtract(5, "years").format("YYYY-MM-DD"))
                  }
                  maxDate={new Date(moment().format("YYYY-MM-DD"))}
                  placeholder="From"
                  attrs={{
                    onChange: (e) => {
                      const newFromDate = new Date(e);
                      const currentDate = new Date(
                        moment().format("YYYY-MM-DD")
                      );
                      let type = getSearchByOptions(newFromDate, currentDate);
                      if (newFromDate > new Date(filter.to)) {
                        setFilter((state) => {
                          const data = {
                            ...state,
                            from: formatDate(newFromDate),
                            to: formatDate(currentDate),
                            perspective: type.searchBy?.[0]?.value,
                          };
                          fetchGraphData(data);
                          return data;
                        });
                      } else {
                        setFilter((state) => {
                          const data = {
                            ...state,
                            from: formatDate(newFromDate),
                            perspective: type?.[0]?.value,
                          };
                          fetchGraphData(data);
                          return data;
                        });
                      }
                    },
                  }}
                />
              )}
              {filter.duration === "custom" && (
                <CustomInputDate
                  label="To"
                  style={{ fontWeight: "bold" }}
                  placeholder="To"
                  maxDate={new Date(moment().format("YYYY-MM-DD"))}
                  minDate={new Date(filter.from)}
                  attrs={{
                    onChange: (e) => {
                      let type = getSearchByOptions(
                        new Date(filter.from),
                        new Date(e)
                      );
                      setFilter((state) => {
                        const data = {
                          ...state,
                          to: formatDate(new Date(e)),
                          perspective: type?.[0]?.value,
                        };
                        fetchGraphData(data);
                        return data;
                      });
                    },
                  }}
                  value={new Date(filter.to)}
                />
              )}
              {filter.duration === "custom" && (
                <div className="self-end">
                  <CustomSelect
                    label="Perspective"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setFilter((state) => {
                        const data = {
                          ...state,
                          perspective: e?.value,
                        };
                        fetchGraphData(data);
                        return data;
                      });
                    }}
                    value={filter.perspective}
                    data={getSearchByOptions(
                      new Date(filter.from),
                      new Date(filter.to)
                    )}
                  />
                </div>
              )}
            </div>
          )}

          {user?.permissions?.sale_month_year_wise && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <GraphCard
                loader={subscriptionData.loader}
                graphCard={subscriptionData}
                isAmount={false}
              />

              <GraphCard
                loader={salesData.loader}
                graphCard={salesData}
                isAmount={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(NewHome));
