import React, { useEffect, useRef } from "react";
import { Modal } from "rsuite";
import NotificationSound from "../../../../assets/ringtone/bubbleSound.mp3";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";

const CustomModal = ({ isOpen, onClose, title, loading = false, body }) => {
  const modalRef = useRef(null);

  const playSound = () => {
    const audio = new Audio(NotificationSound);
    audio.play().catch((err) => console.error("Audio playback error:", err));
  };

  const handleBackdropClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      if (isOpen) {
        // playSound();
      } else {
        onClose();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleBackdropClick);
      return () => {
        document.removeEventListener("mousedown", handleBackdropClick);
      };
    }
  }, [isOpen]);
  return (
    <Modal
      size="full"
      open={isOpen}
      onClose={onClose}
      overflow={true}
      backdrop={"static"}
      onBackdropClick={handleBackdropClick}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <div ref={modalRef}>
        <Modal.Body>{loading ? <ShimmerLoader rows={22} /> : body}</Modal.Body>
      </div>
    </Modal>
  );
};

export default CustomModal;
