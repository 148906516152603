import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import { ApiGet, setMessage } from "../../lib/AppHelper";
import moment from "moment";
import Button from "../../components/Button";
import InputDate from "../../components/form/InputDate";
import { CSVLink } from "react-csv";
import { Pagination } from "rsuite";
import { RefreshIcon } from "@heroicons/react/outline";
export class LeadCaptureReport extends Component {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.getReports = this.getReports.bind(this);
  }
  MessageRef = React.createRef();

  state = {
    reports: [],
    headers: [
      { label: "Member Name", key: "name" },
      { label: "Member Email", key: "email" },
      { label: "Member Mobile", key: "mobile" },
      { label: "Product Name", key: "product_master.product_name" },
      { label: "Created At", key: "created_at" },
      { label: "Gender", key: "gender" },
      { label: "Pin Code", key: "pin_code" },
    ],
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      page: 1,
      count: 10,
      status: "",
      all: false,
      employee_id: "",
    },
    employees: [],
    toMaxDate: new Date(),
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["status"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, count, employees, headers, toMaxDate } =
      this.state;
    const { user } = this.props;
    const { permissions } = user;
    return (
      <>
        {permissions && permissions?.customer_lead_capture ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
              <div className="text-right flex-col">
                <div className="float-right flex space-x-2">
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <span className="mr-2">Show All</span>
                    <input
                      onChange={(e) => {
                        this.setState({
                          params: {
                            ...this.state.params,
                            all: e.target.checked,
                          },
                        });
                      }}
                      type="checkbox"
                      className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    />
                  </div>
                  <button
                    style={{ fontSize: "12px" }}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                  >
                    <CSVLink
                      data={reports}
                      headers={headers}
                      filename={"leadReports.csv"}
                    >
                      Download
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 grid-cols-2 gap-x-2">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <>
                        <option value={option.id} key={index}>
                          {option.name} ({option.mobile})
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>

              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table className="min-w-full border" id="table-to-xls">
                      <thead className="border-b">
                        <tr>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Product
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Member Name
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Phone no
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Email
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Created At
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Gender
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center w-full">
                            Pincode
                          </th>
                        </tr>
                      </thead>
                      {reports.map((record, index) => {
                        return (
                          <tbody key={index}>
                            <tr className="border-b" key={index}>
                              <td
                                style={{ maxWidth: "200px" }}
                                className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                {record?.product_master?.product_display_name}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.name}
                              </td>
                              <td className="text-sm capitalize text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.mobile}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.email}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.created_at}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.gender}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.pin_code}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                      {reports.length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            height="50"
                            className="text-red-500 font-light text-center"
                          >
                            Your result is empty
                          </td>
                        </tr>
                      ) : (
                        false
                      )}
                    </table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.params.page}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>
                You do not have permission to view Customer Lead Capture Report
              </h4>
            </div>
          </div>
        )}
      </>
    );
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  onFilterChange = (e) => {
    this.setState(
      { params: { ...this.state.params, all: e.target.value } },
      () => {
        this.getReports(this.state.params);
      }
    );
  };

  componentDidMount() {
    this.getReports(this.state.params);
    this.getTeam();
  }
  handleClick = () => {
    const { params } = this.state;
    this.getReports(params);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await ApiGet("reseller/customer-leads", request)
      .then((response) => {
        if (response.data) {
          const items = Object.values(response.data.data);
          this.setState({ reports: items, total: response.data.total });
          if (this.state.params.all == true) {
            this.setState({ layout: ["total"] });
            this.setState({ limit: response.data.total });
          } else {
            this.setState({ layout: ["total", "-", "pager", "skip"] });
            this.setState({ limit: 10 });
          }
        } else {
          this.state.reports = [];
        }
        const me = ApiGet("me");
        if (me.status === "success") {
          AppStore.dispatch({ type: "USER_UPDATED", user: me.data });
        }
      })
      .catch((error) => {
        this.state.reports = [];
        setMessage("somthing went wrong", "error", () => {});
      });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const LeadCaptureReportConnect = connect(mapStateToProps)((props) => {
  return <LeadCaptureReport {...props} />;
});

export default withRouter(LeadCaptureReportConnect);
