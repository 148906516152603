import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import InputGroup from "../../../../../components/formikForm/InputGroup";
import {
  fieldLengthValidation,
  fieldValidation,
} from "../../../common/data/constants";

const SlabSubProduct = ({ title, slabData, keyField, setFieldValue }) => {
  const [slabs, setSlabs] = useState([]);
  const [open, setOpen] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (slabData) {
      const formattedSlabs = Object.keys(slabData)
        .filter((key) => key.includes("_days"))
        .map((key, index) => {
          const [min, max] = slabData[key]
            ? slabData[key].split("-")
            : ["", ""];
          return {
            min: min || "",
            max: max || "",
            amount: slabData[key.replace("_days", "_amount")] || "",
            error: "",
            isDisabled: index !== 0, // Disable all slabs except the first initially
          };
        });

      // Apply auto-fill logic for min/max and enabling next slabs
      const updatedSlabs = autoFillNextSlab(validateSlabs(formattedSlabs));

      setSlabs(updatedSlabs);
    }
  }, [slabData]);

  const handleChange = (index, field, value) => {
    let updatedSlabs = [...slabs];

    updatedSlabs = updatedSlabs.map((slab, i) => {
      if (i === index) {
        let newMin = slab.min;
        let newMax = slab.max;

        if (field === "amount") {
          return { ...slab, amount: value };
        }

        if (field === "max") {
          newMax = value;
        }

        return { ...slab, min: newMin, max: newMax };
      }
      return slab;
    });

    updatedSlabs = validateSlabs(updatedSlabs);
    updatedSlabs = autoFillNextSlab(updatedSlabs);

    const slabData = {};
    updatedSlabs.forEach((item, i) => {
      slabData[`${keyField}${i + 1}_days`] = `${item.min}-${item.max}`;
      slabData[`${keyField}${i + 1}_amount`] = item.amount || "";
    });

    setSlabs(updatedSlabs);
    setFieldValue(slabData);
  };

  // Auto-fill next slab's min only when its own max is set
  const autoFillNextSlab = (updatedSlabs) => {
    return updatedSlabs.map((slab, i) => {
      if (i === 0) {
        slab.min = "1"; // First slab always starts at 1
        slab.isDisabled = false;
      } else {
        const prevSlabMax = updatedSlabs[i - 1]?.max;
        const prevSlabValid = prevSlabMax && Number(prevSlabMax) >= 1;

        if (prevSlabValid) {
          slab.min = String(Number(prevSlabMax) + 1);
          slab.isDisabled = false;
        } else {
          slab.min = "";
          slab.max = "";
          slab.amount = "";
          slab.isDisabled = true;
        }
      }
      return slab;
    });
  };

  // Validate slabs
  const validateSlabs = (updatedSlabs) => {
    return updatedSlabs.map((slab) => {
      if (slab.max && Number(slab.max) <= Number(slab.min)) {
        slab.error = `Max must be greater than Min (${slab.min})`;
      } else {
        slab.error = "";
      }
      return slab;
    });
  };

  return (
    <div className="col-span-3">
      <div className="overflow-x-auto mt-4">
        <div className="flex justify-between items-center">
          <h3 className="mb-3 ml-2 text-gray-700 text-sm font-medium">
            Slab details for {title} (Please enter the max days)
          </h3>
          <div className="flex gap-1">
            <InputGroup
              label="Total Days"
              placeHolder="Enter total slab days"
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  (e.target.value.length < 7 &&
                    fieldValidation.number.test(e.target.value))
                ) {
                  handleChange(0, "max", 0);
                  setTotalDays(e.target.value);
                }
              }}
              max={7}
            />
            <InputGroup
              label="Total Amount"
              placeHolder="Enter total slab amount"
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  (e.target.value.length < 7 &&
                    fieldValidation.number.test(e.target.value))
                ) {
                  handleChange(0, "amount", 0);
                  setTotalAmount(e.target.value);
                }
              }}
              max={7}
            />
          </div>
        </div>
        <table className="min-w-full border border-slate-400">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="border border-slate-300 text-xs font-medium px-4 py-2 text-center">
                Slab
              </th>
              <th className="border border-slate-300 text-xs font-medium px-4 py-2 text-center">
                Slab Days
              </th>
              <th className="border border-slate-300 text-xs font-medium px-4 py-2 text-center">
                Amount (per-day)
              </th>
              <th className="border border-slate-300 text-xs font-medium px-4 py-2 text-center">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {slabs.map((slab, index) => (
              <tr key={index} className="border border-slate-300">
                <td className="border border-slate-300 text-sm text-gray-900 text-center">
                  {index + 1}
                </td>

                <td className="border border-slate-300 text-sm text-gray-900 p-1 text-center relative">
                  <div className="flex items-center gap-1 justify-center ">
                    <input
                      type="number"
                      value={slab.min}
                      className="text-center border rounded-lg p-1 w-16 bg-gray-200"
                      disabled
                      placeholder="0"
                    />
                    <span>-</span>
                    <div className="relative flex items-center">
                      <input
                        value={slab.max}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === "" ||
                            (inputValue.length < 5 &&
                              fieldValidation.number.test(inputValue))
                          ) {
                            if (totalDays) {
                              // Sum of previous slabs' max values
                              const previousMaxSum = slabs
                                .slice(0, index)
                                .reduce(
                                  (sum, slab) => sum + Number(slab.max || 0),
                                  0
                                );

                              // Calculate the maximum allowed value for the current slab
                              const maxAllowed = totalDays - previousMaxSum;

                              if (inputValue <= maxAllowed) {
                                handleChange(index, "max", e.target.value);
                              }
                            } else {
                              handleChange(index, "max", e.target.value); // No totalDays set, no restriction
                            }
                          }
                        }}
                        className={`text-center border rounded-lg p-1 w-16 ${
                          slab.error ? "border-red-500" : "border-gray-300"
                        } ${slab.isDisabled && "bg-gray-200"}`}
                        disabled={slab.isDisabled}
                        placeholder="0"
                      />
                      {slab.error && (
                        <div className="absolute -right-4">
                          {open === index && (
                            <div
                              onMouseEnter={() => setOpen(index)}
                              onMouseLeave={() => setOpen(false)}
                              className="bg-red-600 -top-10 left-4 absolute text-white text-sm p-3 rounded-md flex items-start gap-2 min-w-max"
                            >
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="text-white"
                              />
                              <span>{slab.error}</span>
                            </div>
                          )}
                          <FontAwesomeIcon
                            onMouseEnter={() => setOpen(index)}
                            onMouseLeave={() => setOpen(false)}
                            icon={faExclamationCircle}
                            className="text-red-500"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </td>

                <td className="border border-slate-300 text-sm text-gray-900 text-center">
                  <input
                    value={slab.amount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        inputValue === "" ||
                        (inputValue.length < 7 &&
                          fieldValidation.number.test(inputValue))
                      ) {
                        const slabDays =
                          slab.min &&
                          slab.max &&
                          Number(slab.max) >= Number(slab.min)
                            ? Number(slab.max) - Number(slab.min) + 1
                            : 0;

                        if (totalAmount) {
                          // Calculate total amount of previous slabs
                          const previousTotalAmount = slabs
                            .slice(0, index)
                            .reduce((sum, prevSlab) => {
                              const days =
                                prevSlab.min && prevSlab.max
                                  ? Number(prevSlab.max) -
                                    Number(prevSlab.min) +
                                    1
                                  : 0;
                              return sum + days * Number(prevSlab.amount || 0);
                            }, 0);

                          const projectedTotal =
                            previousTotalAmount + slabDays * inputValue;

                          // Allow input only if total (previous slabs + current) ≤ totalAmount
                          if (projectedTotal <= totalAmount) {
                            handleChange(index, "amount", e.target.value);
                          }
                        } else {
                          handleChange(index, "amount", e.target.value); // No totalAmount restriction
                        }
                      }
                    }}
                    className={`text-center border outline-none rounded-lg p-1 w-20 ${
                      slab.isDisabled && "bg-gray-200"
                    }`}
                    disabled={slab.isDisabled}
                    placeholder="0"
                  />
                </td>

                <td className="border border-slate-300 text-sm text-gray-900 text-center">
                  {slab.min &&
                  slab.max &&
                  slab.amount &&
                  Number(slab.max) >= Number(slab.min)
                    ? (Number(slab.max) - Number(slab.min) + 1) *
                      Number(slab.amount)
                    : 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SlabSubProduct;
