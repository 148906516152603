import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import ProductGrid from "../productGrid/ProductGrid";

const SideBar = ({ isCollapsed, setIsCollapsed, menueData }) => {
  const [activeMenuItem, setActiveMenuItem] = useState({
    key: "Products",
    component: <ProductGrid />,
  });

  const handleClickBar = (data) => {
    if (data.label === activeMenuItem.key) {
      return;
    } else {
      setActiveMenuItem({
        key: data.label,
        component: data.component,
      });
    }
  };

  return (
    <div className="flex gap-3 h-screen">
      <div
        className={`${
          isCollapsed ? "w-1/4 md:w-20 " : "w-1/2 md:w-1/4"
        } transition-all duration-300  overflow-hidden`}
      >
        <Sidebar collapsed={isCollapsed}>
          <Menu
            menuItemStyles={{
              button: ({ level, active }) => {
                return {
                  color: active ? "white" : "black",
                  backgroundColor: active ? "#393939" : undefined,
                  "&:hover": {
                    color: "black", // Hover text color
                    backgroundColor: "#D3D3D3", // Hover background color
                  },
                };
              },
            }}
          >
            <MenuItem
              icon={<FaBars />}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            />
            {menueData.map((item, index) =>
              item.isSubMenu ? (
                <SubMenu
                  icon={item.icon(item.label === activeMenuItem.key)}
                  label={item.label}
                  defaultOpen={true}
                >
                  {item.children.map((sub, i) => {
                    return (
                      <MenuItem
                        active={sub.label === activeMenuItem.key}
                        onClick={() => handleClickBar(sub)}
                        icon={sub.icon(sub.label === activeMenuItem.key)}
                      >
                        {sub.label}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              ) : (
                <MenuItem
                  active={item.label === activeMenuItem.key}
                  onClick={() => handleClickBar(item)}
                  icon={item.icon(item.label === activeMenuItem.key)}
                >
                  {item.label}
                </MenuItem>
              )
            )}
          </Menu>
        </Sidebar>
      </div>
      <div
        className={` ${
          isCollapsed ? "w-3/4 md:w-full " : "w-1/2 md:w-full "
        }  bg-white-100 `}
      >
        {activeMenuItem.component}
      </div>
    </div>
  );
};

export default SideBar;
