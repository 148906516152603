import React, { useEffect, useRef, useState } from "react";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { useSelector } from "react-redux";
import CardFilter from "../rewampClaims/component/CardFilter";
import { DatePicker, SelectPicker } from "rsuite";
import {
  AllFilterValue,
  DateFilter,
  Label,
  handleRemoveItem,
  setFormateDate,
} from "../rewampClaims/component/constant";
import moment from "moment";
import {
  ApiGet,
  downloadCSVFileForClaimPoint,
  getResellerId,
  postApiClaim,
} from "../../lib/AppHelper";
import { ClaimCard } from "./ClaimsCard";
import { AppStore } from "../../lib/AppStore";
import { toast } from "react-toastify";
import CommentsModal from "../rewampClaims/component/CommentsModal";
import CustomModal from "../rewampClaims/component/CustomModal";
import DataTable from "./DataTable";
import Button from "../../components/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HeaderCards from "../rewampClaims/component/HeaderCards";
import { useLocation } from "react-router-dom";
import { ImageType } from "../heathCamp/constant";
import Compressor from "compressorjs";
import { addCommentAPI } from "../rewampClaims/api";
import { NewClaimsFilters } from "../rewampClaims/component/NewClaimsFilters";
const tab = [
  { label: "All", value: "all" },
  { label: "Open ", value: "open_claim" },
  { label: "Closed ", value: "closed_claim" },
  { label: "Draft ", value: "draft_claim" },
];

const Index = () => {
  const location = useLocation();
  const { state } = location;
  const user = useSelector((state) => state.user);
  const permissions = user.permissions;
  const [filter, setFilter] = useState(
    localStorage.getItem("card_Filter") || ""
  );
  const [cardFilterArray, setCardFilterArray] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [ticketDetails, setTicketDetails] = React.useState({
    title: "",
    description: "",
    status: "In_Progress",
    priority: "High",
  });
  const [ticketDocuments, setTicketDocuments] = React.useState([]);
  const [open, setOpen] = useState(null);
  const [tabValue, setTabValue] = useState("all");
  const [headerData, setHeaderData] = useState({
    all: null,
    closed_claim: null,
    open_claim: null,
    draft_claim: null,
  });
  const [ticketLoading, setTicketLoading] = useState(false);
  const MessageRef = useRef();
  const FilterChildRef = useRef(null);
  const fetchAPIData = React.useRef(null);
  const [claimSpecification, setClaimSpecification] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnerId, setPartnerId] = useState("");

  const getPartner = async () => {
    const response = await ApiGet(`get-team-partner`);
    if (response?.status === "success") {
      setPartners(response?.data);
    }
  };

  const fetchCardsCounts = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    try {
      const response = await ApiGet(
        `claims-status-count-by-partner?id=${partnerId}`
      );
      if (response?.status === "success") {
        setCardFilterArray(response?.data?.notification);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };
  const fetchCounts = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    try {
      const response = await ApiGet(
        `claim-all-open-close-count-by-partner?id=${partnerId}`
      );
      if (response?.status === "success") {
        setHeaderData({
          all: response.data.total || 0,
          closed_claim: response.data.total_closed_claims || 0,
          open_claim: response.data.total_open_claims || 0,
          draft_claim: response.data.total_draft_claims || 0,
        });
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (windowSize[0] > 800)
        window.scrollTo({ top: 600, behavior: "smooth" });
    }
  };

  const handleFetch = () => {
    if (fetchAPIData.current) {
      fetchAPIData.current();
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    fetchCardsCounts();
    fetchCounts();
    getSpecification();
    getPartner();
  }, [partnerId]);

  const onChangeFileHandeler = (event) => {
    if (ticketDocuments.length > 4) {
      toast.warning("maximum file can be upto 5");
      return;
    }
    const currentFile = event?.target?.files?.[0];

    if (currentFile) {
      const mimeType = currentFile.type;
      const fileSize = currentFile.size;

      if (mimeType !== "application/pdf" && !ImageType.includes(mimeType)) {
        toast.warning("Only images or PDFs are allowed");
      } else if (mimeType === "application/pdf" && fileSize > 5000000) {
        toast.warning("PDF size should not be more than 5 MB");
      } else if (mimeType === "application/pdf" && fileSize <= 5000000) {
        setTicketDocuments((prev) => [...prev, currentFile]);
      } else if (ImageType.includes(mimeType)) {
        new Compressor(currentFile, {
          quality: 0.6,
          success(result) {
            setTicketDocuments((prev) => [...prev, result]);
          },
          error(err) {
            console.error(err.message);
          },
        });
      }
    }
    event.target.value = null;
  };

  const handelSubmitRaiseTicket = async (data) => {
    if (!data) return;

    setTicketLoading(true);
    try {
      await postApiClaim("add-ticket", data)
        .then(async (res) => {
          if (res.code === 200) {
            const commentData = {
              ticketMasterId: res?.data?.id,
              createdBy: getResellerId(),
              comments: data?.ticketTitle,
              referenceId: data?.referenceId,
              subReferenceId: data?.subReferenceId,
              userApp: "RESELLER",
            };
            try {
              await addCommentAPI({
                data: commentData,
                documents: ticketDocuments,

                onSuccess: () => {
                  setTicketDetails({
                    title: "",
                    description: "",
                    status: "In_Progress",
                    priority: "High",
                  });
                  setOpen(null);
                  toast.success("Ticket raise successfully");
                  fetchCardsCounts();
                  handleFetch();
                  setTicketLoading(false);
                  setTicketDocuments([]);
                },
                onError: (message) => {
                  setTicketLoading(false);
                  toast.error(message);
                },
              });
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FilterChildRef?.current?.handelInitalValue();
    setFilterValues({});
    if (filter === "SETTLED" || filter === "REJECTED") {
      setDateFilter({
        ...dateFilter,
        dateType: "1_month",
        from: setFormateDate("1_month")[0],
        to: setFormateDate("1_month")[1],
      });
    } else {
      setDateFilter({
        ...dateFilter,
        dateType: "All",
        from: "",
        to: "",
      });
    }
  }, [filter]);

  const downloadExcel = async () => {
    const filteredCardFilterArray = cardFilterArray?.filter(
      (item) => item?.status !== "PARTIAL_SETTLE" && item?.status !== "DISPUTE"
    );

    AppStore.dispatch({ type: "LOADING", loading: true });
    const data = {
      statusList: filter
        ? [filter]
        : AllFilterValue(tabValue, filteredCardFilterArray),
      resellerId: partnerId ? partnerId : getResellerId(),
      from: dateFilter.from,
      to: dateFilter.to,
      specification_type: dateFilter.specificationType,
    };
    try {
      await downloadCSVFileForClaimPoint(
        "claimReport/claim-reseller-dashboard-report",
        data,
        "report.csv"
      );
    } catch (error) {
      toast.error(error?.message || "something went wrong");
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  function removeFile(id) {
    const items = handleRemoveItem(id, ticketDocuments);
    setTicketDocuments(items);
  }
  useEffect(() => {
    if (state?.item) setOpen(state?.item);
  }, [state]);

  const getSpecification = async () => {
    await ApiGet("claim-specification").then((res) => {
      if (res.code === 200) {
        const tempData = res?.data?.specification?.map((item) => ({
          label: `${item}`?.charAt(0)?.toUpperCase() + `${item}`?.slice(1),
          value: item,
        }));
        setClaimSpecification(
          [
            {
              label: "All",
              value: "",
            },
            ...tempData,
          ] || []
        );
      } else {
        setClaimSpecification([]);
      }
    });
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row md:flex-col md:justify-between items-center">
        <div className="w-full lg:flex-1 lg:justify-start flex">
          <AppBackButton to="/" label="Back" color="#000000" />
        </div>
        <div className="w-full lg:flex-1 flex justify-center">
          <AppHeading
            className="text-center font-medium mb-6"
            style={{ color: "#000000" }}
          >
            Partner wise reimbursements
          </AppHeading>
        </div>
        <div className="hidden lg:flex lg:flex-1"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3">
        <div>
          <select
            onChange={(e) => setPartnerId(e.target.value)}
            value={partnerId}
            className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
          >
            <option value="">All Partner</option>
            {partners?.map((option, index) => (
              <option value={option.id} key={index}>
                {option.name} ({option.mobile})
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="w-full mt-6">
        <HeaderCards
          tab={tab}
          headerData={headerData}
          setTabValue={setTabValue}
          setFilter={setFilter}
          tabValue={tabValue}
        />

        {/* content */}
        <div>
          <div className="w-full flex flex-wrap gap-4 my-6">
            <CardFilter
              cardFilterArray={cardFilterArray}
              setFilter={setFilter}
              filter={filter}
              windowSize={windowSize}
              tabValue={tabValue}
            />
          </div>

          <div className="my-6">
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent:
                  windowSize[0] < 800 ? "center" : "space-between",
                flexDirection: windowSize[0] < 800 ? "column" : "row",
              }}
            >
              <div
                style={{
                  flex: windowSize[0] < 800 ? "unset" : "6",
                  width: windowSize[0] < 800 ? "100%" : "auto",
                }}
              >
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Search
                </label>
                <NewClaimsFilters
                  setValue={setFilterValues}
                  filterValues={filterValues}
                  ref={FilterChildRef}
                />
              </div>
              <div
                style={{
                  flex: windowSize[0] < 800 ? "unset" : "2",
                  width: windowSize[0] < 800 ? "100%" : "auto",
                }}
              >
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Reimbursement type
                </label>
                <SelectPicker
                  data={claimSpecification}
                  value={dateFilter?.specificationType || ""}
                  cleanable={false}
                  onChange={(value) => {
                    setDateFilter({
                      ...dateFilter,
                      specificationType: value,
                    });
                  }}
                  searchable={false}
                  style={{
                    width: windowSize[0] < 800 ? "100%" : "250px",
                    color: "black",
                    opacity: "0 !important",
                    backgroundColor: "white",
                    borderRadius: "12px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: windowSize[0] < 800 ? "unset" : "2",
                  width: windowSize[0] < 800 ? "100%" : "auto",
                }}
              >
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Date range
                </label>
                <SelectPicker
                  placeholder={"--Select Date--"}
                  data={DateFilter}
                  value={dateFilter.dateType}
                  cleanable={false}
                  onChange={(value) => {
                    if (value === "custom_date" || value === null) {
                      setDateFilter({
                        ...dateFilter,
                        dateType: value,
                        from: null,
                        to: null,
                        datePage: 1,
                      });
                    } else {
                      setDateFilter({
                        ...dateFilter,
                        dateType: value,
                        from: setFormateDate(value)[0],
                        to: setFormateDate(value)[1],
                        datePage: 1,
                      });
                    }
                  }}
                  searchable={false}
                  style={{
                    width: windowSize[0] < 800 ? "100%" : "250px",
                    color: "black",
                    opacity: "0 !important",
                    backgroundColor: "white",
                    borderRadius: "12px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-6">
            {dateFilter.dateType === "custom_date" && (
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: windowSize[0] < 800 ? "center" : "start",
                  flexDirection: windowSize[0] < 800 ? "column" : "row",
                }}
              >
                <div
                  style={{
                    flex: windowSize[0] < 800 ? "unset" : "",
                    width: windowSize[0] < 800 ? "100%" : "auto",
                  }}
                >
                  <label className="flex text-sm text-gray-700 justify-left pb-2">
                    From
                  </label>
                  <DatePicker
                    format="dd/MM/yyyy"
                    placeholder="From"
                    style={{ width: windowSize[0] < 800 ? "100%" : "250px" }}
                    oneTap
                    cleanable={false}
                    onChange={(value) =>
                      setDateFilter({
                        ...dateFilter,
                        from: value ? moment(value).format("YYYY-MM-DD") : null,
                      })
                    }
                    disabledDate={(date) => {
                      if (date > new Date()) {
                        return true;
                      }
                    }}
                    className="claims-filters-from"
                  />
                </div>
                <div
                  style={{
                    flex: windowSize[0] < 800 ? "unset" : "",
                    width: windowSize[0] < 800 ? "100%" : "auto",
                  }}
                >
                  <label className="flex text-sm text-gray-700 justify-left pb-2">
                    To
                  </label>
                  <DatePicker
                    format="dd/MM/yyyy"
                    placeholder="To"
                    oneTap
                    disabled={!dateFilter.from}
                    cleanable={false}
                    style={{ width: windowSize[0] < 800 ? "100%" : "250px" }}
                    onChange={(value) =>
                      setDateFilter({
                        ...dateFilter,
                        to: value ? moment(value).format("YYYY-MM-DD") : null,
                      })
                    }
                    disabledDate={(date) => {
                      if (
                        date > new Date() ||
                        date < new Date(dateFilter.from)
                      ) {
                        return true;
                      }
                    }}
                    className="claims-filters-to"
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className="flex justify-between md:items-center md:flex-row flex-col items-start md:gap-0 gap-2"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            <div>
              {dateFilter.from && dateFilter.to
                ? `Data from ${dateFilter.from} to ${dateFilter.to}`
                : "Data from Lifetime"}
              &nbsp; {filter && `| Filter: ${Label[filter]}`}
            </div>
            <div>
              <Button
                title=""
                attrs={{
                  style: {
                    backgroundColor: "transparent",
                    color: "#DB2228",
                    borderRadius: "4px",
                    border: "1px solid #DB2228",
                    boxShadow: "none",
                    padding: "8px",
                  },
                  onClick: () => downloadExcel(),
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
                &nbsp;
                <span style={{ fontWeight: "600", padding: "0 6px" }}>
                  Download
                </span>
              </Button>
            </div>
          </div>
          <div>
            <DataTable
              key={partnerId}
              request={ApiGet}
              fetchCardsCounts={fetchCardsCounts}
              fetchCounts={fetchCounts}
              renderItem={(item) => (
                <ClaimCard
                  item={item}
                  filter={filter}
                  setOpen={setOpen}
                  windowSize={windowSize}
                />
              )}
              endPoint={`claims-by-partner?id=${partnerId}`}
              filterValues={filterValues}
              dateFilter={dateFilter}
              filter={filter}
              setOpen={setOpen}
              fetchAPIData={fetchAPIData}
            />
          </div>
        </div>
      </div>

      {open && open?.sub_claims[0]?.raised_ticket === "1" ? (
        <CommentsModal open={open} setOpen={setOpen} />
      ) : (
        <CustomModal
          open={open}
          setOpen={setOpen}
          ticketDetails={ticketDetails}
          setTicketDetails={setTicketDetails}
          handelSubmitRaiseTicket={handelSubmitRaiseTicket}
          ticketLoading={ticketLoading}
          onChangeFileHandeler={onChangeFileHandeler}
          ticketDocuments={ticketDocuments}
          removeFile={removeFile}
        />
      )}
    </>
  );
};

export default Index;
