import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
import {
  ApiGet,
  ApiPost,
  JavaApiPost,
  setError,
  setMessage,
  validateEmail,
  validateName,
  validateNumber,
} from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import { govtIds } from "../../services/common/data";
import { InputSelect } from "../../components/form/InputSelect";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { InputFile } from "../../components/form/InputFile";
import {
  addTeamDocSize,
  extensions,
  getFileExtension,
} from "../../lib/constants";

export class AddEmployee extends Component {
  state = {
    errors: {},
    govDetails: {
      ...govtIds?.filter(
        (elm) => this.props.user?.banking?.government_id_type === elm.value
      )?.[0],
    },
    params: {
      email: "",
      mfiPartner: "",
      mobile: "",
      products: [],
      referralPartner: "",
      reporting_to: "",
      name: "",
      government_id_type: "",
      government_id: "",
      document_front: "",
      document_back: "",
      category_id: "",
      associateRole: false,
      ...this.props.location.state,
    },
    permissions: [],
    employees: [],
    products: [],
    referralPartner: [],
    mfiPartner: [],
    teamCategory: [],
    isInheritRole: false,
  };
  MessageRef = React.createRef();
  frontImgRef = React.createRef();
  backImgRef = React.createRef();

  render() {
    const { products, permissions } = this.props;
    const {
      errors,
      params,
      employees,
      referralPartner,
      mfiPartner,
      teamCategory,
    } = this.state;

    const isNameAvailable =
      employees?.length &&
      employees.some((item) => item?.name?.toLowerCase() === "me");

    return (
      <>
        <AppBackButton to="/">Back</AppBackButton>
        {permissions?.reseller_add ? (
          <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
            <div>
              <AppHeading>Add Team</AppHeading>
              <h4 className="text-md mb-6 mt-6">Please enter details</h4>

              <div className="mb-5">
                <label className="flex text-sm text-gray-700 justify-left">
                  Select Reporting To{" "}
                  <span className="text-lg text-red-500">*</span>
                </label>
                <div className="mt-2 flex space-x-4">
                  <select
                    onChange={(e) => this.onChangeHandler(e, "reporting_to")}
                    className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                  >
                    {!isNameAvailable ? <option value="">Me</option> : false}
                    {employees?.map((option, index) => {
                      return (
                        <option value={option.id} key={index}>
                          {option.name} ({option.mobile})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <InputGroup
                error={errors.name}
                label="Name"
                starMark={true}
                placeholder="Enter name..."
                attrs={{
                  maxLength: 32,
                  value: params.name,
                  onChange: (e) => {
                    if (
                      /^[a-zA-Z\s]+$/.test(e.target.value) ||
                      e.target.value === ""
                    )
                      this.onChangeHandler(e, "name");
                  },
                }}
                // onEnter={this.submit}
              />

              <InputGroup
                label="Mobile no"
                placeholder="0000000000"
                starMark={true}
                type="tel"
                prefix="+91"
                attrs={{
                  maxLength: 10,
                  value: params.mobile,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (e.target.value[0] < 6) {
                      return;
                    }
                    if (isValidInteger || e.target.value == "") {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "mobile"
                      );
                    }
                  },
                  autoFocus: true,
                }}
                validateError={(value) => {
                  if (!value || value?.length === 10) {
                    return false;
                  } else {
                    return "please enter valid mobile number";
                  }
                }}
                error={errors.mobile}
                // onEnter={this.submit}
              />
              {permissions?.hide_reseller_email ? null : (
                <InputGroup
                  error={errors.email}
                  label="Email"
                  type="email"
                  placeholder="Enter email address... "
                  attrs={{
                    maxLength: 64,
                    value: params.email,
                    onChange: (e) => this.onChangeHandler(e, "email"),
                  }}
                  validateError={(value) => {
                    if (value && validateEmail(value)) {
                      return false;
                    } else if (!value) {
                      return false;
                    } else {
                      return "please enter correct email";
                    }
                  }}
                  // onEnter={this.submit}
                />
              )}
              {referralPartner.length > 0 &&
                permissions?.add_referral_partner && (
                  <div className="mb-5">
                    <label className="flex text-sm text-gray-700 justify-left">
                      Add Referral Partner
                    </label>
                    <div className="mt-2 flex space-x-4">
                      <select
                        onChange={(e) =>
                          this.onChangeHandler(e, "referralPartner")
                        }
                        className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                      >
                        <option value="">Select</option>
                        {referralPartner?.map((option, index) => {
                          return (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

              {permissions?.aiqa_center_head_office && (
                <div className="mb-5">
                  <label className="flex text-sm text-gray-700 justify-left">
                    Aiqa Center Branch
                  </label>
                  <div className="mt-2 flex space-x-4">
                    <select
                      onChange={(e) => this.onChangeHandler(e, "mfiPartner")}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="">Select</option>
                      {mfiPartner?.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {permissions?.add_team_assign_reseller_category && (
                <div className="mt-3">
                  <label className="flex text-sm text-gray-700 justify-left">
                    Choose Category
                    <span className="text-lg text-red-500">*</span>
                  </label>
                  <div className="mt-2 flex space-x-4">
                    <select
                      onChange={(e) => this.onChangeHandler(e, "category_id")}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="">Select</option>
                      {teamCategory?.map((category, index) => {
                        return (
                          <option key={index} value={category.id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {permissions?.add_team_government_id_show && (
                <div className="mt-3">
                  <InputSelect
                    width="full"
                    label="Govt ID Type"
                    starMark={
                      permissions?.add_team_government_id_attachment_required
                    }
                    requiredMark={
                      permissions?.add_team_government_id_required
                        ? true
                        : false
                    }
                    attrs={{
                      value: params.government_id_type,
                      onChange: (e) => {
                        this.onChangeHandler(e, "government_id_type");
                      },
                    }}
                    options={govtIds}
                  />

                  <InputGroup
                    label="Govt ID"
                    placeholder="Enter Govt ID..."
                    starMark={
                      permissions?.add_team_government_id_required
                        ? true
                        : false
                    }
                    requiredMark={
                      permissions?.add_team_government_id_attachment_required
                    }
                    attrs={{
                      disabled: !params.government_id_type,
                      value: params.government_id,
                      onChange: (e) => {
                        if (
                          /^[a-zA-Z0-9\-]*$/.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            e.target.value.length <=
                            (this.state.govDetails.maxLength
                              ? this.state.govDetails.maxLength
                              : 20)
                          ) {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value.replace(
                                    this.state.govDetails.regValidation,
                                    ""
                                  ),
                                },
                              },
                              "government_id"
                            );
                          }
                        }
                      },
                    }}
                    validateError={(value) => {
                      if (
                        !value ||
                        this.state.govDetails?.validateFunction(value)
                      ) {
                        return false;
                      } else {
                        return "please enter valid gov. id number";
                      }
                    }}
                    error={errors.government_id}
                    // onEnter={this.submit}
                  />
                </div>
              )}

              {this.state?.govDetails?.frontImg &&
                params.government_id_type && (
                  <InputFile
                    label="Attachment"
                    error={errors.document_front}
                    file={params.document_front}
                    starMark={
                      permissions?.add_team_government_id_attachment_required
                    }
                    placeholder={
                      !params.document_front && (
                        <div className="text-center items-center flex-col inline-flex">
                          <PlusCircleIcon className="text-primary" width={24} />
                          <p className="mt-2 text-center text-sm text-primary">
                            Upload {this.state.govDetails?.label?.toLowerCase()}{" "}
                            (front-side) document.
                            <br />
                            (png, jpg, jpeg and pdf)
                            <br />
                            (max size 5MB)
                            <br />
                          </p>
                        </div>
                      )
                    }
                    attrs={{
                      onChange: (e) => this.onDocChange(e, "document_front"),
                      accept: "image/*,.pdf",
                    }}
                    onDocDelete={() => {
                      _.set(params, "document_front", null);
                      this.setState({ params: params });
                    }}
                  />
                )}

              {this.state?.govDetails?.backImg &&
                params?.government_id_type && (
                  <InputFile
                    error={errors?.document_back}
                    file={params?.document_back}
                    label="Attachment "
                    starMark={
                      permissions?.add_team_government_id_attachment_required
                    }
                    placeholder={
                      !params.document_back && (
                        <div className="text-center items-center flex-col inline-flex">
                          <PlusCircleIcon className="text-primary" width={24} />
                          <p className="mt-2 text-center text-sm text-primary">
                            Upload{" "}
                            {this.state?.govDetails?.label?.toLowerCase()}{" "}
                            (back-side) document.
                            <br />
                            (png, jpg, jpeg and pdf)
                            <br /> (max size 5MB)
                            <br />
                          </p>
                        </div>
                      )
                    }
                    attrs={{
                      onChange: (e) => this.onDocChange(e, "document_back"),
                      accept: "image/*,.pdf",
                    }}
                    onDocDelete={() => {
                      _.set(params, "document_back", null);
                      this.setState({ params: params });
                    }}
                  />
                )}
              {permissions?.add_inherit_role && (
                <>
                  <div className="flex">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="associateRole"
                      onChange={(e) => {
                        this.setState({ isInheritRole: e.target.checked });
                        _.set(params, "associateRole", e.target.checked);
                        this.setState({ params: params });
                      }}
                      value={this.state.isInheritRole}
                    />
                    <label
                      for="associateRole"
                      className="form-check-label inline-block text-gray-800"
                    >
                      Inherit role
                    </label>
                  </div>
                  <p
                    className="text-xs font-medium pt-1"
                    style={{ color: "#Db2228" }}
                  >
                    By selecting "Inherit Role," all roles will be transferred
                    to this team members.{" "}
                  </p>
                </>
              )}
              {permissions?.add_member_product && (
                <div>
                  <p className="mt-2 mb-3">Assign Products</p>
                  <div className="box-border h-40 w-100 p-4 border-2 overflow-auto">
                    {products?.length > 0 &&
                      products?.map((item, index) => (
                        <div className="flex" key={index}>
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox"
                            onChange={(e) => this.productAdd(e)}
                            value={item.id}
                            id={item.id}
                            checked={this.state.products.includes(`${item.id}`)}
                          />
                          <label
                            className="form-check-label inline-block text-gray-800"
                            htmlFor={item.id}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              <Button
                className="mt-5"
                attrs={{
                  type: "button",
                  onClick: this.submit,
                  disabled: !this.isReady(),
                }}
                size="md"
                title={"Submit"}
              />
            </div>
          </div>
        ) : (
          <div className="text-red-500">
            <h4>You don't have permission to Add Team Member</h4>
          </div>
        )}
      </>
    );
  }

  isReady = () => {
    let ready = true;
    const {
      add_team_government_id_required,
      add_team_government_id_attachment_required,
      add_team_assign_reseller_category,
    } = this.props.permissions;

    const {
      govDetails,
      params: {
        name,
        mobile,
        email,
        government_id_type,
        government_id,
        document_front,
        document_back,
        category_id,
      },
    } = this.state;

    if (!name || !mobile || mobile?.length < 10) {
      ready = false;
    } else if (email && !validateEmail(email)) {
      ready = false;
    } else if (add_team_assign_reseller_category && !category_id) {
      ready = false;
    } else if (
      add_team_government_id_required &&
      (!government_id_type || !government_id)
    ) {
      ready = false;
    } else if (government_id && !govDetails?.validateFunction(government_id)) {
      ready = false;
    } else if (add_team_government_id_attachment_required) {
      const govDetails = this.state.govDetails;
      if (
        (govDetails.frontImg && !document_front) ||
        (govDetails.backImg && !document_back)
      )
        ready = false;
    } else {
      ready = true;
    }
    return ready;
  };

  submit = async () => {
    const { user } = this.props;
    const { permissions } = user;

    if (permissions && !permissions?.reseller_add) {
      return setError("Yo do not have permission to add team");
    }
    const { params } = this.state;
    let formData = new FormData();
    const key = [
      "associateRole",
      "email",
      "mfiPartner",
      "mobile",
      "referralPartner",
      "reporting_to",
      "name",
      "government_id_type",
      "government_id",
      "document_front",
      "document_back",
      "category_id",
    ];
    key.map((elem) => {
      formData.append(elem, params[elem]);
    });
    formData.append("products", JSON.stringify(this.state?.products));
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("create/employee", formData);
    if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (!_.isEmpty(response.errors)) {
        this.setState({ errors: response.errors });
        setError(response.message);
      } else {
        setError(response.message);
      }
    } else {
      if (this?.state?.isInheritRole) {
        this.sendRolesAndProduct(response?.data);
      }

      setMessage(response.message, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.props.history.push("/employees");
      });
    }
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    if (key === "government_id_type") {
      let govObj = govtIds.filter((elm) => e.target.value === elm.value);
      params["government_id"] = "";
      params["document_front"] = "";
      params["document_back"] = "";
      this.setState({ params: params, govDetails: govObj?.[0] });
    } else {
      params[key] = e.target.value;
      this.setState({ params: params });
    }
  };

  onDocChange = (event, key) => {
    const { params } = this.state;
    const file = event?.target?.files?.[0];
    const extension = getFileExtension(file);
    if (file) {
      if (extensions.includes(extension)) {
        const fileSize = file?.size;
        if (fileSize < addTeamDocSize) {
          _.set(params, key, file);
          this.setState({
            params: params,
            errors: {
              [key]: "",
            },
          });
        } else {
          this.setState({
            errors: {
              [key]: "File size should be less than 5mb",
            },
          });
        }
      } else {
        this.setState({
          errors: {
            [key]: "File must be .pdf, .jpeg, .jpg, .png",
          },
        });
      }
    }
  };

  productAdd = (e) => {
    let newData = e.target.value;
    if (e.target.checked) {
      if (this.state.products == []) this.setState({ products: newData });
      else this.setState({ products: [...this.state.products, newData] });
    } else {
      const data = this.state.products.filter((e) => {
        if (e === newData) {
        } else {
          return e;
        }
      });
      this.setState({ products: data });
    }
  };

  async componentDidMount() {
    await this.getTeam();
    await this.getReferralPartner();
    await this.getMfi();
    await this.getCategory();

    if (this.props.products?.length === 1) {
      const data = this.props.products[0]?.id;
      this.setState({ products: [`${data}`] });
    }
  }

  componentDidUpdate(prevProps) {
    // Check if props have changed
    if (prevProps?.products?.length !== this.props?.products?.length) {
      if (this.props?.products?.length === 1) {
        const data = this.props.products[0]?.id;
        this.setState({ products: [`${data}`] });
      }
    }
  }

  getReferralPartner = async () => {
    if (this.props.permissions?.add_referral_partner) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("referral-partner");
      if (response.status === "success") {
        this.setState({ referralPartner: response.data });
      } else {
        await setError(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  getMfi = async () => {
    if (this.props.permissions?.aiqa_center_head_office) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("get-mfi");
      if (response.status === "success") {
        this.setState({ mfiPartner: response.data });
      } else {
        await setError(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  getCategory = async () => {
    if (this.props.permissions?.add_team_assign_reseller_category) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("reseller-categories");
      if (response.status === "success") {
        this.setState({ teamCategory: response.data });
      } else {
        await setError(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  getTeam = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  sendRolesAndProduct = async (d) => {
    let data = {
      resellerId: d?.basicDetail?.reseller_id || d?.id,
      associateRole: this.state.isInheritRole,
    };
    const response = await JavaApiPost(
      "enrollment-service/reseller/assignRolesAndProductsToChild",
      data
    );
  };
}

const mapStateToProps = (state) => {
  const { loading, user, products } = state;

  return {
    loading: loading,
    user: user,
    products: products,
    permissions: user.permissions,
  };
};

const AddEmployeeConnect = connect(mapStateToProps)((props) => {
  return <AddEmployee {...props} />;
});

export default withRouter(AddEmployeeConnect);
