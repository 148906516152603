import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../../../lib/AppHelper";
import { Checkbox, CheckboxGroup, Pagination } from "rsuite";
import CustomAccordian from "../../common/components/CustomAccordian";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";
import CheckIcon from "../../../../assets/productCreation/check.png";
import { ArrowRight, ArrowDropDown } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faVial,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { fieldValidation } from "../../common/data/constants";
import { FaMicroscope } from "react-icons/fa";

const LabTestsInfo = ({ fields, values, setFieldValue }) => {
  const [initalLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [benefitLoading, setBenefitLoading] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [data, setData] = useState([]);
  const [labTestInfo, setLabTestInfo] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [total, setTotal] = useState();
  const [searchFilters, setSearchFilters] = useState({
    ids: [],
    lab_test_name: "",
    technical_name: "",
    member_covered: "",
    lab_test_limit: "",
    status: "Active",
    rowsPerPage: 5,
    page: 1,
    limit: 5,
    orderBy: "DESC",
    shortBy: "",
    from: "",
    to: "",
  });
  const [open, setOpen] = useState(false);
  const [selectedOpen, setSelectedOpen] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [selectedLabTestInfo, setSelectedLabTestInfo] = useState([]);
  const payloadRef = useRef();

  const handleChange = (v, e, c) => {
    if (e.target.checked) {
      const newData = data.filter((elem) => elem.id == e.target.value);
      setFieldValue("labTestID", [...values, ...newData]);
    } else {
      handleRemove(e.target.value);
    }
  };

  const onBenefitPress = (index, benefitId) => {
    if (isActive !== index) {
      setIsActive(index); // Set the active index
      setBenefitLoading(true);
      fetchBenefits(benefitId);
    } else {
      setIsActive(null); // Collapse the currently active panel
      setBenefits([]);
    }
  };

  const handelChangePage = (page) => {
    setSearchFilters((prev) => {
      const data = { ...prev, page: page };
      setInitialLoading(true);
      debouncedFetchLabTests(data);
      return data;
    });
  };

  const fetchBenefits = async (benefitId) => {
    const response = await ApiGet(
      `admin/show-lab-test-parameter-benefit?parameter_id=${benefitId}`
    );
    if (response.code == 200) {
      setBenefits(response.data);
    } else {
      toast.error(response.message);
    }
    setBenefitLoading(false);
  };

  const fetchLabTestInfo = async (id) => {
    const response = await ApiGet(
      `admin/show-lab-test-parameter?lab_test_feature_id=${id}`
    );

    if (response.code == 200) {
      setLabTestInfo(response.data);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const fetchSelectedLabTestInfo = async (id) => {
    const response = await ApiGet(
      `admin/show-lab-test-parameter?lab_test_feature_id=${id}`
    );

    if (response.code == 200) {
      setSelectedLabTestInfo(response.data);
    } else {
      toast.error(response.message);
    }
    setSelectLoading(false);
  };

  const debouncedFetchLabTests = useCallback(
    debounce(async (searchFilters) => {
      if (payloadRef.current === JSON.stringify(searchFilters)) {
        return;
      } else {
        payloadRef.current = JSON.stringify(searchFilters);
        const response = await ApiPost("admin/get-all-lab-test", searchFilters);
        if (response.code == 200) {
          setData(response.data.results);
          setTotal(response.data.total);
        } else {
          toast.error(response?.message);
        }
        setInitialLoading(false);
      }
    }, 500),

    []
  );
  const getLabTestInfo = (id) => {
    setLoading(true);
    fetchLabTestInfo(id);
  };
  const getSelectedLabTestInfo = (id) => {
    setSelectLoading(true);
    fetchSelectedLabTestInfo(id);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchFilters((prev) => {
      let data = { ...prev, lab_test_name: value, page: 1 };
      if (value.length > 1 || value == "") {
        setInitialLoading(true);
        debouncedFetchLabTests(data);
      }

      return data;
    });
  };

  useEffect(() => {
    setInitialLoading(true);
    debouncedFetchLabTests(searchFilters);
  }, []);

  const handleRemove = (id) => {
    const data = values.filter((item) => item.id != id);
    setFieldValue("labTestID", data);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div
        className={`${
          initalLoading ? "bg-white" : "bg-gray-100"
        } rounded-2xl p-4 shadow-lg`}
      >
        <div className="flex justify-between items-center border-b pb-1 mb-2 border-black ">
          <h2 className="text-lg font-semibold ">Available Lab Test</h2>
          <input
            value={searchFilters.lab_test_name}
            placeholder="Search by lab test name"
            className=" custom-input-type block px-3 py-1 border rounded-md shadow-sm 
                text-gray-700 focus:outline-none 
                focus:ring-2 focus:ring-gray-300 focus:border-gray-200
                border-gray-200 disabled:bg-gray-200 transition"
            onChange={(e) => {
              if (
                e.target.value === "" ||
                fieldValidation.name.test(e.target.value)
              ) {
                handleSearch(e);
              }
            }}
          />
        </div>
        {initalLoading ? (
          <ShimmerLoader rows={8} />
        ) : (
          <>
            <CheckboxGroup
              name="checkbox-group"
              onChange={handleChange}
              value={values?.map((elem) => elem.id)}
            >
              {data?.length > 0 ? (
                data?.map((item) => {
                  return (
                    <CustomAccordian
                      key={item.id}
                      active={open === item.lab_test_name}
                      header={
                        <div className="flex h-6 items-center">
                          {open === item.lab_test_name ? (
                            <ArrowDropDown
                              className="rotate-180"
                              onClick={() => setOpen(false)}
                            />
                          ) : (
                            <ArrowRight
                              onClick={() => {
                                setOpen(item.lab_test_name);
                                getLabTestInfo(item.id);
                              }}
                            />
                          )}
                          <Checkbox value={item.id} key={item.id}>
                            <div className="flex items-center space-x-2">
                              <FontAwesomeIcon
                                icon={faVial}
                                className="text-primary-400"
                              ></FontAwesomeIcon>
                              <p
                                style={{
                                  color: "#555",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {item.lab_test_name}{" "}
                              </p>
                            </div>
                          </Checkbox>
                        </div>
                      }
                      children={
                        <>
                          {loading ? (
                            <ShimmerLoader rows={4} />
                          ) : (
                            <div>
                              {labTestInfo.length ? (
                                labTestInfo.map((labTest, i) => {
                                  return (
                                    <CustomAccordian
                                      active={isActive == i}
                                      className={"border border-gray-200 mb-1"}
                                      header={
                                        <div
                                          className="flex items-center gap-2 justify-between"
                                          onClick={() =>
                                            onBenefitPress(i, labTest.id)
                                          }
                                        >
                                          <div className="flex items-center gap-2 ">
                                            <FaMicroscope
                                              size={30}
                                              color="#FF6B6B"
                                            />
                                            <p className="text-sm">
                                              {labTest?.parameter_name}
                                            </p>
                                          </div>
                                          <div>{isActive == i ? "-" : "+"}</div>
                                        </div>
                                      }
                                      children={
                                        <>
                                          {benefitLoading ? (
                                            <ShimmerLoader rows={2} />
                                          ) : (
                                            <div>
                                              <ul>
                                                {benefits.length ? (
                                                  benefits.map((benefit) => {
                                                    return (
                                                      <li className="flex gap-1">
                                                        <img
                                                          alt=""
                                                          src={CheckIcon}
                                                          style={{
                                                            width: "20px",
                                                            height: "20px",
                                                          }}
                                                        />
                                                        {benefit?.benefit}
                                                      </li>
                                                    );
                                                  })
                                                ) : (
                                                  <div>
                                                    <p className="text-sm">
                                                      No information found
                                                    </p>
                                                  </div>
                                                )}
                                              </ul>
                                            </div>
                                          )}
                                        </>
                                      }
                                    />
                                  );
                                })
                              ) : (
                                <div>
                                  <p className="text-sm">
                                    No information found
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      }
                    />
                  );
                })
              ) : (
                <div className="w-full text-center p-4">No record found</div>
              )}
            </CheckboxGroup>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={total}
              limit={searchFilters.limit}
              activePage={searchFilters.page}
              onChangePage={(page, e) => {
                e?.preventDefault();
                handelChangePage(page);
              }}
            />
          </>
        )}
      </div>
      <div className="bg-gray-100 rounded-2xl p-4 shadow-lg overflow-auto">
        <h2 className="text-lg font-semibold mb-4 border-b border-black">
          Selected Lab Test
        </h2>

        {values?.length > 0 ? (
          values.map((item) => {
            return (
              <CustomAccordian
                key={item.id}
                active={selectedOpen === item.lab_test_name}
                header={
                  <div className="flex h-6 items-center justify-between">
                    <div className="flex items-center">
                      {selectedOpen === item.lab_test_name ? (
                        <ArrowDropDown
                          className="rotate-180"
                          onClick={() => setSelectedOpen(false)}
                        />
                      ) : (
                        <ArrowRight
                          onClick={() => {
                            setSelectedOpen(item.lab_test_name);
                            getSelectedLabTestInfo(item.id);
                          }}
                        />
                      )}

                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={faVial}
                          className="text-primary-400"
                        ></FontAwesomeIcon>
                        <p
                          style={{
                            color: "#555",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {item.lab_test_name}{" "}
                        </p>
                      </div>
                    </div>

                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      className="text-red-500 cursor-pointer"
                      onClick={() => handleRemove(item.id)}
                    />
                  </div>
                }
                children={
                  <>
                    {selectLoading ? (
                      <ShimmerLoader rows={4} />
                    ) : (
                      <div>
                        {selectedLabTestInfo.length ? (
                          selectedLabTestInfo.map((labTest, i) => {
                            return (
                              <CustomAccordian
                                active={isActive == i}
                                className={"border border-gray-200 mb-1"}
                                header={
                                  <div
                                    className="flex items-center gap-2 justify-between"
                                    onClick={() =>
                                      onBenefitPress(i, labTest.id)
                                    }
                                  >
                                    <div className="flex items-center gap-2 ">
                                      <FaMicroscope size={30} color="#FF6B6B" />
                                      <p className="text-sm">
                                        {labTest?.parameter_name}
                                      </p>
                                    </div>
                                    <div>{isActive == i ? "-" : "+"}</div>
                                  </div>
                                }
                                children={
                                  <>
                                    {benefitLoading ? (
                                      <ShimmerLoader rows={2} />
                                    ) : (
                                      <div>
                                        <ul>
                                          {benefits.length ? (
                                            benefits.map((benefit) => {
                                              return (
                                                <li className="flex gap-1">
                                                  <img
                                                    alt=""
                                                    src={CheckIcon}
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                  />
                                                  {benefit?.benefit}
                                                </li>
                                              );
                                            })
                                          ) : (
                                            <div>
                                              <p className="text-sm">
                                                No information found
                                              </p>
                                            </div>
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </>
                                }
                              />
                            );
                          })
                        ) : (
                          <div>
                            <p className="text-sm">No information found</p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                }
              />
            );
          })
        ) : (
          <div className="w-full text-center -mt-10 flex items-center justify-center h-full">
            No items selected. Please choose from the list to see them here.
          </div>
        )}
      </div>
    </div>
  );
};

export default LabTestsInfo;
