import React, { useState } from "react";
import PropTypes from "prop-types";

const Button = ({ type, onClick, style, disabled, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const baseStyles = {
    color: "white",
    fontWeight: "300",
    transition: "all 0.2s ease-in-out",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  const typeStyles = {
    primary: {
      backgroundColor: disabled ? "#94A3B8" : isHovered ? "#DE383D" : "#DB2228",
      border: "none",
    },
    secondary: {
      backgroundColor: disabled ? "#94A3B8" : isHovered ? "#2A2A2A" : "#000000",
      border: "none",
    },
  };

  const combinedStyles = {
    ...baseStyles,
    ...(typeStyles[type] || {}),
    ...style,
  };

  return (
    <button
      type="button"
      onClick={!disabled ? onClick : undefined} // Prevent onClick when disabled
      style={combinedStyles}
      disabled={disabled}
      onMouseEnter={() => !disabled && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "stroke"]).isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  type: "primary",
  onClick: () => {},
  style: {},
  disabled: false,
};

export default Button;
