import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { ApiPost, setError, setMessage } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import AdminMain from "./AdminMain";
import { InputGroup } from "../../components/form/InputGroup";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { InputTextArea } from "../../components/form/InputTextArea";
export class AddRole extends Component {
  BasicBtn = React.createRef();
  state = {
    errors: {},
    params: {
      role_name: "",
      description: "",
    },
  };

  render() {
    const { params, errors } = this.state;

    return (
      <>
        <div className="w-11/12">
          <AppBackButton to="/">Back</AppBackButton>
        </div>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <AppHeading>Add Role</AppHeading>
            <div className="pt-10">
              <InputGroup
                requiredMark="true"
                error={errors.role_name}
                label="Enter Role Name"
                placeholder="Enter Role name..."
                attrs={{
                  value: params.role_name,
                  onChange: (e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    this.onChangeHandler(
                      { target: { name: "role_name", value } },
                      "role_name"
                    );
                  },
                  onKeyDown: (e) => {
                    if (
                      e.key === "Enter" &&
                      params.role_name.trim().length >= 3 &&
                      params.description.trim().length >= 3
                    ) {
                      e.preventDefault();
                      this.submit();
                    }
                  },
                }}
                maxLength="40"
              />
              <InputTextArea
                requiredMark="true"
                label="Description"
                name="description"
                placeholder="Enter Description..."
                attrs={{
                  value: params.description,
                  onChange: (e) => this.onChangeHandler(e, "description"),
                  onKeyDown: (e) => {
                    if (
                      e.key === "Enter" &&
                      params.role_name.trim().length >= 3 &&
                      params.description.trim().length >= 3
                    ) {
                      e.preventDefault();
                      this.submit();
                    }
                  },
                }}
                maxLength="99"
              />

              <Button
                attrs={{
                  type: "button",
                  onClick: this.submit,
                  disabled:
                    params.role_name.trim().length < 3 ||
                    params.description.trim().length < 3,
                }}
                size="md"
                title={"Submit"}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  submit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ errors: false });
    const response = await ApiPost("add-role", this.state.params);
    AppStore.dispatch({ type: "LOADING", loading: false });

    if (response.code === 200) {
      this.setState({ params: { role_name: "", description: "" } });
      this.props.history.push("/roles");
    }
    if (response?.status && response?.status === "error") {
      setError(response?.data?.message);
      toast.error(response?.message);
    } else if (response?.status === "error") {
      if (!_.isEmpty(response?.errors)) {
        this.setState({ errors: response?.errors });
      } else {
        this.MessageRef.current.show(response?.message, "error");
      }
    } else {
      setMessage(response.message);
    }
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };
}

export default withRouter(AddRole);
