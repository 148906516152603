export const headers = [
  {
    name: "Reseller_id",
    mandatory: false,
    type: "number",
    dataKey: "Reseller_id",
    columnColor: [],
  },
  {
    name: "Product_id",
    mandatory: false,
    type: "number",
    dataKey: "Product_id",
    columnColor: [],
  },
  { name: "SrNo", mandatory: false, dataKey: "SrNo", columnColor: [] },
  {
    name: "Customer_Id",
    mandatory: true,
    dataKey: "Customer_Id",
    columnColor: [],
  },
  {
    name: "Branch_code",
    mandatory: false,
    dataKey: "Branch_code",
    columnColor: [],
  },
  {
    name: "Branch_name",
    mandatory: false,
    dataKey: "Branch_name",
    columnColor: [],
  },
  {
    name: "Loan_Code",
    mandatory: true,
    dataKey: "Loan_Code",
    columnColor: [],
  },
  {
    name: "Disbursementdate",
    mandatory: true,
    dataKey: "Disbursementdate",
    columnColor: [],
  },
  { name: "State", mandatory: false, dataKey: "State", columnColor: [] },
  {
    name: "Pincode",
    mandatory: true,
    type: "number",
    dataKey: "Pincode",
    columnColor: [],
  },
  {
    name: "Cust_Name",
    mandatory: true,
    type: "string",
    dataKey: "Cust_Name",
    columnColor: [],
  },
  { name: "Cust_Dob", mandatory: true, dataKey: "Cust_Dob", columnColor: [] },
  {
    name: "Cust_Gender",
    mandatory: true,
    dataKey: "Cust_Gender",
    columnColor: [],
  },
  {
    name: "Mobile",
    mandatory: true,
    type: "number",
    dataKey: "Mobile",
    columnColor: [],
  },
  { name: "Email_Id", mandatory: false, dataKey: "Email_Id", columnColor: [] },
  {
    name: "Cust_Id_Type",
    mandatory: true,
    dataKey: "Cust_Id_Type",
    columnColor: [],
  },
  {
    name: "Cust_Id_Number",
    mandatory: true,
    dataKey: "Cust_Id_Number",
    columnColor: [],
  },
  {
    name: "Coinsured_Name",
    mandatory: true,
    dataKey: "Coinsured_Name",
    columnColor: [],
  },
  {
    name: "Coinsured_Dob",
    mandatory: true,
    dataKey: "Coinsured_Dob",
    columnColor: [],
  },
  {
    name: "Coinsured_Gender",
    mandatory: true,
    dataKey: "Coinsured_Gender",
    columnColor: [],
  },
  {
    name: "Coinsured_Relation",
    mandatory: true,
    dataKey: "Coinsured_Relation",
    columnColor: [],
  },
  {
    name: "Coinsured_Id_Type",
    mandatory: true,
    dataKey: "Coinsured_Id_Type",
    columnColor: [],
  },
  {
    name: "Coinsured_Id_Number",
    mandatory: true,
    dataKey: "Coinsured_Id_Number",
    columnColor: [],
  },
  {
    name: "Family_1_name",
    mandatory: false,
    dataKey: "Family_1_name",
    columnColor: [],
  },
  {
    name: "Family_1_DOB",
    mandatory: false,
    dataKey: "Family_1_DOB",
    columnColor: [],
  },
  {
    name: "Family_1_Gender",
    mandatory: false,
    dataKey: "Family_1_Gender",
    columnColor: [],
  },
  {
    name: "Family_1_Relation",
    mandatory: false,
    dataKey: "Family_1_Relation",
    columnColor: [],
  },
  {
    name: "Family_2_name",
    mandatory: false,
    dataKey: "Family_2_name",
    columnColor: [],
  },
  {
    name: "Family_2_DOB",
    mandatory: false,
    dataKey: "Family_2_DOB",
    columnColor: [],
  },
  {
    name: "Family_2_Gender",
    mandatory: false,
    dataKey: "Family_2_Gender",
    columnColor: [],
  },
  {
    name: "Family_2_Relation",
    mandatory: false,
    dataKey: "Family_2_Relation",
    columnColor: [],
  },
  {
    name: "Family_3_name",
    mandatory: false,
    dataKey: "Family_3_name",
    columnColor: [],
  },
  {
    name: "Family_3_DOB",
    mandatory: false,
    dataKey: "Family_3_DOB",
    columnColor: [],
  },
  {
    name: "Family_3_Gender",
    mandatory: false,
    dataKey: "Family_3_Gender",
    columnColor: [],
  },
  {
    name: "Family_3_Relation",
    mandatory: false,
    dataKey: "Family_3_Relation",
    columnColor: [],
  },
  {
    name: "Family_4_name",
    mandatory: false,
    dataKey: "Family_4_name",
    columnColor: [],
  },
  {
    name: "Family_4_DOB",
    mandatory: false,
    dataKey: "Family_4_DOB",
    columnColor: [],
  },
  {
    name: "Family_4_Gender",
    mandatory: false,
    dataKey: "Family_4_Gender",
    columnColor: [],
  },
  {
    name: "Family_4_Relation",
    mandatory: false,
    dataKey: "Family_4_Relation",
    columnColor: [],
  },
  {
    name: "Policy_Start_Date",
    mandatory: false,
    dataKey: "Policy_Start_Date",
    columnColor: [],
  },
  { name: "Tenure", mandatory: true, dataKey: "Tenure", columnColor: [] },
  {
    name: "Loan_Amount",
    mandatory: true,
    dataKey: "Loan_Amount",
    columnColor: [],
  },
];
