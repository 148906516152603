import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AppBackButton,
  AppHeading,
  AppRow,
  MemberIcon,
} from "../components/AppComponents";
import Button from "../components/Button";
import InputGroup from "../components/form/InputGroup";
import { AppStore } from "../lib/AppStore";
import { DocumentIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { ApiGet, setError } from "../lib/AppHelper";
import { withRouter } from "react-router-dom";
import { InputSelect } from "../components/form/InputSelect";
import { InputFile } from "../components/form/InputFile";

const govt_id_types = [
  {
    value: "aadhar",
    label: "Aadhar",
  },
  {
    value: "voter",
    label: "Voter",
  },
];

export class KYCUpdate extends Component {
  BasicBtn = React.createRef();
  MessageRef = React.createRef();

  state = {
    params: null,
    application: null,
    errors: {},
  };

  render() {
    const { application, params, errors } = this.state;
    if (application === null || params === null) return false;

    return (
      <>
        <AppBackButton to={`/subscription/${application.id}`}>
          Back
        </AppBackButton>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <AppHeading>Update KYC</AppHeading>
          <h4 className="text-md mb-6 mt-6">
            Upload documents to update your KYC.
          </h4>
          <div>
            {application.members.map((member) => {
              return (
                <AppRow
                  className="border-b-2 border-dashed pb-3 mb-4 last:border-b-0"
                  key={member.id}
                >
                  <div className="pt-1">
                    <MemberIcon member={member} />
                  </div>
                  <div className="flex-1">
                    <AppHeading className="mb-4">
                      {member.name}{" "}
                      <span className="text-sm">({member.relation_title})</span>
                    </AppHeading>
                    <InputFile
                      error={errors.profile_photo}
                      label="Profile Photo"
                      placeholder={
                        params[`member_${member.id}`].profile_photo ? (
                          <div className="text-center items-center flex-col w-32 inline-flex">
                            <DocumentIcon className="text-primary" width={24} />
                            <p className="mt-2 whitespace-nowrap text-center text-sm text-primary max-w-full truncate">
                              <span>
                                {
                                  params[`member_${member.id}`].profile_photo
                                    .name
                                }
                              </span>
                            </p>
                          </div>
                        ) : (
                          <div className="text-center items-center flex-col w-32 inline-flex">
                            <PlusCircleIcon
                              className="text-primary"
                              width={24}
                            />
                            <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                              Profile Photo
                              <br />
                              Upload Image
                            </p>
                          </div>
                        )
                      }
                      attrs={{
                        onChange: (e) =>
                          this.onFileChange(
                            e,
                            `member_${member.id}.profile_photo`
                          ),
                        accept: "image/*",
                        capture: "environment",
                      }}
                    />

                    <InputSelect
                      error={errors.document_type}
                      label="ID Type"
                      attrs={{
                        value: params[`member_${member.id}`].document_type,
                        onChange: (e) =>
                          this.onChangeHandler(
                            e,
                            `member_${member.id}.document_type`
                          ),
                      }}
                      options={govt_id_types}
                    />

                    <InputGroup
                      error={errors.document_id}
                      label="Govt ID No"
                      placeholder=""
                      attrs={{
                        value: params[`member_${member.id}`].document_id,
                        onChange: (e) =>
                          this.onChangeHandler(
                            e,
                            `member_${member.id}.document_id`
                          ),
                      }}
                    />

                    <AppRow>
                      <InputFile
                        error={errors.document_file_front}
                        label="Govt ID"
                        placeholder={
                          params[`member_${member.id}`].document_file_front ? (
                            <div className="text-center items-center flex-col w-32 inline-flex">
                              <DocumentIcon
                                className="text-primary"
                                width={24}
                              />
                              <p className="mt-2 whitespace-nowrap text-center text-sm text-primary max-w-full truncate">
                                <span>
                                  {
                                    params[`member_${member.id}`]
                                      .document_file_front.name
                                  }
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div className="text-center items-center flex-col w-32 inline-flex">
                              <PlusCircleIcon
                                className="text-primary"
                                width={24}
                              />
                              <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                                Front
                                <br />
                                ID Card Image
                              </p>
                            </div>
                          )
                        }
                        attrs={{
                          onChange: (e) =>
                            this.onFileChange(
                              e,
                              `member_${member.id}.document_file_front`
                            ),
                          accept: "image/*",
                          capture: "environment",
                        }}
                      />

                      {params[`member_${member.id}`].document_type ===
                      "aadhar" ? (
                        <InputFile
                          error={errors.document_file_back}
                          label={<span className="invisible">Govt ID</span>}
                          placeholder={
                            params[`member_${member.id}`].document_file_back ? (
                              <div className="text-center items-center flex-col w-32 inline-flex">
                                <DocumentIcon
                                  className="text-primary"
                                  width={24}
                                />
                                <p className="mt-2 whitespace-nowrap text-center text-sm text-primary max-w-full truncate">
                                  <span>
                                    {
                                      params[`member_${member.id}`]
                                        .document_file_back.name
                                    }
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <div className="text-center items-center flex-col w-32 inline-flex">
                                <PlusCircleIcon
                                  className="text-primary"
                                  width={24}
                                />
                                <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                                  Back
                                  <br />
                                  ID Card Image
                                </p>
                              </div>
                            )
                          }
                          attrs={{
                            onChange: (e) =>
                              this.onFileChange(
                                e,
                                `member_${member.id}.document_file_back`
                              ),
                            accept: "image/*",
                            capture: "environment",
                          }}
                        />
                      ) : (
                        false
                      )}
                    </AppRow>
                  </div>
                </AppRow>
              );
            })}
          </div>

          <div>
            <div>
              <Button
                attrs={{
                  ref: this.BasicBtn,
                  type: "button",
                  onClick: this.next,
                }}
                size="md"
                title={"Submit KYC"}
              />
            </div>
            <p className="text-sm text-center mt-6">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
              odit?{" "}
              <a
                href="/#"
                className="text-primary underline hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary rounded-sm px-1"
              >
                Terms &amp; Condition
              </a>
            </p>
          </div>
        </div>
      </>
    );
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params });
  };

  onFileChange = (event, key) => {
    if (event.target.files && event.target.files[0]) {
      const { params } = this.state;
      _.set(params, key, event.target.files[0]);
      this.setState({ params: params });
    }
  };

  async componentDidMount() {
    const id = this.props.match.params.appId;
    await this.getApplication(id);

    this.makeParams();
  }

  makeParams = () => {
    const { application } = this.state;

    const params = {};

    application.members.forEach((member) => {
      _.set(params, `member_${member.id}.profile_photo`, ``);
      _.set(params, `member_${member.id}.document_type`, `aadhar`);
      _.set(params, `member_${member.id}.document_id`, ``);
      _.set(params, `member_${member.id}.document_file_front`, ``);
      _.set(params, `member_${member.id}.document_file_back`, ``);
    });

    this.setState({ params: params });
  };

  getApplication = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`subscription/${id}`);
    if (response.status === "success") {
      this.setState({ application: response.data });
    } else {
      await setError(response.message);
      this.props.history.push("/");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  onError = (error) => {
    setError(error, () => {
      this.props.history.replace("/subscriptions");
    });
  };

  onSuccess = async (message) => {
    await this.getApplication(this.state.application.id);
  };
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const KYCUpdateConnect = connect(mapStateToProps)((props) => {
  return <KYCUpdate {...props} />;
});

export default withRouter(KYCUpdateConnect);
