import React, { Component } from "react";
import { Disclosure } from "@headlessui/react";
import { ArrowLeftIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import AppMenu from "../../components/AppMenu";
import Logo from "../../components/Logo";
import UserMenu from "../../components/UserMenu";
import AppSpinner from "../../components/AppSpinner";
import { connect } from "react-redux";
import { AppMessage } from "../../components/AppMessage";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { AppLogout, getError, getMessage } from "../../lib/AppHelper";
import { Modal } from "rsuite";

export class AdminMain extends Component {
  loadingRef = React.createRef();

  state = {
    open: false,
  };

  render() {
    const {
      loading,
      page_title = "",
      progress,
      MessageRef = null,
      user,
    } = this.props;

    const { open } = this.state;

    const mainStyle = loading ? { pointerEvents: "none" } : {};

    return (
      <div>
        {loading ? (
          <div
            tabIndex={0}
            ref={this.loadingRef}
            className="fixed w-screen h-screen bg-primary-50 z-50 opacity-60 flex items-center justify-center"
          >
            <AppSpinner />
          </div>
        ) : (
          false
        )}
        {MessageRef !== null ? <AppMessage ref={MessageRef} /> : false}
        <div style={mainStyle}>
          <Disclosure as="nav" className="border-b border-gray-400">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <Link to="/">
                          <Logo />
                        </Link>
                      </div>
                      <AppMenu />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        {user?.status ? (
                          <UserMenu />
                        ) : user ? (
                          <Button
                            attrs={{ onClick: () => this.onLogout() }}
                            title="Logout"
                          />
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-primary inline-flex items-center justify-center p-2 rounded-xl text-white hover:text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-200 focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
                <Disclosure.Panel className="md:hidden">
                  <AppMenu mobile={true} />
                  {user?.status ? (
                    <UserMenu mobile={true} />
                  ) : user ? (
                    <Button
                      attrs={{ onClick: () => this.onLogout() }}
                      title="Logout"
                    />
                  ) : (
                    false
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Modal
            maxWidth="md"
            open={open}
            onClose={this.handleClose}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontWeight: "600" }}>Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to logout?</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.handleLogout}
                style={{ backgroundColor: "#db2228", color: "white" }}
              >
                Yes
              </Button>
              <Button
                onClick={this.handleClose}
                style={{ backgroundColor: "lightGray", color: "black" }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <div
            className="h-1 rounded-xl bg-primary transition-all border-primary"
            style={{ width: `${progress}%` }}
          ></div>

          {page_title ? (
            <header className="bg-white shadow">
              <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center space-x-8">
                <Link
                  to="/"
                  className="text-primary flex items-center space-x-2"
                >
                  <ArrowLeftIcon width={24} />
                  <p className="text-sm">Back</p>
                </Link>
                <h1 className="text-3xl font-bold text-gray-900">
                  {page_title}
                </h1>
              </div>
            </header>
          ) : (
            false
          )}
          <main>
            {/* <AdminSidebar /> */}
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <div className="px-4 py-6 mb-10 sm:px-0">
                {this.props.children}
              </div>
              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>
    );
  }

  onLogout = () => {
    this.setState({ open: true });
  };

  handleLogout = () => {
    this.setState();
    AppLogout();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.loading !== this.props.loading && this.props.loading) {
      this.loadingRef.current.focus();
    }
  }

  componentDidMount() {
    this.checkMessages();
  }

  checkMessages = async () => {
    const error = await getError();
    if (error) {
      this.props.MessageRef?.current.show(error, "error");
    }

    const message = await getMessage();
    if (message) {
      this.props.MessageRef?.current.show(message, "success");
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user, progress, products } = state;
  return {
    loading: loading,
    user: user,
    progress: progress,
    products: products,
  };
};

export default connect(mapStateToProps)((props) => {
  return <AdminMain {...props} />;
});
