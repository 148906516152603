import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const ProductViewToggle = ({
  selectedView,
  setSelectedView,
  toggleData = [
    { label: "SampleView1", value: "1" },
    { label: "SampleView2", value: "2" },
  ],
  onChange,
}) => {
  const handleChange = (event, newView) => {
    if (newView !== null) {
      setSelectedView(newView);
      onChange?.(newView);
    }
  };

  return (
    <div className="flex justify-center">
      <ToggleButtonGroup
        value={selectedView}
        exclusive
        onChange={handleChange}
        aria-label="product view toggle"
        className="bg-gray-100 p-0.5 rounded-md shadow-sm"
        size="small"
      >
        {toggleData.map((item) => {
          return (
            <ToggleButton
              value={item?.value}
              aria-label={item?.value}
              sx={{
                textTransform: "none",
                borderRadius: "5px",
                fontWeight: 500,
                fontSize: "0.8rem",
                padding: "3px 8px",
                "&.Mui-selected": {
                  backgroundColor: "#d32f2f",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#b71c1c" },
                },
              }}
            >
              {item?.label}
            </ToggleButton>
          );
        })}
        {/* <ToggleButton
          value="Drafts"
          aria-label="drafts"
          sx={{
            textTransform: "none",
            borderRadius: "5px",
            fontWeight: 500,
            fontSize: "0.8rem",
            padding: "3px 8px",
            "&.Mui-selected": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              "&:hover": { backgroundColor: "#b71c1c" },
            },
          }}
        >
          Drafts
        </ToggleButton>

        <ToggleButton
          value="Products"
          aria-label="products"
          sx={{
            textTransform: "none",
            borderRadius: "5px",
            fontWeight: 500,
            fontSize: "0.8rem",
            padding: "3px 8px",
            "&.Mui-selected": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              "&:hover": { backgroundColor: "#b71c1c" },
            },
          }}
        >
          Products
        </ToggleButton> */}
      </ToggleButtonGroup>
    </div>
  );
};

export default ProductViewToggle;
