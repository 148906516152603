import React from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";

export function RadioInput({
  loading = false,
  name,
  label = "",
  requiredMark = "",
  starMark = false,
  labelLink = null,
  attrs = {},
  options = [],
  value = null,
  error = null,
  buttonHidden = null,
}) {
  return (
    <div className="mb-4">
      <InputLabel
        label={label}
        labelLink={labelLink}
        requiredMark={requiredMark}
        buttonHidden={buttonHidden}
        starMark={starMark}
      />
      <div className="flex gap-4 flex-wrap mt-2">
        {options.map((option, index) => {
          return (
            <div key={index} className="flex gap-1">
              <input
                id={`${name}-${index}-${option.value}`}
                name={`${name}`}
                type="radio"
                className="focus:ring-primary-500 h-5 w-5 text-primary-500 border-gray-300"
                checked={value == option.value}
                value={option.value}
                {...attrs}
              />
              <label
                className={`border-red-400 ml-2 block text-sm mr-1 text-gray-700`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-500">{error.join(" ")}</p>
      ) : (
        false
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <RadioInput {...props} />;
});
