import React, { useEffect, useState } from "react";
import vector from "../assets/png/Vector.png";
import { ArrowLeftIcon, ChevronRightIcon, XIcon } from "@heroicons/react/solid";
import { Divider } from "@mui/material";
import { ApiGet, JavaApiGet, JavaApiPut, setError } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const SubscriptionPreview = () => {
  const history = useHistory();
  const params = useParams();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [couponList, setCouponList] = useState(null);
  const [couponDetails, setCouponDetails] = useState(null);
  const [couponStep, setCouponStep] = useState(1);
  const [usersDetails, setUsersDetails] = useState(null);

  const getApplication = async () => {
    const id = params?.id;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`subscription/${id}`);
    if (response.status === "success") {
      if (response?.data?.policy_id) {
        history.replace("/");
      }
      const { product_id, reseller_id } = response?.data;
      setSubscriptionDetails(response?.data);
      setUsersDetails(response?.data?.insured_member);
      setCouponDetails(response?.data?.product_coupon_mapping);
      if (product_id && reseller_id) {
        fetchCoupon(product_id, reseller_id, response?.data);
      }
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
      await setError(response?.message);
      history.replace("/");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const applyCoupon = async (type, id, data) => {
    const { reseller_id, product_id, application_id } =
      data || subscriptionDetails;
    const coupon_id = id || subscriptionDetails?.coupon_id;
    const payload = {
      resellerId: reseller_id,
      productId: product_id,
      couponId: coupon_id,
      applicationId: application_id,
      applied: type ? type : false,
    };
    const response = await JavaApiPut(
      "enrollment-service/api/active_inactive_coupon",
      payload
    );
    if (response.code === 200) {
      if (type) {
        toast.success(response?.message);
      }
      getApplication();
      setCouponStep(1);
    } else {
      getApplication();
      setCouponStep(1);
    }
  };

  const fetchCoupon = async (productId, resellerId, data) => {
    const response = await JavaApiGet(
      `enrollment-service/api/coupons/${resellerId}/${productId}`
    );
    if (response.code === 200) {
      if (data?.coupon_id) {
        const isAvailable = response?.responseObject?.find(
          (coupon) => coupon.couponId === data?.coupon_id
        );
        if (!isAvailable) {
          await applyCoupon("", data?.coupon_id, data);
        }
      }
      setCouponList(response?.responseObject);
    } else if (data?.coupon_id) {
      await applyCoupon();
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  const cleanDescription = () => {
    if (subscriptionDetails?.product_master?.description) {
      return subscriptionDetails?.product_master?.description
        ?.replace(/<\/?li>/g, "")
        ?.split("\n")
        ?.map((item) => item.trim());
    }
  };

  const onSaveHandle = () => {
    const id = params?.id;
    history.push(`/subscription/${id}`);
  };

  const handleBackButton = () => {
    if (couponStep === 2) {
      setCouponStep(1);
    } else {
      history.goBack();
    }
  };

  const calTotalAmount = (data) => {
    if (data?.actual_policy_amount) {
      return `₹${data?.actual_policy_amount}`;
    } else if (data?.amount) {
      return `₹${data?.amount}`;
    } else {
      return false;
    }
  };

  const discountedAmount = (data) => {
    if (data?.product_coupon_mapping && data?.actual_policy_amount) {
      return `₹${data?.actual_policy_amount - data?.amount}`;
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        onClick={handleBackButton}
        className="flex items-center text-primary pb-3 cursor-pointer"
      >
        <ArrowLeftIcon width={17} className="mr-3" />
        <p>{"Back"}</p>
      </div>

      {couponStep === 1 && usersDetails ? (
        <div className="flex flex-col gap-4">
          {subscriptionDetails?.product_master?.product_lab_test?.auto_book ? (
            <section className="border rounded-xl px-10 py-6 bg-primary-50 flex flex-col gap-4">
              <div className="flex justify-between">
                <label className="font-semibold">Member name</label>
                <p>{usersDetails?.[0]?.name}</p>
              </div>
              <div className="flex justify-between">
                <label className="font-semibold">Phone number</label>
                <p>{usersDetails?.[0]?.mobile}</p>
              </div>
              <div className="flex justify-between">
                <label className="font-semibold">Plan name</label>
                <p>
                  {subscriptionDetails?.product_master?.product_display_name ||
                    subscriptionDetails?.product_master?.product_name}
                </p>
              </div>
              <div>
                <label className="font-semibold">Plan Detail</label>
                <ul className="list-disc pl-4 text-base my-3 mx-3">
                  {cleanDescription()?.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </div>
            </section>
          ) : (
            <section className="border rounded-xl px-10 py-6 bg-primary-50 flex flex-col gap-4">
              <p className="font-semibold">Member name</p>
              <div class="overflow-hidden rounded-xl border border-gray-400 w-full">
                <table className="table-fixed border-collapse w-full">
                  <thead className="bg-primaryBlack text-white ">
                    <tr>
                      <th>Name</th>
                      <th>D.O.B</th>
                      <th>Gender</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersDetails?.map((item) => (
                      <tr key={item?.id} className="border-b">
                        <td align="center">{item.name}</td>
                        <td align="center">
                          {item?.dob && moment(item.dob).format("DD-MM-YYYY")}
                        </td>
                        <td align="center">{item?.gender}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          )}

          {couponList?.length > 0 ? (
            <section className="rounded-xl border">
              <h2>
                <button
                  type="button"
                  className={`${
                    !couponDetails ? "cursor-pointer" : "cursor-default"
                  } flex items-center justify-between w-full p-5 font-medium 
                     rtl:text-right text-gray-500 gap-3`}
                  onClick={() => !couponDetails && setCouponStep(2)}
                >
                  <span className="flex items-center gap-3">
                    <img className="h-5" src={vector} alt="img" />{" "}
                    {couponDetails
                      ? `${
                          couponDetails?.product_coupon_master?.coupon_name
                        } (${
                          couponDetails?.product_coupon_master
                            ?.discount_percentage || "--"
                        }% off)`
                      : "Use Coupons"}
                  </span>
                  {couponDetails ? (
                    <XIcon
                      className="h-5 cursor-pointer"
                      onClick={() => applyCoupon()}
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 cursor-pointer"
                      onClick={() => setCouponStep(2)}
                    />
                  )}
                </button>
              </h2>
            </section>
          ) : (
            <></>
          )}

          <section className="bg-primary-50 p-6 border rounded-xl flex flex-col gap-4">
            <div className="flex justify-between">
              <label className="font-semibold">Total Amount</label>
              <p className="text-lg">{calTotalAmount(subscriptionDetails)}</p>
            </div>
            {discountedAmount(subscriptionDetails) ? (
              <div className="flex justify-between">
                <label className="font-semibold">Discount applied</label>
                <p className="text-lg">
                  {discountedAmount(subscriptionDetails)}
                </p>
              </div>
            ) : (
              false
            )}
            <Divider />
            <div className="flex justify-between">
              <label className="font-semibold">Amount Payable</label>
              <p className="text-primary font-semibold text-xl">
                {subscriptionDetails?.amount &&
                  `₹${subscriptionDetails?.amount}`}
              </p>
            </div>
          </section>
          <section className="flex justify-center">
            <button
              onClick={onSaveHandle}
              className="px-3 py-2 rounded-lg bg-primary-500 text-white cursor-pointer"
            >
              Save and continue
            </button>
          </section>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {couponList?.map((item) => (
            <div
              key={item.couponId}
              class="p-6 bg-primary-50 border rounded-xl flex flex-col gap-2"
            >
              <div className="flex justify-between">
                <div className="font-medium text-lg">{item?.couponName}</div>
                <button
                  onClick={() => applyCoupon(true, item?.couponId)}
                  className="border-2 border-green-600 px-3 rounded-lg py-1 text-green-600 font-medium bg- 
                   green-50"
                >
                  Apply
                </button>
              </div>
              <Divider />
              <p className="text-base font-medium">Valid for new user.</p>
              {item?.couponContent && (
                <p className="text-green-500 font-medium text-sm">
                  {item?.couponContent}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SubscriptionPreview;
