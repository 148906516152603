import { useState } from "react";
import CustomAccordian from "../../common/components/CustomAccordian";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";
import { ArrowRight, ArrowDropDown } from "@mui/icons-material";
import { Checkbox } from "rsuite";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ProductBenefits = ({
  values,
  setFieldValue,
  productBenefits,
  loading,
}) => {
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = (id, key, e) => {
    if (e.target.checked) {
      if (values?.[key]) setFieldValue(key, [...values?.[key], id]);
      else {
        setFieldValue(key, [id]);
      }
    } else {
      let data = values[key]?.filter((item) => item != id);
      setFieldValue(key, data);
    }
  };

  const handleAllBenefitChange = (key, e) => {
    if (e) {
      let data = productBenefits[key].map((item) => item.id);
      setFieldValue(key, data);
    } else {
      setFieldValue(key, []);
    }
  };

  return (
    <>
      {loading ? (
        <ShimmerLoader rows={8} />
      ) : (
        Object.keys(productBenefits || {}).map((item, index) => {
          return (
            <CustomAccordian
              header={
                <div
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                >
                  {open ? (
                    <ArrowDropDown className="rotate-180" />
                  ) : (
                    <ArrowRight />
                  )}
                  <Checkbox
                    checked={
                      productBenefits?.[item]?.length === values?.[item]?.length
                    }
                    name={item}
                    value={item}
                    onChange={(e, v) => {
                      handleAllBenefitChange(item, v);
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <FontAwesomeIcon
                        icon={faClipboardCheck}
                        size="1x"
                        style={{ color: "#57C4FF" }}
                      />
                      <p
                        className="text-sm text-gray-700"
                        style={{ fontWeight: 550 }}
                      >
                        {" "}
                        {item}
                      </p>
                    </div>
                  </Checkbox>
                </div>
              }
              bordered={true}
              children={
                <ul
                  className=" space-y-1 text-gray-500 list-inside dark:text-gray-400"
                  style={{ marginLeft: "10px" }}
                >
                  {productBenefits[item].map((obj) => {
                    return (
                      <li key={obj.id} className="flex items-center gap-1">
                        <input
                          checked={values?.[item]?.includes(obj.id)}
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(obj.id, item, e)
                          }
                        />
                        <p
                          style={{
                            color: "black",
                            lineHeight: "1.6",
                            fontSize: "14px",
                            fontStyle: "italic",
                          }}
                        >
                          {obj.description_text}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              }
            />
          );
        })
      )}
    </>
  );
};
