import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Placeholder } from "rsuite";
import { makeStyles } from "@mui/styles";
import { customStyles } from "../data";
import "./styles.css";

const CustomDataTable = ({
  title = "Data Table",
  loading,
  columns,
  data,
  rowsPerPage,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  subHeader = false,
  subHeaderComponent,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%",
    },
  }));

  const CustomLoader = () => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        {[1, 2, 3, 4].map(() => {
          return (
            <Placeholder.Paragraph
              rows={4}
              style={{ margin: 10 }}
              rowHeight={8}
              rowMargin={12}
              active={true}
              graph="circle"
            />
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <DataTable
        title={title}
        progressPending={loading}
        progressComponent={<CustomLoader />}
        persistTableHead={true}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        columns={columns}
        data={data}
        theme="customTheme"
        customStyles={customStyles}
        onSort={handleSort}
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponent}
      />
    </div>
  );
};

export default CustomDataTable;
