import React, { useState, useEffect } from "react";
import { AppBackButton } from "../../components/AppComponents";
import PurchaseStock from "./PurchaseStock/Index";
import TransferStock from "./TransferStock/Index";
import { PURCHASE_STOCK, TRANSFER_STOCK } from "../../lib/constants";

const InventoryStock = ({ component }) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <AppBackButton />
      {component === PURCHASE_STOCK && (
        <PurchaseStock component={component} windowSize={windowSize} />
      )}
      {component === TRANSFER_STOCK && (
        <TransferStock component={component} windowSize={windowSize} />
      )}
    </>
  );
};

export default InventoryStock;
