import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

const Cards = ({
  primaryText,
  Image,
  ImageHelperText,
  icon,
  loader = false,
  secondaryText,
  redirect = {},
  iconText = null,
  iconColor,
}) => {
  return (
    <div
      className="flex flex-col py-3 px-4 gap-4 bg-white"
      style={{
        borderRadius: "24px",
        boxShadow: "2px 2px 4px 0px #0000000A",
      }}
    >
      <div
        className="text-base font-semibold text-left"
        style={{ color: "#393939" }}
      >
        {primaryText}
      </div>
      <div className="text-center">
        <div
          className="flex flex-col justify-center"
          style={{ height: 45, fontSize: 22, fontWeight: "500" }}
        >
          {loader ? (
            <CircularProgress
              size="30px"
              style={{ color: "#db2228", alignSelf: "center" }}
            />
          ) : (
            secondaryText && secondaryText
          )}
          {Image && (
            <img
              src={Image}
              height={45}
              width={45}
              style={{ alignSelf: "center" }}
            />
          )}
        </div>

        {redirect.to ? (
          <div
            className="mt-4 m-auto right-0 font-medium text-xs text-center"
            style={{ color: iconColor, width: "fitContent" }}
          >
            <Link to={{ pathname: redirect.to, state: redirect.state }}>
              {iconText && iconText}
              {icon && icon}
            </Link>
          </div>
        ) : null}
      </div>
      {ImageHelperText && (
        <div className="text-center mb-2">{ImageHelperText}</div>
      )}
    </div>
  );
};

export default Cards;
