import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../common/components/CustomDataTable";
import { ApiPost } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import VendorIcon from "../../../../src/assets/productCreation/vendor.png";
import VendorInfo from "./VendorInfo";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { CustomVendorSearchFilter } from "../common/components/CustomSearchFilter";
import { Modal, Button } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import AddVendor from "./VendorCreation";
import CustomModal from "../../admin/components/CustomModal";
import { addProductButtonStyles } from "../common/data";
import { Close } from "@mui/icons-material";

const VendorGrid = () => {
  const initialValue = {
    name: "",
    vendor_code: "",
    approved: "",
    rowsPerPage: 5,
    page: 1,
    limit: 5,
    orderBy: "DESC",
    shortBy: "",
    from: "",
    to: "",
  };
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchFilters, setSearchFilters] = useState(initialValue);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const payloadRef = useRef();

  const vendorGridColumns = [
    {
      name: (
        <Tooltip title="Vendor details" placement="right" arrow>
          <p className="w-full flex justify-center items-center">Vendor Code</p>
        </Tooltip>
      ),
      selector: (row) => row?.vendor_code,
      center: true,
      cell: (row) => (
        <VendorInfo
          id={row?.vendor_code}
          name={row?.name}
          vendorCode={row?.vendor_code}
          status={row?.approved}
        />
      ),
    },
    { name: "Name", selector: (row) => row?.name, width: "300px" },

    {
      name: "Active Status",
      selector: (row) => {
        if (row?.approved)
          return (
            <Tooltip title="Active" placement="right" arrow>
              <p className="w-full flex justify-center items-center">
                <FaCheckCircle style={{ color: "green" }} />
              </p>
            </Tooltip>
          );
        else if (row?.approved == false) {
          return (
            <Tooltip title="Awaiting approval" placement="right" arrow>
              <p className="w-full flex justify-center items-center">
                <FaClock style={{ color: "#8B8000" }} />
              </p>
            </Tooltip>
          );
        } else {
          return "-";
        }
      },
      center: true,
    },
  ];

  const debouncedFetchVendors = async (payload) => {
    const data = {
      ...payload,
      approved:
        payload.approved === ""
          ? ""
          : payload.approved === "active"
          ? true
          : false,
    };
    if (payloadRef.current === JSON.stringify(data)) {
      return;
    } else {
      setLoading(true);
      payloadRef.current = JSON.stringify(data);
      const response = await ApiPost("admin/get-all-vendor", data);
      if (response.code == 200) {
        setRowData(response.data?.results);
        setTotal(response.data?.total);
      } else {
        toast.error(response?.message);
        setRowData([]);
        setTotal(0);
      }
      setLoading(false);
    }
  };

  const debouncedHandleSort = ({ sortField }) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        shortBy: sortField,
        orderBy: searchFilters.orderBy == "DESC" ? "ASC" : "DESC",
      };
      debouncedFetchVendors(data);
      return data;
    });
  };

  const handlePageChange = (page) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        page: page,
      };
      debouncedFetchVendors(data);
      return data;
    });
  };

  const handlePerRowsChange = (limit, page) => {
    setSearchFilters((prev) => {
      const data = {
        ...prev,
        page: page,
        limit: limit,
      };
      debouncedFetchVendors(data);
      return data;
    });
  };

  const handleSearch = () => {
    debouncedFetchVendors({ ...searchFilters, page: 1 });
  };

  const onResetHandle = () => {
    setSearchFilters((prev) => {
      const data = initialValue;
      debouncedFetchVendors(data);
      return data;
    });
  };

  useEffect(() => {
    debouncedFetchVendors(searchFilters);
  }, []);

  return (
    <div className="relative shadow-md sm:rounded-lg w-full pb-2">
      <CustomDataTable
        title={
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center gap-2 ">
              <img
                alt=""
                style={{ width: "30px", height: "30px" }}
                src={VendorIcon}
              />
              <p className="text-l font-bold"> Vendors</p>
            </div>
            <Button
              style={addProductButtonStyles}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <>
                <PlusIcon style={{ width: 14, height: 14 }} />
                Add Vendor
              </>
            </Button>
          </div>
        }
        data={rowData}
        columns={vendorGridColumns}
        loading={loading}
        rowsPerPage={searchFilters.rowsPerPage}
        totalRows={total}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        handleSort={debouncedHandleSort}
        subHeader={true}
        subHeaderComponent={
          <CustomVendorSearchFilter
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            handleSubmit={() => {
              handleSearch();
            }}
            onResetHandle={onResetHandle}
          />
        }
      />

      <CustomModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        backDropClose={true}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        borderRadius="16px"
      >
        <div className="w-full flex justify-between">
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              style={{ width: "30px", height: "30px" }}
              src={VendorIcon}
            />
            <p className="text-l font-bold"> Add Vendor</p>
          </div>
          <Close
            className="cursor-pointer"
            onClick={() => setIsModalOpen(false)}
          />
        </div>

        <Modal.Body>
          <AddVendor
            setIsModalOpen={setIsModalOpen}
            fetchData={debouncedFetchVendors}
          />
        </Modal.Body>
      </CustomModal>
    </div>
  );
};

export default VendorGrid;
