import React, { useEffect, useRef, useState } from "react";
import { AppBackButton, AppHeading } from "../../../components/AppComponents";
import Button from "../../../components/Button";
import { InputDate } from "../../../components/form/InputDate";
import moment from "moment";
import { CheckPicker, Toggle } from "rsuite";
import ReportTable from "./ReportTable";
import { AppStore } from "../../../lib/AppStore";
import { getApiClaimSales, postApiClaimSales } from "../../../lib/AppHelper";
import { Pagination } from "rsuite";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import computeValue, { ASC, getNestedValue } from "./constant";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

const ServiceabilityReports = () => {
  const prevFilterRef = useRef();
  const hasFetchedReports = useRef(false);
  const [initialsValues, setInitialValues] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    viewBy: "Disb",
    externalFilters: [],
  });
  const [externalBenefitsArray, setExternalBenefitsArray] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [sort, setSort] = useState({ label: "resellerName", sortBy: "ASC" });

  const [reports, setReports] = useState([]);
  const handleOnChangeDate = (e, key) => {
    const myVal = { ...initialsValues };
    myVal[key] = new Date(e).toISOString().split("T")[0];
    setInitialValues(myVal);
  };
  const onChangeHandler = (e, key) => {
    const myObjVal = { ...initialsValues };
    myObjVal[key] = e.target.value;
    setInitialValues(myObjVal);
  };

  const fetchReports = async (pg = "") => {
    if (
      !initialsValues.from ||
      !initialsValues.to ||
      initialsValues.externalFilters.length === 0
    ) {
      toast.warning("Please fill mandantory field");
      return;
    }
    let data = {
      startDate: initialsValues.from,
      endDate: initialsValues.to,
      viewedBy: initialsValues.viewBy,
      page: pg ? pg : page - 1,
      size: 10,
      benefits: initialsValues.externalFilters,
    };
    if (JSON.stringify(prevFilterRef.current) === JSON.stringify(data)) return;
    AppStore.dispatch({ type: "LOADING", loading: true });
    await postApiClaimSales("serviceability_report", data)
      .then((res) => {
        if (res.code === 200) {
          setToggle(false);
          setReports(res.data);
          setCount(res.count);
        } else if (res.code === 400) {
          console.log("Err", res.code);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        prevFilterRef.current = data;
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
  };

  const fetchExternalBenefits = async () => {
    await getApiClaimSales("external_benefits")
      .then((res) => {
        if (res.code === 200) {
          const external_benefitsArray = Object.entries(res.data).map(
            ([key, value]) => {
              return {
                label: key.toUpperCase().replace(/ /g, " "),
                value: value.replace(/ /g, " "),
              };
            }
          );
          setExternalBenefitsArray(external_benefitsArray);
          setInitialValues({
            ...initialsValues,
            externalFilters: external_benefitsArray.map((item) => item.value),
          });
        } else if (res.code === 400) {
          console.log("Err", res.code);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })

      .finally(() => {
        console.log("finally");
      });
  };

  const handelToggleChange = (value) => {
    if (value) {
      fetchBelow100();
    }
    setToggle(value);
  };

  const handelPagination = (pg) => {
    if (page === pg) return;
    setPage(pg);
    fetchReports(pg - 1);
  };

  const fetchBelow100 = async () => {
    const result = [];
    const data = [...reports];
    data?.map((record) => {
      const { productDtos } = record;
      productDtos?.map((item) => {
        const { hospiCashDtos, acDtos } = item.productBenefits;
        if (
          computeValue(hospiCashDtos, item) < 100 ||
          computeValue(acDtos, item) < 100
        )
          result.push(record);
        // if (computeValue(acDtos, item) < 100) result.push(record);
      });
    });
    setFilteredData(result);
  };

  function onRefresh() {}
  useEffect(() => {
    fetchExternalBenefits();
  }, []);

  useEffect(() => {
    if (
      !hasFetchedReports.current &&
      initialsValues.externalFilters.length > 0
    ) {
      fetchReports();
      hasFetchedReports.current = true;
    }
  }, [initialsValues.externalFilters]);

  useEffect(() => {
    const sortedData = [...reports];
    if (sort.label) {
      sortedData.sort((a, b) => {
        let aValue = getNestedValue(a, sort.label);
        let bValue = getNestedValue(b, sort.label);

        if (sort.label.includes("activationData")) {
          const benefitType = sort.label.split(".")[0];
          aValue =
            a.productDtos?.[0].productBenefits[benefitType]?.[0]
              ?.activationData || 0;
          bValue =
            b.productDtos?.[0].productBenefits[benefitType]?.[0]
              ?.activationData || 0;
        }

        if (aValue < bValue) {
          return sort.sortBy === "ASC" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sort.sortBy === "ASC" ? 1 : -1;
        }
        return 0;
      });
    }

    setReports(sortedData);
  }, [sort]);
  return (
    <>
      <div className="grid md:grid-cols-2 gap-2">
        <div>
          <AppBackButton to="/">Back</AppBackButton>
        </div>
      </div>
      <AppHeading
        className="mb-10 text-center text-sm font-semibold"
        size="xl"
        style={{ color: "#DB2228" }}
      >
        Serviceability reports
      </AppHeading>
      <div className="grid md:grid-cols-5 gap-2">
        <div>
          <InputDate
            label="From"
            value={[initialsValues.from]}
            placeholder="from"
            maxDate={new Date()}
            attrs={{
              onChange: (e) => handleOnChangeDate(e, "from"),
              value: new Date(initialsValues.from),
            }}
          />
        </div>
        <div>
          <InputDate
            label="To"
            value={initialsValues.to}
            placeholder="to"
            maxDate={new Date()}
            // minDate={new Date(initialsValues.from)}
            attrs={{
              onChange: (e) => handleOnChangeDate(e, "to"),
              value: new Date(initialsValues.to),
            }}
          />
        </div>
        <div style={{ marginTop: 5 }}>
          <label className="flex text-sm text-gray-700 justify-left pb-2">
            View By
          </label>
          <select
            onChange={(e) => onChangeHandler(e, "viewBy")}
            value={initialsValues.viewBy}
            className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
          >
            <option value="Uploaded">Uploaded</option>
            <option value="Disb">Disbursed</option>
          </select>
        </div>
        <div style={{ marginTop: 5 }}>
          <label className="flex text-sm text-gray-700 justify-left pb-2">
            Externals Insurance Benefit{" "}
            <span style={{ color: "#DB2228", fontSize: "16px" }}>*</span>
          </label>
          <CheckPicker
            value={initialsValues.externalFilters}
            data={externalBenefitsArray}
            searchable={false}
            onChange={(value) =>
              setInitialValues({ ...initialsValues, externalFilters: value })
            }
            style={{ width: 224 }}
            placeholder="-select-"
          />
        </div>
        <div
          style={{
            paddingTop: "2rem",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            attrs={{ type: "button", onClick: () => fetchReports() }}
            size="md"
            title={""}
          >
            <Tooltip title="Search" placeholder="left" arrow>
              <SearchIcon />
            </Tooltip>
          </Button>
          <Button
            attrs={{ type: "button", onClick: onRefresh }}
            size="md"
            title={""}
          >
            <Tooltip title="Refresh" placeholder="top" arrow>
              <RefreshIcon />
            </Tooltip>
          </Button>
        </div>
      </div>
      <ReportTable
        reports={reports}
        initialsValues={initialsValues}
        setSort={setSort}
        sort={sort}
      />
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={4}
          size="xs"
          layout={["total", "-", "pager", "skip"]}
          total={toggle ? filteredData.length : count}
          limit={10}
          activePage={page}
          onChangePage={(pg) => handelPagination(pg)}
        />
      </div>
    </>
  );
};

export default ServiceabilityReports;
