import {
  ArrowLeftIcon,
  DocumentIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppHeading } from "../components/AppComponents";
import Button from "../components/Button";
import { InputFile } from "../components/form/InputFile";
import InputGroup from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { InputSelect } from "../components/form/InputSelect";
import { setResellerToken } from "../helpers/Helper";
import {
  ApiGet,
  ApiPost,
  AppLogout,
  removeData,
  setData,
  setError,
  setMessage,
  validateEmail,
  validateName,
  validateNumber,
} from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import { toast } from "react-toastify";
import { govtIds } from "../services/common/data";
import { addTeamDocSize, extensions, getFileExtension } from "../lib/constants";
import TermsModal from "../components/form/TermsModal";
import { isValidPan } from "../utils/govIdValidation";

export class Register extends Component {
  MessageRef = React.createRef();
  BasicBtn = React.createRef();
  checked_pin = _.get(this.props.user, "basic.pincode", "");

  state = {
    govDetails: {
      ...govtIds?.filter(
        (elm) => this.props.user?.basic?.government_id_type === elm.value
      )?.[0],
    },
    government_id: this.props.user?.basic?.government_id
      ? this.props.user?.basic?.government_id
      : "",
    government_id_type: this.props?.user?.basic?.government_id_type
      ? this.props.user?.basic?.government_id_type
      : "",
    document_front_url: this.props?.user?.basic?.document_front_url,
    document_back_url: this.props?.user?.basic?.document_back_url,

    params: {
      mobile: this.props.user.mobile,
      email: this.props?.user?.email ? this.props?.user?.email : "",
      name: this.props?.user?.name ? this.props?.user?.name : "",

      dob: "",
      // gender: this.props.user.gender ? this.props.user.gender : "m",
      pin: _.get(this.props, "user.basic.pincode", ""),
      state: _.get(this.props, "user.basic.state", ""),
      qualification: _.get(this.props, "user.professional.qualification", ""),
      profession: _.get(this.props, "user.professional.profession", ""),
      invesment_work: _.get(
        this.props,
        "user.professional.invesment_work",
        "full"
      ),
      income: _.get(this.props, "user.professional.income", 0),
      experience: _.get(this.props, "user.professional.experience", 0),
      payment_mode: _.get(this.props, "user.banking.payment_mode", "neft"),
      pan: _.get(this.props, "user.banking.pan", ""),
      tin: _.get(this.props, "user.banking.tin", ""),
      document: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      upi_id: _.get(this.props, "user.banking.upi", ""),
      // photo_base64: null,
      photo: "",
      governmentIdType: _.get(this.props, "user.banking.upi", ""),
      governmentId: "",
    },
    step: 1,
    errors: {},
    permissions: this.props?.user?.permissions,
    open: false,
  };

  isReady = () => {
    const { step, params, permissions, govDetails } = this.state;
    if (step === 1) {
      if (
        permissions?.hide_reseller_pincode &&
        permissions?.hide_reseller_email &&
        params?.mobile &&
        params?.name
      ) {
        return true;
      } else if (
        permissions?.hide_reseller_pincode &&
        params?.mobile &&
        params?.email &&
        params?.name
      ) {
        if (validateEmail(params?.email?.trim())) {
          return true;
        }
      } else if (
        permissions?.hide_reseller_email &&
        params?.mobile &&
        params?.name &&
        params?.pin?.length === 6
      ) {
        return true;
      } else if (params?.mobile && params?.email && params?.name && params?.pin)
        if (params?.pin?.length === 6 && validateEmail(params?.email?.trim())) {
          return true;
        }
    } else if (step === 2) {
      if (
        permissions?.mandatory_reseller_government_id &&
        params?.governmentIdType &&
        params?.governmentId &&
        govDetails?.validateFunction(params?.governmentId)
      ) {
        return true;
      } else if (params?.governmentIdType && !params?.governmentId) {
        return false;
      } else if (
        params?.governmentId &&
        !govDetails?.validateFunction(params?.governmentId)
      ) {
        return false;
      } else if (!permissions?.mandatory_reseller_government_id) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { loading, user } = this.props;
    const {
      params,
      step,
      errors,
      permissions,
      government_id,
      government_id_type,
      document_front_url,
      document_back_url,
    } = this.state;
    const ready = this.isReady();

    const cmpnyGovtIds = [
      ...govtIds,
      {
        label: "Pan Card",
        value: "pan",
        maxLength: 10,
        frontImg: true,
        backImg: false,
        regValidation: null,
        validateFunction: isValidPan,
      },
    ];
    return (
      <>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <AppHeading>Registration</AppHeading>
            {step > 1 ? (
              <div
                onClick={this.prev}
                className="flex items-center text-primary cursor-pointer pt-4"
              >
                <ArrowLeftIcon width={17} className="mr-3" />
                {"Back"}
              </div>
            ) : (
              false
            )}
            {/* Basic Information */}
            {step === 1 ? (
              <div>
                <h4 className="mt-1 text-md mb-6">
                  Please enter basic details
                </h4>
                <InputGroup
                  starMark={true}
                  label="Phone no"
                  type="tel"
                  placeholder="0000000000"
                  prefix="+91"
                  validateError={(value) => {
                    if (validateNumber(value)) {
                      return false;
                    } else {
                      return "please enter valid mobile number";
                    }
                  }}
                  attrs={{
                    maxLength: 10,
                    value: user.mobile,
                    readOnly: true,
                  }}
                />
                <InputGroup
                  starMark={true}
                  error={errors.name}
                  label="Employee / Company Name"
                  placeholder="Name"
                  attrs={{
                    value: params.name,
                    onChange: (e) => {
                      if (
                        /^[a-zA-Z\s]+$/.test(e.target.value) ||
                        e.target.value === ""
                      )
                        this.onChangeHandler(e, "name");
                    },
                    maxLength: 64,
                    autoFocus: true,
                    readOnly: permissions?.reseller_name_read_only,
                  }}
                />

                {permissions?.hide_reseller_email ? null : (
                  <InputGroup
                    starMark={permissions?.hide_reseller_email ? false : true}
                    error={errors.email}
                    label="Email"
                    placeholder="Enter email..."
                    validateError={(value) => {
                      if (validateEmail(value.trim())) {
                        return false;
                      } else if (!value) {
                        return false;
                      } else {
                        return "please enter correct email ID";
                      }
                    }}
                    attrs={{
                      value: params.email,
                      onChange: (e) => this.onChangeHandler(e, "email"),
                    }}
                  />
                )}
                {permissions?.government_id_show_on_profile ? (
                  <>
                    <InputSelect
                      width="full"
                      label="Govt ID Type"
                      attrs={{
                        disabled: true,
                        value: government_id_type,
                        readOnly: true,
                      }}
                      options={govtIds}
                    />
                    <InputGroup
                      label="Government ID"
                      placeholder="Enter Govt ID..."
                      attrs={{
                        disabled: true,
                        value: government_id,
                        readOnly: true,
                      }}
                    />
                    <div className="flex-row flex-wrap">
                      {document_front_url && (
                        <InputFile
                          label="Attachment-front"
                          file={document_front_url}
                          attrs={{
                            disabled: document_front_url,
                            accept: "image/*,.pdf",
                          }}
                          url={document_front_url}
                          readOnly={true}
                        />
                      )}
                      {document_back_url && (
                        <InputFile
                          label="Attachment-back"
                          file={document_back_url}
                          isCrossView={document_back_url}
                          attrs={{
                            disabled: document_back_url,
                            accept: "image/*,.pdf",
                          }}
                          url={document_back_url}
                          readOnly={true}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  false
                )}
                {permissions?.hide_reseller_pincode ? null : (
                  <>
                    <InputGroup
                      starMark={
                        permissions?.hide_reseller_pincode ? false : true
                      }
                      error={errors.pin}
                      label="Pincode"
                      placeholder="Pincode"
                      attrs={{
                        maxLength: 6,
                        value: params?.pin,
                        onChange: (e) => {
                          if (e.target.value?.length === 0) {
                            this.onChangeHandler(
                              { target: { value: null } },
                              "state"
                            );
                          }
                          this.onChangeHandler(
                            {
                              target: {
                                value: e.target.value?.replace(/\D/, ""),
                              },
                            },
                            "pin"
                          );
                          if (e.target.value?.length === 6) this.checkPin();
                        },
                        onBlur: this.checkPin,
                      }}
                      onEnter={this.checkPin}
                    />

                    {params.state ? (
                      <InputGroup
                        label="Your State"
                        placeholder="Enter state..."
                        attrs={{ value: params?.state, readOnly: true }}
                      />
                    ) : (
                      false
                    )}
                  </>
                )}
              </div>
            ) : (
              false
            )}

            {/* Banking Information */}
            {step === 2 && !permissions?.hide_reseller_banking_section ? (
              <div>
                <h4 className="mt-1 text-md mb-6">
                  Please enter KYC details to complete registration
                </h4>
                <>
                  <InputSelect
                    width="full"
                    requiredMark={
                      permissions?.mandatory_reseller_government_id
                        ? true
                        : false
                    }
                    label="Govt ID Type"
                    error={errors?.governmentIdType}
                    attrs={{
                      value: params.governmentIdType,
                      onChange: (e) => {
                        this.onChangeHandler(e, "governmentIdType");
                      },
                    }}
                    options={cmpnyGovtIds}
                  ></InputSelect>

                  <InputGroup
                    label="Government ID"
                    placeholder="Enter Govt ID..."
                    requiredMark={
                      permissions?.mandatory_reseller_government_id ||
                      params.governmentIdType
                        ? true
                        : false
                    }
                    attrs={{
                      disabled: params?.governmentIdType === "",
                      value: params.governmentId,
                      onChange: (e) => {
                        if (
                          /^[a-zA-Z0-9\-]*$/.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            e.target.value.length <=
                            (this.state?.govDetails?.maxLength
                              ? this.state?.govDetails?.maxLength
                              : 20)
                          ) {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value?.replace(
                                    this.state.govDetails?.regValidation,
                                    ""
                                  ),
                                },
                              },
                              "governmentId"
                            );
                          }
                        }
                      },
                    }}
                    error={errors?.governmentId}
                    onEnter={this.submit}
                    validateError={(value) => {
                      if (
                        !value ||
                        this.state?.govDetails?.validateFunction(value)
                      ) {
                        return false;
                      } else {
                        return "please enter valid gov. id number";
                      }
                    }}
                  />
                </>

                <InputFile
                  error={errors.document}
                  label="Identity Proof"
                  file={params.document}
                  placeholder={
                    !params.document && (
                      <div className="text-center items-center flex-col inline-flex">
                        <PlusCircleIcon className="text-primary" width={24} />
                        <p className="mt-2 p text-center text-sm text-primary">
                          Upload identity proof
                          <br />
                          (max size 5MB)
                          <br /> (allowed types .jpeg, .jpg, .pdf, .png)
                        </p>
                      </div>
                    )
                  }
                  attrs={{
                    onChange: (e) => this.onDocChange(e, "document"),
                    disabled: params?.document_front,
                    accept: "image/*,.pdf",
                  }}
                  onDocDelete={() => {
                    _.set(params, "document", null);
                    this.setState({ params: params });
                  }}
                />

                <div
                  className={`${
                    params.payment_mode === "neft" ? "" : "hidden"
                  }`}
                ></div>
                <div
                  className={`${
                    params?.payment_mode === "upi" ? "" : "hidden"
                  }`}
                >
                  <InputGroup
                    starMark={true}
                    error={errors.upi_id}
                    label="Enter UPI ID"
                    placeholder="Enter UPI ID..."
                    attrs={{
                      value: params.upi_id,
                      onChange: (e) => this.onChangeHandler(e, "upi_id"),
                    }}
                  />
                </div>
              </div>
            ) : (
              false
            )}
          </div>
          <div>
            <div>
              <Button
                attrs={{
                  disabled: !ready,
                  type: "button",
                  onClick: this.next,
                  ref: this.BasicBtn,
                }}
                size="md"
                title={step === 3 ? "Register" : "Save and Continue"}
              />
              {step > 1 ? (
                <button
                  onClick={this.prev}
                  className="w-full flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500"
                >
                  Back
                </button>
              ) : (
                false
              )}
            </div>
            <p
              className="text-sm text-center mt-6"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ open: true });
              }}
            >
              <span className="text-primary underline hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary rounded-sm px-1">
                Terms &amp; Condition
              </span>
            </p>
          </div>
        </div>
        <TermsModal open={this.state.open} handleClose={this.handleClose} />
      </>
    );
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  checkPin = async () => {
    if (!this.state.params.pin) return;
    // if (this.state.params.pin === this.checked_pin) return;
    this.checked_pin = this.state.params.pin;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`check-pin/${this.state.params.pin}`);
    if (response.status === "success" && response.data !== null) {
      this.setState({ errors: { pin: "" } }, () => {
        this.onChangeHandler({ target: { value: response.data } }, "state");
      });
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else if (response.status === "error") {
      this.setState({ errors: response.errors }, () => {
        this.onChangeHandler({ target: { value: null } }, "state");
      });
    } else {
      this.onChangeHandler({ target: { value: null } }, "state");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  onChangeHandler = (e, key) => {
    const cmpnyGovtIds = [
      ...govtIds,
      {
        label: "Pan Card",
        value: "pan",
        maxLength: 10,
        frontImg: true,
        backImg: false,
        regValidation: null,
        validateFunction: isValidPan,
      },
    ];
    const { params } = this.state;
    params[key] = e.target.value;
    if (key === "governmentIdType") {
      let govObj = cmpnyGovtIds.filter((elm) => e.target.value === elm.value);
      params["government_id"] = "";
      params["document_front"] = "";
      params["document_back"] = "";
      params["document"] = "";
      params["governmentId"] = "";
      this.setState({ params: params, govDetails: govObj?.[0] });
    }

    this.setState({ params: params });
  };

  next = () => {
    switch (this.state.step) {
      case 1:
        return this.saveBasic();
      // case 2:
      //   return this.saveProfessional();
      case 2:
        return this.saveBanking();
      default:
        return false;
    }
  };

  prev = () => {
    if (this.state.step == 3) {
      this.setState({ step: this.state.step - 2 });
    } else {
      this.setState({ step: this.state.step - 1 });
    }
  };

  doSubmit = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    setTimeout(() => {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }, 3000);
  };

  componentDidUpdate(prevProp, prevState) {
    if (prevState.step !== this.state.step) {
      this.setProgress();
    }
  }

  setProgress = () => {
    AppStore.dispatch({
      type: "PROGRESS",
      progress: 100 / (4 - this.state.step),
    });
  };

  saveBasic = () => {
    const { user } = this.props;
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const { name, dob, photo, email, gender, pin, state } = this.state.params;
      const data = new FormData();
      data.append("name", name ? name : "");
      data.append("dob", dob ? dob : "");
      // data.append("gender", gender);
      data.append("email", email ? email : "");
      data.append("pin", pin ? pin : "");
      data.append("state", state ? state : "");
      data.append("photo", photo ? photo : "");

      const response = await ApiPost("register/basic", data);
      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        AppStore.dispatch({ type: "LOADING", loading: false });
        if (!user?.permissions?.hide_reseller_banking_section) {
          this.setState({ step: 2 });
        } else {
          this.saveBanking();
        }
      }
    });
  };

  saveProfessional = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ errors: {} }, async () => {
      const { qualification, profession, invesment_work, experience, income } =
        this.state.params;
      const response = await ApiPost("register/professional", {
        qualification: qualification,
        profession: profession,
        invesment_work: invesment_work,
        experience: experience,
        income: income,
      });

      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ step: 3 });
      }
    });
  };

  saveBanking = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const {
        bank_name,
        account_number,
        ifsc_code,
        upi_id,
        payment_mode,
        pan,
        tin,
        governmentId,
        governmentIdType,
        document,
      } = this.state.params;

      const data = new FormData();
      // data.append("bank_name", bank_name);
      // data.append("account_number", account_number);
      // data.append("ifsc_code", ifsc_code);
      // data.append("upi_id", upi_id);
      data.append("payment_mode", payment_mode);
      // data.append("pan", pan);
      // data.append("tin", tin);
      data.append("governmentId", governmentId ? governmentId : "");
      data.append("governmentIdType", governmentIdType ? governmentIdType : "");
      data.append("document", document ? document : "");

      const response = await ApiPost("register/banking", data);

      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        if (response.status == "success") {
          if (response.data?.authToken && response.data?.token) {
            toast.success("Registration completed successfully!");
          } else {
            toast.success("Registration completed successfully! please login.");
          }
        }
        if ("token" in response.data && "auth_token" in response.data) {
          await setData("access_token", response.data.token, async () => {
            const me = await ApiGet("me");
            if (me.status === "success") {
              AppStore.dispatch({
                type: "SIGN_IN",
                user: me.data,
                token: response.data.token,
              });
              AppStore.dispatch({ type: "PROGRESS", progress: 0 });
              AppStore.dispatch({ type: "LOADING", loading: false });
              setResellerToken(me.data?.reseller_id_by_token || null);
              this.props.history.push("/");
            } else {
              await removeData("access_token");
              await removeData("auth_token");
              await setError(response.message);
            }
          });
          await setData("auth_token", response.data?.authToken);
        } else {
          setMessage(response.data.message, async () => {
            // await AppLogout();
            if (response.data?.authToken && response.data?.token) {
              AppStore.dispatch({
                type: "SIGN_IN",
                user: response?.data?.user,
                token: response?.data?.token,
              });
              AppStore.dispatch({ type: "PROGRESS", progress: 0 });
              AppStore.dispatch({ type: "LOADING", loading: false });
              setResellerToken(response.data?.reseller_id_by_token || null);
              await setData("auth_token", response.data?.authToken);
            } else {
              await AppLogout();
            }
            this.props.history.push("/");
          });
        }
      }
    });
  };

  onPhotoChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1 + 10000000) {
        await setError("File size exceeded 5MB");
      } else {
        const { params } = this.state;

        _.set(
          params,
          "photo_base64",
          URL.createObjectURL(event.target.files[0])
        );
        _.set(params, "photo", event.target.files[0]);

        this.setState({ params: params });
      }
    }
  };

  onDocChange = (event, key) => {
    const { params } = this.state;
    const file = event?.target?.files?.[0];
    const extension = getFileExtension(file);
    if (file) {
      if (extensions.includes(extension)) {
        const fileSize = file?.size;
        if (fileSize < addTeamDocSize) {
          _.set(params, key, file);
          this.setState({
            params: params,
            errors: {
              [key]: "",
            },
          });
        } else {
          this.setState({
            errors: {
              [key]: "File size should be less than 5mb",
            },
          });
        }
      } else {
        this.setState({
          errors: {
            [key]: "File must be .pdf, .jpeg, .jpg, .png",
          },
        });
      }
    } else {
      this.setState({
        errors: {
          [key]: "",
        },
      });
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const RegisterConnect = connect(mapStateToProps)((props) => {
  return <Register {...props} />;
});

export default withRouter(RegisterConnect);
