import React from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";

export function InputRadio({
  loading = false,
  name,
  label = "",
  requiredMark = "",
  starMark = false,
  labelLink = null,
  attrs = {},
  options = [],
  value = null,
  inline = true,
  className = "",
  error = null,
  buttonHidden = null,
  radioPositionStart = false,
  disabled,
}) {
  let loading_disabled = loading ? { disabled: true } : {};
  return (
    <div className="mb-5">
      <InputLabel
        label={label}
        labelLink={labelLink}
        requiredMark={requiredMark}
        buttonHidden={buttonHidden}
        starMark={starMark}
      />
      <div
        className={[
          `mt-2 ${inline ? "grid grid-cols-1 md:grid-cols-3" : "flex-col"} ${
            inline ? "justify-between" : "space-y-4"
          }`,
          className,
        ].join(" ")}
      >
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className={[
                `flex items-center`,
                inline
                  ? "mt-4"
                  : radioPositionStart
                  ? ""
                  : "flex-row-reverse space-x-reverse space-x-4 justify-end",
              ].join(" ")}
            >
              <input
                id={`${name}-${index}-${option.value}`}
                name={`${name}`}
                type="radio"
                className={`focus:ring-primary-500 h-5 w-5 text-primary-500 border-gray-300 ${
                  buttonHidden == true ? "hidden" : ""
                }  ${disabled ? "cursor-not-allowed" : ""}`}
                checked={value == option.value}
                value={option.value}
                {...attrs}
                {...loading_disabled}
                // style={{ display: "none" }}
                disabled={disabled}
              />
              <label
                style={{
                  width: inline ? "350px" : "auto",
                  borderColor:
                    buttonHidden == true && value == option.value
                      ? "red"
                      : "transparent",
                  borderWidth: 1,
                  borderRadius: "0.75rem",
                }}
                htmlFor={`${name}-${index}-${option.value}`}
                className={`border-red-400 ml-2 block text-sm mr-1 text-gray-700${
                  inline ? "" : " flex-1"
                }`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-500">{error.join(" ")}</p>
      ) : (
        false
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <InputRadio {...props} />;
});
