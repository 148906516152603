import React, { useCallback, useState } from "react";
import ZipFileUploader from "./FileUploader";
import { toast } from "react-toastify";
import { MAX_REIMBURSEMENT_ZIP_FILE_SIZE } from "../../../lib/constants";
import { AppBackButton } from "../../../components/AppComponents";
import { useHistory } from "react-router-dom";
import { postApiClaim } from "../../../lib/AppHelper";
import { useSelector } from "react-redux";
import { AppStore } from "../../../lib/AppStore";

const UploadReimbursementData = () => {
  const history = useHistory();
  const user = useSelector((state) => state?.user);
  const [zipFile, setZipFile] = useState(null);

  const onFileSelect = useCallback((e) => {
    const file = e.target.files[0];
    const ext = file.name.split(".").pop();
    if (file) {
      if (file?.size > MAX_REIMBURSEMENT_ZIP_FILE_SIZE) {
        toast.error("File size should not be more than 500 MB");
        return;
      }
      if (ext !== "zip") {
        toast.error("Only zip file allowed");
        return;
      }
      setZipFile(file);
    }
  }, []);

  const onFileDelete = useCallback(() => {
    setZipFile(null);
  }, []);

  const handleUploadFile = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const email = user?.email;
    const formData = new FormData();
    formData.append("file", zipFile);
    formData.append("emailRecipients", email);
    const response = await postApiClaim("parseClaimExcelData", formData);
    if (response?.code === 200) {
      history.push("/reimbursements/raise-reimbursement", { key: "bulk" });
    } else {
      toast.error(response?.message || "Something went wrong!");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      onFileSelect({ target: e.dataTransfer });
    }
    AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = () => {
    AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      aria-current={1}
    >
      <div className="flex w-full flex-col md:flex-row gap-1 md:gap-2 mb-4">
        <div className="w-2/5">
          <AppBackButton to="/">Back</AppBackButton>
        </div>
        <h2 className="w-full md:w-2/5 text-center md:text-left text-primary-500 font-semibold text-xl">
          Raise reimbursement
        </h2>
      </div>
      <ZipFileUploader
        file={zipFile}
        filename={zipFile?.name}
        label="Upload zip file"
        accept=".zip,.7zip"
        onChangeHandle={onFileSelect}
        onDeleteHandler={onFileDelete}
        handleUploadFile={handleUploadFile}
      />
    </div>
  );
};

export default UploadReimbursementData;
