import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { ApiGet, getData } from "../lib/AppHelper";
import { DatePicker, TagGroup } from "rsuite";
import { Search } from "./Search";
import moment from "moment";
import PaginationData from "./admin/components/PaginationData";
import "./styles.css";

export const ExpiredPolicy = ({ loading }) => {
  const history = useHistory();
  const MessageRef = React.useRef();
  const [filterValues, setFilterValues] = useState({
    to: moment().subtract(1, "days").format("YYYY-MM-DD"),
    from: moment().subtract(91, "days").format("YYYY-MM-DD"),
    value: 90,
  });
  const [permissions, setPermissions] = useState();
  const [getSearch, setGetSearch] = useState(false);
  const data = history?.location?.state;
  const [allData, setAllData] = useState();

  const filter = [
    {
      to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      from: moment().subtract(1, "days").format("YYYY-MM-DD"),
      label: "Today",
      value: 1,
    },
    {
      to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      from: moment().subtract(8, "days").format("YYYY-MM-DD"),
      label: "Within last 7 days",
      value: 7,
    },
    {
      to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      from: moment().subtract(16, "days").format("YYYY-MM-DD"),
      label: "Within last 15 days",
      value: 15,
    },
    {
      to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      from: moment().subtract(31, "days").format("YYYY-MM-DD"),
      label: "Within last 30 days",
      value: 30,
    },
    {
      to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      from: moment().subtract(91, "days").format("YYYY-MM-DD"),
      label: "Within last 90 days",
      value: 90,
    },
  ];

  const [dateFilter, setDateFilter] = useState({
    from: "",
    to: "",
  });

  useEffect(() => {
    (async () => {
      const response = await ApiGet("expired-policy-count");
      if (response.status === "success") {
        setAllData(response.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const permission = await getData("permissions");
      if (permission?.expire_policy_view) {
        setPermissions(permission);
      }
    })();
    if (filterValues) {
      history.replace();
    }
  }, [filterValues]);

  const handleGetSearch = useCallback((e) => {
    if (e > 0) setGetSearch(false);
    else setGetSearch(true);
  }, []);

  return (
    <>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-full md:w-1/3">
          <AppBackButton to="/">Back</AppBackButton>
        </div>
        <div className="w-full md:w-1/3 text-center mb-5">
          <AppHeading>Expired Subscriptions</AppHeading>
        </div>
        <div className="w-full md:w-1/3 none md:block"></div>
      </div>

      {permissions?.expire_policy_view ? (
        <div className="w-full mb-5">
          <Search
            setValue={(value) => setFilterValues({ ...filterValues, ...value })}
            filterValues={filterValues}
          />
        </div>
      ) : (
        false
      )}

      {permissions?.expire_policy_view ? (
        <div className="w-full flex flex-col space-y-4">
          <div className="flex flex-wrap justify-between">
            {filter?.map((item, index) => {
              return (
                <TagGroup
                  key={index}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    margin: 2,
                  }}
                >
                  <div
                    style={{
                      borderRadius: 30,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFilterValues({
                        ...filterValues,
                        from: item.from,
                        to: item.to,
                        value: item.value,
                      });
                    }}
                  >
                    <button
                      style={{
                        backgroundColor:
                          item.value === filterValues?.value ? "#db2228" : "",
                        color:
                          item.value === filterValues?.value ? "white" : "",
                        borderWidth: 1,
                        borderRadius: 30,
                      }}
                      className="bg-default-500 active:bg-default-600 text-xs py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 px-6"
                      type="button"
                    >
                      {item.label}
                      {item.value === 1 && <p>{allData?.oneDayBack}</p>}
                      {item.value === 7 && (
                        <p>{allData?.sevenDayBackPolicyCount}</p>
                      )}
                      {item.value === 15 && (
                        <p>{allData?.fifteenDayBackPolicyCount}</p>
                      )}
                      {item.value === 30 && (
                        <p>{allData?.thirtyDayBackPolicyCount}</p>
                      )}
                      {item.value === 90 && (
                        <p>{allData?.ninetyDayBackPolicyCount}</p>
                      )}
                    </button>
                  </div>
                </TagGroup>
              );
            })}

            <DatePicker
              format="dd/MM/yyyy"
              placeholder="From"
              value={filterValues.from ? new Date(filterValues.from) : null}
              oneTap
              className={filterValues.value === null ? `custom-datepicker` : ""}
              style={{
                width: "160px",
                margin: "5px",
                borderColor: filterValues.value === null ? "#db2228" : "",
                color: filterValues.value === null ? "white" : "",
              }}
              onChange={(value) => {
                if (value === null) {
                  setFilterValues({
                    ...filterValues,
                    from: null,
                    to: null,
                    value: 90,
                  });
                } else {
                  setFilterValues({
                    ...filterValues,
                    from: value ? moment(value).format("YYYY-MM-DD") : null,
                    value: null,
                  });
                }
              }}
              disabledDate={(date) => {
                if (date > new Date()) {
                  return true;
                }
                if (dateFilter?.to && new Date(dateFilter?.to) <= date) {
                  return true;
                }
              }}
            />
            <DatePicker
              format="dd/MM/yyyy"
              placeholder="To"
              value={filterValues.to ? new Date(filterValues.to) : null}
              oneTap
              className={filterValues.value === null ? `custom-datepicker` : ""}
              style={{
                width: "160px",
                margin: "5px",
                borderColor: filterValues.value === null ? "#db2228" : "",
                color: filterValues.value === null ? "white" : "",
              }}
              disabled={!filterValues.from}
              onChange={(value) =>
                setFilterValues({
                  ...filterValues,
                  to: value ? moment(value).format("YYYY-MM-DD") : null,
                  value: null,
                })
              }
              disabledDate={(date) => {
                if (date > new Date()) {
                  return true;
                }
                if (filterValues?.from && new Date(filterValues?.from) > date) {
                  return true;
                }
              }}
            />
          </div>

          <div className="flex flex-wrap">
            <PaginationData
              data={data}
              request={ApiGet}
              endPoint={"expired-policy"}
              filterValues={filterValues}
              handleGetSearch={handleGetSearch}
              permissions={permissions}
              pageType="expired"
            />
          </div>
        </div>
      ) : (
        <div className="p-10 text-red-500">
          <h4>You do not have permission to view Expired Members</h4>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const ExpiredPolicyConnect = connect(mapStateToProps)((props) => {
  return <ExpiredPolicy {...props} />;
});

export default withRouter(ExpiredPolicyConnect);
