import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import AdminMain from "./AdminMain";
import DualList from "../../components/ListBox/src";
import { ApiGet, ApiPost, setMessage, setError } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import { Filters } from "../Filters";

export class AssignGroupToPartner extends Component {
  BasicBtn = React.createRef();

  state = {
    options: [],
    selected: [],
    roles: [],
    filterValues: {},
    selectedLevel: "",
    team: "",
  };

  onChange = (selected, b) => {
    this.setState({
      selected: selected,
    });

    let request = {
      user_id: this.state.selectedLevel,
      deleted: b,
      group_ids: selected,
    };
    this.callAPI(request);
  };

  async callAPI(request) {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("admin/user-role-group-mapping", request);
    if (response.status === "success") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      setMessage(response.message);
    } else {
      setError(response.message);
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  }

  componentDidMount() {
    this.getGroups();
  }

  componentDidUpdate(prevProp, prevState) {
    if (!_.isEqual(prevState.filterValues, this.state.filterValues)) {
      this.getTeam();
    }
  }

  getGroups = async () => {
    const response = await ApiGet("admin/get-role-group");
    if (response.status === "success") {
      const formattedOptions = response?.data.map(
        ({ role_group_id, role_group_name, description }) => ({
          label: role_group_name,
          value: role_group_id,
          description,
        })
      );

      this.setState({
        options: formattedOptions,
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getTeam = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("search-team", this.state.filterValues);
    if (response.status === "success") {
      this.setState({
        team: response.data,
        selected: response.data ? response?.data?.selected_role_group : [],
        selectedLevel: response.data ? response?.data?.id : "",
      });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  setFilterValues = (values) => {
    this.setState({ filterValues: values });
  };

  render() {
    const { permissions } = this.props;
    const { options, selected, filterValues, team } = this.state;
    return (
      <>
        {permissions?.assign_group_to_partner ? (
          <>
            <div className="flex md:flex-2">
              <div className="w-11/12">
                <AppBackButton to="/">Back to Dashboard</AppBackButton>
              </div>
            </div>

            <AppHeading className="text-center">
              Assign Group To Partner
            </AppHeading>

            <div className="grid md:grid-cols-1 mt-5">
              <Filters
                setValue={this.setFilterValues}
                filterValues={filterValues}
              />
            </div>

            {filterValues &&
            Object.keys(filterValues).length > 0 &&
            Array.isArray(options) &&
            options.length > 0 &&
            team ? (
              <>
                <div className="grid md:grid-cols-3 gap-4 pt-5">
                  <div>
                    Name: <span className="pl-5 font-medium">{team.name}</span>
                  </div>
                  <div>
                    Email:{" "}
                    <span className="pl-5 font-medium	">{team.email}</span>
                  </div>
                  <div>
                    Mobile:{" "}
                    <span className="pl-5 font-medium	">{team.mobile}</span>
                  </div>
                </div>

                <DualList
                  options={options}
                  onChange={this.onChange}
                  selected={selected}
                />
              </>
            ) : filterValues.search && !team ? (
              <h4 className="text-red-500	 mt-5">Reseller not found</h4>
            ) : (
              false
            )}
          </>
        ) : (
          <div className="text-red-500">
            <div>
              <AppBackButton to="/">Back</AppBackButton>
            </div>
            <h4>
              You don't have permission to view assigns groups to partners
            </h4>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return {
    loading: loading,
    permissions: user.permissions,
  };
};

const AssignGroupToPartnerConnect = connect(mapStateToProps)((props) => {
  return <AssignGroupToPartner {...props} />;
});

export default withRouter(AssignGroupToPartnerConnect);
