import { ApiGet } from "../../../../lib/AppHelper";

//Image validations and extentions
export const MAX_FILE_SIZE = 5242880; //5MB
const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

export function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

export const fieldLengthValidation = {
  name: 50,
  number: 8,
};

export const fieldValidation = {
  name: /^[a-zA-Z0-9\s'-]+$/,
  number: /^[0-9\b]+$/,
  gst: /^(100|[1-9]?\d)(\.\d+)?$/,
};

//Fetch vendor for sub policy
export const fetchVendorList = async (subPolicy) => {
  if (subPolicy) {
    const response = await ApiGet(
      `admin/get-vendor?specification=${subPolicy}`
    );
    if (response.code == 200) {
      return response?.data;
    } else {
      return [];
    }
  }
};

export const fetchProductBenefit = async (subPolicy) => {
  if (subPolicy) {
    const response = await ApiGet(
      `admin/get-product-benefit?specification=${subPolicy}`
    );
    if (response.code == 200) {
      return response?.data;
    } else {
      return [];
    }
  }
};
