import React from "react";
import Graph from "./Graph";
import ShimmerLoader from "../../../components/common/ShimmerLoader";
import NoData from "../../../assets/png/noData.png";
import { getNumber } from "../common/data";

const GraphCard = ({ loader, graphCard, isAmount }) => {
  return (
    <div className="flex justify-center h-100 px-6 py-6 flex-col gap-6 rounded-2xl bg-white shadow-md">
      {loader ? (
        <>
          <ShimmerLoader rows={17} />
        </>
      ) : graphCard?.data?.length ? (
        <>
          <div className="flex flex-row justify-between">
            <span className="font-semibold text-lg">{graphCard?.label}</span>
          </div>
          <div className="mt-2">
            <Graph
              topBaseGraphColor={graphCard.styles?.topBaseGraphColor}
              bottomBaseGraphColor={graphCard.styles?.bottomBaseGraphColor}
              borderColor={graphCard.styles?.borderColor}
              data={graphCard.data}
            />
          </div>

          <div className="flex flex-col justify-center items-center mt-2 h-12">
            <p className="font-semibold text-lg">
              {getNumber(graphCard?.value, isAmount)}
            </p>
            <p className="font-normal text-base opacity-80">
              {graphCard?.description}
            </p>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <img width={100} height={100} src={NoData} alt="No Data Found" />
          <p className="font-normal text-base opacity-80">{"No data found"}</p>
        </div>
      )}
    </div>
  );
};

export default GraphCard;
