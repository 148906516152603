import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { ApiGet, getData } from "../lib/AppHelper";
import PaginationData from "./admin/components/PaginationData";
import { TagGroup } from "rsuite";
import { Search } from "./Search";

export const ExpiringSoonPolicy = ({ loading }) => {
  const history = useHistory();
  const MessageRef = React.useRef();
  const [permissions, setPermissions] = useState();
  const [getSearch, setGetSearch] = useState(false);
  const [allData, setAllData] = useState();
  const data = history?.location?.state;

  const daysInCurrentMonth = () => {
    var currentDate = new Date();
    var month = currentDate.getMonth() + 1;
    var year = currentDate.getFullYear();
    var daysInMonth = new Date(year, month, 0).getDate();
    return daysInMonth;
  };

  const [filterValues, setFilterValues] = useState({
    days: daysInCurrentMonth(),
  });

  const filter = [
    {
      value: 0,
      label: "Subscription Expiring Today",
    },
    {
      value: 7,
      label: "Subscription Expiring within 7 days",
    },
    {
      value: 15,
      label: "Subscription Expiring within 15 days",
    },
    {
      value: daysInCurrentMonth(),
      label: "Subscription Expiring within 1 month",
    },
  ];

  useEffect(() => {
    (async () => {
      const response = await ApiGet("expiring-soon-policy-count");
      if (response.status === "success") {
        setAllData(response.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const permission = await getData("permissions");
      if (permission?.expiring_soon_policy_view) {
        setPermissions(permission);
      }
    })();
    if (filterValues) {
      history.replace();
    }
  }, [filterValues]);

  const handleGetSearch = useCallback((e) => {
    if (e > 0) setGetSearch(false);
    else setGetSearch(true);
  }, []);

  return (
    <>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-full md:w-1/3">
          <AppBackButton to="/">Back</AppBackButton>
        </div>
        <div className="w-full md:w-1/3 text-center mb-5">
          <AppHeading>Subscriptions Expiring Soon</AppHeading>
        </div>
        <div className="w-full md:w-1/3 none md:block"></div>
      </div>

      {permissions?.expiring_soon_policy_view ? (
        <div className="w-full mb-5">
          <Search
            setValue={(value) => setFilterValues({ ...filterValues, ...value })}
            filterValues={filterValues}
          />
        </div>
      ) : (
        false
      )}

      {permissions?.expiring_soon_policy_view ? (
        <div className="w-full flex flex-col space-y-4">
          <div className="flex flex-wrap justify-between">
            {filter?.map((item, index) => {
              return (
                <TagGroup
                  key={index}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    margin: 2,
                  }}
                >
                  <div
                    style={{
                      borderRadius: 30,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFilterValues({ ...filterValues, days: item.value });
                    }}
                  >
                    <button
                      style={{
                        margin: 7,
                        backgroundColor:
                          item.value === filterValues?.days ? "#db2228" : "",
                        color: item.value === filterValues?.days ? "white" : "",
                        borderWidth: 1,
                        borderRadius: 30,
                      }}
                      className="bg-default-500 active:bg-default-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      {item.label}
                      {item.value === 0 && <p>{allData?.todayPolicyCount}</p>}
                      {item.value === 7 && (
                        <p>{allData?.sevenDayBackPolicyCount}</p>
                      )}
                      {item.value === 15 && (
                        <p>{allData?.fifteenDayBackPolicyCount}</p>
                      )}
                      {item.value === daysInCurrentMonth() && (
                        <p>{allData?.ThirtyDayBackPolicyCount}</p>
                      )}
                    </button>
                  </div>
                </TagGroup>
              );
            })}
          </div>

          <div className="flex flex-wrap">
            <PaginationData
              data={data}
              request={ApiGet}
              endPoint={"expiring-soon-policy"}
              filterValues={filterValues}
              handleGetSearch={handleGetSearch}
              permissions={permissions}
              pageType="expiring-soon"
            />
          </div>
        </div>
      ) : (
        <div className="p-10 text-red-500">
          <h4>You do not have permission to view policies expiring soon</h4>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const ExpiringSoonPolicyConnect = connect(mapStateToProps)((props) => {
  return <ExpiringSoonPolicy {...props} />;
});

export default withRouter(ExpiringSoonPolicyConnect);
