import React, { useEffect, useRef, useState } from "react";
import { MenuItems } from "../screens/NewHome/common/data";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Logo from "../components/Logo";
import Button from "../components/Button";
import UserMenu from "../components/UserMenu";
import { ApplicationViewIcon, NotificationIcon } from "./SidebarIcons";
import { AppStore } from "../lib/AppStore";
import * as MyRsuite from "rsuite";
import SimpleBackdrop from "../components/common/Backdrop";
import AppSpinner from "../components/AppSpinner";
import { OnlineStatus } from "../screens/Main";
import { ApiGet, AppLogout } from "../lib/AppHelper";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";
import SwitchToggle from "../screens/NewHome/common/SwitchToggle";
import BreadCrumb from "../components/common/BreadCrumb";

const Layout = (props) => {
  const { loading, onDrop, onDragOver, onDragLeave, current, backdrop } = props;
  const userPermissions = props.user?.permissions || {};
  const screenLayout = props.screenLayout;

  const location = useLocation();

  const loadingRef = useRef();
  const contentRef = useRef();
  const [open, setOpen] = useState(false);
  const [isApplicationMenuOpen, setApplicationMenuOpen] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState({
    key: "Home",
    to: "",
  });

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (current === 1) AppStore.dispatch({ type: "BACKDROP", backdrop: true });
  };

  const onLogout = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    AppLogout();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleApplicationMenu = () => {
    setApplicationMenuOpen(!isApplicationMenuOpen);
  };

  const getProducts = async () => {
    AppStore.dispatch({
      type: "PRODUCTS",
      productLoading: true,
    });

    try {
      if (props?.user === null) return;
      if (props?.user?.status !== 1) return;
      const response = await ApiGet("products");
      if (response.status === "success") {
        AppStore.dispatch({
          type: "PRODUCTS",
          products: response.data,
          productLoading: false,
        });
      }
    } catch (error) {
      AppStore.dispatch({
        type: "PRODUCTS",
        productLoading: false,
      });
    }
  };

  useEffect(() => {
    if (loading && loadingRef.current) {
      loadingRef.current.focus();
    }
  }, [loading]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
    const activeMenu = MenuItems.find((menu) =>
      menu.isSubMenu
        ? menu.childrenData.find((item) => {
            let isExcel = !!item?.excelPermission;
            let excelPermissionRole = isExcel
              ? item.excelPermission.role
              : { to: item.to };

            return isExcel
              ? excelPermissionRole?.some((perm) => userPermissions?.[perm])
                ? item.to === location.pathname
                : item.excelPermission.to === location.pathname
              : item.to === location.pathname;
          })
        : menu.to === location.pathname
    );

    if (activeMenu && activeMenu.isSubMenu) {
      let activeSubMenu = activeMenu?.childrenData.find((item) => {
        let isExcel = !!item?.excelPermission;
        let excelPermissionRole = isExcel
          ? item.excelPermission.role
          : { to: item.to };

        return isExcel
          ? excelPermissionRole?.some((perm) => userPermissions?.[perm])
            ? item.to === location.pathname
            : item.excelPermission.to === location.pathname
          : item.to === location.pathname;
      });
      setActiveMenuItem({
        key: activeSubMenu.label,
        to: activeSubMenu.to,
      });
    } else if (activeMenu) {
      setActiveMenuItem({
        key: activeMenu.label,
        to: activeMenu.to,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      {backdrop && current === 1 && (
        <SimpleBackdrop
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
        />
      )}
      <div className="h-full overflow-hidden" onDragEnter={handleDragEnter}>
        <OnlineStatus />

        <div>
          <header
            style={{
              width: "100%",
              height: "70px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
            className="bg-white shadow-md px-5 z-50 border-b border-gray-100"
          >
            <div className="flex items-center gap-2">
              {props.user?.status === 1 &&
              props.user.basic &&
              props.user.banking ? (
                <>
                  <div
                    className="border-none rounded-full p-2 bg-white hover:bg-gray-200 cursor-pointer hidden md:block"
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    <FaBars />
                  </div>
                  <div
                    className="border-none rounded-full p-2 bg-white hover:bg-gray-200 cursor-pointer block md:hidden"
                    onClick={() => {
                      isCollapsed && setIsCollapsed(false);
                      setIsToggle(!isToggle);
                    }}
                  >
                    <FaBars />
                  </div>
                </>
              ) : null}
              <Link to="/">
                <Logo />
              </Link>
            </div>

            <div className="hidden md:flex">
              {props.user?.status === 1 &&
              props.user.basic &&
              props.user.banking ? (
                <div className="flex flex-1 items-center justify-between gap-3">
                  {props.user
                    ? props.user?.permissions?.center_coordinator ||
                      props.user?.permissions?.aiqa_center
                      ? null
                      : props.user?.permissions?.dashboard_toggle && (
                          <Tooltip
                            title="Switch dashboard view"
                            placement="bottom"
                            arrow
                          >
                            <div className="flex justify-center items-center relative">
                              <SwitchToggle
                                value={screenLayout}
                                handleValueChange={() => {
                                  AppStore.dispatch({
                                    type: "SCREEN_LAYOUT",
                                    screenLayout:
                                      screenLayout == "Old Layout"
                                        ? "New Layout"
                                        : "Old Layout",
                                  });
                                  localStorage.setItem(
                                    "SCREEN_LAYOUT",
                                    screenLayout == "Old Layout"
                                      ? "New Layout"
                                      : "Old Layout"
                                  );
                                }}
                                label="Dashboard"
                              />
                            </div>
                          </Tooltip>
                        )
                    : null}

                  {props.user &&
                  (!props.user?.permissions?.center_coordinator ||
                    !props.user?.permissions?.aiqa_center)
                    ? props.user?.permissions?.recent_updates && (
                        <Tooltip title="Updates" placement="bottom" arrow>
                          <Link to="/notifications">
                            <div className="hover:text-dark-900 relative flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                              <NotificationIcon width={20} height={20} />
                            </div>
                          </Link>
                        </Tooltip>
                      )
                    : null}

                  <UserMenu />
                </div>
              ) : props.user ? (
                <Button
                  title="Logout"
                  attrs={{
                    onClick: () => onLogout(),
                  }}
                />
              ) : null}
            </div>

            {/* Mobile menu button */}
            <div className="flex flex-row items-center gap-3 md:hidden">
              {props.user?.status === 1 &&
              props.user.basic &&
              props.user.banking ? (
                props.user &&
                (!props.user?.permissions?.center_coordinator ||
                  !props.user?.permissions?.aiqa_center) ? (
                  <div
                    className="z-99999 flex h-10 w-10 items-center justify-center rounded-lg text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 lg:hidden bg-gray-100 dark:bg-gray-800 cursor-pointer"
                    onClick={toggleApplicationMenu}
                  >
                    <ApplicationViewIcon />
                  </div>
                ) : null
              ) : props.user ? (
                <Button
                  title="Logout"
                  attrs={{
                    onClick: () => onLogout(),
                  }}
                />
              ) : null}
            </div>
          </header>

          {/* Application Menu for mobile view */}
          <div
            className={`${
              isApplicationMenuOpen ? "flex" : "hidden"
            } items-center justify-between w-full gap-4 px-5 py-4 shadow-md md:hidden`}
          >
            <div className="flex items-center gap-2 2xsm:gap-3">
              {props.user &&
              (!props.user?.permissions?.center_coordinator ||
                !props.user?.permissions?.aiqa_center)
                ? props.user?.permissions?.dashboard_toggle && (
                    <Tooltip
                      title="Switch dashboard view"
                      placement="bottom"
                      arrow
                    >
                      <div className="flex justify-center items-center relative cursor-pointer">
                        <SwitchToggle
                          value={screenLayout}
                          handleValueChange={() => {
                            AppStore.dispatch({
                              type: "SCREEN_LAYOUT",
                              screenLayout:
                                screenLayout == "Old Layout"
                                  ? "New Layout"
                                  : "Old Layout",
                            });
                            localStorage.setItem(
                              "SCREEN_LAYOUT",
                              screenLayout == "Old Layout"
                                ? "New Layout"
                                : "Old Layout"
                            );
                          }}
                          label="Dashboard"
                        />
                      </div>
                    </Tooltip>
                  )
                : null}

              {props.user &&
              (!props.user?.permissions?.center_coordinator ||
                !props.user?.permissions?.aiqa_center)
                ? props.user?.permissions?.recent_updates && (
                    <Tooltip title="Updates" placement="bottom" arrow>
                      <Link to="/notifications">
                        <div className="hover:text-dark-900 relative flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                          <NotificationIcon width={20} height={20} />
                        </div>
                      </Link>
                    </Tooltip>
                  )
                : null}
            </div>
            <UserMenu />
          </div>

          <div
            className="flex flex-1"
            style={{ height: isApplicationMenuOpen ? "75vh" : "89vh" }}
          >
            {props.user?.status === 1 &&
            props.user.basic &&
            props.user.banking ? (
              <Sidebar
                collapsed={isCollapsed}
                transitionDuration={200}
                backgroundColor="#fff"
                className="bg-white transition-all duration-200 border-r flex-shrink-0"
                breakPoint="md"
                toggled={isToggle}
                onBackdropClick={() => setIsToggle(false)}
              >
                <Menu
                  closeOnClick={true}
                  menuItemStyles={{
                    button: ({ active }) => ({
                      color: "black",
                      fontSize: "14px",
                      fontWeight: active ? "500" : "400",
                      backgroundImage: active
                        ? "linear-gradient(90deg, rgba(219, 34, 40, 0.12) 0%, rgba(219, 34, 40, 0.03) 100%)"
                        : "white",
                    }),
                  }}
                >
                  <div
                    className="border-none p-2 cursor-pointer block md:hidden"
                    onClick={() => setIsToggle(!isToggle)}
                  >
                    <Link to="/">
                      <Logo />
                    </Link>
                  </div>
                  {MenuItems.map((item, index) => {
                    const menuPermission = item?.permission;
                    let hasPermission =
                      typeof menuPermission == "boolean"
                        ? menuPermission
                        : menuPermission?.some(
                            (perm) => userPermissions?.[perm]
                          );
                    return item.isSubMenu
                      ? hasPermission && (
                          <SubMenu
                            key={index}
                            icon={item.icon(item.label === activeMenuItem.key)}
                            label={item.label}
                            defaultOpen={false}
                          >
                            <div
                              style={{
                                maxHeight: isCollapsed ? "350px" : "none",
                                overflowY: isCollapsed ? "auto" : "visible",
                              }}
                            >
                              {item.childrenData.map((sub, i) => {
                                const childMenuPermission = sub?.permission;
                                let childHasPermission =
                                  typeof childMenuPermission == "boolean"
                                    ? childMenuPermission
                                    : childMenuPermission?.some(
                                        (perm) => userPermissions?.[perm]
                                      );

                                let isExcel = sub?.excelPermission
                                  ? true
                                  : false;
                                let excelPermissionRole = sub?.excelPermission
                                  ? sub?.excelPermission?.role
                                  : { to: sub.to };

                                return (
                                  childHasPermission && (
                                    <Tooltip
                                      title={sub.label}
                                      placement="right"
                                      arrow
                                    >
                                      <Link
                                        key={i}
                                        to={
                                          isExcel
                                            ? {
                                                pathname:
                                                  excelPermissionRole?.some(
                                                    (perm) =>
                                                      userPermissions?.[perm]
                                                  )
                                                    ? sub.to
                                                    : sub?.excelPermission.to,
                                                state: sub.state,
                                              }
                                            : {
                                                pathname: sub.to,
                                                state: sub.state,
                                              }
                                        }
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            if (isToggle) {
                                              setIsToggle(false);
                                            }
                                          }}
                                          active={
                                            sub.label === activeMenuItem.key
                                          }
                                          icon={sub.icon(
                                            sub.label === activeMenuItem.key
                                          )}
                                        >
                                          {sub.label}
                                        </MenuItem>
                                      </Link>
                                    </Tooltip>
                                  )
                                );
                              })}
                            </div>
                          </SubMenu>
                        )
                      : hasPermission && (
                          <Tooltip title={item.label} placement="right" arrow>
                            <Link key={index} to={item.to}>
                              <MenuItem
                                onClick={() => {
                                  if (isToggle) {
                                    setIsToggle(false);
                                  }
                                }}
                                active={item.label === activeMenuItem.key}
                                icon={item.icon(
                                  item.label === activeMenuItem.key
                                )}
                              >
                                {item.label}
                              </MenuItem>
                            </Link>
                          </Tooltip>
                        );
                  })}
                </Menu>
              </Sidebar>
            ) : null}
            <main
              className="relative flex-1 transition-all duration-200 px-4 py-3 w-full h-full overflow-y-auto"
              ref={contentRef}
              style={{ backgroundColor: "#f8f8f8" }}
            >
              {loading ? (
                <div
                  tabIndex={0}
                  ref={loadingRef}
                  className="absolute w-full h-full bg-primary-50 z-50 opacity-60 flex items-center justify-center border-0 border-transparent"
                >
                  <AppSpinner />
                </div>
              ) : (
                false
              )}
              {props.user?.status === 1 &&
              props.user.basic &&
              props.user.banking ? (
                <span>
                  <BreadCrumb />
                </span>
              ) : null}
              {props.children}
            </main>
          </div>
        </div>
        {/* Logout Modal for users with status of 0 */}
        <MyRsuite.Modal
          maxWidth="md"
          open={open}
          onClose={handleClose}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MyRsuite.Modal.Header>
            <MyRsuite.Modal.Title style={{ fontWeight: "600" }}>
              Logout
            </MyRsuite.Modal.Title>
          </MyRsuite.Modal.Header>
          <MyRsuite.Modal.Body>
            Are you sure you want to logout?
          </MyRsuite.Modal.Body>
          <MyRsuite.Modal.Footer>
            <MyRsuite.Button
              onClick={handleLogout}
              style={{ backgroundColor: "#db2228", color: "white" }}
            >
              Yes
            </MyRsuite.Button>
            <MyRsuite.Button
              onClick={handleClose}
              style={{ backgroundColor: "lightGray", color: "black" }}
            >
              Cancel
            </MyRsuite.Button>
          </MyRsuite.Modal.Footer>
        </MyRsuite.Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  user: state.user,
  progress: state.progress,
  history: state.history,
  backdrop: state.backdrop,
  headerDownload: state.headerDownload,
  screenLayout: state.screenLayout,
});

export default connect(mapStateToProps)(Layout);
