import React, { Component, Suspense } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  AppBackButton,
  AppHeading,
  downloadBase64Data,
} from "../components/AppComponents";
import { withRouter } from "react-router";
// import { SelectSubPolicyForm } from "./StepClaimForm/SelectSubPolicyForm";
import { HospitalizationReasonForm } from "./StepClaimForm/HospitalizationReasonForm";
import UserInformation from "./StepClaimForm/UserInformation";
import { BankDetailsForm } from "./StepClaimForm/BankDetailsForm";
import { HealthDocuments } from "./StepClaimForm/HealthDocuments";
import {
  ApiGet,
  ApiPostClientId,
  ApiPutClientId,
  deleteClaimDoc,
  getApiClaim,
  getResellerId,
  setError,
  submitClaim,
  uploadClaimDocuments,
} from "../lib/AppHelper";
import { SubmittedForm } from "./StepClaimForm/SubmittedForm";
import { AppStore } from "../lib/AppStore";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import Stepper from "../components/common/Stepper";
import CustomModal from "./StepClaimForm/CustomModal";
import AddNewBank from "./StepClaimForm/AddNewBank";
const AddNewHospital = React.lazy(() =>
  import("./StepClaimForm/AddNewHospital")
);

const steps = [
  { title: "Reimbursement details" },
  { title: "Bank details" },
  { title: "Supporting documents" },
];
export class Claim extends Component {
  constructor(props) {
    super(props);
    const {
      policyId,
      memberId,
      claimId = null,
      subClaimId,
      start_date,
    } = this.props.match.params || {};
    this.state = {
      step: 1,
      subPolicyList: [],
      subPolicyIds: [],
      params: {},
      errors: {},
      policyId,
      productId: this.props.location.state?.productId,
      memberId,
      start_date,
      resellerId: getResellerId(),
      hospitalId: null,
      hospitalName: null,
      hospitalState: null,
      hospitalCity: null,
      reason: null,
      bankId: null,
      bankName: null,
      otherBankName: null,
      accountName: null,
      accountNumber: null,
      ifscCode: null,
      docsRequired: [],
      claimId: claimId,
      hospitals: [],
      states: [],
      cities: [],
      banks: [],
      subClaimId,
      productSpecification: null,
      productSpecificationStatus: null,
      maxClaimAmount: null,
      dateOfaccident: null,
      dateOfDeath: null,
      accidentCause: null,
      causeOfDeath: null,
      open: false,
      claimStartDate: null,
      claimEndDate: null,
      isModalOpen: {
        isOpen: false,
        label: "",
      },
      isBankModalOpen: {
        isOpen: false,
        label: "",
      },
    };
  }

  prevStep = () => {
    const { step } = this.state;
    if (step > 1) {
      this.setState({ step: step - 1 });
    }
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  onRemove = async () => {
    const { open } = this.state;
    const res = await deleteClaimDoc(`docs/${open.id}`);
    if (res.code === 200) {
      const records = this.state[`doc_${open.docId}`];
      if (records.length) {
        const updatedRecords = records.filter((doc) => doc.id !== open.id);
        this.setState({
          [`doc_${open.docId}`]: updatedRecords,
          open: false,
        });
      }
    }
  };

  handleSubmitRecord = async (id) => {
    const records = this.state[`doc_${id}`];
    AppStore.dispatch({ type: "LOADING", loading: true });
    await uploadClaimDocuments(id, this.state.claimId, records).then((res) => {
      if (res.code === 200) {
      }
    });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getClaim = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`claim/${id}`);
    if (response?.status === "success") {
      const {
        policy_id,
        status,
        claim_reason,
        account_holder_name,
        account_number,
        ifsc_code,
        bank_id,
        bank_name,
        insured_member_id,
        hospital_id,
        hospital_name,
        hospital_city_id,
        hospital_state_id,
        other_bank_name,
        claim_id,
        claim_slab_type,
        type,
        claim_partner,
        claim_type,
        accident_date,
        claim_end_date,
        claim_start_date,
        rectification_category,
      } = response.data;

      let jsonofdocs = {};
      const docTypeId = [
        ...new Set(
          response?.data?.documents?.map((item) => item?.documnet_id_type)
        ),
      ];

      if (docTypeId && docTypeId?.length > 0) {
        docTypeId?.map(async (res) => {
          const response = await getApiClaim(`docsByReferenceId/${id}/${res}`);
          this.setState((prevState) => {
            if (response?.length === 0) return;
            const documents = response?.map((doc) => {
              return {
                docId: res,
                id: doc?.document?.id,
                blob: downloadBase64Data(
                  doc?.encodedResource,
                  doc?.document?.documentName,
                  doc?.mimeType,
                  false
                ),
              };
            });
            return {
              ...prevState,
              [`doc_${res}`]: documents,
            };
          });
        });
      }

      if (response?.data?.documents && response?.data?.documents?.length) {
        jsonofdocs["forUpdate"] = true;
        response.data.documents.map((doc) => {
          const data = jsonofdocs[`doc_${doc.documnet_id_type}`] || [];
          jsonofdocs[`doc_${doc.documnet_id_type}`] = [...data, { ...doc }];
        });
      }

      const newData = {
        step: 1,
        params: {},
        errors: {},
        policyId: policy_id,
        memberId: insured_member_id,
        resellerId: getResellerId(),
        hospitalId: String(hospital_id),
        hospitalName: hospital_name ? String(hospital_name) : "",
        hospitalCity: String(hospital_city_id),
        hospitalState: String(hospital_state_id),
        reason: claim_reason,
        bankId: bank_id,
        bankName: bank_name,
        otherBankName: other_bank_name,
        accountName: account_holder_name,
        accountNumber: account_number,
        ifscCode: ifsc_code,
        docsRequired: [],
        claimId: claim_id,
        status: status,
        productSpecification: claim_partner?.[0]?.type,
        productSpecificationStatus: type,
        claimType: claim_type,
        dateOfaccident: accident_date,
        claimEndDate: claim_end_date,
        claimStartDate: claim_start_date,
        ...jsonofdocs,
      };

      let stepperCondition = 1;
      if (rectification_category === "Personal_Details") {
        stepperCondition = 1;
      } else if (rectification_category === "Bank_Details") {
        stepperCondition = 2;
      } else if (rectification_category === "Med_Documents") {
        this.submitStepForSupportingDocument(newData);
        stepperCondition = 3;
      }
      this.setState((prev) => ({
        ...prev,
        ...newData,
        step: stepperCondition,
      }));
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  componentDidMount() {
    const { claimId = null } = this.props.match.params || {};

    if (claimId) {
      this.getClaim(claimId);
    }
    this.getStateData();
    this.resize();

    const { location } = this.props;
    const subPolicy = location.subPolicy;

    if (subPolicy) {
      let checkedValue = Number(subPolicy["sub_policy_id"]);
      let subCheckedValue = Number(subPolicy["sub_product_id"]);
      let productSpecification =
        subPolicy?.product_claim_detail?.["claim_type"];
      let productSpecificationStatus =
        subPolicy?.product_claim_detail?.["claim_nature"];
      let max_claim_amount =
        subPolicy?.product_claim_detail?.["max_claim_amount"];
      let spec = subPolicy?.product_claim_detail?.["product_specification"];

      this.setState({
        ["subPolicyList"]: [checkedValue],
        ["subPolicyIds"]: [subCheckedValue],
        productSpecification: productSpecification,
        productSpecificationStatus: productSpecificationStatus,
        maxClaimAmount: max_claim_amount,
        specification_type: spec,
        claimType: productSpecification,
      });
    }
  }

  getStateData = async () => {
    const res = await getApiClaim("state");
    this.setState({ states: res.data });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.hospitalState &&
      prevState.hospitalState !== this.state.hospitalState
    ) {
    }
  }

  submitStepForm = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { claimId, subClaimId } = this.props.match.params || {};
    const {
      policyId,
      productId,
      memberId,
      reason,
      hospitalId,
      hospitalName,
      hospitalCity,
      hospitalState,
      accountName,
      accountNumber,
      ifscCode,
      subPolicyList,
      resellerId,
      bankId,
      bankName,
      otherBankName,
      productSpecification,
      productSpecificationStatus,
      isPatientDied = false,
      dateOfaccident,
      dateOfDeath,
      causeOfDeath,
      accidentCause,
      specification_type,
      claimEndDate,
      claimStartDate,
    } = this.state;

    const data = {
      policyId,
      memberId,
      reason: reason || accidentCause || causeOfDeath || "N/A",
      hospitalId: hospitalId || "6666",
      hospitalName,
      hospitalCityId: hospitalCity,
      hospitalStateId: hospitalState,
      accountName,
      accountNumber,
      ifscCode,
      subPolicyList,
      resellerId,
      bankId,
      bankName,
      otherBankName,
      productId,
      isPatientDied,
      specification: specification_type,
      type: productSpecificationStatus?.toUpperCase(),
      deathDate: dateOfDeath,
      causeOfDeath: causeOfDeath,
      accidentDate: dateOfaccident,
      claimType: productSpecification,
      claimEndDate: claimEndDate,
      claimStartDate: claimStartDate,
    };

    const claimUpdateApi = `claims/${claimId}/sub-claims/${subClaimId}`;
    if (claimId) {
      await ApiPutClientId(claimUpdateApi, data)
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              claimId: res.data.claimId,
              docsRequired: res.data.docsRequired,
            });
            this.nextStep();
          } else if (res.code === 400) {
            setError(res.message);
          }
        })
        .finally(() => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
    } else {
      await ApiPostClientId("claims", data)
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              claimId: res.data.claimId,
              docsRequired: res.data.docsRequired,
            });
            this.nextStep();
          } else if (res.code === 400) {
            setError(res.message);
          }
        })
        .finally(() => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
    }
  };

  submitStepForSupportingDocument = async (data) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { claimId, subClaimId } = this.props.match.params || {};

    const claimUpdateApi = `claims/${claimId}/sub-claims/${subClaimId}`;
    if (claimId) {
      await ApiPutClientId(claimUpdateApi, data)
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              claimId: res.data.claimId,
              docsRequired: res.data.docsRequired,
            });
            // this.nextStep();
          } else if (res.code === 400) {
            setError(res.message);
          }
        })
        .finally(() => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
    }
  };

  submitClaimForm = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    submitClaim(
      `claims/${this.state.claimId}/submit?resellerId=${getResellerId()}`
    )
      .then((res) => {
        if (res.code === 200) {
          this.nextStep();
        } else {
          setError(res.message);
        }
      })
      .catch((error) => console.log(error.message))
      .finally(() => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
  };

  handelAddNewHospital = (data) => {
    this.setState({
      hospitalId: data.hospitalId,
      hospitalName: data.hospitalName,
      hospitalCity: data.cityId,
      hospitalState: data.stateId,
      isModalOpen: {
        ...this.state.isModalOpen,
        label: "",
        isOpen: false,
      },
    });
  };

  handelAddNewBank = (data) => {
    this.setState({
      bankId: data.bankId,
      bankName: data.bankName,
      isBankModalOpen: {
        ...this.state.isBankModalOpen,
        label: "",
        isOpen: false,
      },
    });
  };

  resize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const { step, subPolicyList, subPolicyIds, productSpecification } =
      this.state;
    const { claimId = null, subClaimId } = this.props.match.params || {};

    return (
      <>
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <div className="w-full md:flex-1 md:justify-start flex">
            {step < 4 &&
            step > 1 &&
            ((step >= 1 && !claimId) || (claimId && step >= 2)) ? (
              <div
                onClick={this.prevStep}
                className="flex items-center pb-3"
                style={{
                  cursor: "pointer",
                  width: "fit-content",
                  color: "#000000",
                }}
              >
                <ArrowLeftIcon width={17} className="mr-3" />
                <p>{"Back"}</p>
              </div>
            ) : (
              <AppBackButton to="/" label="Back" color="#000000" />
            )}
          </div>
          <div className="w-full md:flex-1 flex justify-center">
            {step !== 4 && (
              <AppHeading
                className="text-center font-medium mb-6"
                style={{ color: "#000000" }}
              >
                Raise Reimbursement
              </AppHeading>
            )}
          </div>
          <div className="hidden md:flex md:flex-1"></div>
        </div>

        {step >= 1 && this.state.isMobile && step !== 4 && (
          <p className="flex border-b-2 items-center gap-2 justify-center pb-2">
            <span className="flex justify-center text-center border-2 rounded-xl h-8 w-8">
              {step}
            </span>
            {steps[step - 1].title}
          </p>
        )}

        {productSpecification != "CLOAN" &&
          !this.state.isMobile &&
          step >= 1 &&
          step !== 4 && <Stepper steps={steps} activeStep={step - 1} />}
        <div
          className={`w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-2`}
        >
          {/* {step === 1 ? (
            <div style={{ width: "100%" }}>
              <SelectSubPolicyForm
                key="1"
                onSubmit={this.nextStep}
                setValue={(value) => this.setState(value)}
                value={subPolicyList}
                subValue={subPolicyIds}
                field={"subPolicyList"}
                subField={"subPolicyIds"}
                state={this.state}
                isMobile={this.state.isMobile}
              />
            </div>
          ) : (
            false
          )} */}

          {step === 1 ? (
            <>
              <UserInformation
                setValue={(value) => this.setState(value)}
                state={this.state}
                isMobile={this.state.isMobile}
              />

              <HospitalizationReasonForm
                key="1"
                onSubmit={this.nextStep}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                isMobile={this.state.isMobile}
                handleAddnew={(value) => {
                  this.setState({
                    isModalOpen: {
                      ...this.state.isModalOpen,
                      label: value,
                      isOpen: true,
                    },
                  });
                }}
              />
            </>
          ) : (
            false
          )}

          {step === 2 ? (
            <div style={{ width: "100%" }}>
              <BankDetailsForm
                key="2"
                onSubmit={this.submitStepForm}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                isMobile={this.state.isMobile}
                handleAddnew={(value) => {
                  this.setState({
                    isBankModalOpen: {
                      ...this.state.isBankModalOpen,
                      label: value,
                      isOpen: true,
                    },
                  });
                }}
              />
            </div>
          ) : (
            false
          )}

          {step === 3 ? (
            <div style={{ width: "100%", marginTop: "30px" }}>
              <HealthDocuments
                key="3"
                onSubmit={this.submitClaimForm}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                columns={this.state.docsRequired}
                forUpdate={subClaimId}
              />
            </div>
          ) : (
            false
          )}
          {step === 4 ? (
            <SubmittedForm
              onSubmit={this.nextStep}
              setValue={(value) => this.setState(value)}
              state={this.state}
              value={subPolicyIds}
              prevStep={this.prevStep}
              columns={this.state.docsRequired}
            />
          ) : (
            false
          )}
        </div>
        <CustomModal
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
          handleSuccess={this.onRemove}
        />
        {this.state.isModalOpen.isOpen && (
          <Suspense fallback={<div>Loading...</div>}>
            <AddNewHospital
              open={this?.state?.isModalOpen?.isOpen}
              label={this?.state?.isModalOpen?.label}
              handelAddNewHospital={this.handelAddNewHospital}
              handleClose={() => {
                this.setState({
                  isModalOpen: {
                    ...this.state.isModalOpen,
                    label: "",
                    isOpen: false,
                  },
                });
              }}
              state={this.state}
            />
          </Suspense>
        )}
        {this.state.isBankModalOpen.isOpen && (
          <Suspense fallback={<div>Loading...</div>}>
            <AddNewBank
              open={this?.state?.isBankModalOpen?.isOpen}
              label={this?.state?.isBankModalOpen?.label}
              handelAddBank={this.handelAddNewBank}
              handleClose={() => {
                this.setState({
                  isBankModalOpen: {
                    ...this.state.isBankModalOpen,
                    label: "",
                    isOpen: false,
                  },
                });
              }}
              state={this.state}
            />
          </Suspense>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};

const ClaimConnect = connect(mapStateToProps)((props) => {
  return <Claim {...props} />;
});

export default withRouter(ClaimConnect);
