import React, { useEffect, useState } from "react";
import CustomAccordian from "../../../common/components/CustomAccordian";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Checkbox } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt, faStethoscope } from "@fortawesome/free-solid-svg-icons";
import InputSelect from "../../../../../components/formikForm/InputSelect";
import InputGroup from "../../../../../components/formikForm/InputGroup";
import {
  fetchProductBenefit,
  fetchVendorList,
  fieldLengthValidation,
  fieldValidation,
} from "../../../common/data/constants";
import MultiSelector from "../../../../../components/formikForm/MultiSelector";
import { memberCoveredOptions, tenureTypeOptions } from "../../../common/data";
import { ApiGet } from "../../../../../lib/AppHelper";

const Accidental = ({
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  membersCovered,
}) => {
  const [open, setOpen] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [claimDocs, setClaimDocs] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);

  const fetchClaimDocument = async () => {
    setLoadingDocs(true);
    const response = await ApiGet("admin/get-claim-documents");
    if (response.code == 200) {
      setClaimDocs(response.data || []);
    }
    setLoadingDocs(false);
  };

  useEffect(() => {
    fetchClaimDocument();
  }, []);

  const getVendorList = async () => {
    setVendorLoading(true);
    const list = await fetchVendorList("AC");
    setVendorList(list);
    setVendorLoading(false);
  };

  const getBenefits = async () => {
    setLoading(true);
    const list = await fetchProductBenefit("AC");
    if (list?.length) {
      const formattedOptions = list.map((benefit) => ({
        label: benefit?.description_text, // Adjust based on API response
        value: benefit?.id,
      }));
      setBenefit(formattedOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open && !vendorList?.length) {
      getVendorList();
    }
    if (open && !benefit?.length) {
      getBenefits();
    }
  }, [open]);

  const handleBenefitChange = (e) => {
    const data = e.map((item) => item?.value);
    setFieldValue("productBenefitId", data);
  };

  const handleDocsChange = (e) => {
    const data = e.map((item) => item?.value);
    setFieldValue("claimDocuments", data);
  };

  return (
    <CustomAccordian
      active={open}
      header={
        <div
          onClick={() => setOpen((prev) => !prev)}
          className="flex justify-between"
        >
          <div className="flex items-center">
            {open ? <ArrowDropDown className="rotate-180" /> : <ArrowRight />}
            <div
              onClick={(e) => {
                e?.stopPropagation();
              }}
            >
              <Checkbox
                name="Accidental Insurance"
                checked={values?.AC}
                onChange={(e, v) => {
                  setFieldValue(`AC`, v);
                  if (v && !open) {
                    setOpen(true);
                  }
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                icon={faShieldAlt}
                style={{ color: "#FF914D" }}
                className="text-primary-200"
              ></FontAwesomeIcon>
              <span
                className="text-base text-gray-700"
                style={{ fontWeight: 550 }}
              >
                Accidental Insurance
              </span>
            </div>
          </div>
        </div>
      }
      children={
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-2 items-center">
            <InputSelect
              error={touched?.vendorId && errors?.vendorId}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              label="Vendor Name "
              name={"vendorId"}
              value={values?.vendorId}
              helperText="Choose vendor for this subscription"
              onChange={(e) => {
                setFieldValue(`vendorId`, e.target.value);
                setFieldTouched("vendorId", true, false);
              }}
              options={vendorList || []}
              loading={vendorLoading}
            />
            <InputSelect
              error={touched?.membersCovered && errors?.membersCovered}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              label="Member Covered"
              name={"membersCovered"}
              value={values?.membersCovered}
              helperText="Members covered for this sub-product."
              onChange={(e) => {
                setFieldValue(`membersCovered`, e.target.value);
                setFieldTouched("membersCovered", true, false);
              }}
              options={memberCoveredOptions.filter(
                (item, index) => index < membersCovered
              )}
            />
            <InputGroup
              error={touched?.claimAmount && errors?.claimAmount}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              max={7}
              label="Reimbursement Amount"
              name="claimAmount"
              value={values?.claimAmount}
              helperText="Reimbursement amount for insurance"
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  fieldValidation.number.test(e.target.value)
                ) {
                  setFieldValue("claimAmount", e.target.value);
                  setFieldTouched("claimAmount", true, false);
                } else if (!values?.claimAmount) {
                  setFieldValue("claimAmount", "");
                }
              }}
            />
            <InputGroup
              error={touched?.funeralAmount && errors?.funeralAmount}
              style={{ fontWeight: "550", fontSize: "12px" }}
              max={7}
              label="Funeral Amount"
              name="funeralAmount"
              value={values?.funeralAmount}
              helperText="Funeral amount should be less than the reimbursement amount"
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  (/^\d*\.?\d*$/.test(e.target.value) &&
                    Number(values.claimAmount) >= Number(e.target.value))
                ) {
                  setFieldValue("funeralAmount", e.target.value);
                  setFieldTouched("funeralAmount", true, false);
                } else if (!values?.funeralAmount) {
                  setFieldValue("funeralAmount", "");
                }
              }}
              disabled={!values?.claimAmount}
              placeHolder={"0"}
            />

            <InputSelect
              error={touched?.tenureType && errors?.tenureType}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              label="Tenure Type"
              name={"tenureType"}
              value={values?.tenureType}
              helperText="Choose the duration type for the product."
              onChange={(e) => {
                setFieldValue(`tenureType`, e.target.value);
                setFieldTouched("tenureType", true, false);
              }}
              options={tenureTypeOptions}
            />

            <InputGroup
              error={touched?.tenure && errors?.tenure}
              style={{ fontWeight: "550", fontSize: "12px" }}
              requiredMark={true}
              disabled={!values?.tenureType}
              label="Tenure"
              name="tenure"
              value={values?.tenure}
              placeHolder="Enter tenure duration (e.g., 12 for 12 months)"
              helperText="The duration for which the product is active."
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  (e.target.value.length < fieldLengthValidation.number &&
                    fieldValidation.number.test(e.target.value))
                ) {
                  setFieldValue("tenure", e.target.value);
                  setFieldTouched("tenure", true, false);
                } else if (!values?.tenure) {
                  setFieldValue("tenure", "");
                }
              }}
            />
            <div className="col-span-3">
              <MultiSelector
                error={touched?.claimDocuments && errors?.claimDocuments}
                labelStyle={{ fontWeight: "550", fontSize: "12px" }}
                label={"Claim Documents"}
                options={claimDocs}
                onChange={handleDocsChange}
                selectedIds={values?.claimDocuments}
                loading={loadingDocs}
                requiredMark={true}
              />
            </div>
            <div className="col-span-3">
              <MultiSelector
                label={"Product Benefit"}
                labelStyle={{ fontWeight: "550", fontSize: "12px" }}
                options={benefit}
                onChange={handleBenefitChange}
                selectedIds={values?.productBenefitId}
                loading={loading}
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default Accidental;
