import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getResellerId,
  getResellerLanguage,
  JavaApiGet,
  postJoinCallAction,
  PythonCareApiPost,
} from "../lib/AppHelper";
import moment from "moment";
import Button from "./Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Modal, Loader } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import { toast } from "react-toastify";
import Doctor from "../images/doctor.png";
import Pharma from "../images/parma.png";
import { useSelector } from "react-redux";
import TimerFromStartDate from "../utils/TimerFromStartDate";
import GetHeader from "./GetHeader";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import CustomModal from "../screens/admin/components/CustomModal";
import ReimbursementSubPolicyDetail from "../screens/raiseReimbursement/reimbursementSingle/ReimbursementSubPolicyDetail.js";

export const MemberIconWithName = ({
  aiqaCenter,
  member,
  bookAppointmentData,
  size = 30,
  setExpanded,
  expanded,
  policyId,
  product_id,
  start_date,
  end_date,
  claimAdd,
  eligibleForClaim,
  componentDidMount,
  data,
}) => {
  const permissions = useSelector((state) => state?.user?.permissions);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBookAppointment, setIsBookAppointment] = useState(false);
  const [appointmentBookedDetails, setAppointmentBookedDetails] = useState("");
  const [patientId, setPatientId] = useState("");
  const [insuredMemberId, setInsuredMemberId] = useState("");
  const [blackOutDays, setBlackOutDays] = useState("");
  const [appointmentData, setAppointmentData] = useState(null);
  const [toggle, setToggle] = useState("policy");
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let subClaimPolicy = false;

  if (member?.sub_policy?.length > 0) {
    for (let index = 0; index < member.sub_policy.length; index++) {
      subClaimPolicy =
        member?.sub_policy[index]?.product_claim_detail?.eligible_for_claim ==
        "Y"
          ? true
          : subClaimPolicy;
    }
  }

  const onRaiseReimbursement = async (member) => {
    if (!member?.application_id) {
      return;
    }
    setModalOpen(member);
  };

  useEffect(() => {
    JavaApiGet("enrollment-service/NonServiceableDay").then((res) => {
      let data = [...res?.responseObject];
      if (data.length) {
        data.map((elem) => {
          if (
            elem.nonServiceableDate == moment().format("YYYY-MM-DD") &&
            elem?.blockAppointments
          ) {
            setBlackOutDays(
              `Sorry! we are not operational on ${moment(
                elem.nonServiceableDate
              ).format("ll")}.`
            );
          }
        });
      }
    });

    if (
      !member?.claim?.length &&
      data?.sub_policy_data?.some((obj) => obj.member_id === member.id)
    ) {
      setToggle("policy");
    }
  }, []);

  const showFirstLetter = (gender) => {
    if (!gender) return " ";
    return gender.charAt(0).toUpperCase();
  };

  const showDateOfBirth = (dob) => {
    if (!dob) return " ";
    return moment(dob).format("DD-MM-YYYY");
  };

  useEffect(() => {
    setIsBookAppointment(bookAppointmentData?.book_appointment);

    if (
      bookAppointmentData?.insured_member_id == member?.insured_member_id ||
      (bookAppointmentData?.name?.toLowerCase() ==
        member?.name?.toLowerCase() &&
        bookAppointmentData?.dob?.toLowerCase() == member?.dob?.toLowerCase() &&
        bookAppointmentData?.gender?.toLowerCase() ==
          member?.gender?.toLowerCase())
    ) {
      setPatientId(bookAppointmentData?.id);
      setInsuredMemberId(bookAppointmentData?.insured_member_id);
    }

    if (bookAppointmentData?.appointment?.length) {
      bookAppointmentData?.appointment?.map((appointmentItemPrimary) => {
        if (
          appointmentItemPrimary?.insured_member_id == member?.insured_member_id
        ) {
          setInsuredMemberId(member?.insured_member_id);
          setAppointmentData(appointmentItemPrimary);
          setAppointmentBookedDetails(
            `Appointment Date/Time ${
              appointmentItemPrimary?.appointment_date &&
              moment(appointmentItemPrimary?.appointment_date).format(
                "DD/MM/YYYY"
              )
            } ${
              appointmentItemPrimary?.appointment_time &&
              moment(appointmentItemPrimary?.appointment_time, [
                "HH:mm",
              ]).format("hh:mm a")
            } `
          );
        }
      });
    }
    if (bookAppointmentData && bookAppointmentData?.members) {
      bookAppointmentData?.members?.map((item) => {
        if (
          item?.insured_member_id == member?.insured_member_id ||
          (item?.name?.toLowerCase() == member?.name?.toLowerCase() &&
            item?.dob?.toLowerCase() == member?.dob?.toLowerCase() &&
            item?.gender?.toLowerCase() == member?.gender?.toLowerCase())
        ) {
          setPatientId(item?.id);
          setInsuredMemberId(item?.insured_member_id);
        }
        item?.appointment?.length &&
          item?.appointment?.map((appointmentItem) => {
            if (
              appointmentItem?.insured_member_id == member?.insured_member_id
            ) {
              setInsuredMemberId(member?.insured_member_id);
              setAppointmentData(appointmentItem);
              setAppointmentBookedDetails(
                `Appointment Date/Time ${
                  appointmentItem?.appointment_date &&
                  moment(appointmentItem?.appointment_date).format("DD/MM/YYYY")
                } ${
                  appointmentItem?.appointment_time &&
                  moment(appointmentItem?.appointment_time, ["HH:mm"]).format(
                    "hh:mm a"
                  )
                }`
              );
            }
          });
      });
    }
  }, [member?.insured_member_id, bookAppointmentData]);

  const bookAppointment = async () => {
    setLoading(true);
    let payload = {
      appointment_date: moment().format("YYYY-MM-DD"),
      appointment_time: moment().format("HH:mm"),
      appointment_type: "video_call",
      call_type: "connect_now",
      channel: "personal_clinic",
      created_from: "reseller",
      doctor_specialty: 1,
      patient: patientId,
      self_unpaid_appointment: false,
      type: "patient",
      language: getResellerLanguage() || "Hindi",
      application_id: member?.application_id,
      insured_member_id: insuredMemberId
        ? insuredMemberId
        : member?.insured_member_id,
      reseller_id: getResellerId(),
    };
    const data = await PythonCareApiPost("eclinic-appointment/", payload);
    if (data.status === "SUCCESS") {
      handleClose();
      toast.success("Appointment created successfully");
      setAppointmentData(data?.data);
      setAppointmentBookedDetails(
        `Appointment Date/Time ${
          data?.data?.appointment_date &&
          moment(data?.data?.appointment_date).format("DD/MM/YYYY")
        } ${
          data?.data?.appointment_time &&
          moment(data?.data?.appointment_time, ["HH:mm"]).format("hh:mm a")
        } `
      );
      componentDidMount();
    } else {
      if (data?.message) toast.error(data?.message);
      if (data?.data) toast.error(data?.data);
      else toast.error("Appointment not created");
    }
    setLoading(false);
    handleClose();
  };

  const handleJoinCall = (item) => {
    const data = {
      appointment_id: item.id,
      aiqa_center_joined: true,
      aiqa_center_id: getResellerId(),
    };
    postJoinCallAction(data);
    window.open(
      item?.meet_url,
      "popUpWindow",
      "height=300,width=700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
    );
  };

  const showRelation = (item) => {
    if (!item) return " ";
    item = item.replaceAll("_", " ");
    item = item.replaceAll("-", " ");
    return item;
  };

  const onChangeTab = (value) => {
    setToggle(value);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const transformString = (str) => {
    if (!str) return "";
    const result = str.replace(/_/g, " ");

    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  };

  const validateDate = (dateToCheck) => {
    // Get today's date
    let today = moment();
    // Parse the dateToCheck
    let date = moment(dateToCheck, "YYYY-MM-DD");
    // Check if date is after today
    if (date.isAfter(today, "day")) {
      return false;
    } else {
      return true;
    }
  };

  const isEndDateValid = (dateToCheck) => {
    // Get today's date
    let today = moment();
    // Parse the dateToCheck and add 90 days
    let date = moment(dateToCheck, "YYYY-MM-DD").add(90, "days");
    // Check if the date (after adding 90 days) is before today
    return !date.isBefore(today, "day");
  };

  return (
    <>
      <div
        onClick={() => {
          if (
            member?.claim?.length ||
            data?.sub_policy_data.some((obj) => obj.member_id === member.id)
          )
            setExpanded((expanded) => !expanded);
        }}
        className="block md:flex justify-between"
      >
        <div>
          {GetHeader(member, size)}
          <p className="pl-10 text-medium text-md capitalize">
            {member?.name?.toLowerCase()}
          </p>
          <p className="pl-10 text-xs">
            {showRelation(member?.relation)} | {showFirstLetter(member?.gender)}{" "}
            | {showDateOfBirth(member?.dob)}
          </p>
        </div>

        <div className="flex justify-center items-center mt-4 md:mt-0">
          <div className="flex w-full justify-center content-center items-center flex-col md:flex-row">
            {(permissions?.center_coordinator || aiqaCenter) &&
              isBookAppointment &&
              validateDate(start_date) &&
              !appointmentBookedDetails && (
                <Button
                  size={"sm"}
                  title="Book Appointment"
                  attrs={{
                    onClick: () => handleOpen(),
                  }}
                />
              )}

            {(permissions?.center_coordinator || aiqaCenter) &&
            appointmentBookedDetails ? (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="md:w-56 w-full text-center"
              >
                {appointmentBookedDetails}
              </Typography>
            ) : (
              false
            )}

            {permissions?.center_coordinator &&
              appointmentData?.status == "call_initiated" && (
                <div>
                  <div className="flex w-full items-center justify-center">
                    <div className="hidden md:flex mr-2 gap-3">
                      <div className="flex">
                        <img
                          src={Doctor}
                          className="bg-white rounded-full border border-red-500 h-10 w-10"
                          alt="A"
                        />
                        <span
                          style={{
                            backgroundColor: appointmentData?.doctor_joined
                              ? "#37D510"
                              : "#6B6B6B",
                          }}
                          className="h-3 w-3 rounded-full absolute ml-7"
                        />
                      </div>
                      <div className="flex">
                        <img
                          src={Pharma}
                          className="bg-white rounded-full border border-red-500 h-10 w-10"
                          alt="A"
                        />
                        <span
                          style={{
                            backgroundColor: appointmentData?.pharmacist_joined
                              ? "#37D510"
                              : "#6B6B6B",
                          }}
                          className="h-3 w-3 rounded-full absolute ml-7"
                        />
                      </div>
                    </div>
                    <div
                      style={{ backgroundColor: "#F7B500" }}
                      className="mt-3 md:mt-0 h-12 md:h-16 md:w-48 text-white rounded-2xl flex items-center justify-center w-full"
                    >
                      <div className="flex md:block justify-between items-center md:justify-center md:w-36 w-60 pl-4 pr-4">
                        <div>On Going Call</div>
                        <span className="float-right text-xs ml-5">
                          {
                            <TimerFromStartDate
                              startDate={appointmentData?.call_join_time}
                            />
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="md:hidden w-full m-2 flex gap-4">
                    <div>
                      <div className="flex items-center mb-4">
                        <div
                          className="h-4 w-4 rounded-full"
                          style={{ backgroundColor: "#37D510" }}
                        />
                        <label
                          for="default-checkbox"
                          className="ms-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Doctor
                        </label>
                      </div>
                      <div className="flex items-center mb-4">
                        <div
                          className="h-4 w-4 rounded-full"
                          style={{ backgroundColor: "#6B6B6B" }}
                        />
                        <label
                          for="default-checkbox"
                          className="ms-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Pharmacist
                        </label>
                      </div>
                    </div>
                    {/* <div>
                      {
                        <TimerFromStartDate
                          startDate={appointmentData?.call_join_time}
                        />
                      }
                    </div> */}
                  </div>
                </div>
              )}

            {(permissions?.center_coordinator || aiqaCenter) &&
            appointmentData?.status == "pending" &&
            appointmentData.meet_url ? (
              <Button
                size={"sm"}
                title="Join Call"
                attrs={{
                  onClick: () => handleJoinCall(appointmentData),
                  style: { backgroundColor: "#564CC8", width: "150px" },
                }}
                className="mt-3 md:mt-0"
              />
            ) : (
              false
            )}

            {eligibleForClaim?.toLowerCase() === "y" &&
              validateDate(start_date) &&
              isEndDateValid(end_date) && (
                <div
                  // to={{
                  //   pathname: `/reimbursement/${member?.id}/${policyId}`,
                  //   state: {
                  //     memberId: member?.id,
                  //     policyId,
                  //     start_date,
                  //     productId: product_id,
                  //   },
                  // }}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  className="mt-3 md:mt-0"
                >
                  {subClaimPolicy && claimAdd ? (
                    <Button
                      size={"sm"}
                      title="Raise Reimbursement"
                      attrs={{
                        onClick: (e) => {
                          e.stopPropagation();
                          onRaiseReimbursement(member);
                        },
                      }}
                    />
                  ) : (
                    false
                  )}
                </div>
              )}
          </div>

          <div
            style={{ width: 10, fontSize: "x-large" }}
            className="md:block hidden ml-2 mr-2"
          >
            {expanded ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </div>
        </div>
      </div>
      {expanded ? (
        <div>
          <div style={{ margin: "10px", cursor: "default" }}>
            <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                {member?.sub_policy?.length > 0 && (
                  <li className="me-2" role="presentation">
                    <button
                      className={`inline-block p-4 border-b-2 rounded-t-lg   hover:border-red-300 ${
                        toggle === "policy" ? "text-red-500 border-red-300" : ""
                      }`}
                      type="button"
                      onClick={() => onChangeTab("policy")}
                    >
                      Sub Subscription Details
                    </button>
                  </li>
                )}
                {member?.claim?.length > 0 && (
                  <li className="me-2" role="presentation">
                    <button
                      className={`inline-block p-4 border-b-2 rounded-t-lg   hover:border-red-300 ${
                        toggle === "claim" ? "text-red-500 border-red-300" : ""
                      }`}
                      type="button"
                      onClick={() => onChangeTab("claim")}
                    >
                      Reimbursement Details
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <div className="flex gap-2 flex-wrap">
              {member?.claim?.length > 0 &&
                toggle === "claim" &&
                member?.claim?.map((item) => {
                  const transactionNumber = item?.sub_claims?.find(
                    (e) => e?.transaction_number
                  );
                  const claimAmount = item?.sub_claims?.find(
                    (e) => e?.claim_amount
                  );
                  const claimApprovedAmount = item?.sub_claims?.find(
                    (e) => e?.claim_approved_amount
                  );

                  return (
                    <Card
                      sx={{ borderColor: "error.main" }}
                      className="md:w-1/3 w-full"
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          className="flex justify-between"
                          gutterBottom
                        >
                          <span style={{ fontWeight: "600", width: "49%" }}>
                            Bank Name
                          </span>
                          <span>:&nbsp;</span>
                          <span style={{ width: "49%" }}>
                            {capitalizeFirstLetter(item.bank_name)}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          className="flex justify-between"
                          gutterBottom
                        >
                          <span style={{ fontWeight: "600", width: "49%" }}>
                            Account Holder Name
                          </span>
                          <span>:&nbsp;</span>
                          <span style={{ width: "49%" }}>
                            {capitalizeFirstLetter(item.account_holder_name)}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          className="flex justify-between"
                          gutterBottom
                        >
                          <span style={{ fontWeight: "600", width: "49%" }}>
                            Reimbursement Reason
                          </span>
                          <span>:&nbsp;</span>
                          <span style={{ width: "49%" }}>
                            {capitalizeFirstLetter(item.claim_reason)}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          className="flex justify-between"
                          gutterBottom
                        >
                          <span style={{ fontWeight: "600", width: "49%" }}>
                            Reimbursement Amount
                          </span>
                          <span>:&nbsp;</span>
                          <span style={{ width: "49%" }}>
                            {claimAmount?.claim_amount
                              ? claimAmount?.claim_amount
                              : ""}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          className="flex justify-between"
                          gutterBottom
                        >
                          <span style={{ fontWeight: "600", width: "49%" }}>
                            Reimbursement Approved Amount
                          </span>
                          <span>:&nbsp;</span>
                          <span style={{ width: "49%" }}>
                            {claimApprovedAmount?.claim_approved_amount
                              ? claimApprovedAmount?.claim_approved_amount
                              : ""}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          className="flex justify-between"
                          gutterBottom
                        >
                          <span style={{ fontWeight: "600", width: "49%" }}>
                            Reimbursement Status
                          </span>
                          <span>:&nbsp;</span>
                          <span style={{ width: "49%" }}>
                            {item.status ? transformString(item.status) : ""}
                          </span>
                        </Typography>
                        {(item.status === "SETTLED" ||
                          item.status === "PARTIAL_SETTLE") && (
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            className="flex justify-between"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "600", width: "49%" }}>
                              Transaction Number
                            </span>
                            <span>:&nbsp;</span>
                            <span style={{ width: "49%" }}>
                              {transactionNumber?.transaction_number
                                ? transactionNumber?.transaction_number
                                : ""}
                            </span>
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  );
                })}
            </div>

            <div className="flex gap-2 flex-wrap">
              {data?.sub_policy_data?.length > 0 &&
                toggle === "policy" &&
                data?.sub_policy_data?.map((item) => {
                  if (item?.member_id === member?.id) {
                    return (
                      <Card
                        sx={{ borderColor: "error.main" }}
                        className="md:w-1/3 w-full"
                      >
                        <CardContent>
                          {item?.policy_id ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Subscription Id
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                {item?.policy_id || ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.sub_policy_id ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Sub Subscription Id
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                {item?.sub_policy_id || ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.product_benefit ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Product Benefit
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                {capitalizeFirstLetter(item?.product_benefit) ||
                                  ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.loan_amount &&
                          item?.product_specification === "CLOAN" ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Sum insured
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                ₹{item?.loan_amount || ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.pa_amount &&
                          `${item?.product_benefit}`.toUpperCase() ===
                            "Accidental Insurance".toUpperCase() ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Sum insured
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                ₹{item?.pa_amount || ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.vendor_name ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Vendor Name
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                {capitalizeFirstLetter(item?.vendor_name) ===
                                "Aiqahealth"
                                  ? "aiqa health"
                                  : capitalizeFirstLetter(item?.vendor_name) ||
                                    ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.vendor_policy_id ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Vendor Subscription Id
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                {item?.vendor_policy_id || ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}

                          {item?.insured_member_id ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              className="flex justify-between"
                              gutterBottom
                            >
                              <span style={{ fontWeight: "600", width: "49%" }}>
                                Insured Member Id
                              </span>
                              <span>:&nbsp;</span>
                              <span style={{ width: "49%" }}>
                                {item?.insured_member_id || ""}
                              </span>
                            </Typography>
                          ) : (
                            false
                          )}
                        </CardContent>
                      </Card>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Modal open={open} onClose={handleClose} size="xs">
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            <RemindIcon style={{ color: "#ffb300", fontSize: 36 }} />
          </p>
          <p style={{ textAlign: "center" }}>
            {blackOutDays
              ? blackOutDays
              : "Are you sure you want to book Appointment?"}
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {loading ? (
            <Loader size="md" />
          ) : (
            <>
              {!blackOutDays && (
                <Button
                  size={"sm"}
                  attrs={{
                    onClick: () => bookAppointment(),
                  }}
                  title="Yes, I'm sure"
                ></Button>
              )}
              <Button
                size={"sm"}
                attrs={{
                  onClick: () => handleClose(),
                }}
                title={!blackOutDays ? "No, cancel" : "cancel"}
              ></Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <CustomModal
        open={modalOpen}
        setOpen={setModalOpen}
        padding="10px 16px"
        margin="10px 8px"
        minWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        maxWidth={{
          xs: "initial",
          sm: "60%",
          md: "45%",
        }}
        borderRadius="16px"
      >
        <Modal.Header onClick={() => setModalOpen(false)}>
          <Modal.Title style={{ fontWeight: "600" }}>
            Sub Policy Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReimbursementSubPolicyDetail
            applicationId={modalOpen?.application_id}
            memberData={modalOpen}
            setOpen={setModalOpen}
          />
        </Modal.Body>
      </CustomModal>
    </>
  );
};
