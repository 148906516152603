import React, { useEffect, useState } from "react";
import CustomAccordian from "../../../common/components/CustomAccordian";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Checkbox } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import InputSelect from "../../../../../components/formikForm/InputSelect";
import SlabSubProduct from "./SlabSubProduct";
import {
  fetchProductBenefit,
  fetchVendorList,
  fieldLengthValidation,
  fieldValidation,
} from "../../../common/data/constants";
import MultiSelector from "../../../../../components/formikForm/MultiSelector";
import { memberCoveredOptions, tenureTypeOptions } from "../../../common/data";
import InputGroup from "../../../../../components/formikForm/InputGroup";
import { Divider } from "@mui/material";

const Hospi = ({
  allValue,
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  membersCovered,
}) => {
  const [open, setOpen] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);

  const getVendorList = async () => {
    setVendorLoading(true);
    const list = await fetchVendorList("HC");
    setVendorList(list);
    setVendorLoading(false);
  };

  const getBenefits = async () => {
    setLoading(true);
    const list = await fetchProductBenefit("HC");
    if (list?.length) {
      const formattedOptions = list.map((benefit) => ({
        label: benefit?.description_text, // Adjust based on API response
        value: benefit?.id,
      }));
      setBenefit(formattedOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      if (!vendorList?.length) getVendorList();
      if (!benefit.length) getBenefits();
    }
  }, [open]);

  const handleBenefitChange = (e) => {
    const data = e.map((item) => item?.value);
    setFieldValue("productBenefitId", data);
  };

  return (
    <>
      <CustomAccordian
        active={open}
        header={
          <div
            onClick={() => setOpen((prev) => !prev)}
            className="flex justify-between"
          >
            <div className="flex items-center">
              {open ? <ArrowDropDown className="rotate-180" /> : <ArrowRight />}
              <div
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <Checkbox
                  name="Hospital Cash"
                  checked={values?.HC}
                  onChange={(e, v) => {
                    if (v && !open) {
                      setOpen(true);
                    }
                    setFieldValue(`HC`, v);
                  }}
                  className="cursor-pointer"
                />
              </div>
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={faCoins}
                  style={{ color: "#db2228" }}
                  className="text-primary-200"
                ></FontAwesomeIcon>
                <span
                  className="text-base text-gray-700"
                  style={{ fontWeight: 550 }}
                >
                  Hospital Cash
                </span>
              </div>
            </div>
          </div>
        }
        children={
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-2 items-center">
              <InputSelect
                error={touched?.vendorId && errors?.vendorId}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                label="Vendor Name "
                name={"vendorId"}
                value={values?.vendorId}
                helperText="Choose vendor for this subscription"
                onChange={(e) => {
                  setFieldValue(`vendorId`, e.target.value);
                  setFieldTouched("vendorId", true, false);
                }}
                options={vendorList || []}
                loading={vendorLoading}
              />

              <InputSelect
                error={touched?.tenureType && errors?.tenureType}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                label="Tenure Type"
                name={"tenureType"}
                value={values?.tenureType}
                helperText="Choose the duration type for the product."
                onChange={(e) => {
                  setFieldValue(`tenureType`, e.target.value);
                  setFieldTouched("tenureType", true, false);
                }}
                options={tenureTypeOptions}
              />

              <InputGroup
                error={touched?.tenure && errors?.tenure}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                disabled={!values?.tenureType}
                label="Tenure"
                name="tenure"
                value={values?.tenure}
                placeHolder="Enter tenure duration (e.g., 12 for 12 months)"
                helperText="The duration for which the product is active."
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    (e.target.value.length < fieldLengthValidation.number &&
                      fieldValidation.number.test(e.target.value))
                  ) {
                    setFieldValue("tenure", e.target.value);
                    setFieldTouched("tenure", true, false);
                  } else if (!values?.tenure) {
                    setFieldValue("tenure", "");
                  }
                }}
              />
              <InputSelect
                error={touched?.membersCovered && errors?.membersCovered}
                style={{ fontWeight: "550", fontSize: "12px" }}
                requiredMark={true}
                label="Member Covered"
                name={"membersCovered"}
                value={values?.membersCovered}
                helperText="Members covered for this sub-product."
                onChange={(e) => {
                  setFieldValue(`membersCovered`, e.target.value);
                  setFieldTouched("membersCovered");
                }}
                options={memberCoveredOptions.filter(
                  (item, index) => index < membersCovered
                )}
              />
              <SlabSubProduct
                error={touched?.hospiSlab && errors?.hospiSlab}
                slabData={values.hospiSlab}
                title={"Hospital Cash"}
                max={2}
                keyField="hospitalSlab"
                setFieldValue={(value) => setFieldValue(`hospiSlab`, value)}
              />
              <Divider className="col-span-3 pt-5" />
              <SlabSubProduct
                error={touched?.icuSlab && errors?.icuSlab}
                slabData={values.icuSlab}
                title={"ICU"}
                max={2}
                keyField="icuSlab"
                setFieldValue={(value) => setFieldValue(`icuSlab`, value)}
              />
            </div>

            <div className="p-2 flex flex-col gap-1">
              <p className="font-medium">Product Benefit</p>
              <MultiSelector
                selectedIds={values?.productBenefitId || []}
                options={benefit}
                onChange={handleBenefitChange}
                loading={loading}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Hospi;
