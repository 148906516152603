import React, { useState } from "react";
import { toast } from "react-toastify";
import { ApiGet } from "../../../lib/AppHelper";
import { Loader, Placeholder } from "rsuite";
import VendorIcon from "../../../../src/assets/productCreation/vendor.png";
import { FaInfoCircle, FaCheckCircle, FaClock, FaTags } from "react-icons/fa";
import CustomModal from "../common/components/CustomModal";
import { Tooltip } from "@mui/material";

const VendorInfo = ({ id, name, status, vendorCode }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorInfo, setVendorInfo] = useState([]);

  const fetchVendorInfo = async () => {
    setIsModalOpen(true);
    const response = await ApiGet(`admin/show-vendor?vendorCode=${id}`);
    if (response.code == 200) {
      setIsModalOpen(true);
      setVendorInfo(response.data);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const getVendorInfo = () => {
    setLoading(true);
    fetchVendorInfo();
  };

  return (
    <>
      <Tooltip title="Vendor details" placement="right" arrow>
        <div
          className="cursor-pointer underline text-blue-600"
          onClick={getVendorInfo}
        >
          {id}
        </div>
      </Tooltip>

      <CustomModal
        title={
          <div className="flex items-center gap-2 ">
            <img style={{ width: "30px", height: "30px" }} src={VendorIcon} />
            <p className="text-l font-bold"> {`${id} `}</p>
          </div>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        body={
          loading ? (
            <div
              className="flex"
              style={{
                height: "300px",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Placeholder.Paragraph rows={8} />
              <Loader size="md" content="Loading..." />
            </div>
          ) : (
            <VendorDetail
              id={id}
              name={name}
              status={status}
              vendorCode={vendorCode}
              vendor={vendorInfo}
            />
          )
        }
      />
    </>
  );
};

export default VendorInfo;

const VendorDetail = ({ name, status, vendorCode, vendor }) => {
  return (
    <div className="bg-modalBg">
      <div className="p-4">
        <div className="flex items-center gap-2 mb-2 ">
          <span className="text-l font-bold">{"Basic Information"}</span>
        </div>
        <VendorBasic name={name} vendorCode={vendorCode} status={status} />
      </div>
      <div className="p-4">
        <div className="flex items-center gap-2 mb-2 ">
          <span className="text-l font-bold">{"Benefits"}</span>
        </div>
        <VendorBenefits benefits={vendor} />
      </div>
    </div>
  );
};

const VendorBasic = ({ name, vendorCode, status }) => {
  return (
    <>
      <div className="mx-auto bg-white shadow-md rounded-lg border border-gray-200">
        <div className="bg-[#db2228] text-black text-center ml-2 py-3 rounded-t-lg flex items-center justify-start space-x-2">
          <img style={{ width: "20px", height: "20px" }} src={VendorIcon} />
          <h2 className="text-md font-semibold">{name}</h2>
        </div>

        <div className="p-4 text-gray-700 space-y-3">
          <div className="flex items-center space-x-2">
            <FaTags style={{ color: "#db2228" }} />
            <p className="text-sm font-medium">
              <span className="font-semibold">Vendor Code: </span> {vendorCode}
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <FaInfoCircle style={{ color: "#db2228" }} />
            <p className="flex items-center justify-center gap-1 text-sm font-medium">
              <span className="font-semibold">Status: </span>

              {status ? (
                <div className="flex gap-1 items-center">
                  Active <FaCheckCircle style={{ color: "green" }} />
                </div>
              ) : (
                <div className="flex gap-1 items-center">
                  Awaiting approval <FaClock style={{ color: "#8B8000" }} />
                </div>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const VendorBenefits = ({ benefits }) => {
  return (
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-x-auto">
          <table
            className="min-w-full border-solid border border-slate-400"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <thead className="border-b">
              <tr
                className="border border-slate-300"
                style={{ backgroundColor: "#393939", color: "#fff" }}
              >
                <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                  Name
                </th>
                <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                  Specification
                </th>
                <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                  Benefit Code
                </th>
                <th className="border border-slate-300 text-sm font-medium px-4 py-2 whitespace-nowrap text-center">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {benefits?.length > 0 ? (
                benefits?.map((item, index) => {
                  return (
                    <tr className="border border-slate-300" key={index}>
                      <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                        {item?.type || "-"}
                      </td>
                      <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center font-650">
                        {item?.specification || "-"}
                      </td>
                      <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                        {item?.benefit_code || "-"}
                      </td>
                      <td className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center">
                        {item?.approved ? (
                          <p className="w-full flex justify-center items-center">
                            <FaCheckCircle style={{ color: "green" }} />
                          </p>
                        ) : (
                          <p className="w-full flex justify-center items-center">
                            <FaClock style={{ color: "#8B8000" }} />
                          </p>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="border border-slate-300">
                  <td
                    colSpan={4}
                    className="border border-slate-300 text-gray-900 px-4 py-2 whitespace-nowrap text-center"
                  >
                    No information found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
