import React, { useState } from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { getNotificationData } from "./common/data";
import { Link } from "react-router-dom";
import ShimmerLoader from "../../components/common/ShimmerLoader";
import { Modal } from "@mui/material";

const RecentUpdate = ({ data, userPermissions }) => {
  return (
    <div
      className="flex flex-col py-3 px-4 gap-4 bg-white"
      style={{
        borderRadius: "24px",
        boxShadow: "2px 2px 4px 0px #0000000A",
      }}
    >
      <div className="flex justify-between font-semibold text-left">
        <div style={{ color: "#393939", fontSize: "16px" }}>Recent Updates</div>
        <Link to={{ pathname: "/notifications", state: { data: "" } }}>
          <div
            className="font-medium text-xs self-center"
            style={{ color: "rgba(42, 181, 20, 1)", cursor: "pointer" }}
          >
            {"View"}
            <ArrowForwardOutlinedIcon sx={{ fontSize: 12 }} />
          </div>
        </Link>
      </div>

      {Object.keys(data).map((item, index) => {
        const permission = data[item]?.permission;
        let hasPermission = permission.length
          ? permission?.some((perm) => userPermissions?.[perm])
          : true;
        let notifyData = getNotificationData(item, data[item].notificationData);
        let key = data[item].label;
        return (
          hasPermission && (
            <div
              className="w-full border border-gray-200 border-opacity-70 rounded-md relative"
              key={index}
            >
              <span
                style={{
                  background: data[item].styles.backgroundColor,
                  color: "white",
                  fontSize: "10px",
                  padding: "2px 3px",
                  borderRadius: "3px",
                  position: "absolute",
                  left: 5,
                  top: -8,
                  fontWeight: "600",
                }}
              >
                {data[item].label}
              </span>
              {data[item].loader ? (
                <div className="px-2 py-2">
                  <ShimmerLoader rows={1} />
                </div>
              ) : (
                <div className="flex justify-between items-center mt-3 mb-2 ">
                  <UpdateBody data={notifyData} redirect={data[item]?.label} />
                  {notifyData?.length > 0 && (
                    <Link
                      to={{ pathname: "/notifications", state: { data: key } }}
                    >
                      <span
                        className="font-medium text-xs mr-2"
                        style={{
                          color: "rgba(42, 181, 20, 1)",
                        }}
                      >
                        <ArrowForwardOutlinedIcon sx={{ fontSize: 16 }} />
                      </span>
                    </Link>
                  )}
                </div>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default RecentUpdate;

const UpdateBody = ({ data = [], redirect }) => {
  const [open, setOpen] = useState(false);
  const visibleItems = data.slice(0, 2);
  const remainingCount = data.length - visibleItems.length;

  return (
    <div className="flex flex-col">
      {data.length > 0 ? (
        <>
          {visibleItems.map((elm, index) => (
            <div
              key={index}
              className="text-xs px-3 font-medium flex items-center"
            >
              {elm}
              {index === visibleItems.length - 1 && remainingCount > 0 && (
                <span
                  className="ml-2 text-blue-500 cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  +{remainingCount} more
                </span>
              )}
            </div>
          ))}
          <Modal open={open} onClose={() => setOpen(false)}>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded-lg shadow-lg w-80">
                <h3 className="text-lg font-semibold mb-2">All Updates</h3>
                <div className="max-h-60 overflow-auto">
                  {data.map((elm, index) => (
                    <div key={index} className="text-sm border-b p-2">
                      {elm}
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex gap-2">
                  <button
                    className="w-full text-sm self-center bg-gray-200 text-black-700 py-2 rounded-md"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <Link
                    to={{
                      pathname: "/notifications",
                      state: { data: redirect },
                    }}
                    className="w-full text-sm self-center text-center bg-red-500 text-white py-2 rounded-md"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div className="text-xs px-3 font-medium">No new updates</div>
      )}
    </div>
  );
};
