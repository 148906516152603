import React, { useEffect, useState } from "react";
import { ApiGet } from "../../../../lib/AppHelper";
import CustomAccordian from "../../common/components/CustomAccordian";
import ShimmerLoader from "../../../../components/common/ShimmerLoader";
import { ArrowRight, ArrowDropDown } from "@mui/icons-material";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroup from "../../../../components/formikForm/InputGroup";
import InputSelect from "../../../../components/formikForm/InputSelect";
import { fieldValidation } from "../../common/data/constants";

const ProductAddOnBenefitsInfo = ({
  values,
  setFieldValue,
  addOnBenefits,
  setAddOnBenefits,
  initalLoading,
  error,
  setFieldTouched,
  touched,
  errors,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchBenefitVendor = async (slugName, index) => {
    setLoading(slugName);
    if (!addOnBenefits[index].vendor.length) {
      const response = await ApiGet(
        `admin/vendor-for-product-add-on-benefit?slug=${slugName}`
      );
      if (response.code == 200) {
        setAddOnBenefits((prevData) =>
          prevData.map((item, i) =>
            i === index ? { ...item, vendor: response?.data } : item
          )
        );
      }
    }
    setLoading(false);
  };

  return (
    <div>
      {initalLoading ? (
        <ShimmerLoader rows={8} />
      ) : (
        addOnBenefits?.length &&
        addOnBenefits?.map((benefit, index) => {
          return (
            <CustomAccordian
              key={index}
              className={error?.[benefit?.value] ? "border-red-500" : ""}
              onSelect={() => {
                setOpen((prev) => !prev);
                fetchBenefitVendor(benefit?.value, index);
              }}
              header={
                <div className="flex w-full" onClick={() => {}}>
                  {open ? (
                    <ArrowDropDown className="rotate-180" />
                  ) : (
                    <ArrowRight />
                  )}

                  <div className="flex items-center space-x-2">
                    {" "}
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="1x"
                      style={{ color: "#FF914D" }}
                    />
                    <p
                      className="text-sm text-gray-700"
                      style={{ fontWeight: 550 }}
                    >
                      {benefit?.label
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </p>
                  </div>
                </div>
              }
              children={
                <div className="ml-2 grid grid-cols-3 gap-6">
                  <InputSelect
                    error={
                      touched?.[benefit.value]?.productAddOnBenefitId &&
                      errors?.[benefit.value]?.productAddOnBenefitId
                    }
                    style={{ fontWeight: "550", fontSize: "12px" }}
                    requiredMark={true}
                    label="Select Vendor"
                    name="productAddOnBenefitId"
                    value={values?.[benefit?.value]?.productAddOnBenefitId}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue(
                          `${benefit.value}.productAddOnBenefitId`,
                          e.target.value
                        );
                      } else {
                        setFieldValue(
                          `${benefit.value}.productAddOnBenefitId`,
                          e.target.value
                        );
                        setFieldValue(`${benefit.value}.numberOfUses`, "");
                      }
                      setFieldTouched(
                        `${[benefit?.value]}.productAddOnBenefitId`
                      );
                    }}
                    options={benefit?.vendor || []}
                    loading={loading === benefit?.value}
                  />

                  <InputGroup
                    max={3}
                    error={
                      touched?.[benefit.value]?.numberOfUses &&
                      errors?.[benefit.value]?.numberOfUses
                    }
                    style={{ fontWeight: "550", fontSize: "12px" }}
                    disabled={!values?.[benefit?.value]?.productAddOnBenefitId}
                    requiredMark={true}
                    label="Number Of Uses"
                    name="numberOfUses"
                    value={values?.[benefit.value]?.numberOfUses}
                    onChange={(e) => {
                      if (
                        e.target.value == "" ||
                        fieldValidation.number.test(e.target.value)
                      ) {
                        setFieldValue(
                          `${benefit.value}.numberOfUses`,
                          e.target.value
                        );
                        setFieldTouched(`${benefit.value}.numberOfUses`);
                      } else if (!values?.[benefit.value]?.numberOfUses) {
                        setFieldValue(`${benefit.value}.numberOfUses`, "");
                      }
                    }}
                  />
                </div>
              }
            />
          );
        })
      )}
    </div>
  );
};

export default ProductAddOnBenefitsInfo;
