import { CloseIcon } from "../../../Layout/SidebarIcons";

const SearchFilters = ({
  values,
  onChangeHandler,
  onSubmit,
  onClose,
  errors,
  disable,
  onClear,
}) => {
  return (
    <form className="p-4" onChange={onChangeHandler} onSubmit={onSubmit}>
      <div className="grid grid-cols-1 gap-2">
        <div className="w-full flex justify-between items-center">
          <h3 className="font-bold text-sm">Search Filters</h3>
          <div className="cursor-pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>

        <div className="flex flex-col md:grid grid-cols-2 gap-3">
          <div className="w-full">
            <label className="flex text-xs">Mobile number</label>
            <input
              placeholder="Enter mobile number"
              name="mobile"
              className="border border-gray-300 rounded-lg p-2 text-sm w-full focus:ring-0 focus:outline-none"
              value={values?.mobile}
            />
            {errors?.mobile && (
              <p className="mt-2 text-xs text-red-500">{errors?.mobile}</p>
            )}
          </div>
          <div className="w-full">
            <label className="flex text-xs">Aadhar</label>
            <input
              placeholder="Enter aadhar number"
              name="aadhaar_id"
              className="border border-gray-300 rounded-lg p-2 text-sm w-full focus:ring-0 focus:outline-none"
              value={values?.aadhaar_id}
            />
            {errors?.aadhaar_id && (
              <p className="mt-2 text-xs text-red-500">{errors?.aadhaar_id}</p>
            )}
          </div>
          <div className="w-full">
            <label className="flex text-xs">Loan Code/Reference number</label>
            <input
              placeholder="Enter Loan code/Reference number"
              name="loan_code"
              className="border border-gray-300 rounded-lg p-2 text-sm w-full focus:ring-0 focus:outline-none"
              value={values?.loan_code}
            />
            {errors?.loan_code && (
              <p className="mt-2 text-xs text-red-500">{errors?.loan_code}</p>
            )}
          </div>
          <div className="w-full">
            <label className="flex text-xs">Insured Member Id</label>
            <input
              placeholder="Enter member id"
              name="insured_member_id"
              className="border border-gray-300 rounded-lg p-2 text-sm w-full focus:ring-0 focus:outline-none"
              value={values?.insured_member_id}
            />
            {errors?.insured_member_id && (
              <p className="mt-2 text-xs text-red-500">
                {errors?.insured_member_id}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end mt-3 mb-4">
          <button
            type="button"
            className=" text-sm border border-red-500 bg-white-500 text-red-500 px-3 py-1 rounded-lg mr-2"
            onClick={onClear}
          >
            Clear all
          </button>
          <button
            type="submit"
            className={`${
              disable ? "bg-gray-500" : "bg-red-500"
            } text-sm text-white px-3 py-1 rounded-lg`}
            disabled={disable}
            onClick={onSubmit}
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchFilters;
