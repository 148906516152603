import React, { forwardRef, useRef, useState } from "react";
import { Button, Form, Schema, SelectPicker } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { RefreshIcon } from "@heroicons/react/outline";
import { refreshIconStyles, searchButtonStyles } from "../data";

const Field = forwardRef((props, ref) => {
  const {
    name,
    message,
    label,
    accepter,
    error,
    onPressEnter,
    value,
    ...rest
  } = props;
  return (
    <Form.Group
      style={{
        color: "#686D6F",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "12px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      controlId={`${name}-10`}
      ref={ref}
      className={error ? "has-error " : ""}
    >
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control
        name={name}
        accepter={accepter}
        errorMessage={error}
        onKeyDown={(event) => {
          if (event.key === "Enter" && onPressEnter) {
            event.preventDefault(); // Prevent form submission if needed
            onPressEnter(value); // Pass the latest value
          }
        }}
        {...rest}
      />
    </Form.Group>
  );
});

const { NumberType } = Schema.Types;
const model = Schema.Model({
  number: NumberType(),
});

export const CustomProductSearchFilter = ({
  searchFilters,
  setSearchFilters,
  handleSubmit,
  onResetHandle,
}) => {
  const data = ["All", "Individual", "Combo"].map((item) => ({
    label: item,
    value: item == "All" ? "" : item,
  }));
  const formRef = useRef();

  const handleChange = (key, value) => {
    setSearchFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Form
      className="w-full p-2 grid grid-cols-1 md:grid-cols-5 gap-1 md:gap-2 justify-between items-center"
      ref={formRef}
      // onChange={handleChange}
      formValue={searchFilters}
    >
      <Field
        name={"product_name"}
        value={searchFilters.product_name}
        onChange={(value) => handleChange("product_name", value)}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        label="Product Name"
        style={{ width: "100%" }}
      />
      <Field
        name={"product_display_name"}
        value={searchFilters.product_display_name}
        onChange={(value) => handleChange("product_display_name", value)}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        label="Product Display Name"
        style={{ width: "100%" }}
      />
      <Field
        className="rsuit-select-picker"
        name={"product_classification"}
        value={searchFilters.product_classification}
        onSelect={(value) => handleChange("product_classification", value)}
        onClean={() => {
          handleChange("product_classification", "");
        }}
        label="Type"
        size="sm"
        accepter={SelectPicker}
        data={data}
        searchable={false}
        placeholder="Select an option"
        style={{ width: "100%" }}
      />
      <div className="flex gap-2 ">
        <Button onClick={onResetHandle} style={refreshIconStyles}>
          <RefreshIcon width={22} className="text-white" />
        </Button>
        <Button style={searchButtonStyles} onClick={handleSubmit}>
          <SearchIcon size="3em" />
          Search
        </Button>
      </div>
    </Form>
  );
};

export const CustomDraftProductSearchFilter = ({
  searchFilters,
  setSearchFilters,
  handleSubmit,
  onResetHandle,
}) => {
  const formRef = useRef();

  const handleChange = (key, value) => {
    setSearchFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Form
      className="w-full p-2 grid grid-cols-1 md:grid-cols-5 gap-1 md:gap-2 justify-between items-center"
      ref={formRef}
      // onChange={handleChange}
      formValue={searchFilters}
    >
      <Field
        name={"product_name"}
        value={searchFilters.product_name}
        onChange={(value) => handleChange("product_name", value)}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        label="Product Name"
        style={{ width: "100%" }}
      />
      <Field
        name={"product_display_name"}
        value={searchFilters.product_display_name}
        onChange={(value) => handleChange("product_display_name", value)}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        label="Product Display Name"
        style={{ width: "100%" }}
      />
      <div className="flex gap-2 ">
        <Button onClick={onResetHandle} style={refreshIconStyles}>
          <RefreshIcon width={22} className="text-white" />
        </Button>
        <Button style={searchButtonStyles} onClick={handleSubmit}>
          <SearchIcon size="3em" />
          Search
        </Button>
      </div>
    </Form>
  );
};

export const CustomVendorSearchFilter = ({
  searchFilters,
  setSearchFilters,
  handleSubmit,
  onResetHandle,
}) => {
  const data = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Awaiting approval", value: "awaiting_approval" },
  ];
  const formRef = useRef();

  const handleChange = (key, value) => {
    setSearchFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Form
      className="w-full p-2 grid grid-cols-1 md:grid-cols-4 gap-1 md:gap-2 justify-between items-center"
      ref={formRef}
      // onChange={handleChange}
      formValue={searchFilters}
    >
      <Field
        name={"name"}
        value={searchFilters.name}
        onChange={(value) => handleChange("name", value)}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        label="Vendor Name"
        style={{ width: "100%" }}
      />
      <Field
        name={"vendor_code"}
        value={searchFilters.vendor_code}
        onChange={(value) => handleChange("vendor_code", value)}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        label="Vendor Code"
        style={{ width: "100%" }}
      />
      <Field
        className="rsuit-select-picker"
        name={"approved"}
        onSelect={(value) => handleChange("approved", value)}
        onClean={() => {
          handleChange("approved", "");
        }}
        value={searchFilters.approved}
        label="Type"
        size="sm"
        accepter={SelectPicker}
        data={data}
        searchable={false}
        placeholder="Select an option"
        style={{ width: "100%" }}
      />
      <div className="flex gap-2 ">
        <Button onClick={onResetHandle} style={refreshIconStyles}>
          <RefreshIcon width={22} className="text-white" />
        </Button>
        <Button style={searchButtonStyles} onClick={handleSubmit}>
          <SearchIcon size="3em" style={{ marginRight: "5px" }} />
          Search
        </Button>
      </div>
    </Form>
  );
};

export const CustomLabTestSearchFilter = ({
  searchFilters,
  setSearchFilters,
  handleSubmit,
  onResetHandle,
}) => {
  const data = [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Awaiting approval", value: "Inactive" },
  ];
  const formRef = useRef();

  const handleChange = (key, value) => {
    if (key == "approved") {
      if (value == "true") {
        value = true;
      } else if (value == "false") {
        value = false;
      }
    }
    setSearchFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Form
      className="w-full p-2 grid grid-cols-1 md:grid-cols-4 gap-1 md:gap-2 justify-between items-center"
      ref={formRef}
      // onChange={handleChange}
      formValue={searchFilters}
    >
      <Field
        name={"lab_test_name"}
        value={searchFilters.lab_test_name}
        onChange={(value) => handleChange("lab_test_name", value)}
        label="Lab-test Name"
        style={{ width: "100%" }}
        onPressEnter={(value) => {
          handleSubmit();
        }}
      />
      <Field
        name={"technical_name"}
        value={searchFilters.technical_name}
        onChange={(value) => handleChange("technical_name", value)}
        label="Technical Name"
        style={{ width: "100%" }}
        onPressEnter={(value) => {
          handleSubmit();
        }}
      />
      <Field
        className="rsuit-select-picker"
        name={"status"}
        onSelect={(value) => handleChange("status", value)}
        onClean={() => {
          handleChange("status", "");
        }}
        onPressEnter={(value) => {
          handleSubmit();
        }}
        value={`${searchFilters.status}` ? `${searchFilters.status}` : ""}
        label="Status"
        size="sm"
        accepter={SelectPicker}
        data={data}
        searchable={false}
        placeholder="Select an option"
        style={{ width: "100%" }}
      />
      <div className="flex gap-2 ">
        <Button onClick={onResetHandle} style={refreshIconStyles}>
          <RefreshIcon width={22} className="text-white" />
        </Button>
        <Button style={searchButtonStyles} onClick={handleSubmit}>
          <SearchIcon size="3em" style={{ marginRight: "5px" }} />
          Search
        </Button>
      </div>
    </Form>
  );
};
