import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetHeader from "../../../components/GetHeader";
import Button from "../../../components/common/Button";

const showRelation = (item) => {
  if (!item) return " ";
  item = item.replaceAll("_", " ");
  item = item.replaceAll("-", " ");
  return item;
};

const showDateOfBirth = (dob) => {
  if (!dob) return " ";
  return moment(dob).format("DD-MM-YYYY");
};

const ReimbursementExpandableComponent = ({ data, memberData = null }) => {
  const [expandedAccordion, setExpandedAccordion] = useState("member");
  const [selectedMember, setSelectedMember] = useState(memberData);
  const [selectedSubPolicy, setSelectedPolicy] = useState(null);
  const history = useHistory();

  const handleAccordionChange = (panel) => {
    setExpandedAccordion((prev) => (prev === panel ? null : panel));
  };

  const handleCheckboxChange = (item) => {
    setSelectedMember(item);
  };

  const handleSubPolicyCheckboxChange = (data) => {
    setSelectedPolicy(data);
  };

  const handleClick = () => {
    history.push({
      pathname: `/reimbursement/${selectedMember?.id}/${selectedSubPolicy?.parent_policy_id}`,
      subPolicy: selectedSubPolicy,
    });
  };

  useEffect(() => {
    if (
      data?.members?.length === 1 &&
      !memberData &&
      data?.members?.some((member) =>
        member?.sub_policy?.some(
          (item) => item?.product_claim_detail?.claim_partner?.type
        )
      )
    ) {
      setSelectedMember(data?.members?.[0]);
    }
  }, []);

  return (
    <>
      <Accordion
        expanded={expandedAccordion === "member"}
        onChange={() => handleAccordionChange("member")}
        sx={{
          outline: "none!important",
          boxShadow: "none!important",
          borderRadius: "24px!important",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          marginBottom: "20px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">Please select the member</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container gap={2} justifyContent="center">
            {data?.members &&
              data?.members?.length > 0 &&
              data?.members?.map((member, index) => (
                <Grid
                  item
                  xs={12}
                  md={5}
                  key={index}
                  className="card"
                  style={{ boxShadow: "2px 4px 4px 0px rgba(0, 0, 0, 0.04)" }}
                >
                  <div>
                    <button
                      onClick={() => {
                        if (
                          member?.sub_policy?.some(
                            (item) =>
                              item?.product_claim_detail?.claim_partner?.type
                          )
                        ) {
                          handleCheckboxChange(member);
                        }
                      }}
                      className={`flex items-center justify-center flex-col w-full ${
                        member?.sub_policy?.some(
                          (item) =>
                            item?.product_claim_detail?.claim_partner?.type
                        )
                          ? "cursor-pointer"
                          : "cursor-default"
                      }`}
                    >
                      <div className="flex items-start justify-start w-11/12 h-5">
                        {member?.sub_policy?.some(
                          (item) =>
                            item.product_claim_detail?.claim_partner?.type
                        ) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedMember?.id === member?.id}
                                onChange={(e) => e.stopPropagation()}
                                sx={{
                                  padding: 0,
                                  margin: 0,
                                  "&.Mui-checked": {
                                    borderRadius: "4px!important",
                                    outline: "none!important",
                                    boxShadow: "none!important",
                                  },
                                  "&:focus": {
                                    outline: "none!important",
                                    boxShadow: "none!important",
                                  },
                                }}
                              />
                            }
                          />
                        )}
                      </div>

                      <div className="relative flex items-center justify-center w-full h-12">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "20px",
                            height: "20px",
                            padding: "20px",
                            borderRadius: "50%",
                            background: "rgb(204, 204, 204)",
                          }}
                        >
                          {GetHeader(member, "30px")}
                        </div>
                      </div>
                      <p className="text-medium text-md capitalize mb-3">
                        {member?.name?.toLowerCase()}
                      </p>
                      <div className="w-full px-4 pb-3">
                        <p className="text-xs flex justify-between">
                          <span className="flex-1 text-left">Status</span>
                          <span className="w-1/12 text-center">:-</span>
                          <span className="flex-1 text-left capitalize">
                            {showRelation(member?.relation?.toLowerCase())}
                          </span>
                        </p>
                        <p className="text-xs flex justify-between">
                          <span className="flex-1 text-left">Gender</span>
                          <span className="w-1/12 text-center">:-</span>
                          <span className="flex-1 text-left capitalize">
                            {member?.gender?.toLowerCase()}
                          </span>
                        </p>
                        <p className="text-xs flex justify-between">
                          <span className="flex-1 text-left">
                            Date of birth
                          </span>
                          <span className="w-1/12 text-center">:-</span>
                          <span className="flex-1 text-left">
                            {showDateOfBirth(member?.dob)}
                          </span>
                        </p>
                      </div>
                    </button>
                  </div>
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {selectedMember?.sub_policy?.length > 0 && (
        <Accordion
          expanded={expandedAccordion === "reimbursement"}
          onChange={() => handleAccordionChange("reimbursement")}
          sx={{
            outline: "none!important",
            boxShadow: "none!important",
            borderRadius: "24px!important",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            marginBottom: "12px",
            "&:before": {
              backgroundColor: "transparent!important",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h6">
              Please select the reimbursement type
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container gap={1.5} justifyContent="space-between">
              <Grid item xs={12}>
                {selectedMember?.sub_policy?.map(
                  (item, index) =>
                    item.product_claim_detail?.claim_partner?.type && (
                      <div
                        key={index}
                        className="card mb-3"
                        style={{
                          boxShadow: "2px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <div>
                          <button
                            className="flex py-1 px-2 justify-start items-center w-full"
                            onClick={() => handleSubPolicyCheckboxChange(item)}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    selectedSubPolicy?.sub_policy_id ===
                                    item?.sub_policy_id
                                  }
                                  onChange={(e) => e.stopPropagation()}
                                  sx={{
                                    padding: "0!important",
                                    margin: "0!important",
                                    "&.Mui-checked": {
                                      padding: "0!important",
                                      margin: "0!important",
                                      borderRadius: "4px!important",
                                      outline: "none!important",
                                      boxShadow: "none!important",
                                    },
                                    "&:focus": {
                                      outline: "none!important",
                                      boxShadow: "none!important",
                                    },
                                  }}
                                />
                              }
                            />
                            <p className="-ml-2">
                              {item.product_claim_detail.claim_partner.type}
                            </p>
                          </button>
                        </div>
                      </div>
                    )
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      <div className="flex justify-end justify-items-end">
        <Button
          type="primary"
          onClick={handleClick}
          style={{ borderRadius: "12px", padding: "12px 32px" }}
          disabled={!(selectedMember && selectedSubPolicy)}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default ReimbursementExpandableComponent;
