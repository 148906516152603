import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { ApiGet, getApiClaim } from "../../lib/AppHelper";
import moment from "moment";
import { AppStore } from "../../lib/AppStore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const keyObj = {
  memberId: "Member Id",
  name: "Name",
  mobileNumber: "Mobile number",
  gender: "Gender",
  dob: "D.O.B",
  policyStartDate: "Policy start date",
  policyEndDate: "Policy end date",
};

const UserInformation = ({
  setValue,
  state,
  claimId,
  subClaimId,
  isMobile,
}) => {
  const [userInfo, setUserInfo] = useState([]);

  const fetchUserInfo = async (id) => {
    const response = await getApiClaim(`customerDetails/${id}`);
    if (response.code === 200) {
      let policyEndDate = moment(response?.data[0]?.policyEndDate).format(
        "DD-MM-YYYY"
      );
      let policyStartDate = moment(response?.data[0]?.policyStartDate).format(
        "DD-MM-YYYY"
      );
      setUserInfo(response.data);
      setValue({
        policyEndDate: policyEndDate,
        policyStartDate: policyStartDate,
      });
    } else {
      console.log("failed to fetch user");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const fetchProductSpecification = async (claimId, subClaimId) => {
    const response = await ApiGet(
      `claim?claim_id=${claimId}&sub_claim_id=${subClaimId}`
    );
    const { sub_policy_detail } = response.data;
    setValue({
      productSpecification:
        sub_policy_detail?.product_specification?.product_specification,
    });
  };

  function capitalizeFirstLetter(inputString) {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
  useEffect(() => {
    if (state?.memberId) {
      if (claimId && subClaimId) fetchProductSpecification(claimId, subClaimId);
      AppStore.dispatch({ type: "LOADING", loading: true });
      fetchUserInfo(state.memberId);
    }
  }, [state.memberId]);

  return userInfo.length > 0 ? (
    <>
      <Accordion
        defaultExpanded
        sx={{
          outline: "none!important",
          boxShadow: "none!important",
          borderRadius: "24px!important",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          margin: "40px 0 24px 0!important",
          padding: "0 40px!important",
          backgroundColor: "#FAFAFA",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: "0", margin: "0" }}
        >
          <Typography variant="h6"> Personal details</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0", margin: "0 0 20px 0" }}>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            {Object.keys(userInfo[0]).map((key) => (
              <div key={key} className="flex flex-col">
                <label className="mb-2 text-sm text-gray-700">
                  {keyObj[key]}
                </label>
                <input
                  type="text"
                  value={
                    key === "policyStartDate" || key === "policyEndDate"
                      ? moment(userInfo[0][key]?.split(" ")[0]).format(
                          "DD-MM-YYYY"
                        )
                      : key === "dob"
                      ? moment(userInfo[0][key]).format("DD-MM-YYYY")
                      : capitalizeFirstLetter(userInfo[0][key])
                  }
                  disabled
                  className="px-4 py-2 border border-gray-300 rounded-xl cursor-not-allowed mb-5"
                  style={{ color: "#575757", fontSize: "14px" }}
                />
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  ) : (
    <div
      style={{ height: "200px", display: "grid", placeItems: "center" }}
    ></div>
  );
};

export default UserInformation;
