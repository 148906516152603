import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import { connect } from "react-redux";

export class Index extends Component {
  state = {
    permissions: false,
  };
  render() {
    const { permissions } = this.props.user;
    return (
      <>
        <AppBackButton to="/">Back</AppBackButton>

        <Grid container spacing={2}>
          {permissions?.inventory ? (
            <Grid item xs={6} md={3}>
              <Link to="/inventory-stock-details">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Inventory Stock Details</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.inventory_purchase ? (
            <Grid item xs={6} md={3}>
              <Link to="/inventory-purchase">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Purchase</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
          {permissions?.inventory_transfer ? (
            <Grid item xs={6} md={3}>
              <Link to="/inventory-transfer">
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>Transfer</p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}

          {permissions?.inventory_stock_details ? (
            <Grid item xs={6} md={3}>
              <Link
                to={{
                  pathname: "/inventory-details",
                  state: { componentTo: "/inventory-transfer" },
                }}
              >
                <Card style={styles.parentDiv}>
                  <CardContent>
                    <p style={{ fontSize: 14 }}>
                      Medicine Inventory Stock Details
                    </p>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            false
          )}
        </Grid>
      </>
    );
  }
}

const styles = {
  parentDiv: {
    borderRadius: "10px",
    height: "100px",
    border: "1px solid red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const InventoryConnect = connect(mapStateToProps)((props) => {
  return <Index {...props} />;
});

export default withRouter(InventoryConnect);
