import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import React, { useState } from "react";
import InputLabel from "../form/InputLabel";

const InputRadio = ({
  error = "",
  label = "",
  requiredMark = "",
  style = {},
  options = [],
  value = "",
  name = "",
  onChange = () => {},
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative -mt-8">
      {open && error && (
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className="bg-red-600 -top-10 absolute text-white text-sm p-3 rounded-md flex items-start gap-2"
        >
          <span>{error}</span>
        </div>
      )}

      <div className="flex gap-1 items-center">
        {error && (
          <FontAwesomeIcon
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            icon={faExclamationCircle}
            className="text-red-500"
          />
        )}
        <InputLabel label={label} requiredMark={requiredMark} style={style} />
      </div>
      <div className="flex gap-3 items-center mt-2 flex-wrap">
        {options.map((elem) => (
          <div className="flex gap-2 items-center">
            <Field
              onChange={onChange}
              type="radio"
              name={name}
              value={elem.value}
              checked={value === elem.value}
            />
            <label className="text-gray-700 text-base">{elem.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputRadio;
