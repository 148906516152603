import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { ApiGet, downloadExcelFile } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { Pagination } from "rsuite";
import { RefreshIcon } from "@heroicons/react/solid";

export class PolicyExpireReport extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    count: {
      policy_count: 0,
      policy_amount: 0,
    },
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      status: "",
      page: 1,
      count: 10,
      employee_id: "",
    },
    employees: [],
    toMaxDate: new Date(),
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["status"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, employees } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    return (
      <>
        {permissions && permissions?.policy_expire_report ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "20%" }} className="float-right">
                  <Button
                    attrs={{ type: "button", onClick: this.downloadClick }}
                    size="sm"
                    title={"Download"}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 gap-4">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>

              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="sm"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    {reports ? (
                      <table
                        className="min-w-full table-auto"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <td>
                              {reports.map((item, index) => {
                                return (
                                  <table
                                    className="min-w-full border"
                                    key={index}
                                  >
                                    <thead className="border-b border-r">
                                      <tr className="bg-red-300">
                                        <th
                                          colSpan="6"
                                          scope="col"
                                          className="text-sm font-large capitalize  text-white px-6 py-4 text-center border-r"
                                        >
                                          {item.reseller.reseller_name}
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr>
                                        <td>
                                          <table className="min-w-full">
                                            <thead className="border-b">
                                              <tr>
                                                <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Name
                                                </th>
                                                <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Mobile
                                                </th>
                                                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Amount
                                                </th>
                                                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Start Date
                                                </th>
                                                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                  End Date
                                                </th>
                                                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Product
                                                </th>
                                                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Member ID
                                                </th>
                                                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                  Loan Code/Reference Number
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item.records.map(
                                                (record, index) => {
                                                  return (
                                                    <tr
                                                      className="border-b"
                                                      key={index}
                                                    >
                                                      <td
                                                        className="text-sm capitalize font-bigger px-6 py-4 whitespace-nowrap"
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        <Link
                                                          to={`/subscription/${record.application_id}`}
                                                        >
                                                          {record.customer_name}
                                                        </Link>
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {record.mobile}
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {record.amount}
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {moment(
                                                          record.start_date
                                                        ).format(
                                                          "DD, MMM YYYY"
                                                        )}
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {moment(
                                                          record.end_date
                                                        ).format(
                                                          "DD, MMM YYYY"
                                                        )}
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {
                                                          record.product_display_name
                                                        }
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {
                                                          record.insured_member_id
                                                        }
                                                      </td>
                                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {record.loan_code ??
                                                          "-"}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                );
                              })}
                            </td>
                          </tr>
                        </thead>
                      </table>
                    ) : (
                      false
                    )}
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.params.page}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You don't have permission to view policy expire Report</h4>
            </div>
          </div>
        )}
      </>
    );
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  componentDidMount() {
    this.getReports();
    this.getTeam();
  }

  handleClick = () => {
    this.onPageChange(1);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  downloadClick = () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      status: this.state.params.status,
      reportType: "download",
      employee_id: this.state.params.employee_id,
    };
    this.DownloadReports(payload);
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadExcelFile(
      "report/policy-expire",
      request,
      "PolicyExpireReport.xlsx"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getReports = async (request) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/policy-expire", request);
    if (response.status === "success") {
      if (response?.data?.items) {
        const items = Object.values(response.data.items);
        this.setState({ reports: items });
        this.setState({ count: response.data });
        this.setState({ total: response.data.total });
      } else {
        this.state.reports = [];
      }
    } else {
      // this.MessageRef.current.show(response.message);
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const PolicyExpireReportConnect = connect(mapStateToProps)((props) => {
  return <PolicyExpireReport {...props} />;
});

export default withRouter(PolicyExpireReportConnect);
