import React, { useEffect, useRef, useState } from "react";
import { Button } from "rsuite";
import CustomAccordian from "../../common/components/CustomAccordian";
import { Formik, Form } from "formik";
import BasicDetails from "../../../../assets/productCreation/forms.png";
import SubProductsIcon from "../../../../assets/productCreation/subPolicy.png";
import LabTestIcon from "../../../../assets/productCreation/newLabTestIcon.png";
import AddOnBenefitsIcon from "../../../../assets/productCreation/offer.png";
import Benefits from "../../../../assets/productCreation/benefitIcon.png";
import { ProductBasicInfo } from "./BasicInfo";
import { ProductBenefits } from "./ProductBenefits";
import { SubProducts } from "./SubProduct/SubProduct";
import LabTestsInfo from "./LabTestsInfo";
import ProductAddOnBenefitsInfo from "./ProductAddOnBenefitsInfo";
import {
  addProductButtonStyles,
  cleanPayload,
  getFirstError,
  productCreationInitialValues,
  productCreationValidationSchema,
  saveToDraftsButtonStyles,
  validateSubProductsSelection,
  validateSubProductsTenure,
} from "../../common/data";
import { ApiGet, ApiPost } from "../../../../lib/AppHelper";
import { toast } from "react-toastify";
import { PlusIcon } from "@heroicons/react/outline";
import { cloneDeep } from "lodash";

const ProductCreate = ({
  draftId = null,
  draftProductData = {},
  setIsModalOpen,
  fetchData,
}) => {
  const [addOnBenefits, setAddOnBenefits] = useState();
  const [productBenefits, setProductBenefit] = useState();
  const [loading, setLoading] = useState(false);
  const [initalLoading, setInitialLoading] = useState(false);
  const [benefitLoading, setBenefitLoading] = useState();
  const [finalLoading, setFinalLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);

  const fetchProductAddonBenefits = async () => {
    setInitialLoading(true);
    const response = await ApiGet(`admin/vendor-add-on-benefit`);
    if (response.code == 200) {
      const data = response?.data?.map((item, index) => {
        return {
          label: item.label,
          value: item.slug,
          vendor: [],
        };
      });
      let load = {};
      response?.data?.map((item) => {
        load[item?.slug] = false;
      });

      setBenefitLoading(load);
      setAddOnBenefits(data);
    }
    setInitialLoading(false);
  };

  const fetchProductBenefits = async () => {
    setLoading(true);
    const response = await ApiGet("admin/get-product-benefit");
    if (response.code == 200) {
      setProductBenefit(response?.data);
    }
    setLoading(false);
  };

  const addToDraft = async (values) => {
    let data;
    if (draftId) {
      data = { ...values, draftId: draftId };
    } else {
      data = values;
    }
    setDraftLoading(true);
    const response = await ApiPost("admin/add-product-in-draft", data);
    if (response?.status === "success") {
      toast.success("Saved to drafts successfully");
      setIsModalOpen(false);
      fetchData();
    } else {
      toast.error(response?.message || "Something went wrong");
    }
    setDraftLoading(false);
  };

  const addProduct = async (values, setSubmitting) => {
    let payload = draftId
      ? cleanPayload(values)
      : cleanPayload(cloneDeep(values));
    if (draftId) {
      payload = { ...values, draftId: draftId };
    }

    setFinalLoading(true);
    const response = await ApiPost("admin/add-product", payload);
    if (response?.status === "success") {
      toast.success("Product created successfully");
      setIsModalOpen(false);
      fetchData();
    } else {
      setSubmitting(false);
      toast.error(response?.message || "Something went wrong");
    }
    setFinalLoading(false);
  };

  useEffect(() => {
    fetchProductAddonBenefits();
    fetchProductBenefits();
  }, []);

  return (
    <div className="bg-modalBg">
      <Formik
        validationSchema={productCreationValidationSchema}
        initialValues={
          Object.keys(draftProductData)?.length
            ? draftProductData
            : productCreationInitialValues
        }
        onSubmit={(values, { setSubmitting }) => {
          addProduct(values, setSubmitting);
        }}
        validateOnMount={false} // Prevent errors on mount
        validateOnBlur={true}
        validate={validateSubProductsTenure}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
          validateField,
          validateForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <CustomAccordian
              active={true}
              header={
                <>
                  <div className="flex items-center gap-2 ">
                    <img
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                      src={BasicDetails}
                    />
                    <div>
                      <p className="text-sm font-bold">
                        Basic Information
                        <span className="text-lg text-red-500">*</span>
                      </p>
                      <p
                        style={{
                          color: "black",
                          lineHeight: "1.6",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Enter the essential details of the product, such as its
                        name, tenure, category, and description. Ensure all
                        mandatory fields marked with * are completed accurately.
                      </p>
                    </div>
                  </div>
                </>
              }
              children={
                <ProductBasicInfo
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                  validateField={validateField}
                />
              }
            />
            <CustomAccordian
              active={true}
              header={
                <>
                  <div className="flex items-center gap-2 ">
                    <img
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                      src={SubProductsIcon}
                    />
                    <div>
                      <p className="text-sm font-bold">
                        {" "}
                        Sub Products{" "}
                        <span className="text-lg text-red-500">*</span>
                      </p>
                      <p
                        style={{
                          fontSize: "0.7rem",
                          color: "black",
                          lineHeight: "1.6",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Add one or more policies that apply specifically to this
                        product. You can add multiple policies to define the
                        product's terms and conditions clearly.
                      </p>
                    </div>
                  </div>
                </>
              }
              children={
                <SubProducts
                  allValue={values}
                  values={values?.subProducts || []}
                  setFieldValue={setFieldValue}
                  errors={errors?.subProducts}
                  touched={touched?.subProducts}
                  setFieldTouched={(key) =>
                    setFieldTouched(`subProducts.${key}`, true, false)
                  }
                />
              }
            />
            <CustomAccordian
              active={true}
              header={
                <>
                  <div className="flex items-center gap-2 ">
                    <img
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                      src={LabTestIcon}
                    />
                    <div>
                      <p className="text-sm font-bold">Lab Tests</p>
                      <p
                        style={{
                          fontSize: "0.7rem",
                          color: "black",
                          lineHeight: "1.6",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Select a single lab test to associate with this product.
                        Ensure the selected test aligns with the product’s
                        purpose.
                      </p>
                    </div>
                  </div>
                </>
              }
              children={
                <LabTestsInfo
                  values={values?.labTestID}
                  setFieldValue={setFieldValue}
                />
              }
            />
            <CustomAccordian
              active={true}
              header={
                <>
                  <div className="flex items-center gap-2 ">
                    <img
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                      src={AddOnBenefitsIcon}
                    />
                    <div>
                      <p className="text-sm font-bold">
                        Product add-on benefits
                      </p>
                      <p
                        style={{
                          fontSize: "0.7rem",
                          color: "black",
                          lineHeight: "1.6",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Select additional benefits offered with the product,
                        such as special services from vendors.Browse the
                        available options and check the benefits you want to
                        include (e.g., Ambulance on Call). Add multiple benefits
                        to enhance the product’s value.
                      </p>
                    </div>
                  </div>
                </>
              }
              children={
                <ProductAddOnBenefitsInfo
                  values={values?.vendorProductAddOnBenefit}
                  addOnBenefits={addOnBenefits}
                  setAddOnBenefits={setAddOnBenefits}
                  initalLoading={initalLoading}
                  benefitLoading={benefitLoading}
                  setFieldValue={(key, value) => {
                    setFieldValue(`vendorProductAddOnBenefit.${key}`, value);
                  }}
                  errors={errors.vendorProductAddOnBenefit}
                  touched={touched?.vendorProductAddOnBenefit}
                  setFieldTouched={(key) =>
                    setFieldTouched(
                      `vendorProductAddOnBenefit.${key}`,
                      true,
                      false
                    )
                  }
                />
              }
            />
            <CustomAccordian
              active={true}
              header={
                <>
                  <div className="flex items-center gap-2 ">
                    <img
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                      src={Benefits}
                    />
                    <div>
                      <p className="text-sm font-bold">Product Benefits</p>
                      <p
                        style={{
                          fontSize: "0.7rem",
                          color: "black",
                          lineHeight: "1.6",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Choose the benefits you want displayed on the invoice
                        for this product. Highlight key benefits to ensure
                        customers see what they’re getting. Select only the most
                        important and relevant benefits for clarity.
                      </p>
                    </div>
                  </div>
                </>
              }
              children={
                <ProductBenefits
                  values={values?.productBenefitId}
                  setFieldValue={(key, value) =>
                    setFieldValue(`productBenefitId.${key}`, value)
                  }
                  loading={loading}
                  productBenefits={productBenefits}
                />
              }
            />
            <div className="flex gap-2 justify-end mb-2">
              <Button
                style={saveToDraftsButtonStyles}
                type="button"
                loading={draftLoading}
                onClick={(e) => {
                  e.preventDefault();
                  addToDraft(values);
                }}
              >
                <PlusIcon style={{ marginRight: 3, alignSelf: "center" }} />
                Save to Drafts
              </Button>

              <Button
                loading={finalLoading}
                style={addProductButtonStyles}
                type="button"
                onClick={async () => {
                  const myError = await validateForm();
                  if (Object.keys(myError).length > 0) {
                    const firstError = getFirstError(errors);
                    if (firstError) toast.error(firstError); // Show first error toast
                    return; // Stop submission
                  }
                  if (validateSubProductsSelection(values.subProducts)) {
                    toast.error("Please select at least one sub product.");
                  } else {
                    handleSubmit();
                  }
                }}
              >
                <PlusIcon style={{ marginRight: 3, alignSelf: "center" }} />
                Create Product
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductCreate;
